import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { token } from 'services/LocalStorage/localStorage';
import { useQuery } from 'react-query';
import { QueryStateValue } from 'hooks/useQueryState';
import { QueryKeys } from 'api/queryKeys';

const queryFn = (params: QueryParams) => {
    const uuid = params.uuid;
    const url = `${API_URL().GET_ACCESS_TOKEN}?uuid=${uuid}`;
    return network.post(url, params).then((response) => {
        token.set(response.data.attribute);
        network.defaults.headers.common['Authorization'] = `Bearer ${response.data.attribute}`;
        return {
            username: response.data.username,
        };
    });
};
export const useQueryGetAccessToken = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['getAccessToken', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.uuid),
    });
};

interface QueryParams {
    uuid: QueryStateValue;
}

interface QueryResponse {
    username: string;
}
