import { lowerCase, startCase } from 'lodash';

export const initialState = {
    sortBy: [
        {
            id: 'resourceName',
            desc: false,
        },
    ],
};

export const columns = [
    {
        Header: 'Resource Name',
        accessor: 'resourceName',
        sortType: 'basic',
    },
    {
        Header: 'Description',
        accessor: 'resourceDescription',
    },
    {
        Header: 'Resource Type',
        accessor: (row) => startCase(lowerCase(row.resourceType)),
        width: 80,
    },
    {
        Header: 'Resource Budget',
        accessor: 'resourceUnit',
        width: 90,
    },
    {
        Header: 'Resource Actual',
        accessor: 'resourceActualUnit',
        width: 90,
    },
    {
        Header: 'Resource Remaining',
        accessor: 'resourceRemainingUnit',
        width: 90,
    },
    {
        Header: 'Unit of Measure',
        accessor: 'resourceMeasure',
        width: 90,
    },
];
