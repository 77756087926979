import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';

const mutationFn = (params: QueryParams) => {
    return network.post(API_URL().FORCE_USER_LOGOUT, params).then((response) => {
        return response.data;
    });
};

export const useMutationForceUserLogout = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    username: string;
}
