import { useMutation } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { Range } from 'components/common/GlobalFilterComponent/components/RangeFilter/rangeFilter';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { ResourceType } from 'components/common/GlobalFilterComponent/components/resourceTypeFilter/resourceTypeFilter';
import { Aggregation } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourcesAnalysisChart/resourcesAnalysisChart.utils';
import { QuickAnswerFilter } from 'components/common/GlobalFilterComponent/components/quickAnswerFilter/quickAnswerFilter';

const mutationFn = (params: QueryParams) => {
    return network.post(API_URL().EXTRACT_RESOURCES_CHART_BAR_TO_CSV, params).then((response) => {
        const blob = new Blob([response.data], {
            type: 'text/plain',
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${params.fileName}.csv`;
        a.click();

        return true;
    });
};

export const useMutationExtractResourceChartBarToCsv = () => {
    return useMutation<unknown, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    metaDataId: number | undefined;
    comparedMetaDataId: number | undefined;
    range: Range | undefined;
    resourceId: number | null;
    startDate: number | null;
    endDate: number | null;
    filter: {
        filters: IFilter[];
    };
    resourceType?: ResourceType;
    aggregation: Aggregation;
    quickAnswerFilter?: QuickAnswerFilter;
    fileName?: string;
}
