import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/Tags/components/tags.module.scss';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { CustomizedButton, CustomizedFormErrorMessage, MyCustomSelect } from 'components/common';
import useUserHook from 'hooks/useUserHook';
import { ITagsForm } from 'components/Settings/Components/Tags/tags.utils.type';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useHistory } from 'react-router-dom';
import { useMutationCreateTag } from 'api/mutations/createTag.mutation';
import { useProjectTreeStore } from 'store/projectContract.store';
import React from 'react';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import { useQueryGetProgramGeneralHierarchyList } from 'api/queries/getProgramGeneralHierarchyList.query';
import { convertHierarchyListToSelectOptions } from 'components/Settings/Components/Tags/tags.utils';

export const TagsCreate = () => {
    const { customerId } = useUserHook();
    const { project } = useProjectTreeStore();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm<ITagsForm>({
        defaultValues: {
            levelType: 'Project',
            level: {
                value: project?.id ?? -1,
                label: project?.title ?? '',
            },
        },
    });
    const { mutate, isLoading } = useMutationCreateTag();
    const { handleSnackBar } = useCustomSnackBar();
    const history = useHistory();

    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('ProgramLibraryUploadFiles');

    const userProjects = convertHierarchyListToSelectOptions(projectHierarchyList);

    const handleSave = (data: ITagsForm) => {
        const projectId = data.levelType === 'Portfolio' ? -1 : data.level.value;
        if (!customerId || !projectId) return null;
        mutate(
            {
                customerId,
                name: data.name.trim(),
                projectId,
            },
            {
                onSuccess: () => {
                    handleSnackBar('Successfully created!', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error.response.data || 'Something went wrong, please try again', 'error');
                },
                onSettled: () => {
                    history.goBack();
                },
            },
        );
    };

    const handleCancel = () => {
        history.goBack();
    };

    return (
        <WidgetWithTitle title={`New Tag`}>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className={classes.container}>
                    <div className={`${classes.row} ${classes.rowAlignStart}`}>
                        <label className={classes.label}>Level</label>
                        <div className={`${classes.inputWrapper} ${classes.projectOptions}`}>
                            <Controller
                                render={({ field }) => (
                                    <>
                                        <label className={classes.radioButtonLabel}>
                                            <RadioComponent
                                                {...field}
                                                value={'Portfolio'}
                                                checked={field.value === 'Portfolio'}
                                                name="level-option"
                                            />
                                            Portfolio
                                        </label>
                                        <div className={classes.projectSelection}>
                                            <label className={classes.radioButtonLabel}>
                                                <RadioComponent
                                                    {...field}
                                                    value={'Project'}
                                                    checked={field.value === 'Project'}
                                                    name="level-option"
                                                />
                                                Project{' '}
                                            </label>
                                            <Controller
                                                render={({ field }) => (
                                                    <MyCustomSelect<ISelectOption<number>>
                                                        {...field}
                                                        options={userProjects}
                                                        isDisabled={getValues('levelType') === 'Portfolio'}
                                                    />
                                                )}
                                                name="level"
                                                control={control}
                                            />
                                        </div>
                                    </>
                                )}
                                name="levelType"
                                control={control}
                            />
                        </div>
                    </div>
                    <div className={classes.row}>
                        <label htmlFor={'tags-name'} className={classes.label}>
                            Tag
                        </label>
                        <div className={classes.inputWrapper}>
                            <Controller
                                render={({ field }) => (
                                    <TextSearchInput
                                        {...field}
                                        id={'tags-name'}
                                        name={'tags-name'}
                                        isSearch={false}
                                        className={classes.input}
                                        placeholder="Enter tag..."
                                        maxLength={60}
                                        handleClear={() => {
                                            setValue('name', '');
                                        }}
                                    />
                                )}
                                defaultValue={''}
                                name="name"
                                control={control}
                                rules={{
                                    validate: {
                                        min: (v) => v.length >= 2 || 'must be at least 2 characters',
                                        max: (v) => v.length < 60 || 'must be no more than 60 characters',
                                    },
                                }}
                            />
                            {errors.name && <CustomizedFormErrorMessage text={errors.name.message} />}
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.actionWrapper}>
                            <CustomizedButton type="submit" isLoading={isLoading} disabled={isLoading}>
                                Save
                            </CustomizedButton>
                            <CustomizedButton onClick={handleCancel} color={'secondary'}>
                                Cancel
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
            </form>
        </WidgetWithTitle>
    );
};
