import { VersionSummaryItemStatus } from '../../programStatusSummaryReportTypes';
import colorsVars from 'styles/colors.module.scss';
import { TColors } from 'components/common/WidgetWithCards/WidgetCard/CardItem/ScoreCardTooltip/CardSlider/CardSlider';
import { GraphPoints } from 'api/queries/getProjectStatusScoreCards.query';
import { useVersionStore } from 'store/version.store';
import { formatValueForGraph } from 'utilitys/helpers/general';
import { capitalizeFirstLetter } from 'components/Dashboards/Portfolio/PortfolioOverview/helper';

const {
    statusSummaryGreen,
    statusSummaryRed,
    statusSummaryOrange,
    white,
    statusSummaryGreenGraph,
    statusSummaryRedGraph,
    statusSummaryOrangeGraph,
    statusSummaryGreenGraphLight,
    statusSummaryRedGraphLight,
    statusSummaryOrangeGraphLight,
    graphAnchorBorderColor,
    cardGraphBackGroundDark,
    cardGraphFont,
} = colorsVars;
export const getColorByStatus = ({ status, theme }: { status: string; theme: boolean }): string => {
    switch (status) {
        case VersionSummaryItemStatus.low:
            return theme ? statusSummaryRedGraph : statusSummaryRedGraphLight;
        case VersionSummaryItemStatus.medium:
            return theme ? statusSummaryOrangeGraph : statusSummaryOrangeGraphLight;
        case VersionSummaryItemStatus.high:
            return theme ? statusSummaryGreenGraph : statusSummaryGreenGraphLight;
        default:
            return 'white';
    }
};

export const getMarkerColor = (value, threshold) => {
    if (value >= threshold.highThreshold) {
        return statusSummaryGreen;
    }
    if (value <= threshold.mediumThreshold) {
        return statusSummaryRed;
    }
    if (value > threshold.mediumThreshold && value < threshold.highThreshold) {
        return statusSummaryOrange;
    }
    return white;
};

export const getSegmentColor = (mediumThreshold: number, highThreshold: number, sliderValue: number): TColors => {
    if (sliderValue <= mediumThreshold) {
        return 'red';
    }
    if (sliderValue > highThreshold) {
        return 'green';
    }
    return 'orange';
};

interface serializeDataProps {
    data: {
        name: string;
        graphPoints: GraphPoints[];
    };
    themeStatus: boolean;
}

export const serializeData = ({
    data,
    themeStatus,
}: serializeDataProps): ApexAxisChartSeries | ApexNonAxisChartSeries | undefined => {
    const versionName = useVersionStore.getState().version?.versionName;
    return [
        {
            name: capitalizeFirstLetter(data.name),
            data: data.graphPoints.map((item, index) => ({
                x: index + 1,
                y: item.score,
                fillColor: getColorByStatus({ status: item.status, theme: themeStatus }),
                strokeColor:
                    versionName === item.versionName ? graphAnchorBorderColor : themeStatus ? white : cardGraphFont,
            })),
            color: themeStatus ? white : cardGraphFont,
        },
    ];
};

interface serializeOptionsProps {
    data: {
        name: string;
        graphPoints: GraphPoints[];
    };
    themeStatus: boolean;
    xaxisTitle: string;
    yaxisTitle: string;
    yAxisMaxValue: number | undefined;
}

export const serializeOptions = ({
    data,
    themeStatus,
    xaxisTitle,
    yaxisTitle,
    yAxisMaxValue,
}: serializeOptionsProps): ApexCharts.ApexOptions => {
    const categories = data.graphPoints.map((item) => item.versionName);

    return {
        chart: {
            background: themeStatus ? cardGraphBackGroundDark : white,
            foreColor: cardGraphFont,
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            type: 'category',
            categories: categories,
            labels: {
                show: false,
            },
            title: {
                text: xaxisTitle,
                offsetX: 0,
                offsetY: -15,
                style: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                },
            },
        },
        stroke: {
            width: 2,
        },
        yaxis: {
            min: 0,
            max: yAxisMaxValue,
            tickAmount: 4,
            labels: {
                style: {
                    fontSize: '1.2rem',
                },
                formatter: (value) => {
                    return formatValueForGraph(value);
                },
            },
            title: {
                text: yaxisTitle,
                style: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                },
            },
        },
        markers: {
            size: 5,
        },
        tooltip: {
            theme: themeStatus ? 'dark' : 'light',
        },
    };
};
