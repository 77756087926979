import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import GuardedRoute from '../common/GuardedRoute/guardedRoute';
import NoPermission from './Components/Common/NoPermission/noPermission';
import { UsersManagementPage } from 'components/Settings/Components/UsersManagement/usersManagementPage';
import { Responsive } from 'components/Dashboards/responsive';
import useUserHook from 'hooks/useUserHook';
import { UserGroupsPage } from 'components/Settings/Components/UserGroups/userGroupsPage';
import { UserGroupsEditPage } from 'components/Settings/Components/UserGroups/userGroupsEditPage';
import { UserGroupsCreatePage } from 'components/Settings/Components/UserGroups/userGroupsCreatePage';
import { settingsConfig } from 'components/Settings/settingsConfig';
import RolesManagementPage from 'components/Settings/Components/RolesManagement/rolesManagementPage';
import AddRolePage from 'components/Settings/Components/RolesManagement/addRolePage';
import PageNotFound from 'components/ErrorPages/pageNotFound';
import { TagsPage } from 'components/Settings/Components/Tags/tagsPage';
import { TagsEditPage } from 'components/Settings/Components/Tags/tagsEditPage';
import { TagsCreatePage } from 'components/Settings/Components/Tags/tagsCreatePage';
import { NewProfilePage } from 'components/Settings/Components/UserProfile/newProfilePage';
import { EditProfilePage } from 'components/Settings/Components/UserProfile/editProfilePage';
import { MyProfilePage } from 'components/Settings/Components/UserProfile/myProfilePage';
import { SideMenuListProps } from 'components/common/SideMenu/SideMenu';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

const sideMenuList: SideMenuListProps[] = [
    {
        ...settingsConfig.userProfile,
        id: 'accountAdministrationSettingsNavBar_UserProfile',
        muiIcon: <MuiIcon icon={'manage_accounts'} type={'outlined'} fontSize="2.5rem" color="#9eadc1" />,
    },
    {
        ...settingsConfig.userManagement,
        id: 'accountAdministrationSettingsNavBar_UsersManagement',
        muiIcon: <MuiIcon icon={'people_alt'} type={'outlined'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'UserManagement',
    },
    {
        ...settingsConfig.userGroups,
        id: 'accountAdministrationSettingsNavBar_UserGroups',
        muiIcon: <MuiIcon icon={'groups'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'UserManagement',
    },
    {
        ...settingsConfig.roleManagement,
        id: 'accountAdministrationSettingsNavBar_RolesManagement',
        muiIcon: <MuiIcon icon={'local_police'} type={'outlined'} fontSize="2.5rem" color="#9eadc1" />,
        permissions: 'RolesManagement',
    },
    {
        ...settingsConfig.tags,
        id: 'accountAdministrationSettingsNavBar_Tags',
        muiIcon: <MuiIcon icon={'local_offer'} fontSize="2.5rem" color="#9eadc1" />,
    },
];

const Settings = () => {
    const { path } = useRouteMatch();
    const { ability, isSwitchUser } = useUserHook();
    const UserManagement = ability.can('view', 'UserManagement');
    const ManageProjects = ability.can('view', 'ManageProjects');
    const RolesManagement = ability.can('view', 'RolesManagement');
    return (
        <Responsive sideMenu={sideMenuList} title={'Settings'}>
            <Switch>
                <Route exact path={`${path}/${settingsConfig.userProfile.link}`} component={MyProfilePage} />
                <GuardedRoute
                    path={`${path}/${settingsConfig.userManagement.link}/${settingsConfig.userManagementNewUser.link}`}
                    component={NewProfilePage}
                    auth={UserManagement}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={`${path}/${settingsConfig.userManagement.link}/${settingsConfig.userManagementEditUser.link}/:email`}
                    component={EditProfilePage}
                    auth={UserManagement || isSwitchUser}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={`${path}/${settingsConfig.userManagement.link}`}
                    component={UsersManagementPage}
                    auth={UserManagement}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={`${path}/${settingsConfig.userGroups.link}/${settingsConfig.userGroupsNew.link}`}
                    component={UserGroupsCreatePage}
                    auth={UserManagement}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={`${path}/${settingsConfig.userGroups.link}/${settingsConfig.userGroupsEdit.link}/:userGroupId`}
                    component={UserGroupsEditPage}
                    auth={UserManagement}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={`${path}/${settingsConfig.userGroups.link}`}
                    component={UserGroupsPage}
                    auth={UserManagement}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={[
                        `${path}/${settingsConfig.roleManagement.link}/${settingsConfig.roleManagementNewRole.link}`,
                        `${path}/${settingsConfig.roleManagement.link}/${settingsConfig.roleManagementEditRole.link}/:roleId`,
                    ]}
                    component={AddRolePage}
                    auth={RolesManagement}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={`${path}/${settingsConfig.roleManagement.link}`}
                    component={RolesManagementPage}
                    auth={RolesManagement}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={`${path}/${settingsConfig.tags.link}/${settingsConfig.tagsNew.link}`}
                    component={TagsCreatePage}
                    auth={ManageProjects}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={`${path}/${settingsConfig.tags.link}/${settingsConfig.tagsEdit.link}/:tagId`}
                    component={TagsEditPage}
                    auth={ManageProjects}
                    redirect={`${path}/no-permission`}
                />
                <GuardedRoute
                    path={`${path}/${settingsConfig.tags.link}`}
                    component={TagsPage}
                    auth={ManageProjects}
                    redirect={`${path}/no-permission`}
                />

                <Route exact path={`${path}/no-permission`} component={NoPermission} />

                <Route exact path={`${path}`}>
                    <Redirect to={`${path}/${settingsConfig.userProfile.link}`} />
                </Route>
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Responsive>
    );
};
export default Settings;
