import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { QueryKeys } from 'api/queryKeys';
import { TPreferredSPIMethodO } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferences.utils';

const queryFn = (params: QueryParams) => {
    return network.post(API_URL().GET_PREFFERED_SPI_METHOD, params).then((response) => response.data);
};

export const useQueryGetPrefferedSPIMethod = (params: QueryParams) => {
    return useQuery<TPreferredSPIMethodO, QueryError, TPreferredSPIMethodO, [QueryKeys, unknown]>({
        queryKey: ['getPrefferedSPIMethod', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.projectId),
    });
};

interface QueryParams {
    projectId?: number;
}
