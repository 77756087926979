import { create, StateCreator } from 'zustand';
import { Unionize } from 'utilitys/helpers/TsGeneral';
import { aggregationOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityRate/productivityRate.utils';

export type Aggregation = 'P50' | 'P80' | 'AVERAGE';

interface Props {
    aggregation: ISelectOption<Aggregation> | null;
    selectedStartDate: number | null;
    selectedEndDate: number | null;
    selectedSeries: string | null;
    selectedValue: string | null;
    dataPointIndex: number | null;
    seriesIndex: number | null;
    update: (value: Unionize<Omit<Props, 'update' | 'reset'>>) => void;
    reset: () => void;
}

const state: StateCreator<Props> = (set) => ({
    selectedStartDate: null,
    selectedEndDate: null,
    selectedSeries: null,
    selectedValue: null,
    aggregation: aggregationOptions.find((item) => item.value === 'P50') || null,
    dataPointIndex: null,
    seriesIndex: null,
    update: (value) => set({ ...value }),
    reset: () => set({ selectedStartDate: null, selectedEndDate: null, selectedSeries: null }),
});

export const useProductivityRateStore = create(state);
