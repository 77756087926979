import { IComponentProps, IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { useVersionStore } from 'store/version.store';
import React, { useEffect, useMemo } from 'react';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import localClasses from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityRate/productivityRate.module.scss';
import { BarChart } from 'components/common/charts/barChart/barChart';
import { useQueryGetResourceChart } from 'api/queries/getResourceChart.query';
import {
    getChartConfig,
    serialize,
    categoriesKeys,
    aggregationOptions,
    Aggregation,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourcesAnalysisChart/resourcesAnalysisChart.utils';
import { getStringWithoutHash } from 'utilitys/helpers/general';
import { useResourceAnalysisStore } from 'store/resourceAnalysis.store';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { rangeOptions } from 'components/common/GlobalFilterComponent/components/RangeFilter/rangeFilter';
import { Boxes } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourcesAnalysisChart/components/boxes';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/style/table.module.scss';
import { MyCustomSelect } from 'components/common';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { resourceTypeOptions } from 'components/common/GlobalFilterComponent/components/resourceTypeFilter/resourceTypeFilter';
import { quickAnswerFilterOptions } from 'components/common/GlobalFilterComponent/components/quickAnswerFilter/quickAnswerFilter';
import { useMutationExtractResourceChartBarToCsv } from 'api/mutations/extractResourceChartBarToCsv.mutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useQueryGetProjectDatesForResourceChart } from 'api/queries/getProjectDatesForResourceChart.query';
import { getInputLabel } from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.utils';

export const ResourcesAnalysisChart = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    globalFilterData,
    localFilters,
    externalComponents = null,
    editNarrative = null,
}: IComponentProps) => {
    const id = 'resourcesAnalysisChart';
    const title = 'Resources Analysis | Chart';
    const componentKey = 'resourcesAnalysisChart';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const { themeStatus } = useThemeStatusStore();
    const { version } = useVersionStore();
    const { mutate } = useMutationExtractResourceChartBarToCsv();
    const { handleSnackBar } = useCustomSnackBar();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const {
        update,
        aggregation,
        dataPointIndex: dataPointIndexFromStore,
        seriesIndex: seriesIndexFromStore,
    } = useResourceAnalysisStore();

    const { data: minMaxDates } = useQueryGetProjectDatesForResourceChart({
        metaDataId: latestVersionId,
        comparedMetaDataId: compareVersionId,
    });

    const filters: IFilter[] = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );

    const dateRangeFilterValue = useMemo(() => {
        return globalFilterData?.dateRangeFilter;
    }, [globalFilterData?.dateRangeFilter]);

    const { startDate, endDate } = useMemo(() => {
        const value = widgetId && filter ? localFilters?.range : dateRangeFilterValue;
        if (value && minMaxDates) {
            if (Array.isArray(value)) {
                if (value[0] && value[1]) {
                    return {
                        startDate: value[0],
                        endDate: value[1],
                    };
                } else {
                    return {
                        startDate: minMaxDates?.startProjectDate,
                        endDate: minMaxDates?.endProjectDate,
                    };
                }
            }
        }
        return {
            startDate: null,
            endDate: null,
        };
    }, [widgetId, filter, localFilters?.range, dateRangeFilterValue, minMaxDates]);

    const range = useMemo(() => {
        const value = widgetId && filter ? localFilters?.range : dateRangeFilterValue;
        return Array.isArray(value) ? 'R_2W' : value;
    }, [widgetId, filter, localFilters?.range, dateRangeFilterValue]);

    const { data, isFetching } = useQueryGetResourceChart({
        metaDataId: widgetId && filter ? filter?.filters.metaDataId : latestVersionId,
        comparedMetaDataId: widgetId && filter ? filter?.filters.comparedMetaDataId : compareVersionId,
        range,
        resourceId: globalFilterData?.resource,
        startDate,
        endDate,
        filter: {
            filters: filters,
        },
        aggregation: aggregation?.value || localFilters?.aggregation,
        resourceType: globalFilterData?.resourceType || localFilters?.resourceType,
        quickAnswerFilter: globalFilterData?.quickAnswerFilter || localFilters?.quickAnswerFilter,
    });

    const pinBoardFilterData = useMemo(() => {
        return {
            mainFilters: filters,
            metaDataId: latestVersionId,
            comparedMetaDataId: compareVersionId,
        };
    }, [filters, latestVersionId, compareVersionId]);

    const dataPointSelection = ({ dataPointIndex, seriesIndex }: { dataPointIndex: number; seriesIndex: number }) => {
        const isToggle = dataPointIndex === dataPointIndexFromStore && seriesIndex === seriesIndexFromStore;
        const series = categoriesKeys[seriesIndex].label;
        const value = data?.chart[dataPointIndex][categoriesKeys[seriesIndex].series];
        update({
            selectedValue: isToggle ? null : value,
            selectedSeries: isToggle ? null : series,
            selectedStartDate: isToggle ? data?.startChartDate : data?.chart[dataPointIndex]?.startDate,
            selectedEndDate: isToggle ? data?.endChartDate : data?.chart[dataPointIndex]?.endDate,
            dataPointIndex: isToggle ? null : dataPointIndex,
            seriesIndex: isToggle ? null : seriesIndex,
        });
    };

    const tooltipFormatter = ({ dataPointIndex }: { dataPointIndex: number }) => {
        if (dataPointIndex === undefined || !data) return '';
        const { startDate, endDate } = data.chart[dataPointIndex];
        return `${moment(startDate).format(constants.formats.date.default)} - ${moment(endDate).format(constants.formats.date.default)}`;
    };

    const { options, series } = getChartConfig({
        ...serialize({
            data: data?.chart,
            range: dateRangeFilterValue || localFilters?.range,
        }),
        themeStatus,
        versionDate: version?.versionDate,
        calculatedVersionDate: data?.calculatedVersionDate,
        dataPointSelection,
        tooltipFormatter,
        actualKpi: data?.actualKpi.activities,
    });

    useEffect(() => {
        if (data) {
            update({ selectedStartDate: data.startChartDate, selectedEndDate: data.endChartDate });
        }
    }, [data]);

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: { filters },
        latestVersionId,
    });

    const rangeFilterValue = useMemo(() => {
        if (!localFilters?.range) return [];
        if (widgetId && filter) {
            if (Array.isArray(localFilters?.range)) {
                return [getInputLabel({ startDate: localFilters?.range[0], endDate: localFilters?.range[1] })];
            } else {
                return [rangeOptions.find((item) => item.value === localFilters?.range)?.label || ''];
            }
        }
        return [];
    }, [widgetId, filter, localFilters?.range]);

    const allFormattedFilters = [
        ...formatedFilter,
        {
            name: 'Date Range',
            data: rangeFilterValue,
        },
        {
            name: 'Resource Type',
            data: localFilters?.resourceType
                ? [resourceTypeOptions.find((item) => item.value === localFilters?.resourceType)?.label]
                : [],
        },
        {
            name: 'Quick Answer Filter',
            data: localFilters?.quickAnswerFilter
                ? [quickAnswerFilterOptions.find((item) => item.value === localFilters?.quickAnswerFilter)?.label]
                : [],
        },
        {
            name: 'Aggregation',
            data: localFilters?.aggregation
                ? [aggregationOptions.find((item) => item.value === localFilters?.aggregation)?.label]
                : [],
        },
    ];

    const handleAPICsvExport = () => {
        if (latestVersionId) {
            mutate(
                {
                    metaDataId: widgetId && filter ? filter?.filters.metaDataId : latestVersionId,
                    comparedMetaDataId: widgetId && filter ? filter?.filters.comparedMetaDataId : compareVersionId,
                    range,
                    resourceId: globalFilterData?.resource,
                    startDate,
                    endDate,
                    filter: {
                        filters: filters,
                    },
                    aggregation: aggregation?.value || localFilters?.aggregation,
                    resourceType: globalFilterData?.resourceType || localFilters?.resourceType,
                    quickAnswerFilter: globalFilterData?.quickAnswerFilter || localFilters?.quickAnswerFilter,
                    fileName: `${title}_${subTitle}`,
                },
                {
                    onSuccess: () => {
                        handleSnackBar('File Successfully Downloaded', 'success');

                        sendEventWithDimensions({
                            category: 'Export',
                            action: 'CSV',
                            label: `${title} ${subTitle}`,
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Failed To Save Search', 'error');
                    },
                },
            );
        }
    };

    return (
        <WidgetWithTitle
            id={id}
            title={title}
            tooltip={data?.info}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    localFilters={{
                        aggregation: aggregation.value,
                        resourceType: globalFilterData?.resourceType,
                        quickAnswerFilter: globalFilterData?.quickAnswerFilter,
                        range: dateRangeFilterValue,
                    }}
                    filters={pinBoardFilterData}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown
                    key={'export'}
                    title={title}
                    subTitle={subTitle}
                    handleAPICsvExport={handleAPICsvExport}
                />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={allFormattedFilters} />
                ) : (
                    <div key={'filters'} className={classes.filters}>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>Aggregation</div>
                            <MyCustomSelect<ISelectOption<Aggregation>>
                                id={'aggregation-filter'}
                                value={aggregation}
                                options={aggregationOptions}
                                onChange={(value) => update({ aggregation: value })}
                            />
                        </div>
                    </div>
                ),
            ]}
        >
            <div className={localClasses.container}>
                <Boxes data={data} />
            </div>
            <div className={localClasses.container}>
                {options && (
                    <BarChart
                        options={options}
                        series={series}
                        isFetching={isFetching}
                        noData={!isFetching && !data}
                        height={600}
                    />
                )}
            </div>
        </WidgetWithTitle>
    );
};
