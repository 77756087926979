import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';

const queryFn = (metaDataId: number) => {
    const url = `${API_URL().GET_LATEST_DATE_OF_PROGRAM}?metaDataId=${metaDataId}`;
    return network.get(url).then((response) => {
        return response.data;
    });
};

export const useQueryLatestDateOfProgram = (metaDataId: number) => {
    return useQuery<number, QueryError, number, [QueryKeys, unknown]>({
        queryKey: ['latestDateOfProgram', metaDataId],
        queryFn: () => queryFn(metaDataId),
        enabled: metaDataId !== undefined,
    });
};
