import { Controller, useForm } from 'react-hook-form';
import { useMutationEditAlias } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQueryAliasPreferences';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase, MyCustomSelect } from 'components/common';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/AliasPreferences/EditAlias/editAlias.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { IAliasForm } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/AliasPreferences/alias.utils.type';
import { useMemo } from 'react';

interface Data {
    alias: string;
    contractId: number;
    id: number;
    scope: string;
    typeName: string;
}
interface Props {
    showModal: boolean;
    onHideModal: () => void;
    data: Data;
    tableData: Data[];
}

export const EditAlias = ({ showModal, onHideModal, data, tableData }: Props) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm<IAliasForm>();

    const activityCode = useMemo<ISelectOption<string>>(
        () => ({
            value: data.typeName,
            label: `${data.scope}: ${data.typeName}`,
        }),
        [data],
    );

    const { mutate, isLoading } = useMutationEditAlias();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    const handleSave = (value) => {
        mutate(
            {
                alias: value.alias.trim(),
                id: data?.id,
            },
            {
                onSuccess: () => {
                    handleSnackBar('Alias has Updated', 'success');
                    onHideModal();
                    reset();
                },
                onError: (error) => handleSnackBar(error.response.data, 'error'),
                onSettled: () => {
                    return queryClient.invalidateQueries('useQueryGetActivityCodesAliases');
                },
            },
        );
    };

    const handleHideModal = () => {
        reset();
        onHideModal();
    };

    const isAliasExist = (value) => {
        const isDuplicate = tableData.find((item) => {
            return item.alias === value;
        });

        return isDuplicate ? 'Alias name already exist' : true;
    };

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={onHideModal}>
            <div className={classes.editAliasContainer}>
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className={classes.title}>Edit Alias</div>
                    <div className={classes.subTitle}>Assign an alias to an activity code type:</div>

                    <div className={classes.content}>
                        <div className={classes.selectContainer}>
                            <div className={classes.selectTitle}>Activity Code Type:</div>
                            <div className={classes.selectWrapper}>
                                <MyCustomSelect<ISelectOption<string>>
                                    options={[]}
                                    id={'activity-code'}
                                    value={activityCode}
                                    isDisabled={true}
                                />
                            </div>
                        </div>

                        <div className={classes.selectContainer}>
                            <div className={classes.selectTitle}>Alias:</div>
                            <div className={classes.selectWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <TextSearchInput
                                            {...field}
                                            placeholder={''}
                                            isSearch={false}
                                            handleClear={() => setValue('alias', '')}
                                        />
                                    )}
                                    defaultValue={data?.alias}
                                    name={'alias'}
                                    control={control}
                                    rules={{
                                        required: 'Alias is Required',
                                        minLength: { value: 2, message: 'Alias is to short' },
                                        validate: {
                                            isDuplicate: isAliasExist,
                                        },
                                    }}
                                />
                                {errors.alias && <CustomizedFormErrorMessage text={errors.alias.message} />}
                            </div>
                        </div>
                    </div>

                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={handleHideModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton
                                type={'submit'}
                                size={'large'}
                                color={'primary'}
                                isLoading={isLoading}
                                disabled={isLoading}
                            >
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </form>
            </div>
        </CustomizedModalBase>
    );
};
