import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation } from 'react-query';

const mutationFn = ({ metaDataId, simulationDate, exportTypeRequest, fileName, key }: QueryParams) => {
    const url = API_URL().DOWNLOAD_SIMULATED_VERSION_XER_OR_MPP_FORMAT;
    return network
        .post(url, {
            metaDataId,
            simulationDate,
            exportType: exportTypeRequest,
            key,
        })
        .then((response) => {
            const blob = new Blob([response.data]);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
        });
};

export const useMutationDownloadSimulatedVersion = () => {
    return useMutation<unknown, QueryError, QueryParams>({ mutationFn: (params) => mutationFn(params) });
};

interface QueryParams {
    metaDataId: number;
    key: string;
    exportTypeRequest: string;
    fileName: string;
    simulationDate: number;
}
