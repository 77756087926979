import { create, StateCreator } from 'zustand';
import { Details } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    modalShow: boolean;
    details: Details | null;
    isUploading: boolean;
    showWarningModal: boolean;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}

const state: StateCreator<Props> = (set) => ({
    modalShow: false,
    details: null,
    isUploading: false,
    showWarningModal: false,
    update: (value) => set({ ...value }),
});

export const useProgramLibraryStore = create(state);
