import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ExcellenceAppsRoutes } from 'components/Dashboards/ExcellenceApps/routes';
import ProjectRoutes from 'components/Dashboards/Project/ProjectWrapper/projectRoutes';
import { PortfolioRoutes } from 'components/Dashboards/Portfolio/routes';
import PageNotFound from 'components/ErrorPages/pageNotFound';

const Dashboards = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/program`} component={ExcellenceAppsRoutes} />
            <Route path={`${path}/project`} component={ProjectRoutes} />
            <Route path={`${path}/portfolio`} component={PortfolioRoutes} />
            <Route path="*" component={PageNotFound} />
        </Switch>
    );
};

export default Dashboards;
