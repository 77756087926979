import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import React from 'react';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import {
    cardsDataQueryKey,
    listCardsDataQueryKey,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { ColumnDef } from '@tanstack/react-table';
import { customSortWithNullV8 } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { QueryResponse } from 'api/queries/getSchedulingSimulationResults.query';
import { CellWithWasLabel } from 'components/common/Tables/Cells/CellWithWasLabel';
import { FilterNames } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';
import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedTaskActivitiesTable/SimulatedActivitiesTable.module.scss';

const invalidateKeys = [cardsDataQueryKey, listCardsDataQueryKey];

export const initialState: ITableColumnState = {
    hiddenColumns: ['wbsLevel', 'remainingDurationVariance'],
    columnOrder: [
        'activity',
        'status',
        'activityType',
        'startDate',
        'startDateVariance',
        'finishDate',
        'finishDateVariance',
        'criticalPath',
        'criticalPathChange',
        'float',
        'floatVariance',
        'parentName',
        'grandParentName',
        'remainingDuration',
        'baselineStartDate',
        'baselineStartVariance',
        'baselineFinishDate',
        'baselineFinishVariance',
        'criticalityScore',
    ],
};

export const isStatusChange = (changedActivity: QueryResponse['changedActivities'][0]) => {
    return changedActivity.original.status.name !== changedActivity.modified.status.name;
};

export const columnsV8: ColumnDef<QueryResponse['changedActivities'][0]>[] = [
    {
        header: 'Activity',
        accessorKey: 'title',
        ...columnsCommonConfig.activity,
        size: 350,
        cell: ({ row, table }) => {
            const { getToggleSelectedHandler } = row;
            const { toggleAllRowsSelected } = table;
            const rowData = row.original;
            const { taskVersionHashCode, tracked } = rowData;
            const virtual = true; // all the tasks are virtual in this case
            const activityName = rowData.name;
            const activityId = rowData.activityId;
            const taskId = rowData.id;
            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={taskId}
                    isVirtual={virtual}
                    toggleRowSelected={getToggleSelectedHandler()}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={tracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        header: 'Status',
        accessorKey: 'status.label',
        ...columnsCommonConfig.status,
        cell: ({ row }) => {
            const dataRow = row.original;
            const { assignee, virtual, id, isStatusOrDatesChanged } = dataRow;
            const originalStatus = dataRow.original.status;
            const currentStatus = dataRow.modified.status;
            const isStatusChanged = isStatusChange(dataRow);
            const wasStatus = isStatusChanged ? `Was: ${originalStatus.label}` : `No change`;
            const cardType = 'PROGRAMME_ACTIVITY';
            return (
                <StatusCell
                    isStatusDisabled={true}
                    isAssigneeDisabled={true}
                    assignee={assignee}
                    status={currentStatus}
                    isVirtual={virtual}
                    taskId={id}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    wasStatus={wasStatus}
                    cardType={cardType}
                    category={undefined}
                    isStatusOrDatesChanged={isStatusOrDatesChanged}
                />
            );
        },
    },

    {
        header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessorFn: (original) => {
            return original.taskActivityType;
        },
        cell: ({ getValue }) => (
            <TooltipCellWithCopy text={getValue<QueryResponse['changedActivities'][1]['taskActivityType']>()} />
        ),
    },
    {
        header: 'Start Date',
        ...columnsCommonConfig.startDate,
        accessorKey: 'modified.start',
        size: 180,
        cell: ({ row }) => {
            const wasLabelString =
                row.original.original.start === row.original.modified.start
                    ? 'No change'
                    : `Was: ${moment(row.original.original.start).format(constants.formats.date.default)}`;
            return (
                <CellWithWasLabel
                    wasLabel={wasLabelString}
                    mainValue={
                        <DateCell
                            comparedVersion={moment(row.original.original.start).valueOf()}
                            latestVersionDate={row.original.modified.start}
                        />
                    }
                />
            );
        },
    },
    {
        header: 'Start Date Variance',
        accessorKey: 'startVariance',
        ...columnsCommonConfig.startDateVariance,
        id: 'startVariance',
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <VarianceCell value={getValue<QueryResponse['changedActivities'][0]['startVariance']>()} />
        ),
    },
    {
        header: 'Finish Date ',
        ...columnsCommonConfig.finishDate,
        accessorKey: 'modified.finish',
        size: 180,
        cell: ({ getValue, row }) => {
            const wasLabelString =
                row.original.original.finish === row.original.modified.finish
                    ? 'No change'
                    : `Was: ${moment(row.original.original.finish).format(constants.formats.date.default)}`;

            return (
                <CellWithWasLabel
                    wasLabel={wasLabelString}
                    mainValue={
                        <DateCell
                            latestVersionDate={getValue<QueryResponse['changedActivities'][0]['modified']['finish']>()}
                        />
                    }
                />
            );
        },
    },
    {
        header: 'Finish Date Variance',
        accessorKey: 'finishVariance',
        ...columnsCommonConfig.finishDateVariance,
        id: 'finishVariance',
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <VarianceCell
                className={classes.bold}
                value={getValue<QueryResponse['changedActivities'][0]['finishVariance']>()}
            />
        ),
    },

    {
        header: 'Critical Path',
        accessorKey: 'modified.onCriticalPath',
        ...columnsCommonConfig.criticalPath,
        size: 100,
        sortingFn: customSortWithNullV8,
        cell: ({ row }) => {
            const currentCriticalPath = row.original.modified.onCriticalPath;
            const previousCriticalPath = row.original.original.onCriticalPath;
            const wasLabel =
                currentCriticalPath === previousCriticalPath
                    ? 'No change'
                    : `Was: ${previousCriticalPath ? 'Yes' : 'No'}`;
            return <CellWithWasLabel wasLabel={wasLabel} mainValue={currentCriticalPath ? 'Yes' : 'No'} />;
        },
    },
    {
        header: 'Critical Path Change',
        accessorKey: 'criticalPathChange',
        ...columnsCommonConfig.criticalPathChange,
        sortingFn: customSortWithNullV8,
        cell: ({ row }) => <TooltipCellWithCopy text={row.original.criticalPathChange ? 'Yes' : 'No'} />,
    },
    {
        header: 'Float',
        accessorKey: 'modified.totalFloat',
        ...columnsCommonConfig.float,
        id: 'totalFloat',
        sortingFn: customSortWithNullV8,
        cell: ({ row }) => {
            const currentFloat = row.original.modified.totalFloat;
            const previousFloat = row.original.original.totalFloat;
            const wasLabel = currentFloat === previousFloat ? 'No change' : `Was: ${previousFloat}`;
            return <CellWithWasLabel wasLabel={wasLabel} mainValue={currentFloat} />;
        },
    },
    {
        header: 'Float Variance',
        accessorKey: 'totalFloatVariance',
        ...columnsCommonConfig.floatVariance,
        id: 'totalFloatVariance',
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <TooltipCellWithCopy
                text={getValue<QueryResponse['changedActivities'][0]['totalFloatVariance']>().toString()}
            />
        ),
    },
    {
        header: 'Immediate Parent Name (WBS)',
        accessorKey: 'parentActivity',
        ...columnsCommonConfig.parentName,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => <TooltipCellWithCopy text={`${getValue()}`} />,
    },
    {
        header: 'Grand Parent Name (WBS)',
        accessorKey: 'grandParentActivity',
        ...columnsCommonConfig.grandParentName,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => <TooltipCellWithCopy text={`${getValue()}`} />,
    },

    {
        header: 'Remaining Duration',
        ...columnsCommonConfig.remainingDuration,
        accessorKey: 'modified.remainingDuration',
        size: 100,
        cell: ({ row }) => {
            const currentDuration = row.original.modified.remainingDuration;
            const previousDuration = row.original.original.remainingDuration;
            const wasLabelString = currentDuration === previousDuration ? 'No change' : `Was: ${previousDuration}`;
            return <CellWithWasLabel mainValue={currentDuration} wasLabel={wasLabelString} />;
        },
    },
    {
        header: 'Remaining Duration Variance',
        accessorKey: 'remainingDurationVariance',
        ...columnsCommonConfig.remainingDurationVariance,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <TooltipCellWithCopy
                text={getValue<QueryResponse['changedActivities'][0]['remainingDurationVariance']>().toString()}
            />
        ),
    },
    {
        header: 'Baseline Start Date',
        accessorKey: 'baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <DateCell latestVersionDate={getValue<QueryResponse['changedActivities'][0]['baselineStartDate']>()} />
        ),
    },
    {
        header: 'Baseline Start Variance',
        accessorKey: 'startVarianceFromBaseline',
        ...columnsCommonConfig.baselineStartVariance,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <TooltipCellWithCopy
                text={getValue<QueryResponse['changedActivities'][0]['startVarianceFromBaseline']>().toString()}
            />
        ),
    },
    {
        header: 'Baseline Finish Date',
        accessorKey: 'baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <DateCell latestVersionDate={getValue<QueryResponse['changedActivities'][0]['baselineFinishDate']>()} />
        ),
    },

    {
        header: 'Baseline Finish Variance',
        accessorKey: 'finishVarianceFromBaseline',
        ...columnsCommonConfig.baselineFinishVariance,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <TooltipCellWithCopy
                text={getValue<QueryResponse['changedActivities'][0]['finishVarianceFromBaseline']>().toString()}
            />
        ),
    },
    {
        header: 'WBS Level',
        accessorKey: 'wbsLevel',
        ...columnsCommonConfig.wbsLevel,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <TooltipCellWithCopy text={getValue<QueryResponse['changedActivities'][0]['wbsLevel']>().toString()} />
        ),
    },
    {
        header: 'Criticality Score',
        accessorKey: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => (
            <TooltipCellWithCopy
                text={getValue<QueryResponse['changedActivities'][0]['criticalityScore']>().toString()}
            />
        ),
    },
];

export const buildFilterTemplate = <T,>(name: FilterNames, data: T[]) => {
    return {
        name,
        data,
    };
};

export const buildPayloadFilter = (
    searchName: string,
    statusFilter: ISelectOption<string>[],
    isCriticalPathOnly: boolean,
    isCriticalPathChangedFilter: boolean,
    taskActivityType: ISelectOption<string>[],
) => {
    const filters: IFilter[] = [];
    if (searchName !== undefined && searchName.length > 0) {
        const searchNameFilter = buildFilterTemplate<string>(FilterNames.NAME_SEARCH, [searchName]);
        filters.push(searchNameFilter);
    }
    if (statusFilter.length > 0) {
        const filterValue = statusFilter.map((item) => item.value);
        const statusFilterObj = buildFilterTemplate<string>(FilterNames.OPTIONS_STATUS, filterValue);
        filters.push(statusFilterObj);
    }
    if (isCriticalPathOnly || isCriticalPathChangedFilter) {
        const filterValue: string[] = [];
        if (isCriticalPathOnly) filterValue.push(FilterNames.CRITICAL_PATH);
        if (isCriticalPathChangedFilter) filterValue.push(FilterNames.CRITICAL_PATH_CHANGED);
        const isCriticalPathOnlyFilterObj = buildFilterTemplate<string>(FilterNames.OPTIONS_TYPE, filterValue);
        filters.push(isCriticalPathOnlyFilterObj);
    }

    if (taskActivityType.length > 0) {
        const tasksFilterObj = buildFilterTemplate<string>(
            FilterNames.ACTIVITY_TYPE_FILTER,
            taskActivityType.map((item) => item.value),
        );
        filters.push(tasksFilterObj);
    }
    return filters;
};
