import classes from 'components/common/CustomFilters/DateRangeFilter/MainContent/MainContent.module.scss';
import classesRange from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.module.scss';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import React, { useEffect, useMemo, useState } from 'react';
import defaultArrowImage from 'styles/assets/svg/defualtArrow.svg';
import moment from 'moment-timezone';
import { DateRangeFilterContent } from 'components/common/CustomFilters/TableDateRangeFilter/dateRangeFilterContent';
import { IFields, TDateRangeOption } from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter';
import {
    getSubmitValue,
    getYearOptions,
} from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.utils';
import { useForm } from 'react-hook-form';
import { RangeWithCustom } from 'components/common/GlobalFilterComponent/components/dateRangeFilter/dateRangeFilter';

interface IMainContentProps {
    activeSideMenuOption: RangeWithCustom;
    handlePopoverClose: () => void;
    minDate: number | undefined;
    maxDate: number | undefined;
    value: any;
    onChange: (value: any) => void;
}

export const DateRangeFilterMain = ({
    activeSideMenuOption,
    handlePopoverClose,
    minDate,
    maxDate,
    value,
    onChange,
}: IMainContentProps) => {
    const [dateRangeOption, setDateRangeOption] = useState<TDateRangeOption>('wholeProject');

    const {
        control,
        formState: { errors, isValid },
        clearErrors,
        watch,
        reset,
        trigger,
    } = useForm<IFields>({
        defaultValues: {
            fromMonth: { value: moment.utc(minDate).month(), label: moment.utc(minDate).format('MMMM') },
            fromYear: { value: moment.utc(minDate).year(), label: moment.utc(minDate).format('YYYY') },
            toMonth: { value: moment.utc(maxDate).month(), label: moment.utc(maxDate).format('MMMM') },
            toYear: { value: moment.utc(maxDate).year(), label: moment.utc(maxDate).format('YYYY') },
        },
    });

    useEffect(() => {
        if (Array.isArray(value)) {
            if (value[0] === 0) {
                setDateRangeOption('wholeProject');
            } else {
                setDateRangeOption('dateRange');
                reset({
                    fromMonth: { value: moment.utc(value[0]).month(), label: moment.utc(value[0]).format('MMMM') },
                    fromYear: { value: moment.utc(value[0]).year(), label: moment.utc(value[0]).format('YYYY') },
                    toMonth: { value: moment.utc(value[1]).month(), label: moment.utc(value[1]).format('MMMM') },
                    toYear: { value: moment.utc(value[1]).year(), label: moment.utc(value[1]).format('YYYY') },
                });
            }
        }
    }, [value]);

    useEffect(() => {
        trigger();
    }, [
        dateRangeOption,
        watch('toMonth.value'),
        watch('fromMonth.value'),
        watch('fromYear.value'),
        watch('toYear.value'),
    ]);

    const handleApply = () => {
        const { startDate, endDate } = getSubmitValue({
            dateRangeOption,
            toMonth: watch('toMonth.value'),
            fromMonth: watch('fromMonth.value'),
            fromYear: watch('fromYear.value'),
            toYear: watch('toYear.value'),
        });
        if (activeSideMenuOption !== 'R_CUSTOM') {
            onChange(activeSideMenuOption);
        } else {
            onChange([startDate, endDate]);
        }
    };

    const yearOptions = useMemo(() => {
        return getYearOptions({ minDate, maxDate });
    }, [minDate, maxDate]);

    return (
        <div className={classes.mainContentContainer}>
            <div className={classes.content}>
                {activeSideMenuOption === 'R_CUSTOM' ? (
                    <div className={classesRange.dateRangePopoverContentWrapper}>
                        <form className={classesRange.form}>
                            <DateRangeFilterContent
                                dateRangeOption={dateRangeOption}
                                setDateRangeOption={setDateRangeOption}
                                control={control}
                                yearOptions={yearOptions}
                                errors={errors}
                                clearErrors={clearErrors}
                                minDate={minDate}
                                maxDate={maxDate}
                                watch={watch}
                            />
                        </form>
                    </div>
                ) : (
                    <div className={classes.defaultContent}>
                        <div className={classes.title}>Choose a reference range</div>
                        <div className={classes.subTitle}>No exact date selection is needed in reference</div>
                        <img className={classes.imageWrapper} src={defaultArrowImage} alt={'arrow'} />
                    </div>
                )}
            </div>
            <div className={classes.footer}>
                <div className={classes.actionButtons}>
                    <CustomizedButton size={'large'} color={'secondary'} onClick={handlePopoverClose}>
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton
                        size={'large'}
                        color={'primary'}
                        onClick={handleApply}
                        disabled={!isValid && dateRangeOption === 'dateRange' && activeSideMenuOption === 'R_CUSTOM'}
                    >
                        Apply
                    </CustomizedButton>
                </div>
            </div>
        </div>
    );
};
