import colorsVars from 'styles/colors.module.scss';
import { useVersionStore } from 'store/version.store';
import { GraphPoints } from 'api/queries/getProjectStatusScoreCards.query';
import { formatValueForGraph } from 'utilitys/helpers/general';
import { capitalizeFirstLetter } from 'components/Dashboards/Portfolio/PortfolioOverview/helper';
const {
    white,
    statusSummaryGreenGraph,
    statusSummaryRedGraph,
    statusSummaryOrangeGraph,
    statusSummaryGreenGraphLight,
    statusSummaryRedGraphLight,
    statusSummaryOrangeGraphLight,
    graphAnchorBorderColor,
    cardGraphBackGroundDark,
    cardGraphFont,
} = colorsVars;

export const getTitleColorByStatus = ({ value = 1, theme }: { value: number; theme: boolean }): string => {
    let color = theme ? statusSummaryGreenGraph : statusSummaryGreenGraphLight;
    if (value < 0.9) {
        color = theme ? statusSummaryRedGraph : statusSummaryRedGraphLight;
    }
    if (value >= 0.9 && value < 1) {
        color = theme ? statusSummaryOrangeGraph : statusSummaryOrangeGraphLight;
    }
    return color;
};

interface serializeDataProps {
    data: {
        name: string;
        graphPoints: GraphPoints[];
    };
    themeStatus: boolean;
}

export const serializeData = ({
    data,
    themeStatus,
}: serializeDataProps): ApexAxisChartSeries | ApexNonAxisChartSeries | undefined => {
    const versionName = useVersionStore.getState().version?.versionName;
    return [
        {
            name: capitalizeFirstLetter(data.name),
            data: data.graphPoints.map((item, index) => ({
                x: index + 1,
                y: item.score,
                fillColor: getTitleColorByStatus({ value: item.score, theme: themeStatus }),
                strokeColor:
                    versionName === item.versionName ? graphAnchorBorderColor : themeStatus ? white : cardGraphFont,
            })),
            color: themeStatus ? white : cardGraphFont,
        },
    ];
};

interface serializeOptionsProps {
    data: {
        name: string;
        graphPoints: GraphPoints[];
    };
    themeStatus: boolean;
    xaxisTitle: string;
    yaxisTitle: string;
    yAxisMaxValue: number | undefined;
}

export const serializeOptions = ({
    data,
    themeStatus,
    xaxisTitle,
    yaxisTitle,
    yAxisMaxValue,
}: serializeOptionsProps): ApexCharts.ApexOptions => {
    const categories = data.graphPoints.map((item) => item.versionName);

    return {
        chart: {
            background: themeStatus ? cardGraphBackGroundDark : white,
            foreColor: cardGraphFont,
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            type: 'category',
            categories: categories,
            labels: {
                show: false,
            },
            title: {
                text: xaxisTitle,
                offsetX: 0,
                offsetY: -15,
                style: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                },
            },
        },
        stroke: {
            width: 2,
        },
        yaxis: {
            min: 0,
            max: yAxisMaxValue,
            tickAmount: 4,
            labels: {
                style: {
                    fontSize: '1.2rem',
                },
                formatter: (value) => {
                    return formatValueForGraph(value);
                },
            },
            title: {
                text: yaxisTitle,
                style: {
                    fontSize: '1.4rem',
                    fontWeight: 400,
                },
            },
        },
        markers: {
            size: 5,
        },
        tooltip: {
            theme: themeStatus ? 'dark' : 'light',
        },
    };
};
