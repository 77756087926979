import { useMutation } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { activeDirectory, token } from 'services/LocalStorage/localStorage';

const mutationFn = (params: QueryParams) => {
    return network.post(API_URL().LOGIN, params).then((response) => {
        // Set The Token
        token.set(response.data.accessToken);
        network.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
        activeDirectory.set(
            window['ENV_VARIABLES'] ? window['ENV_VARIABLES']['REACT_APP_ACTIVE_DIRECTORY_URL'] : activeDirectory.get(),
        );
        return response.data;
    });
};

export const useMutationLogin = () => {
    return useMutation<QueryResponse, QueryError, QueryParams>((params) => mutationFn(params));
};

export type QueryParams =
    | {
          password: string;
          username: string;
          mfaCode: string | null;
      }
    | {
          encrypt: string;
          time: number;
      };

export interface QueryResponse {
    accessToken: string;
    email: string;
}
