import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    calendarViewRange: string;
    showActivityLabels: boolean;
    showProgressLine: boolean;
    showBaseline: boolean;
    showDependencies: boolean;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}

const state: StateCreator<Props> = (set) => ({
    calendarViewRange: 'year',
    showActivityLabels: false,
    showProgressLine: false,
    showBaseline: true,
    showDependencies: true,
    update: (value) => set(() => ({ ...value })),
});

export const useGanttSettingsStore = create(persist(state, { name: 'ganttSettings' }));
