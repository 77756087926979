import { useQuery } from 'react-query';
import qs from 'qs';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { QueryKeys } from 'api/queryKeys';

const queryFn = ({ latestVersion }: { latestVersion: number }) => {
    const querystring = qs.stringify({ metaDataId: latestVersion }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().GET_RESOURCE_LIST_FOR_CHART}${querystring}`;

    return network.get(url).then((response) => {
        return response.data;
    });
};
export const useQueryGetResourceListForChart = (params: QueryParams) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['getResourceListForChart', params],
        queryFn: () =>
            queryFn({
                latestVersion: params.latestVersion as number,
            }),
        enabled: Boolean(params.latestVersion),
    });
};

export interface QueryResponse {
    value: number;
    label: string;
    count: number;
}

interface QueryParams {
    latestVersion?: number;
}
