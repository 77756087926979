import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { Controller } from 'react-hook-form';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import React, { useEffect } from 'react';
import { useQueryState } from 'hooks/useQueryState';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import produce from 'immer';

interface Props {
    methods?: any;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<any>>;
    handleFilters?: () => void;
    className?: string;
}

export type QuickAnswerFilter =
    | 'BUDGET_GREATER_THAN_ACTUAL'
    | 'ACTUAL_GREATER_THAN_BUDGET'
    | 'ACTUAL_KPI_GREATER_THAN_REMAINING'
    | 'REMAINING_GREATER_THAN_ACTUAL_KPI';

export const quickAnswerFilterOptions: ISelectOption<QuickAnswerFilter>[] = [
    { label: 'Budget Greater Than Actual', value: 'BUDGET_GREATER_THAN_ACTUAL' },
    { label: 'Actual Greater Than Budget', value: 'ACTUAL_GREATER_THAN_BUDGET' },
    { label: 'Actual KPI Greater Than Remaining', value: 'ACTUAL_KPI_GREATER_THAN_REMAINING' },
    { label: 'Remaining Greater Than Actual KPI', value: 'REMAINING_GREATER_THAN_ACTUAL_KPI' },
];

export const QuickAnswerFilter = ({ methods, name, setState, handleFilters, className }: Props) => {
    const [queryStringValue] = useQueryState(name);
    const { getValueFromStore } = useGlobalFilterDataStore();
    const storeValue = getValueFromStore<Range>(name);

    const formValue = methods?.getValues(name);
    const isValue = formValue || queryStringValue || storeValue;

    useEffect(() => {
        if (setState) {
            if (!isValue) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft[name].isReady = true;
                        draft[name].isEmpty = true;
                        return draft;
                    });
                });
            }
            if (isValue) {
                methods?.setValue(name, isValue);
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft[name].isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, setState]);

    const handleChange = (option: ISelectOption<string> | null) => {
        methods?.setValue(name, option ? option.value : null);
        handleFilters && handleFilters();
    };

    const handleValue = (value) => {
        if (!value) {
            return null;
        }
        return quickAnswerFilterOptions.find((item) => item.value === isValue) || null;
    };

    return (
        <div className={`${classes.selectWrapper} ${className ?? ''}`}>
            <span className={classes.title}>Quick Answers</span>
            <div className={classes.activityCodesSelectWrapper}>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<QuickAnswerFilter>>
                            {...field}
                            options={quickAnswerFilterOptions}
                            id={'global-filter-component-quick-answer-filter'}
                            onChange={handleChange}
                            value={handleValue(field.value)}
                            isClearable={true}
                        />
                    )}
                    name={name}
                    control={methods?.control}
                />
            </div>
        </div>
    );
};
