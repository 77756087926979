import React, { useMemo } from 'react';
import { TreeCrumbs } from 'components/common/TreeCrumbs/TreeCrumbs';
import { last } from 'lodash';
import { IProject, IContract } from 'store/projectContract.store';

import MultipleRoundSpinner from 'components/common/MultipleRoundSpinner/multipleRoundSpinner';
import { useProjectTreeStore } from 'store/projectContract.store';
import { useQueryGetProgramGeneralHierarchyList } from 'api/queries/getProgramGeneralHierarchyList.query';

const selectLastSubProgram = ({ projectHierarchyList, storedProject }) => {
    const p = projectHierarchyList?.children.find((c) => c.id === storedProject?.id);
    if (p) {
        const contract: any = last(p.children);
        if (contract) {
            return {
                id: contract.id,
                title: contract.name,
                uuid: contract.uuid,
            };
        }
    }
};

interface IProjectContractSelectorProps {
    onSelectionFinish: ({ project, contract }: { project: IProject | null; contract: IContract | null }) => void;
    uuid: string | null;
}

export const ProjectContractSelector = ({ onSelectionFinish, uuid }: IProjectContractSelectorProps) => {
    const { project: storedProject, contract: storedContract } = useProjectTreeStore();
    const { data: projectHierarchyList, isLoading: loadingGeneralList } =
        useQueryGetProgramGeneralHierarchyList('ProgramLibraryUploadFiles');

    const selectedNode = useMemo(() => {
        return storedContract || selectLastSubProgram({ projectHierarchyList, storedProject });
    }, [storedProject, storedContract, projectHierarchyList]);

    if (!projectHierarchyList || !selectedNode) return null;
    if (loadingGeneralList) return <MultipleRoundSpinner />;
    return (
        <TreeCrumbs
            selectedNode={uuid || selectedNode.uuid}
            onSelectionFinish={onSelectionFinish}
            data={projectHierarchyList}
            showAddNewButton={false}
            showAll={false}
            updatedNodeUUID={uuid || selectedNode.uuid}
            isDisabled={Boolean(uuid)}
        />
    );
};
