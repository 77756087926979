import { useEffect } from 'react';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { IContract, IProject, useProjectTreeStore } from 'store/projectContract.store';
import { findeNodeByUUID, ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { useQueryGetGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';

export const useProjectTreeWithUrlHook = (): {
    setParams: (project: IProject | null, contract: IContract | null) => void;
} => {
    const { data: currentData } = useQueryGetGeneralHierarchyList();
    const { project, contract, update } = useProjectTreeStore();
    const history = useHistory();
    const { path } = useRouteMatch();
    const { projectId, contractId } = useParams<{ projectId?: string; contractId?: string }>();

    useEffect(() => {
        if (!projectId && !contractId && project) {
            setParams(project, contract);
        }
    }, [projectId, contractId, project, contract]);
    /**
     * set selected project and contract when pasting a copied url
     */
    useEffect(() => {
        const projectUUID = `${projectId}-ProjectIdNameResponse`;
        const contractUUID = `${contractId}-ContractTestResponse`;

        if (currentData) {
            const projectNode =
                currentData && projectUUID
                    ? findeNodeByUUID(currentData, projectUUID as ProjectTreeNode['uuid'])
                    : null;
            const contractNode =
                currentData && contractUUID
                    ? findeNodeByUUID(currentData, contractUUID as ProjectTreeNode['uuid'])
                    : null;

            if (projectNode) {
                update({
                    project: {
                        id: projectNode.id,
                        title: projectNode.name,
                        uuid: projectNode.uuid,
                    },
                    contract: contractNode
                        ? {
                              id: contractNode.id,
                              title: contractNode.name,
                              uuid: contractNode.uuid,
                          }
                        : null,
                });
            }
        }
    }, [currentData, projectId, contractId]);

    const setParams = (project: IProject | null, contract: IContract | null) => {
        const newLocation = generatePath(path, {
            projectId: project?.id,
            contractId: contract?.id,
        });
        history.push(newLocation);
    };
    return { setParams };
};
