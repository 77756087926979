import React, { forwardRef } from 'react';
import classes from './pills.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    type?: 'risk' | 'info' | 'assignee' | 'unAssignee' | 'todo' | 'inProgress' | 'done' | 'tag' | 'user' | 'tableRisk';
    className?: string;
}

export const Pills = forwardRef(
    ({ children, type, className, ...rest }: Props, ref?: React.ForwardedRef<HTMLDivElement>) => {
        return (
            <div
                data-testid={'pills-container'}
                {...rest}
                className={`${classes.pillsContainer} ${type ? classes[type] : ''} ${className ?? ''}`}
                ref={ref}
            >
                {children}
            </div>
        );
    },
);
