import React, { useState } from 'react';
import classes from 'components/common/passwordInput/passwordInput.module.scss';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

export const PasswordInput = React.forwardRef(
    (
        { value, onChange, className, ...rest }: React.HTMLProps<HTMLInputElement>,
        ref: React.ForwardedRef<HTMLInputElement>,
    ) => {
        const [showPassword, setShowPassword] = useState<boolean>(false);

        return (
            <div className={classes.inputWrapper}>
                <input
                    data-testid={'input'}
                    className={`${classes.passwordInput} ${className || ''}`}
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    {...rest}
                    ref={ref}
                />
                <div className={classes.toggleIcon}>
                    <MuiIcon
                        icon={showPassword ? 'visibility_off' : 'visibility'}
                        onClick={() => setShowPassword(!showPassword)}
                        fontSize={'20px'}
                        role={'toggle-icon'}
                        color={'#7C838C'}
                    />
                </div>
            </div>
        );
    },
);
