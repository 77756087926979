import React from 'react';
import { SortingState } from '@tanstack/react-table';
import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SortSelectorComponent/sortSelectorComponent.module.scss';
import { MyCustomSelect } from 'components/common';
import {
    sortByFinishDateVariance,
    SortValueOptions,
} from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SortSelectorComponent/sortSelectorComponent.utils';

export const SortSelectorComponent = ({
    sortingOptions,
    setSortSelector,
    setSorting,
    sortSelector,
}: {
    sortingOptions: ISelectOption<SortValueOptions>[];
    setSortSelector: React.Dispatch<React.SetStateAction<ISelectOption<SortValueOptions>>>;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    sortSelector: ISelectOption<SortValueOptions>;
}) => {
    return (
        <div className={classes.sortSelector}>
            <span>Sort by</span>
            <MyCustomSelect<ISelectOption<SortValueOptions>>
                options={sortingOptions}
                className={classes.selector}
                onChange={(selected) => {
                    if (selected) {
                        if (selected.value !== 'selectedColumn') {
                            const sortedValue = {
                                id: selected.value,
                                desc: selected.value === sortByFinishDateVariance.value,
                            };
                            setSortSelector(selected);
                            setSorting([sortedValue]);
                        }
                    }
                }}
                value={sortSelector}
                menuPlacement={'bottom'}
            />
        </div>
    );
};
