import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { CompareOptions } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo';
import { QueryKeys } from 'api/queryKeys';

const queryFn = (params: QueryParams) => {
    return network
        .post(API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_SEARCH_WORD, {
            customerId: params.customerId,
            paFileMetaDataIds: params.idList,
            projectId: params.projectId,
            query: params.searchWord,
            clusterType: params.compare,
        })
        .then((response) => {
            return response.data;
        });
};

export const useQueryClustersDataBySearchWord = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['clustersDataBySearchWord', params],
        queryFn: () => queryFn(params),
        enabled:
            Boolean(params.searchWord) &&
            Boolean(params.customerId) &&
            Boolean(params.idList) &&
            Boolean(params.compare),
        staleTime: 300000,
    });
};

interface QueryParams {
    idList: number[];
    compare: CompareOptions | undefined;
    projectId: number | undefined;
    customerId: number | undefined;
    searchWord: string | undefined;
}

interface QueryResponse {
    clusterData: QueryResponse;
    clusterList: {
        clusterId: number;
        clusterName: string;
        completedDurationHigh: number | null;
        completedDurationTypical: number | null;
        completedOverrunDaysHigh: number | null;
        completedOverrunDaysTypical: number | null;
        completedOverrunRatioHigh: number | null;
        completedOverrunRatioTypical: number | null;
        fileMetaData: number | null;
        plannedDurationHigh: number | null;
        plannedDurationTypical: number | null;
    }[];
}
