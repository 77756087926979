import { useState, ChangeEvent, KeyboardEvent } from 'react';
import classes from './SimulatedActivitiesFilters.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import SwitchComponent from 'components/common/Switch/Switch';
import {
    activitiesTabActivityTypeInitialOptions,
    statusOptions,
} from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesFilter/simulatedActivitiesFilter.utils';
import { useSimulatedActivitiesFiltersStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesFilter/simulatedActivitiesFilter.store';

export const SimulatedActivitiesFilters = () => {
    const [searchText, setSearchText] = useState<string>('');
    const { isCriticalPathChangedFilter, isCriticalPathFilter, statusFilter, activityTypeFilter, update, activeTab } =
        useSimulatedActivitiesFiltersStore();

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            update({ search: searchText });
        }
    };

    return (
        <div className={classes.filters}>
            <div className={classes.selectWrapper}>
                <div className={classes.title}>Name</div>
                <div className={classes.filterTextSearch}>
                    <TextSearchInput
                        placeholder="Search Activities by Name"
                        value={searchText}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setSearchText(e.target.value);
                        }}
                        handleClear={() => {
                            setSearchText('');
                            update({ search: '' });
                        }}
                        maxLength={200}
                        handleClick={() => update({ search: searchText })}
                        onKeyDown={onKeyDown}
                        className={classes.textInput}
                    />
                </div>
            </div>
            {activeTab === 'activities' && (
                <>
                    <div className={classes.selectWrapper}>
                        <div className={classes.title}>Activity Type</div>
                        <MyCustomSelect<ISelectOption<string>, true>
                            value={activityTypeFilter}
                            options={activitiesTabActivityTypeInitialOptions}
                            id={'activity-type-filter'}
                            onChange={(value) => update({ activityTypeFilter: [...value] })}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti={true}
                            placeholder={'Select Activity Type...'}
                            components={{
                                Option: InputOption,
                                ValueContainer: ValueContainer,
                            }}
                        />
                    </div>
                    <div className={classes.selectWrapper}>
                        <div className={classes.title}>Status</div>
                        <MyCustomSelect<ISelectOption<string>, true>
                            value={statusFilter}
                            options={statusOptions}
                            id={'status-filter'}
                            onChange={(value) => update({ statusFilter: [...value] })}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti={true}
                            placeholder={'Select Status...'}
                            components={{
                                Option: InputOption,
                                ValueContainer: ValueContainer,
                            }}
                        />
                    </div>
                    <div>
                        Critical Path (Post Simulation)
                        <SwitchComponent
                            onChange={(e) =>
                                update({
                                    isCriticalPathFilter: e.target.checked,
                                })
                            }
                            checked={isCriticalPathFilter}
                        />
                    </div>
                    <div>
                        Critical Path Changed
                        <SwitchComponent
                            onChange={(e) =>
                                update({
                                    isCriticalPathChangedFilter: e.target.checked,
                                })
                            }
                            checked={isCriticalPathChangedFilter}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
