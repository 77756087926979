import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ITopOverdueData,
    useMutationExtractDelayDriverCsv,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/queries/activitiesQuery';
import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import { useQueryActivities, IActivitiesData } from 'api/queries/getDelayDriver.query';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { CriticalAnalysisDelayDriversFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/criticalAnalysisDelayDriversFilter';

import {
    filterMapper,
    FilterNames,
    getFiltersData,
} from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';

import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { IRenderCriticalAnalysisDelayDrivers } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/style/table.module.scss';
import {
    columnsV8,
    initialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisDelayDriversComponent/utils';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import NoData from 'components/common/NoData/noData';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { useReactTableState } from 'hooks/useReactTableState';
import { serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { useProgramCriticalAnalysisStore } from 'store/programCriticalAnalysis.store';
import { getStringWithoutHash } from 'utilitys/helpers/general';
import { getMonths } from 'utilitys/helpers/Date/dateHelper';
import { TitleWithCounter } from 'components/common/titleWithCounter/titleWithCounter';

const RenderCriticalAnalysisDelayDrivers = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IRenderCriticalAnalysisDelayDrivers) => {
    const {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState({ initialSorting: widgetId && filter?.filters ? filter?.filters.sortingRequestList : [] });
    const globalDateFilter = useProgramCriticalAnalysisStore((store) => store.dateFilter);
    const [dateFilter, setDateFilter] = useState<ISelectOption<string> | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const { handleSnackBar } = useCustomSnackBar();
    const mutation = useMutationExtractDelayDriverCsv();
    const [statusFilter, setStatusFilter] = useState<ISelectOption<string>[]>([
        {
            value: 'TODO',
            label: 'To Do',
        },
        {
            value: 'IN_PROGRESS',
            label: 'In Progress',
        },
    ]);
    const [isCriticalPath, setIsCriticalPath] = useState<boolean>(false);
    const [isDelayDriver, setIsDelayDriver] = useState<boolean>(true);
    const [typeFilter, setTypeFilter] = useState<ISelectOption<string>[]>([]);
    const [search, setSearch] = useState<string>('');
    const [onSearch, setOnSearch] = useState<string>('');
    const { sendEventWithDimensions } = useEventWithDimensions();
    const title = 'Critical Activities & Delay Drivers';
    const id = 'activitiesDelayDrivers';
    const componentKey = 'topCriticalActivitiesDelayDrivers';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                      [FilterNames['START_DATE']]:
                          dateFilter && dateFilter.value !== 'All Months' ? [dateFilter.value] : [],
                      [FilterNames.NAME_SEARCH]: onSearch.length > 0 ? [onSearch] : [],
                      [FilterNames.OPTIONS_STATUS]: statusFilter.map((item) => item.value).map(filterMapper),
                      [FilterNames.OPTIONS_TYPE]: isCriticalPath
                          ? [...typeFilter.map((item) => item.value).map(filterMapper), 'CRITICAL_PATH']
                          : typeFilter.map((item) => item.value).map(filterMapper),
                      [FilterNames.DELAY_DRIVER]: isDelayDriver ? ['true'] : ['false'],
                  }),
        [filter, globalFilterData, dateFilter, typeFilter, statusFilter, onSearch, isCriticalPath, isDelayDriver],
    );

    //  reset Pagination on sorting and new filter request
    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [sorting]);

    useEffect(() => {
        handleDate(globalDateFilter);
        if (globalDateFilter.value !== 'All Months') {
            setIsDelayDriver(false);
            setStatusFilter([
                {
                    value: 'TODO',
                    label: 'To Do',
                },
                {
                    value: 'IN_PROGRESS',
                    label: 'In Progress',
                },
                {
                    value: 'Done',
                    label: 'Done',
                },
            ]);
            handleSnackBar(`'Critical Activities' table filtered to ${globalDateFilter?.label}`, 'info');
        }
    }, [globalDateFilter]);

    const { data, isLoading, isFetching } = useQueryActivities({
        filter: {
            filters,
        },
        id: idList,
        sortingRequestList: sorting,
        paging: { ...pagination, page: pagination.page - 1 },
    });

    const months = getMonths({ startDate: data?.startDate, endDate: data?.finishDate });

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: widgetId && filter ? { filters: filter?.filters?.mainFilters || [] } : undefined,
        latestVersionId,
    });
    const filteredRows: IActivitiesData[] = useMemo(() => data?.delayDrivers || [], [data]);
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();
    const handleAPICsvExport = () => {
        if (latestVersionId) {
            mutation.mutate(
                {
                    fileMetaDataId: latestVersionId,
                    filter: filters,
                    fileName: `${title}_${subTitle}`,
                },
                {
                    onSuccess: () => {
                        handleSnackBar('File Successfully Downloaded', 'success');

                        sendEventWithDimensions({
                            category: 'Export',
                            action: 'CSV',
                            label: `${title} ${subTitle}`,
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Failed To Save Search', 'error');
                    },
                },
            );
        }
    };

    const handleSearch = useCallback((e) => {
        setSearch(e.target.value);
    }, []);

    const handleClearSearch = useCallback(() => {
        setSearch('');
        setOnSearch('');
    }, []);

    const handleOnSearch = useCallback(() => {
        setOnSearch(search);
        setPagination({ ...pagination, page: 1 });
        sendEventWithDimensions({
            category: 'Activities Table(s)',
            action: 'Search by Activity Name',
            label: search,
        });
    }, [search]);

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                handleOnSearch();
            }
        },
        [search],
    );

    const handleStatus = useCallback(
        (value) => {
            setTypeFilter(value);
            setPagination({ ...pagination, page: 1 });
            sendEventWithDimensions({
                category: 'Activities Table(s)',
                action: 'Status Filter click',
                label: value.label || 'Deselect All',
            });
        },
        [pagination],
    );

    const handleStatusType = useCallback(
        (value) => {
            setPagination({ ...pagination, page: 1 });
            setStatusFilter(value);
        },
        [pagination],
    );

    const setWidgetColumns = (widgetId: string) => {
        if (tableInstance) {
            mutateSetWidgetColumns(
                { columns: { hiddenColumns: tableInstance.state.hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    const handleCriticalPath = useCallback(
        (event) => {
            const isChecked = event.target.checked;
            setPagination({ ...pagination, page: 1 });
            setIsCriticalPath(isChecked);
        },
        [pagination],
    );

    const handleDelayDriver = useCallback(
        (event) => {
            const isChecked = event.target.checked;
            setPagination({ ...pagination, page: 1 });
            setIsDelayDriver(isChecked);
        },
        [pagination],
    );

    const handleDate = useCallback(
        (value) => {
            setDateFilter(value);
            setPagination({ ...pagination, page: 1 });
        },
        [pagination],
    );

    useEffect(() => {
        updatedInitialState && setColumnOrder(updatedInitialState.columnOrder ?? []);
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (column && column.id) {
                        return {
                            ...column,
                            isVisible: !hiddenColumns.includes(column.id),
                        };
                    }
                    return null;
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    useEffect(() => {
        if (data) {
            setTotalPages(data.numOfPages);
        }
    }, [data]);

    const pinBoardFilterData = useMemo(() => {
        return {
            mainFilters: filters,
            sortingRequestList: sorting,
        };
    }, [filters, sorting]);

    return (
        <ErrorBoundary>
            <WidgetWithTitle
                id={id}
                title={<TitleWithCounter title={title} counter={data?.numOfRows} />}
                tooltip={data?.info}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId && formatedFilter.length > 0 ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                    ) : (
                        <CriticalAnalysisDelayDriversFilter
                            key={'filter'}
                            search={search}
                            handleSearch={handleSearch}
                            handleClearSearch={handleClearSearch}
                            handleOnSearch={handleOnSearch}
                            onKeyDown={onKeyDown}
                            handleDateFilter={handleDate}
                            months={months}
                            dateFilter={dateFilter}
                            handleStatusType={handleStatusType}
                            statusFilter={statusFilter}
                            handleStatus={handleStatus}
                            typeFilter={typeFilter}
                            handleCriticalPath={handleCriticalPath}
                            isCriticalPath={isCriticalPath}
                            handleDelayDriver={handleDelayDriver}
                            isDelayDriver={isDelayDriver}
                        />
                    ),
                ]}
                titleComponents={[
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    <CustomizedTooltip
                        key={'CustomizTableColumns'}
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={'Customize table columns'}
                        triggerElement={
                            <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                                <Icon name={'tune-filter'} />
                            </CustomizedRoundIcon>
                        }
                    />,
                    <AddWidget
                        key={'AddWidget'}
                        componentKey={componentKey}
                        title={title}
                        projectId={projectId}
                        contractId={contractId}
                        widgetId={widgetId}
                        route={route}
                        filters={pinBoardFilterData}
                        callBack={setWidgetColumns}
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown
                        key={'export'}
                        title={title}
                        handleAPICsvExport={handleAPICsvExport}
                        subTitle={subTitle}
                    />,
                ]}
            >
                <div data-testid={'table-container'} className={classes.tableContainer}>
                    {updatedInitialState && (
                        <>
                            <TableV8<ITopOverdueData>
                                data={filteredRows}
                                columns={columnsV8}
                                isLoading={isLoading || isFetching}
                                noData={<NoData>No activities match the applied filter</NoData>}
                                sorting={sorting}
                                setSorting={setSorting}
                                columnVisibility={columnVisibility}
                                setColumnVisibility={setColumnVisibility}
                                columnOrder={columnOrder}
                                setColumnOrder={setColumnOrder}
                            />

                            <div className={classes.pagination}>
                                <CustomPagination
                                    totalPages={totalPages}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    isDisabled={isFetching}
                                />
                            </div>
                        </>
                    )}
                </div>

                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    widgetId={widgetId}
                    componentKey={componentKey}
                    tableInstance={tableInstance}
                    initialState={initialState}
                    columns={columnsV8}
                />
            </WidgetWithTitle>
        </ErrorBoundary>
    );
};

export default React.memo(RenderCriticalAnalysisDelayDrivers);
