import { create, StateCreator } from 'zustand';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    sessionKey: string | null;
    cacheIsReady: boolean;
    resetSimulationSession: () => void;
    isRunning: boolean;
    update: (value: Unionize<Omit<Props, 'update' | 'resetSimulationSession'>>) => void;
}

const state: StateCreator<Props> = (set) => ({
    isRunning: false,
    cacheIsReady: false,
    sessionKey: null,
    resetSimulationSession: () => {
        set({ cacheIsReady: false, sessionKey: null, isRunning: false });
    },
    update: (value: Unionize<Omit<Props, 'update' | 'resetSimulationSession'>>) => set({ ...value }),
});

export const useSimulationCacheKeyStore = create(state);
