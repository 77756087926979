import { mapper } from 'components/common/bryntumGantt/helper';
import { IGanttChartResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramGanttChart/ganttChart.types';
import _ from 'lodash';

export const groupViewList: ISelectOption<string>[] = [
    {
        value: 'expandAll',
        label: 'Expand All',
    },
    {
        value: 'collapseAll',
        label: 'Collapse All',
    },
    {
        value: 'default',
        label: 'Collapse top 3 levels',
    },
];

export type ProgressType = 'submittedDuration' | 'criticality';

interface GetFlatTasksProps {
    activities: IGanttChartResponse['activities'] | undefined;
    progress: ProgressType;
}

export const getFlatTasks = ({ activities, progress }: GetFlatTasksProps) => {
    return activities
        ? _.sortBy(activities, [
              function (o) {
                  return o.orderId;
              },
          ]).map((item) => mapper(item, progress))
        : [];
};
