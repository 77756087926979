import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';

const queryFn = (params: QueryParams) => {
    const url = `${API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_SEARCH_WORDS_LIST}?customerId=${params}`;

    return network.post(url).then((response) => {
        return response.data;
    });
};

export const useQueryClustersSearchWordsList = (params: QueryParams) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['clustersSearchWordsList', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params),
        staleTime: 300000,
    });
};

type QueryParams = number | undefined;

interface QueryResponse {
    clusterName: string;
    id: number;
}
