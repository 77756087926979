import classes from 'components/common/titleWithBeta/titleWithBeta.module.scss';
import { Pills } from 'components/common/pills/pills';

export const TitleWithBeta = ({ title }: { title: string }) => {
    return (
        <div className={classes.container}>
            {title}
            <Pills>BETA</Pills>
        </div>
    );
};
