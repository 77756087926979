import { removeHtmlTagsFromString } from 'utilitys/helpers/general';
import { renderToString } from 'react-dom/server';

export const isAllColumnsSelected = (data: MappedColumn[]) => data.filter(filterRowOptions).every((el) => el.isVisible);

export const getLabelFromColumnHeader = (header: string | Function): string => {
    return typeof header === 'function'
        ? removeHtmlTagsFromString(renderToString(header()).replace(/<br\s*\/?>/gi, ' '))
        : header.replace(/<\/?[^>]+(>|$)/g, '');
};

export const mapper = (item: Column & { isVisible: boolean }): MappedColumn => {
    if ('Header' in item) {
        return {
            id: item.id,
            Header: item.Header,
            isVisible: item.isVisible,
            isDisableReorder: item.isDisableReorder || false,
            isDisableVisibility: item.isDisableVisibility || false,
            accessor: item.accessor,
        };
    } else {
        return {
            id: item.id,
            Header: item.header,
            isVisible: item.isVisible,
            isDisableReorder: item.isDisableReorder || false,
            isDisableVisibility: item.isDisableVisibility || false,
            accessor: item.accessorKey,
        };
    }
};

interface ColumnV8 {
    id: string;
    accessorKey: string;
    header: string;
    isDisableReorder: boolean;
    isDisableVisibility: boolean;
}

interface ColumnMainTable {
    id: string;
    accessor: string;
    Header: string;
    isDisableReorder: boolean;
    isDisableVisibility: boolean;
}

export type Column = ColumnV8 | ColumnMainTable;

export interface MappedColumn {
    id: string;
    Header: string;
    isVisible: boolean;
    isDisableReorder: boolean;
    isDisableVisibility: boolean;
    accessor: string;
}

export const filterRowOptions = (item: { id: string }) => item.id !== 'rowOptions';
export const filterColumnsVisibility = ({
    columns,
    visibilityCondition,
}: {
    columns: Column[];
    visibilityCondition: (value: Column) => boolean;
}): MappedColumn[] => {
    return columns
        .filter(filterRowOptions)
        .map((item) => ({
            ...item,
            isVisible: visibilityCondition(item),
        }))
        .map(mapper);
};
