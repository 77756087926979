import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation, useQuery } from 'react-query';
import { orderBy } from 'lodash';
import { IProgramVersion } from 'components/common/VersionCompareSelectorSection/VersionCompareSelectorWrapper/VersionCompareSelectorWrapper';
import { Version } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';

const refreshProgramAnalysisData = (params: RefreshProgramAnalysisData) => {
    const preparedURL = new URL(API_URL().REFRESH_PROGRAM_ANALYSIS);
    params.projectIDs && preparedURL.searchParams.set('projectIDs', params.projectIDs.toString());
    params.contractId && preparedURL.searchParams.set('contractId', params.contractId.toString());

    return network
        .post(preparedURL.toString())
        .then((response) => response.data)
        .catch((error: Error) => {
            throw error;
        });
};

const getProjectVersions = ({ projectId, contractId }: GetProjectVersions) => {
    const preparedURL = new URL(API_URL().FETCH_PROGRAM_DASHBOARD_STORED_XER_FILE_VERSIONS);
    preparedURL.searchParams.set('contractId', String(contractId || -1));
    preparedURL.searchParams.set('projectId', String(projectId));
    return network.get(preparedURL.toString()).then((response) => {
        return orderBy(response.data, ['uploadedDate'], ['desc']);
    });
};

const uploadXerFile = (params) => {
    const data = new FormData();
    data.append('file', params.file, params.fileName);

    const payload: any = {
        projectId: params.projectId,
        contractId: params.contractId,
        isBaseline: params.isBaseline,
        contractName: params.contractName,
        versionId: params.versionId,
        uploadingUser: params.uploadingUser,
        versions: params.versions,
        language: params.language,
        mppACEnabled: params.isTransformMPP,
    };

    if (params.plf_file) {
        data.append('plf_file', params.plf_file, params.plf_file.name);
    }

    if (params.anotherFile) {
        data.append('plf_file', params.anotherFile, params.anotherFile.name);
    }

    if (params.versionName) {
        payload.versionName = params.versionName;
    }

    if (params.versionDate) {
        payload.versionDate = params.versionDate;
    }

    data.append('request', JSON.stringify(payload));

    const url = API_URL().UPLOAD_PROGRAM_DASHBOARD_XER_FILE_VERSIONS;
    return network
        .post(url, data, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers['Content-Type'] = 'multipart/form-data';
                    }
                    return data;
                },
            ],
        })
        .then(() => {
            return true;
        })
        .catch((error) => {
            throw error;
        });
};

const uploadProjectFile = (params) => {
    const data = new FormData();
    data.append('file', params.file, params.fileName);

    const payload: any = {
        projectId: params.projectId,
        contractId: params.contractId,
        contractName: params.contractName,
        uploadingUser: params.uploadingUser,
        versions: params.versions,
        customVersionName: params.customVersionName,
        isBaseline: params.isBaseline,
        isIntegratedProjects: params.isIntegratedProjects,
        warningCheck: params.warningCheck,
        language: params.language,
        runCPM: params.runCPM,
    };

    data.append('request', JSON.stringify(payload));

    const url = API_URL().UPLOAD_XER_PROJECT_FILE;
    return network
        .post(url, data, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers['Content-Type'] = 'multipart/form-data';
                    }
                    return data;
                },
            ],
        })
        .then(() => {
            return true;
        })
        .catch((error) => {
            throw error;
        });
};

const removeXerFile = (params) => {
    return network.post(API_URL().REMOVE_PROGRAM_DASHBOARD_VERSION, { ids: params.ids }).catch((error) => {
        throw error;
    });
};

const uploadPlfFile = (params) => {
    const data = new FormData();
    data.append('file', params.file, params.file.name);
    const url = API_URL().PROGRAM_DASHBOARD_PLF_ANALYSIS;
    return network
        .post(url, data, {
            transformRequest: [
                (data, headers) => {
                    if (headers) {
                        headers['Content-Type'] = 'multipart/form-data';
                    }
                    return data;
                },
            ],
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const downLoadXerFile = (params) => {
    const url = `${API_URL().DOWNLOAD_XER_FILE}/${params.id}`;
    return network
        .get(url, {
            responseType: 'blob',
        })
        .then((response) => {
            const blob = new Blob([response.data]);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = params.fileName;
            a.click();
        })
        .catch((error) => {
            throw error;
        });
};

const renameProject = (params) => {
    const url = API_URL().RENAME_PROJECT;
    return network
        .put(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const renameSubProgram = (params) => {
    const url = API_URL().RENAME_SUB_PROGRAM;
    return network
        .put(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const renameVersion = (params) => {
    const url = API_URL().RENAME_VERSION;
    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getLanguageList = () => {
    const url = API_URL().GET_LANGUAGE_LIST;
    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationRefreshProgramAnalysisData = () => {
    return useMutation<any, QueryError, RefreshProgramAnalysisData>((params) => refreshProgramAnalysisData(params));
};

export const useQueryGetProjectVersions = (params: GetProjectVersions) => {
    return useQuery<IProgramVersion[], QueryError>(['getProjectVersions', params], () => getProjectVersions(params), {
        enabled: Boolean(params.projectId),
        placeholderData: [],
    });
};

export const useMutationUploadXerFile = () => {
    return useMutation<any, QueryError, UploadXerPayload>((params) => uploadXerFile(params));
};

export const useMutationRemoveXerFile = () => {
    return useMutation<any, QueryError, RemoveXerVersion>((params) => removeXerFile(params));
};

export const useMutationUploadPlfFile = () => {
    return useMutation<any, QueryError, Partial<UploadXerPayload>>((params) => uploadPlfFile(params));
};

export const useMutationRenameProject = () => {
    return useMutation<any, QueryError, IRenameProject>((params) => renameProject(params));
};

export const useMutationRenameSubProgram = () => {
    return useMutation<any, QueryError, IRenameSubProgram>((params) => renameSubProgram(params));
};

export const useMutationDownLoadXerFile = () => {
    return useMutation<any, QueryError, DownLoadXerFile>(['downLoadXerFile'], (params) => downLoadXerFile(params));
};

export const useMutationUploadProjectFile = () => {
    return useMutation<unknown, QueryError, UploadProjectsPayload>((params) => uploadProjectFile(params));
};

export const useMutationRenameVersion = () => {
    return useMutation<unknown, QueryError, IRenameVersion>((params) => renameVersion(params));
};

export const useQueryGetLanguageList = () => {
    return useQuery<ISelectOption<string>[], QueryError>('languageList', () => getLanguageList());
};

interface IRenameVersion {
    metaDataid: number;
    versionName: string;
}

interface DownLoadXerFile {
    id: number;
    fileName: string;
}

interface RefreshProgramAnalysisData {
    projectIDs?: number[];
    contractId?: number;
}

interface GetProjectVersions {
    projectId?: number | null;
    contractId?: number | null;
}

interface DownLoadXerFile {
    id: number;
}

export interface UploadXerPayload {
    projectId: number;
    contractId: number | null;
    contractName: string;
    versionId: string[];
    uploadingUser: string;
    isBaseline: boolean[];
    file: Blob;
    plf_file?: File | null;
    anotherFile?: File | null;
    versionName?: string;
    versionDate?: string;
    fileName: string;
    versions: Version[];
}

export interface UploadProjectsPayload {
    projectId: number;
    contractId: number | null;
    contractName: string;
    uploadingUser: string;
    file: Blob;
    fileName: string;
    versions: Version[];
    customVersionName: string;
    isBaseline: boolean;
    isIntegratedProjects: boolean;
    warningCheck: boolean;
    runCPM: boolean;
}

interface RemoveXerVersion {
    ids: number[];
}

interface IRenameProject {
    projectId: number;
    projectName: string;
}

interface IRenameSubProgram {
    contractId: number;
    contractName: string;
}
