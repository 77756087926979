import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/programCriticalAnalysis.module.scss';
import CriticalAnalysisHeatMap from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisHeatMap/criticalAnalysisHeatMap';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { MyCustomSelect } from 'components/common';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useQueryState } from 'hooks/useQueryState';
import {
    defaultHeatMapOption,
    heatMapInitialData,
    heatMapOptions,
    HeatMapOptionsValue,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import {
    TableDateRangeFilter,
    TDateRangeOption,
} from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter';
import { List } from 'components/common/ListWrapper/list';
import {
    resolutionOptions,
    serializeHeatmap,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/components/CriticalAnalysisHeatMapComponent/RenderCriticalAnalysisHeatMap.utils';
import moment from 'moment-timezone';
import { useProgramCriticalAnalysisStore } from 'store/programCriticalAnalysis.store';
import { useQueryHeatMapActivityStart } from 'api/queries/heatMapActivityStart.query';
import { useQueryHeatMapActivityResources } from 'api/queries/heatMapActivityResources.query';
import { useQueryHeatMapActivityStartOrFinish } from 'api/queries/heatMapActivityStartOrFinish.query';
import { useQueryHeatMapActivityDuration } from 'api/queries/heatMapActivityDuration.query';
import { getStringWithoutHash } from 'utilitys/helpers/general';

const RenderCriticalAnalysisHeatMap = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    localFilters,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const [dateRange, setDateRange] = useState<{ startDate: number | null; endDate: number | null }>(
        widgetId && filter && filter.filters.dateRange
            ? filter.filters.dateRange
            : {
                  startDate: null,
                  endDate: null,
              },
    );
    const [dateRangeOption, setDateRangeOption] = useState<TDateRangeOption>('wholeProject');
    const [resolutionValue, setResolutionValue] = useState<ISelectOption<boolean>>(
        widgetId && filter && filter.filters.resolutionValue
            ? filter.filters.resolutionValue
            : {
                  value: false,
                  label: 'Month',
              },
    );

    const { update } = useProgramCriticalAnalysisStore();

    useEffect(() => {
        if (latestVersionId && !widgetId && !filter) {
            setDateRange({
                startDate: null,
                endDate: null,
            });
            setDateRangeOption('wholeProject');
        }
    }, [latestVersionId, widgetId, filter]);

    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );

    const payload = useMemo(() => {
        return {
            id: idList,
            filter: {
                filters,
            },
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            isWeekResolution: resolutionValue.value,
        };
    }, [idList, filters, dateRange, resolutionValue]);

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: widgetId && filter ? { filters: filter?.filters?.mainFilters || [] } : undefined,
        latestVersionId,
    });
    const { data: heatMapActivityStarts, isFetching: heatMapActivityStartFetching } =
        useQueryHeatMapActivityStart(payload);
    const { data: heatMapActivityDuration, isFetching: heatMapActivityDurationFetching } =
        useQueryHeatMapActivityDuration(payload);
    const { data: heatMapActivityStartOrFinish, isFetching: heatMapActivityStartOrFinishFetching } =
        useQueryHeatMapActivityStartOrFinish(payload);
    const { data: heatMapActivityResources, isFetching: heatMapActivityResourcesFetching } =
        useQueryHeatMapActivityResources(payload);

    const isFetching =
        heatMapActivityStartFetching &&
        heatMapActivityDurationFetching &&
        heatMapActivityStartOrFinishFetching &&
        heatMapActivityResourcesFetching;

    const heatmap = useMemo(() => {
        if (
            heatMapActivityStarts &&
            heatMapActivityDuration &&
            heatMapActivityStartOrFinish &&
            heatMapActivityResources
        ) {
            return {
                heatMapActivityStarts,
                heatMapActivityDuration,
                heatMapActivityStartOrFinish,
                heatMapActivityResources,
            };
        }
        return null;
    }, [heatMapActivityStarts, heatMapActivityDuration, heatMapActivityStartOrFinish, heatMapActivityResources]);

    const [riskDensityBy, setRiskDensityBy] = useQueryState('riskDensityBy');

    const selectByInitialValue: string | undefined = useMemo(() => {
        return widgetId && localFilters?.selectBy ? localFilters.selectBy.value : riskDensityBy;
    }, [widgetId, localFilters, riskDensityBy]);

    const [selectBy, setSelectBy] = useState<ISelectOption<HeatMapOptionsValue> | null>(null);
    useEffect(() => {
        if (!selectByInitialValue && !widgetId) {
            setRiskDensityBy(defaultHeatMapOption.value);
            setSelectBy(defaultHeatMapOption);
        } else {
            setSelectBy(heatMapOptions.find((filter) => filter.value === selectByInitialValue) || null);
        }
    }, [selectByInitialValue, widgetId]);

    const selectedHeatmap = useMemo(() => {
        if (selectBy && heatmap) {
            return heatmap[selectBy.value];
        }
        return heatMapInitialData;
    }, [selectBy, heatmap]);

    const {
        info,
        heatMapItemResponseList: data,
        endProjectDate: endProjectDateFromBE,
        startProjectDate: startProjectDateFromBE,
    } = selectedHeatmap;

    const [endProjectDate, setEndProjectDate] = useState<number | null>(null);
    const [startProjectDate, setStartProjectDate] = useState<number | null>(null);

    useEffect(() => {
        if (heatmap && !endProjectDate && !startProjectDate) {
            setEndProjectDate(endProjectDateFromBE);
            setStartProjectDate(startProjectDateFromBE);
        }
    }, [heatmap, endProjectDate, startProjectDate, endProjectDateFromBE, startProjectDateFromBE]);

    const title = 'Risk Density by';
    const serialized = useMemo(() => {
        return serializeHeatmap(data, resolutionValue.value);
    }, [data, resolutionValue]);

    const setDateOnDelayDriver = useCallback(
        (value: string) => {
            const date = serialized.find((item) => item.id === value);
            if (date) {
                const m = moment(date.date);
                update({
                    dateFilter: {
                        value: m.format('yyyyMM'),
                        label: m.format('MMM yyyy'),
                    },
                });
            }
        },
        [serialized],
    );

    const handleChange = (value: ISelectOption<HeatMapOptionsValue> | null) => {
        if (value) {
            setRiskDensityBy(value.value);
        }
    };

    const id = 'RiskDensityByActivityStarts';
    const componentKey = 'riskHeatMap';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    const handleDateRangeSelection = (value: { startDate: number | null; endDate: number | null }) => {
        setDateRange(value);
    };

    const handleResolutionFilter = (value: ISelectOption<boolean>) => {
        setResolutionValue(value);
    };

    const pinBoardFilterData = useMemo(() => {
        return {
            mainFilters: filters,
            dateRange: dateRange,
            resolutionValue: resolutionValue,
        };
    }, [filters, dateRange, resolutionValue]);

    // Date Range Filter Reset when global filters change
    useEffect(() => {
        if (!widgetId && !filter) {
            setDateRange({
                startDate: null,
                endDate: null,
            });
            setDateRangeOption('wholeProject');
            setEndProjectDate(null);
            setStartProjectDate(null);
        }
    }, [globalFilterData]);

    return (
        <WidgetWithTitle
            id={id}
            title={title}
            tooltip={info}
            titleComponents={[
                <div className={classes.titleComponents} key={'filterCriticalAnalysisHeatMap'}>
                    {widgetId ? (
                        <h3>{selectBy?.label}</h3>
                    ) : (
                        <MyCustomSelect<ISelectOption<HeatMapOptionsValue>>
                            id={'filterCriticalAnalysisHeatMap'}
                            value={selectBy}
                            options={heatMapOptions}
                            onChange={handleChange}
                            key={'filterCriticalAnalysisHeatMap-Key'}
                        />
                    )}
                    <div className={classes.dateRangeWrapper}>
                        <div>Date Range</div>
                        {startProjectDate && endProjectDate && (
                            <TableDateRangeFilter
                                onChange={handleDateRangeSelection}
                                minDate={startProjectDate}
                                maxDate={endProjectDate}
                                value={dateRange}
                                isDisabled={!!(widgetId && filter)}
                                dateRangeOption={dateRangeOption}
                                setDateRangeOption={setDateRangeOption}
                            />
                        )}
                    </div>
                </div>,
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    title={title}
                    componentKey={componentKey}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={pinBoardFilterData}
                    localFilters={{ selectBy }}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,

                widgetId && formatedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                ) : null,
            ]}
        >
            <div className={classes.topSection}>
                <div>{!widgetId && <span>Click any month to filter the activities table below.</span>}</div>
                <div className={classes.resolutionWrapper}>
                    <div className={classes.title}>Resolution:</div>
                    <List<boolean>
                        onChange={handleResolutionFilter}
                        options={resolutionOptions}
                        value={resolutionValue}
                    />
                </div>
            </div>
            <CriticalAnalysisHeatMap
                data={serialized}
                handleClick={setDateOnDelayDriver}
                resolutionValue={resolutionValue}
                noData={!isFetching && heatmap !== null && serialized.length === 0}
                isFetching={isFetching}
            />
        </WidgetWithTitle>
    );
};

export default RenderCriticalAnalysisHeatMap;
