import { create, StateCreator } from 'zustand';
import { Unionize } from 'utilitys/helpers/TsGeneral';
import { wbsTabActivityTypeInitialOptions } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesFilter/simulatedActivitiesFilter.utils';
import { TabKeys } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivityTabs/simulatedActivitiesTabs.utils';

interface Props {
    activeTab: TabKeys;
    search: string;
    activityTypeFilter: ISelectOption<string>[];
    statusFilter: ISelectOption<string>[];
    isCriticalPathFilter: boolean;
    isCriticalPathChangedFilter: boolean;
    resetFilters: () => void;
    update: (value: Unionize<Omit<Props, 'update' | 'resetFilters'>>) => void;
}

export const initialState: Omit<Props, 'resetFilters' | 'update'> = {
    activeTab: 'wbs',
    search: '',
    activityTypeFilter: wbsTabActivityTypeInitialOptions,
    statusFilter: [],
    isCriticalPathFilter: false,
    isCriticalPathChangedFilter: false,
};

const state: StateCreator<Props> = (set) => ({
    ...initialState,
    update: (value) => set({ ...value }),
    resetFilters: () => {
        set({ ...initialState });
    },
});

export const useSimulatedActivitiesFiltersStore = create(state);
