import classes from './ProgramActivityRelatedActivityDates.module.scss';
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import {
    useMutationGetCurrentActualDates,
    useMutationGetFinishDateDueToEditStartDateOrDuration,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/queries/useMutationMainCard';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/ProgramActivityCard/ProgramActivityCard.types';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import constants from 'components/common/Constants/constants';
import { QueryResponse as CardResponse } from 'api/queries/getMainCard.query';
import { Constraints } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainCard/Common/Constraints/Constraints';
import { useVersionStore } from 'store/version.store';
import { CustomizedDatePicker } from 'components/common/CustomizedDatePicker/CustomizedDatePicker';
import { CustomizedFormErrorMessage, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import useUserHook from 'hooks/useUserHook';
import { isEmpty } from 'lodash';
import Icon from 'components/common/Icons/icon';

interface IProgramActivityRelatedActivityDatesProps {
    data: CardResponse;
    setValue: UseFormSetValue<IFields>;
    control: Control<IFields, IFields>;
    watch: UseFormWatch<IFields>;
    register: UseFormRegister<IFields>;
    errors: FieldErrors<IFields>;
}

export const ProgramActivityRelatedActivityDates = ({
    data,
    setValue,
    control,
    watch,
    register,
    errors,
}: IProgramActivityRelatedActivityDatesProps) => {
    const remainingDurationInDaysValue = watch('remainingDurationInDays');
    const submittedDurationCompleteValue = watch('submittedDurationComplete');
    const cardDates = watch('cardDates');
    const status = watch('status');
    const currentVersion = useVersionStore((state) => state.version);

    const { ability } = useUserHook();
    const isActivityProgressUpdate = ability.can('view', 'ActivityProgressUpdate');
    const isEditActivityDatesPrivilege = ability.can('view', 'EditActivityStatus');
    const isStatusChangedFromOriginal = status.name !== status.originalStatus;

    const isRemainingDurationInputEnable = status.name !== 'DONE' && isActivityProgressUpdate;
    const isCompletionInputEnable = status.name !== 'DONE' && isActivityProgressUpdate;

    const isStatusDoneAndMilestone = status.name === 'DONE' && data.category.name.includes('MILESTONE');

    const { mutate: getCurrentActualDatesMutate } = useMutationGetCurrentActualDates();
    const { mutate: getFinishDateDueToEditStartDateOrDuration } =
        useMutationGetFinishDateDueToEditStartDateOrDuration();

    useEffect(() => {
        if (isStatusChangedFromOriginal && status.name !== 'TODO' && currentVersion) {
            getCurrentActualDatesMutate(
                {
                    id: data.id,
                    laneId: status.name,
                    isVirtual: data.isVirtual,
                    versionId: currentVersion.id,
                },
                {
                    onSuccess: (res) => {
                        setValue('startDate', res.startDate);
                        setValue('endDate', res.endDate);
                    },
                },
            );
        }
    }, [status, currentVersion, isStatusChangedFromOriginal, data]);

    useEffect(() => {
        if (status.name === 'TODO') {
            setValue('startDate', data.originalStartDate, { shouldDirty: true });
            setValue('endDate', data.originalEndDate, { shouldDirty: true });
        }

        if (status.name === 'DONE') {
            setValue('remainingDurationInDays', 0);
            setValue('submittedDurationComplete', 100);
        } else {
            setValue('remainingDurationInDays', data.remainingDurationInDays);
            setValue('submittedDurationComplete', data.submittedDurationComplete);
        }
    }, [status, data]);

    // max length for remainingDurationInDays(999) & submittedDurationComplete(100)
    useEffect(() => {
        if (remainingDurationInDaysValue && remainingDurationInDaysValue.toString().length > 3) {
            const sliced = remainingDurationInDaysValue.toString().slice(0, -1);
            setValue('remainingDurationInDays', Number(sliced), { shouldDirty: true });
        }

        if (
            submittedDurationCompleteValue &&
            (submittedDurationCompleteValue.toString().length > 3 || submittedDurationCompleteValue > 100) &&
            isCompletionInputEnable
        ) {
            const sliced = submittedDurationCompleteValue.toString().slice(0, -1);
            setValue('submittedDurationComplete', Number(sliced), { shouldDirty: false });
        }
    }, [remainingDurationInDaysValue, submittedDurationCompleteValue, isCompletionInputEnable]);

    const handleStartDate = (value: Date | number) => {
        const currentEndDate = watch('endDate');
        const remainingDurationInDays = watch('remainingDurationInDays');

        if (currentEndDate && currentEndDate < moment(value).valueOf()) {
            setValue('endDate', moment(value).valueOf(), { shouldDirty: true });
        }

        setValue('startDate', moment(value).valueOf(), { shouldDirty: true });
        handleSetFinishDateDueToEditStartDateOrDuration(moment(value).valueOf(), remainingDurationInDays);
    };

    const handleEndDate = (value: Date) => {
        if (isStatusDoneAndMilestone) {
            setValue('startDate', moment(value).valueOf(), { shouldDirty: true });
        }

        setValue('endDate', moment(value).valueOf(), { shouldDirty: true });
    };

    const handleSetFinishDateDueToEditStartDateOrDuration = (startDate, remainingDurationInDaysValue) => {
        if (
            status.name === 'IN_PROGRESS' &&
            remainingDurationInDaysValue !== null &&
            !isNaN(remainingDurationInDaysValue)
        ) {
            getFinishDateDueToEditStartDateOrDuration(
                {
                    id: data.id,
                    startDate: startDate,
                    remainingDurationInDays: remainingDurationInDaysValue,
                    isVirtual: data.isVirtual,
                    taskId: data.taskId,
                },
                {
                    onSuccess: (res) => {
                        setValue('endDate', res.endDate);
                    },
                },
            );
        }
    };

    return (
        <div className={classes.programActivityRelatedActivityDatesContainer}>
            <div className={classes.title}>Related Activity Dates</div>

            {cardDates.baselinePlannedStartDate && cardDates.baselinePlannedStartDate.length > 0 && (
                <div className={classes.mainContent}>
                    <div className={classes.title}>Baseline</div>
                    <div className={classes.content}>
                        <div className={classes.date}>
                            <div className={classes.dateTitle}>Start</div>
                            <div data-testid={'baseline-start-date'} className={classes.dateValue}>
                                {cardDates.baselinePlannedStartDate}
                            </div>
                        </div>
                        <div className={classes.date}>
                            <div className={classes.dateTitle}>End</div>
                            <div data-testid={'baseline-end-date'} className={classes.dateValue}>
                                {cardDates.baselinePlannedEndDate}
                            </div>
                        </div>
                        {!data.isFoundInBaseline && (
                            <CustomizedTooltip
                                tooltipContent={
                                    'This activity is not present in the designated Baseline, hence the Baseline dates are taken from the latest schedule planned date for this activity'
                                }
                                triggerElement={
                                    <CustomizedRoundIcon
                                        size={'2.5rem'}
                                        data-testid={'is-found-in-baseline-round-icon'}
                                    >
                                        <Icon
                                            data-testid={'is-found-in-baseline-icon'}
                                            name="info-icon"
                                            size={'1.3rem'}
                                        />
                                    </CustomizedRoundIcon>
                                }
                            />
                        )}
                    </div>
                </div>
            )}

            <div className={classes.mainContent} data-track-id={`dates-card`}>
                <div className={classes.title}>{status.name === 'DONE' ? 'Completed' : 'Current'}</div>
                <div className={classes.content}>
                    {!isStatusDoneAndMilestone && (
                        <div className={classes.date}>
                            <div className={classes.dateTitle}>Start</div>
                            {status.name !== 'TODO' && isEditActivityDatesPrivilege ? (
                                <Controller
                                    render={({ field }) => (
                                        <CustomizedDatePicker
                                            value={field.value}
                                            handleChange={handleStartDate}
                                            minDate={moment().subtract(20, 'year').valueOf()}
                                        />
                                    )}
                                    name={'startDate'}
                                    control={control}
                                />
                            ) : (
                                <div data-testid={'activity-start-date'} className={classes.dateValue}>
                                    {moment(watch('startDate')).format(constants.formats.date.default)}
                                </div>
                            )}
                        </div>
                    )}

                    <div className={classes.date}>
                        <div className={classes.dateTitle}>End</div>
                        {status.name === 'DONE' && isEditActivityDatesPrivilege ? (
                            <Controller
                                render={({ field }) => (
                                    <CustomizedDatePicker
                                        value={field.value}
                                        handleChange={handleEndDate}
                                        minDate={
                                            isStatusDoneAndMilestone
                                                ? moment().subtract(20, 'year').valueOf()
                                                : moment(watch('startDate')).valueOf()
                                        }
                                    />
                                )}
                                name={'endDate'}
                                control={control}
                            />
                        ) : (
                            <div data-testid={'activity-end-date'} className={classes.dateValue}>
                                {moment(watch('endDate')).format(constants.formats.date.default)}
                            </div>
                        )}
                    </div>

                    {isEditActivityDatesPrivilege && isStatusChangedFromOriginal && status.name === 'IN_PROGRESS' && (
                        <CustomizedTooltip
                            tooltipContent={
                                <div>
                                    <div>
                                        Estimated Finish Date is based on selected Start Date plus Remaining Duration.
                                    </div>
                                    <div>
                                        Note its only an estimate as the date will anyway be updated by the schedule
                                        tool (Primavera, MS-Project) based on Start Date plus Remaining Duration and
                                        constraints.
                                    </div>
                                </div>
                            }
                            triggerElement={
                                <CustomizedRoundIcon size={'2.5rem'}>
                                    <Icon name="info-icon" size={'1.3rem'} />
                                </CustomizedRoundIcon>
                            }
                        />
                    )}
                </div>
            </div>

            <div className={classes.mainContent}>
                <div className={classes.relatedActivityDatesOptions}>
                    <div className={classes.remaining}>
                        <label>Remaining Duration</label>
                        <input
                            type="number"
                            className={`${classes.remainingInput} ${
                                errors.remainingDurationInDays ? classes.error : ''
                            }`}
                            {...register('remainingDurationInDays', {
                                valueAsNumber: true,
                                required: 'Remaining Duration is required',
                            })}
                            onChange={(e) => {
                                setValue('remainingDurationInDays', Number(e.target.value), { shouldDirty: true });
                                handleSetFinishDateDueToEditStartDateOrDuration(
                                    watch('startDate'),
                                    Number(e.target.value),
                                );
                            }}
                            disabled={!isRemainingDurationInputEnable}
                        />
                    </div>
                    <div className={`${classes.completion} ${status.name === 'TODO' ? classes.hide : ''}`}>
                        <label>% Completion</label>
                        {!isCompletionInputEnable ? (
                            <input
                                value={Math.round(submittedDurationCompleteValue)}
                                disabled={true}
                                className={classes.completionInput}
                            />
                        ) : (
                            <input
                                type="number"
                                className={`${classes.completionInput} ${
                                    errors.submittedDurationComplete ? classes.error : ''
                                }`}
                                {...register('submittedDurationComplete', {
                                    valueAsNumber: true,
                                    required: 'Completion is required',
                                })}
                                value={submittedDurationCompleteValue}
                                disabled={!isCompletionInputEnable}
                            />
                        )}
                    </div>
                </div>
            </div>

            {!isEmpty(errors) && (
                <div className={classes.mainContent}>
                    {errors.remainingDurationInDays && (
                        <CustomizedFormErrorMessage text={errors.remainingDurationInDays.message} />
                    )}
                    {errors.submittedDurationComplete && (
                        <CustomizedFormErrorMessage text={errors.submittedDurationComplete.message} />
                    )}
                </div>
            )}

            <div className={classes.mainContent}>
                <div className={classes.title}>Constraint</div>
                <div className={classes.content}>
                    <Constraints data={data.activityConstraints} />
                </div>
            </div>
        </div>
    );
};
