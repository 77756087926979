import { IContract, IProject } from 'store/projectContract.store';
import { QueryResponse } from 'api/queries/versionList.query';

export const buildContractObj = (version: QueryResponse): IContract => {
    return {
        uuid: version.contractUuid,
        title: version.contractName,
        id: version.contractId,
    };
};
export const buildProjectObj = (version: QueryResponse): IProject => {
    return {
        uuid: version.projectUuid,
        title: version.projectName,
        id: version.projectId,
    };
};
