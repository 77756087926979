import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { ISingleSmallCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { QueryKeys } from 'api/queryKeys';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { SortingState } from '@tanstack/react-table';

const queryFn = (params: QueryParams) => {
    return network.post(API_URL().FETCH_PROGRAM_DASHBOARD_TOP_DELAY_DRIVERS, params).then((response) => {
        return response.data;
    });
};
export const useQueryActivities = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryFn: () => queryFn(params),
        queryKey: ['futurePotentialActivities', params],
        enabled: params?.id.length > 0,
    });
};

interface QueryParams {
    id: number[];
    paging: {
        page: number;
        size: number;
    };
    filter: {
        filters: IFilter[];
    };
    sortingRequestList: SortingState;
}

interface QueryResponse {
    delayDrivers: IActivitiesData[];
    info: IToolTip;
    numOfPages: number;
    numOfRows: number;
    startDate: number;
    finishDate: number;
}

export interface IActivitiesData {
    criticalityScore: number;
    float: null | number;
    taskVersionHashCode: string;
    plannedStartDate: number;
    plannedFinishDate: number;
    actualStartDate: number;
    actualFinishDate: number;
    numOfPredecessors: number;
    numOfSuccessors: number;
    projectWeekStart: number;
    projectWeekFinish: number;
    projectMonthStart: number;
    projectMonthFinish: number;
    activityId: string;
    parentName: string;
    grandParentName: string;
    plannedDuration: number;
    actualDuration: number;
    remainingDuration: number;
    completedDuration: number;
    activityType: string;
    activityName: string;
    overrunDays: number;
    overrunPercent: number;
    criticalPath: boolean;
    delayDriver: boolean;
    currentStartDate: number;
    currentFinishDate: number;
    baselineStartDate: number;
    baselineFinishDate: number;
    durationOverun: number;
    cardBoardViewResponse: ISingleSmallCardResponse;
}
