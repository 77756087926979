import React from 'react';
import classes from './AddSubProgramModal.module.scss';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase } from 'components/common';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { IContract, IProject, useProjectTreeStore } from 'store/projectContract.store';
import { useMutationCreateContract } from 'components/common/TreeProgramFilter/Queries/treeProgramFilterMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import { findeNodeByUUID, ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { useHistory } from 'react-router-dom';
import { useBasePath } from 'hooks/useBasePath';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

interface IAddSubProgramModalForm {
    name: string;
}

interface IAddSubProgramModal {
    showModal: boolean;
    onHideModal: () => void;
    callback?: (project: IProject | null, contract: IContract | null) => void;
}

export const AddSubProgramModal = ({ showModal, onHideModal, callback }: IAddSubProgramModal) => {
    const history = useHistory();
    const basePath = useBasePath();
    const { project, update, parentNode } = useProjectTreeStore();
    const { mutate: mutationCreateContract, isLoading: isLoadingCreateContract } = useMutationCreateContract();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();
    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        reset,
    } = useForm<IAddSubProgramModalForm>({
        defaultValues: {
            name: '',
        },
    });

    const handleSaveContractCallback = ({
        projectUid,
        contractUid,
        newData,
    }: {
        projectUid: ProjectTreeNode['uuid'];
        contractUid: ProjectTreeNode['uuid'];
        newData?: ProjectTreeNode;
    }) => {
        const projectNode = newData && projectUid ? findeNodeByUUID(newData, projectUid) : null;
        const contractNode = newData && contractUid ? findeNodeByUUID(newData, contractUid) : null;

        if (projectNode && contractNode) {
            update({
                project: {
                    id: projectNode.id,
                    title: projectNode.name,
                    uuid: projectNode.uuid,
                },
                contract: {
                    id: contractNode.id,
                    title: contractNode.name,
                    uuid: contractNode.uuid,
                },
            });
            if (callback) {
                callback(
                    {
                        id: projectNode.id,
                        title: projectNode.name,
                        uuid: projectNode.uuid,
                    },
                    {
                        id: contractNode.id,
                        title: contractNode.name,
                        uuid: contractNode.uuid,
                    },
                );
            }
        }
        update({ parentNode: null });
    };

    const handleSaveContract = (value: string) => {
        if (parentNode) {
            mutationCreateContract(
                {
                    contractName: value,
                    projectId: parentNode.id,
                },
                {
                    onSuccess: (response) => {
                        onHideModal();
                        Promise.all([
                            queryClient.invalidateQueries('getGeneralHierarchyList'),
                            queryClient.invalidateQueries('GetProgramGeneralHierarchyList'),
                        ]).then(() => {
                            const newData = queryClient.getQueryData<ProjectTreeNode>('getGeneralHierarchyList');
                            handleSaveContractCallback({
                                projectUid: parentNode.uuid,
                                contractUid: response.contractUid,
                                newData,
                            });
                            handleSnackBar('Sub-Program created successfully', 'success');

                            // should return to base url to show the data for the new contract
                            // not for program Library because we need to add the new project and contract ID's to the url
                            if (basePath !== `/dashboard/project/${projectConfig.programLibrary.link}`) {
                                history.push(basePath);
                            }
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(`'${value}' ${error.response?.data}`, 'error');
                    },
                },
            );
        }
    };

    const handleSaveUseForm = (value) => {
        handleSaveContract(value.name);
        closeModal();
    };

    const closeModal = () => {
        reset();
        onHideModal();
    };

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={closeModal}>
            <div className={classes.addSubProgramModalContainer}>
                <form onSubmit={handleSubmit(handleSaveUseForm)} autoComplete="off">
                    <div className={classes.title}>Add Sub-Program</div>
                    <div className={classes.inputTitle}>Project: {project?.title}</div>
                    <div className={classes.inputTitle}>Name *</div>

                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'Add text...'}
                                    isSearch={false}
                                    maxLength={30}
                                    handleClear={() => {
                                        setValue('name', '');
                                    }}
                                />
                            )}
                            name={`name`}
                            control={control}
                            rules={{
                                required: 'Name is Required',
                                minLength: {
                                    value: 2,
                                    message: 'Name is too short',
                                },
                                pattern: {
                                    value: /^(?!\s+$).*/,
                                    message: 'Name cannot be empty',
                                },
                                validate: {
                                    notValidName: (value) =>
                                        value.toLowerCase() !== 'all' || '"All" is not a valid sub-program name.',
                                },
                            }}
                        />
                    </div>

                    {errors.name && <CustomizedFormErrorMessage text={errors.name.message} />}

                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton
                                type="submit"
                                size={'large'}
                                color={'primary'}
                                isLoading={isLoadingCreateContract}
                                disabled={isLoadingCreateContract}
                            >
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </form>
            </div>
        </CustomizedModalBase>
    );
};
