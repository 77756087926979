export const statusOptions: ISelectOption<string>[] = [
    {
        value: 'TODO',
        label: 'To Do',
    },
    {
        value: 'IN_PROGRESS',
        label: 'In Progress',
    },
    {
        value: 'DONE',
        label: 'Done',
    },
];

export const wbsTabActivityTypeInitialOptions: ISelectOption<string>[] = [
    {
        value: 'WBS',
        label: 'WBS',
    },
];

export const activitiesTabActivityTypeInitialOptions: ISelectOption<string>[] = [
    {
        value: 'Task',
        label: 'Tasks',
    },
    {
        value: 'Milestone',
        label: 'Milestones',
    },
    {
        value: 'LOE',
        label: 'Level Of Effort(LOE)',
    },
];
