import { ExportCsv } from 'components/common/ExportDropdown/ExportCsv/exportCsv';
import { CustomizedDropdownMenu } from 'components/common/CustomizedDropdownMenu/CustomizedDropdownMenu';
import {
    convertComponentToImage,
    convertComponentsToPPTX,
    convertTableToPPTX,
} from 'components/common/ExportDropdown/exportDropdown.utils';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';

import useUserHook from 'hooks/useUserHook';
import React, { forwardRef, RefObject, useCallback } from 'react';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';

import { startCase } from 'lodash';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface Props {
    title?: string;
    subTitle: string;
    data?: any[];
    pptxData?: any;
    tableInstance?: any;
    handleAPICsvExport?: ({ isCompact }: { isCompact?: boolean }) => void;
    handleAPICsvExportCompact?: () => void;
    handleAPIXlsExport?: () => void;
    handleAPIExcelExport?: () => void;
    handleExrExport?: () => void;
    handleMppExport?: () => void;
    activeTabTitle?: string;
}
export const ExportDropdown = forwardRef(
    (
        {
            title = '',
            data,
            pptxData,
            tableInstance,
            subTitle,
            handleAPICsvExport,
            handleAPICsvExportCompact,
            handleAPIXlsExport,
            handleAPIExcelExport,
            handleExrExport,
            handleMppExport,
            activeTabTitle,
        }: Props,
        boundaryRef: React.ForwardedRef<HTMLDivElement>,
    ) => {
        const { sendEventWithDimensions } = useEventWithDimensions();
        const { logo } = useUserHook();
        const elementRef = boundaryRef as RefObject<HTMLDivElement>;
        const handleExportImage = useCallback(() => {
            sendEventWithDimensions({
                category: 'Export',
                action: 'Image',
                label: `${title} ${subTitle}`,
            });
            convertComponentToImage({
                elementRef: elementRef,
                widgetTitle: title,
                subTitle,
            });
        }, [boundaryRef, title, subTitle]);

        const handleExportPptx = useCallback(() => {
            sendEventWithDimensions({
                category: 'Export',
                action: 'PPTX',
                label: `${title} ${subTitle}`,
            });
            if (pptxData) {
                return convertTableToPPTX({
                    elementRef: boundaryRef,
                    widgetTitle: title,
                    logoSRC: logo,
                    linkToWidget: window.location.href,
                    pptxData,
                    subTitle,
                });
            } else {
                if (elementRef) {
                    return convertComponentsToPPTX({
                        elementRef: {
                            current: [{ el: elementRef.current, order: 0, subTitle, title }],
                        },
                        widgetTitle: title,
                        logoSRC: logo,
                        linkToWidget: window.location.href,
                    });
                }
            }
        }, [boundaryRef, title, subTitle, logo, pptxData]);
        const csvFileTitle = activeTabTitle
            ? `${title}_${startCase(activeTabTitle)}_${subTitle}`
            : `${title}_${subTitle}`;
        return (
            <CustomizedDropdownMenu triggerElement={<Trigger />}>
                <div onClick={handleExportImage}>Export to IMAGE</div>
                <div onClick={handleExportPptx}>Export to PPTX</div>
                {data && data.length > 0 && <ExportCsv tableInstance={tableInstance} fileTitle={csvFileTitle} />}
                {handleAPICsvExport && (
                    <div onClick={handleAPICsvExport}>
                        Export to CSV{`${handleAPICsvExportCompact ? ' (Full)' : ''}`}
                    </div>
                )}
                {handleAPICsvExportCompact && (
                    <div onClick={handleAPICsvExportCompact}>Export to CSV (Visible columns only)</div>
                )}
                {handleAPIXlsExport && <div onClick={handleAPIXlsExport}>Export to P6 Import Format</div>}
                {handleAPIExcelExport && <div onClick={handleAPIExcelExport}>Export to Excel</div>}
                {handleExrExport && <div onClick={handleExrExport}>Export to XER</div>}
                {/*{handleMppExport && <div onClick={handleMppExport}>Export to MS-Project XML</div>}*/}
            </CustomizedDropdownMenu>
        );
    },
);

const Trigger = () => (
    <CustomizedTooltip
        tooltipPosition={TooltipPosition.Top}
        tooltipContent={`Export`}
        triggerElement={
            <CustomizedRoundIcon>
                <MuiIcon icon={'download'} fontSize={'2.1rem'} />
            </CustomizedRoundIcon>
        }
    />
);
