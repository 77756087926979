import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import {
    IGateForMapping,
    IMappingData,
    IMilestones,
    ITemplate,
} from 'components/Dashboards/Portfolio/phasePerformance/manage/manage.types';
import { QueryStateValue } from 'hooks/useQueryState';

const getTemplates = () => {
    const url = API_URL().PHASE_PERFORMANCE_GET_All_TEMPLATES;

    return network
        .post(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getSingleTemplate = (templateId) => {
    const url = API_URL().PHASE_PERFORMANCE_GET_TEMPLATE;

    return network
        .post(url, { template_id: templateId })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getMilestones = (contractId) => {
    const url = API_URL().PHASE_PERFORMANCE_GET_MILESTONES;

    return network
        .post(url, { contract_id: contractId })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getMapping = (params: IGetMappingPayload) => {
    const url = API_URL().PHASE_PERFORMANCE_GET_MAPPING;

    delete params.contractUuid;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

interface IGetTemplatesResponse {
    info: IToolTip;
    templates: ITemplate[];
}

const useQueryGetTemplates = () => {
    return useQuery<IGetTemplatesResponse, QueryError>(['phasePerformanceTemplates'], () => getTemplates());
};

const useQueryGetSingleTemplate = (templateId: QueryStateValue, contractId?: QueryStateValue) => {
    return useQuery<IGateForMapping, QueryError>(
        ['phasePerformanceSingleTemplate', templateId],
        () => getSingleTemplate(templateId),
        {
            enabled: Boolean(templateId) && !Boolean(contractId),
            cacheTime: 0,
        },
    );
};

const useQueryGetMilestones = (contractId) => {
    return useQuery<IMilestones[], QueryError>(
        ['phasePerformanceMilestones', contractId],
        () => getMilestones(contractId),
        {
            enabled: Boolean(contractId),
        },
    );
};

interface IGetMappingPayload {
    template_id: QueryStateValue;
    contract_id: number | undefined;
    contractUuid?: string | null;
    phase_template_id: QueryStateValue;
}

const useQueryGetMapping = (params: IGetMappingPayload) => {
    return useQuery<IMappingData, QueryError>(['phasePerformanceMapping', params], () => getMapping(params), {
        enabled:
            Boolean(params.template_id) &&
            Boolean(params.contract_id) &&
            Boolean(params.contractUuid) &&
            Boolean(params.phase_template_id),
        cacheTime: 0,
    });
};

export { useQueryGetTemplates, useQueryGetSingleTemplate, useQueryGetMilestones, useQueryGetMapping };
