import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import { ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    project: IProject | null;
    contract: IContract | null;
    parentNode: ProjectTreeNode | null;
    currentList: ProjectTreeNode[];
    modalType: TModalType;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}

const state: StateCreator<Props> = (set) => ({
    project: null,
    contract: null,
    parentNode: null,
    currentList: [],
    modalType: null,
    update: (value) => set(() => ({ ...value })),
});

export const useProjectTreeStore = create(persist(state, { name: 'currentProject' }));

export type TModalType =
    | 'project'
    | 'subProgram'
    | 'REFRESH_SUB_PROGRAM'
    | 'REFRESH_PROJECT'
    | 'DELETE_SUB_PROGRAM'
    | 'DELETE_PROJECT'
    | 'RENAME_PROJECT'
    | 'RENAME_SUB_PROGRAM'
    | null;

export interface IProject {
    id: number;
    title: string;
    uuid: ProjectTreeNode['uuid'];
}

export interface IContract {
    id: number;
    title: string;
    uuid: ProjectTreeNode['uuid'];
}
