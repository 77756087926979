import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/libraryTable.module.scss';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import NoData from 'components/common/NoData/noData';
import { columnsV8 } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/RenderLibraryTable.utils';
import React from 'react';
import { useQueryGetProjectVersions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { useSubscription } from 'react-stomp-hooks';
import useUserHook from 'hooks/useUserHook';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import { useReactTableState } from 'hooks/useReactTableState';
import { IProgramVersion } from 'components/common/VersionCompareSelectorSection/VersionCompareSelectorWrapper/VersionCompareSelectorWrapper';
import { ProgramTags } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/programTags';
import { useProjectTreeStore } from 'store/projectContract.store';
import { useQueryGetTagsPerContract } from 'api/queries/getTagsPerContract.query';
import { CopyVersionModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/copyVersionModal/copyVersionModal';
import { useCopyVersionStore } from 'store/copyVersion.store';

const LibraryTable = () => {
    const { email } = useUserHook();
    const { project, contract } = useProjectTreeStore();
    const {
        data: versions,
        isLoading: isLoadingVersions,
        refetch: refetchVersion,
    } = useQueryGetProjectVersions({
        projectId: project?.id,
        contractId: contract?.id,
    });

    const { update } = useCopyVersionStore();
    const { data: tagsPerContract } = useQueryGetTagsPerContract({ contractId: contract?.id });

    useSubscription(email ? [`/topic/${email}.notifications.push`] : [], (response) => {
        const { type, message } = JSON.parse(response.body);
        if (type === 'VersionStatus') {
            if (message.metaDataId) {
                return refetchVersion();
            }
        }
    });

    const updatedVersions = versions
        ? versions.map((version) => {
              return {
                  ...version,
                  className:
                      version.status.toLowerCase() === 'pending'
                          ? classes.pendingStatus
                          : version.status.toLowerCase() === 'error'
                            ? classes.errorStatus
                            : '',
              };
          })
        : [];

    const { sorting, setSorting, columnFilters, setColumnFilters } = useReactTableState({
        initialSorting: [
            {
                id: 'versionDate',
                desc: true,
            },
        ],
    });

    return (
        <>
            <WidgetWithTitle
                className={classes.LibraryTableContainer}
                id={'uploaded_programs'}
                title={`Uploaded Programs (${versions?.length})`}
                titleComponents={<ProgramTags list={tagsPerContract} />}
            >
                <div className={classes.container}>
                    <TableV8<IProgramVersion>
                        columns={columnsV8}
                        data={updatedVersions}
                        sorting={sorting}
                        setSorting={setSorting}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        isLoading={isLoadingVersions}
                        manualSorting={false}
                        noData={
                            <NoData>
                                No programs uploaded yet for this sub-program. Click the plus button on top right to
                                upload program files.
                            </NoData>
                        }
                    />
                </div>
            </WidgetWithTitle>
            <CopyVersionModal
                onHideModal={() => update({ showModal: false, versionHashCode: null, versionId: null })}
            />
        </>
    );
};

export default LibraryTable;
