import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { TreeProgramTopHeader, VersionCompareSelectorWrapper } from 'components/common';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { TagsFilter } from 'components/common/GlobalFilterComponent/components/TagsFilter/TagsFilter';
import { ActivityCodesFilter } from 'components/common/GlobalFilterComponent/components/ActivityCodesFilter/ActivityCodesFilter';
import { WbsFilter } from 'components/common/GlobalFilterComponent/components/WbsFilter/wbsFilter';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { ResourcesAnalysisChartWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourcesAnalysisChart/resourcesAnalysisChartWrapper';
import { ResourceFilter } from 'components/common/GlobalFilterComponent/components/ResourceFilter/resourceFilter';
import { ResourceAnalysisTableWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourceAnalysisTable/resourceAnalysisTableWrapper';
import { ResourceTypeFilter } from 'components/common/GlobalFilterComponent/components/resourceTypeFilter/resourceTypeFilter';
import { QuickAnswerFilter } from 'components/common/GlobalFilterComponent/components/quickAnswerFilter/quickAnswerFilter';
import { DateRangeFilter } from 'components/common/GlobalFilterComponent/components/dateRangeFilter/dateRangeFilter';

export const ResourcesAnalysisPage = () => {
    useFullStoryPageView({ pageName: projectConfig.resourcesAnalysis.title });
    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={projectConfig.resourcesAnalysis.title}>
                            <ShareInsightButton
                                title={projectConfig.resourcesAnalysis.title}
                                link={window.location.href}
                            />
                            <GlobalFilterButton />
                        </TreeProgramTopHeader>
                        <VersionCompareSelectorWrapper allowSingleVersion={true} />
                    </VersionSection>
                </VersionWrapper>
                <GlobalFilterComponent
                    defaultValues={{
                        dateRangeFilter: 'R_4W',
                        quickAnswerFilter: null,
                        resourceType: null,
                        resource: null,
                        milestoneFragnet: null,
                        tags: [],
                        activityCodes: [],
                        wbs: null,
                    }}
                >
                    <DateRangeFilter name={'dateRangeFilter'} />
                    <QuickAnswerFilter name={'quickAnswerFilter'} />
                    <ResourceTypeFilter name={'resourceType'} />
                    <ResourceFilter name={'resource'} />
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                    <TagsFilter name={'tags'} />
                    <ActivityCodesFilter name={'activityCodes'} />
                    <WbsFilter name={'wbs'} />
                </GlobalFilterComponent>
            </HeaderWrapper>

            <MainContent>
                <ResourcesAnalysisChartWrapper />
                <ResourceAnalysisTableWrapper />
            </MainContent>
            <MainCardRoutingModal queryKeys={['getResourceTable', 'getResourceChart', 'getStoredTags']} />
        </PageContainer>
    );
};
