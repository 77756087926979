import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';

const mutationFn = (params: QueryParams) => {
    const fileName = params.exportType === 'XER' ? `${params.fileName}.xer` : `${params.fileName}.mpp.xml`;
    delete params.fileName;

    return network
        .post(API_URL().EXPORT_XER_OR_MPP, params)
        .then((response) => {
            const blob = new Blob([response.data], {
                type: 'text/plain',
            });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();

            return true;
        })
        .catch((error) => {
            throw error;
        });
};

export const useMutationExportXerOrMpp = () => {
    return useMutation<unknown, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    metaDataId: number;
    exportType: string;
    fileName?: string;
}
