import {
    generateTabs,
    TabKeys,
} from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivityTabs/simulatedActivitiesTabs.utils';
import { CustomizedTabs } from 'components/common/CustomizedTabs/CustomizedTabs';
import React from 'react';

import { useSimulatedActivitiesFiltersStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesFilter/simulatedActivitiesFilter.store';
import {
    activitiesTabActivityTypeInitialOptions,
    statusOptions,
    wbsTabActivityTypeInitialOptions,
} from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesFilter/simulatedActivitiesFilter.utils';

export const SimulatedActivitiesTabs = () => {
    const { activeTab, update: updateFilters } = useSimulatedActivitiesFiltersStore();

    const tabs = generateTabs();
    const handleActiveTab = (tab: TabKeys) => {
        if (tab === 'activities') {
            updateFilters({
                activeTab: tab,
                search: '',
                activityTypeFilter: activitiesTabActivityTypeInitialOptions,
                statusFilter: statusOptions,
            });
        } else if (tab === 'wbs') {
            updateFilters({
                activityTypeFilter: wbsTabActivityTypeInitialOptions,
                statusFilter: [],
                search: '',
                isCriticalPathFilter: false,
                isCriticalPathChangedFilter: false,
                activeTab: tab,
            });
        }
    };

    return <CustomizedTabs<TabKeys> tabs={tabs} activeTab={activeTab} setActiveTab={handleActiveTab} />;
};
