import classes from 'components/common/Tables/Cells/DetailsCell.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import { AddCommentWidget } from 'components/common/Tables/CommonWidgets/AddCommentWidget/AddCommentWidget';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import colorsVars from 'styles/colors.module.scss';
const { white } = colorsVars;
export interface ICardLastComment {
    lastComment: string;
    lastCommentCreatedDate: number;
    lastCommentFirstUserName: string;
    lastCommentLastUserName: string;
}

interface IDetailsCellProps {
    details: string;
    cardLastComment: ICardLastComment | null;
    id: number;
    invalidateQueriesKey: string[];
    isVirtual: boolean;
    isGenAiDetails: boolean;
}

export const DetailsCell = ({
    details,
    cardLastComment,
    id,
    invalidateQueriesKey,
    isVirtual,
    isGenAiDetails,
}: IDetailsCellProps) => {
    const [isAddComment, setIsAddComment] = useState<boolean>(false);
    const addComment = () => {
        setIsAddComment(true);
    };

    const now = moment();
    const publishedDate =
        cardLastComment &&
        moment(cardLastComment.lastCommentCreatedDate).format(constants.formats.date.fullDateWithMilliSeconds);
    const publishedDateHumanize =
        cardLastComment && moment.duration(moment(cardLastComment?.lastCommentCreatedDate).diff(now)).humanize(true);

    const isMoreThenMonthAgo = now?.diff(moment(cardLastComment?.lastCommentCreatedDate), 'month') !== 0;

    return (
        <div className={classes.detailsCell}>
            {isAddComment ? (
                <AddCommentWidget
                    id={id}
                    setIsAddComment={setIsAddComment}
                    invalidateQueriesKey={invalidateQueriesKey}
                    isVirtual={isVirtual}
                />
            ) : (
                <CustomizedTooltip
                    contentStyles={{ maxWidth: 800 }}
                    tooltipContent={
                        <div>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: cardLastComment ? cardLastComment.lastComment : details,
                                }}
                            />
                        </div>
                    }
                    triggerElement={
                        <div className={classes.content}>
                            {cardLastComment ? (
                                <div>
                                    <span dangerouslySetInnerHTML={{ __html: cardLastComment.lastComment }} />
                                </div>
                            ) : (
                                <div>
                                    <span dangerouslySetInnerHTML={{ __html: details }} />
                                    {isGenAiDetails && (
                                        <div className={classes.aiGenerated}>AI Generated narrative</div>
                                    )}
                                </div>
                            )}
                        </div>
                    }
                />
            )}

            {cardLastComment && !isAddComment && (
                <div className={classes.latestCommentDetails}>
                    <span>Latest comment,</span>
                    <span>{isMoreThenMonthAgo ? publishedDate : publishedDateHumanize}</span>
                    <span>
                        by {cardLastComment.lastCommentFirstUserName} {cardLastComment.lastCommentLastUserName}
                    </span>
                </div>
            )}

            {!isAddComment && (
                <div className={classes.actionButtons}>
                    <CustomizedTooltip
                        tooltipContent={'Add Comment'}
                        triggerElement={
                            <CustomizedRoundIcon
                                onClick={addComment}
                                backGroundColor={'#2C8FA5'}
                                hoverColor={'#0a6577'}
                            >
                                <MuiIcon icon={'playlist_add'} color={white} />
                            </CustomizedRoundIcon>
                        }
                    />
                </div>
            )}
        </div>
    );
};
