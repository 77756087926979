import { useQuery } from 'react-query';
import qs from 'qs';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { QueryKeys } from 'api/queryKeys';

const queryFn = ({
    latestVersion,
    filter,
    taskVersionHashCode,
}: {
    latestVersion: number;
    filter?: string;
    taskVersionHashCode?: string | null;
}) => {
    const querystring = qs.stringify(
        { metaDataId: latestVersion, filter, taskVersionHashCode },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().GET_FRAGNET_MILESTONES}${querystring}`;

    return network.post(url).then((response) => {
        return response.data;
    });
};
export const useQueryGetMilestoneFragnet = (params: QueryParams) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['getMilestoneFragnet', params],
        queryFn: () =>
            queryFn({
                latestVersion: params.latestVersion as number,
                filter: params.filter,
            }),
        enabled: Boolean(params.latestVersion),
    });
};
export const useQueryGetSingleMilestoneFragnet = (params: {
    latestVersion?: number;
    taskVersionHashCode?: string | null;
}) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['getSingleMilestoneFragnet', params],
        queryFn: () =>
            queryFn({
                latestVersion: params.latestVersion as number,
                taskVersionHashCode: params.taskVersionHashCode,
            }),
        enabled: Boolean(params.latestVersion) && Boolean(params.taskVersionHashCode),
    });
};

export interface QueryResponse {
    id: number;
    activity_id: string;
    name: string;
    activity_status: string;
    activity_type: string;
    preceding_activities: number;
    succeding_activities: number;
    task_version_hash_code: string;
}

interface QueryParams {
    latestVersion?: number;
    filter: string;
}
