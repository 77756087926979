import colorsVars from 'styles/colors.module.scss';
import { ColumnDef } from '@tanstack/react-table';
import { QueryResponse } from 'api/queries/getRunRateAnalysisBreakdown.query';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { CustomizedTooltip } from 'components/common';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { lowerCase, startCase } from 'lodash';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { DetailsCell } from 'components/common/Tables/Cells/DetailsCell';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import CriticalityScoreCell from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/Cells/criticalityScoreCell';
import { customSortWithNullV8 } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { PillsCell } from 'components/common/Tables/Cells/PillsCell';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import React from 'react';

const { color6: orange, delayDriverColor, textColor } = colorsVars;

const invalidateKeys = [];

export const columnsV8: ColumnDef<QueryResponse['cardList'][0]>[] = [
    {
        header: 'Activity',
        accessorKey: 'title',
        ...columnsCommonConfig.activity,
        cell: ({ row, table }) => {
            const { taskVersionHashCode, isTracked, isVirtual } = row.original;
            const activityName = row.original.title;
            const activityId = row.original.type.id === 2 ? row.original.taskActivityId : `C-${row.original.id}`;
            const taskId = row.original.id;

            const cardIndicators = row.original.cardIndicators;
            const { getToggleSelectedHandler } = row;
            const { toggleAllRowsSelected } = table;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={taskId}
                    isVirtual={isVirtual}
                    cardIndicators={cardIndicators}
                    taskVersionHashCode={taskVersionHashCode}
                    isTracked={isTracked}
                    toggleRowSelected={getToggleSelectedHandler()}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        header: 'Status',
        accessorKey: 'status.label',
        ...columnsCommonConfig.status,
        cell: ({ row }) => {
            const { assignee, startDate, endDate, status, isVirtual, originalStatus, id, isStatusOrDatesChanged } =
                row.original;
            const startDateFormatted = moment(startDate).format(constants.formats.date.default);
            const endDateFormatted = moment(endDate).format(constants.formats.date.default);
            const wasStatus = `${startDateFormatted} - ${endDateFormatted}`;
            const cardType = row.original.type.name;
            const category = row.original.category;

            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={id}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    wasStatus={wasStatus}
                    cardType={cardType}
                    category={category}
                    isStatusOrDatesChanged={isStatusOrDatesChanged}
                />
            );
        },
    },
    {
        header: 'Submitted Completion',
        ...columnsCommonConfig.submittedDurationComplete,
        accessorKey: 'submittedDurationComplete',
        cell: ({ getValue }) => {
            const value = getValue();
            return (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${value}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(value)} />}
                />
            );
        },
    },
    {
        header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessorFn: (original) =>
            original.type?.name === 'PROGRAMME_ACTIVITY' ? original.taskActivityType : original.type?.label,
        cell: ({ getValue }) => <TooltipCellWithCopy text={getValue()} />,
    },
    {
        header: 'Details/Last Comment',
        accessorKey: 'details',
        ...columnsCommonConfig.details,
        cell: ({ row }) => {
            const { cardLastComment, details, id, isVirtual, isGenAiDetails } = row.original;
            return (
                <DetailsCell
                    details={details}
                    cardLastComment={cardLastComment}
                    id={id}
                    invalidateQueriesKey={invalidateKeys}
                    isVirtual={isVirtual}
                    isGenAiDetails={isGenAiDetails}
                />
            );
        },
    },
    {
        header: 'Critical Path',
        accessorFn: (row) => (row.cardIndicators.isCriticalPath.isIndicatorOn ? 'Yes' : 'No'),
        ...columnsCommonConfig.criticalPath,
    },
    {
        header: 'Start Date',
        ...columnsCommonConfig.startDate,
        accessorKey: 'startDate',
        cell: ({ getValue, row }) => {
            const changeMapDate = row.original.startVariance;
            const comparedVersion = row.original.baselineStartDate;
            return (
                <DateCell
                    latestVersionDate={getValue()}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        header: 'Finish Date',
        ...columnsCommonConfig.finishDate,
        accessorKey: 'endDate',
        cell: ({ getValue, row }) => {
            const changeMapDate = row.original.finishVariance;
            const comparedVersion = row.original.baselineFinishDate;
            return (
                <DateCell
                    latestVersionDate={getValue()}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        header: 'Baseline Start Date ',
        accessorKey: 'baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Baseline Finish Date',
        accessorKey: 'baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Remaining Duration',
        ...columnsCommonConfig.remainingDuration,
        accessorKey: 'remainingDurationInDays',
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Immediate Parent Name ',
        ...columnsCommonConfig.parentName,
        accessorKey: 'parentActivity',
        cell: ({ getValue }) => <TooltipCellWithCopy text={getValue()} />,
    },
    {
        header: 'Grand Parent Name',
        ...columnsCommonConfig.grandParentName,
        accessorKey: 'grandParentActivity',
        cell: ({ getValue }) => <TooltipCellWithCopy text={getValue()} />,
    },
    {
        header: 'Criticality Score',
        accessorKey: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        cell: ({ getValue, row }) => {
            const delayDriver = row.original.cardIndicators.isDelayDriver.isIndicatorOn;
            const style = delayDriver ? { color: delayDriverColor, fontWeight: 600 } : {};
            const numOfPredecessors = row.original.numOfPredecessors || row.original.predeccessors?.length;
            const numOfSuccessors = row.original.numOfSuccessors || row.original.successors?.length;
            const precedingActivities = row.original.precedingActivities;
            const succeedingActivites = row.original.succeedingActivites;
            const precedingRisk = row.original.precedingRisk;
            const succeedingRisk = row.original.succeedingRisk;
            return (
                <CriticalityScoreCell
                    succeedingRisk={succeedingRisk}
                    precedingRisk={precedingRisk}
                    numOfSuccessors={numOfSuccessors}
                    numOfPredecessors={numOfPredecessors}
                    succeedingActivites={succeedingActivites}
                    precedingActivities={precedingActivities}
                    style={style}
                    value={getValue()}
                />
            );
        },
        sortingFn: 'basic',
    },
    {
        header: 'Float',
        accessorKey: 'float',
        ...columnsCommonConfig.float,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Tags',
        accessorKey: 'tagList',
        ...columnsCommonConfig.tagList,
        cell: ({ getValue }) => {
            const list = getValue().map((item) => item.name);
            return list.length > 0 ? <PillsCell list={list} type={'tag'} /> : null;
        },
    },
    {
        header: 'Start Variance',
        ...columnsCommonConfig.lateStart,
        accessorKey: 'startVariance',
        cell: ({ getValue }) => {
            const value = getValue<number>();
            return <VarianceCell value={Math.ceil(value)} />;
        },
    },
    {
        header: 'Finish Variance',
        ...columnsCommonConfig.lateFinish,
        accessorKey: 'finishVariance',
        cell: ({ getValue }) => {
            const value = getValue<number>();
            return <VarianceCell value={Math.ceil(value)} />;
        },
    },
    {
        header: 'Planned Start Date ',
        accessorKey: 'plannedStartDate',
        ...columnsCommonConfig.plannedStartDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Planned Finish Date',
        accessorKey: 'plannedFinishDate',
        ...columnsCommonConfig.plannedFinishDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Actual Start Date',
        accessorKey: 'actualStartDate',
        ...columnsCommonConfig.actualStartDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Actual Finish Date',
        accessorKey: 'actualFinishDate',
        ...columnsCommonConfig.actualFinishDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Planned Duration',
        ...columnsCommonConfig.plannedDuration,
        accessorKey: 'plannedDurationInDays',
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Actual Duration',
        ...columnsCommonConfig.actualDuration,
        accessorKey: 'actualDurationInDays',
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Current Duration',
        ...columnsCommonConfig.completedDuration,
        accessorKey: 'completedDurationInDays',
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Current Duration Overrun',
        ...columnsCommonConfig.durationOverrun,
        accessorKey: 'completedDurationOverrun',
        cell: ({ getValue }) => {
            const value = getValue<number>();
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{Math.ceil(value)}</span>;
        },
    },
    {
        header: 'Pred.',
        accessorKey: 'numOfPredecessors',
        ...columnsCommonConfig.numOfPredecessors,
    },
    {
        header: 'Succ.',
        accessorKey: 'numOfSuccessors',
        ...columnsCommonConfig.numOfSuccessors,
    },
    {
        header: 'Project Week Start ',
        accessorKey: 'projectWeekStart',
        ...columnsCommonConfig.projectWeekStart,
    },
    {
        header: 'Project Week Finish',
        accessorKey: 'projectWeekFinish',
        ...columnsCommonConfig.projectWeekFinish,
    },
    {
        header: 'Project Month Start',
        accessorKey: 'projectMonthStart',
        ...columnsCommonConfig.projectMonthStart,
    },
    {
        header: 'Project Month Finish',
        accessorKey: 'projectMonthFinish',
        ...columnsCommonConfig.projectMonthFinish,
    },
    {
        header: 'Last Submitted Status',
        ...columnsCommonConfig.originalStatus,
        accessorKey: 'originalStatus',
        cell: ({ getValue }) => (getValue() ? getValue<{ id: number; label: string; name: string }>().label : ''),
    },
];

export const initialState: ITableColumnState = {
    hiddenColumns: [
        'currentStartDate',
        'currentFinishDate',
        'lateFinish',
        'lateStart',
        'plannedStartDate',
        'plannedFinishDate',
        'actualStartDate',
        'actualFinishDate',
        'plannedDuration',
        'actualDuration',
        'completedDuration',
        'durationOverrun',
        'numOfPredecessors',
        'numOfSuccessors',
        'projectWeekStart',
        'projectWeekFinish',
        'projectMonthStart',
        'projectMonthFinish',
        'tagList',
        'originalStatus',
        'baselineStartDate',
        'baselineFinishDate',
        'details',
    ],
};
