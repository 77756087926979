import classes from './ImportantActivities.module.scss';
import React, { useEffect, useState } from 'react';
import SliderComponent from 'components/common/Slider/Slider';
import {
    useMutationSetSubProgramPreferenceImportance,
    useQueryGetSubProgramPreferenceImportance,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQuerySubProgramPreferences';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { ConfirmSaveModal, CustomizedButton } from 'components/common';
import { useProjectTreeStore } from 'store/projectContract.store';
import subProgramClasses from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/components/subProgramPreferences.module.scss';

export const ImportantActivities = () => {
    const { project, contract } = useProjectTreeStore();
    const [actionButtonDisabled, setActionButtonDisabled] = useState<boolean>(true);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [topScores, setTopScores] = useState<number>(10);
    const [submittedCompletions, setSubmittedCompletions] = useState<number>(0);

    const { handleSnackBar } = useCustomSnackBar();

    const { data, refetch } = useQueryGetSubProgramPreferenceImportance({ subProgramId: contract?.id });
    const { mutate, isLoading } = useMutationSetSubProgramPreferenceImportance();

    const resetDefault = () => {
        if (data) {
            setTopScores(data.defaultTopCriticalityPercent);
            setSubmittedCompletions(data.defaultCompletionPercent);
        }
    };

    useEffect(() => {
        resetDefault();
        setActionButtonDisabled(true);
    }, [data]);

    const handleHideModal = () => {
        setShowConfirmModal(false);
        setActionButtonDisabled(true);
        resetDefault();
    };

    const handleTopScores = (e) => {
        setTopScores(e.target.value);
        if (
            data?.defaultTopCriticalityPercent === e.target.value &&
            submittedCompletions === data?.defaultCompletionPercent
        ) {
            setActionButtonDisabled(true);
        } else {
            setActionButtonDisabled(false);
        }
    };

    const handleSubmittedCompletions = (e) => {
        setSubmittedCompletions(e.target.value);
        if (data?.defaultCompletionPercent === e.target.value && topScores === data?.defaultTopCriticalityPercent) {
            setActionButtonDisabled(true);
        } else {
            setActionButtonDisabled(false);
        }
    };

    const handleSave = () => {
        if (project && contract) {
            setShowConfirmModal(false);
            mutate(
                {
                    projectId: project.id,
                    subProgramId: contract.id,
                    topCriticalityPercent: topScores,
                    completionPercent: submittedCompletions,
                    isNeedRefresh: true,
                },
                {
                    onSuccess: () => handleSnackBar('Important Activities has changed', 'success'),
                    onError: (error) => handleSnackBar(error?.response?.data || 'Something went wrong ', 'error'),
                    onSettled: () => refetch(),
                },
            );
        }
    };

    const topScoresLabelFormat = (value) => {
        return <div className={classes.labelSize}>{value === 100 ? `${value} (Any score)` : value}</div>;
    };

    const submittedCompletionsLabelFormat = (value) => {
        return <div className={classes.labelSize}>{value === 0 ? `${value} (Any Completion %)` : value}</div>;
    };

    return (
        <div className={classes.importantActivitiesContainer}>
            <span className={subProgramClasses.title}>Important Activities:</span>
            <div data-testid={'important-activity-container'} className={classes.container}>
                <div>Important Activity is</div>
                <div className={classes.sectionWrapper}>
                    <div className={classes.subTitle}>
                        on Critical Path <span className={classes.boldText}>Or</span> its Criticality Score is in the
                        top %:
                    </div>
                    <div className={classes.sliderWrapper}>
                        <SliderComponent
                            value={topScores}
                            step={1}
                            min={1}
                            max={100}
                            valueLabelDisplay={'on'}
                            onChange={handleTopScores}
                            valueLabelFormat={topScoresLabelFormat}
                        />
                    </div>
                </div>
                <div>And</div>
                <div className={classes.sectionWrapper}>
                    <div className={classes.subTitle}>
                        in Status Incomplete where Submitted Completion % is greater or equal to:
                    </div>
                    <div className={classes.sliderWrapper}>
                        <SliderComponent
                            value={submittedCompletions}
                            step={1}
                            min={0}
                            max={100}
                            valueLabelDisplay={'on'}
                            onChange={handleSubmittedCompletions}
                            valueLabelFormat={submittedCompletionsLabelFormat}
                        />
                    </div>
                </div>
                <div className={classes.actionButtons}>
                    <CustomizedButton color={'secondary'} onClick={handleHideModal}>
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton
                        color={'primary'}
                        onClick={() => setShowConfirmModal(true)}
                        disabled={actionButtonDisabled}
                    >
                        Save
                    </CustomizedButton>
                </div>
            </div>

            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={isLoading}
            >
                You have made changes which require sub-program refresh, which may take a few minutes. Are you sure you
                like to apply this change?
            </ConfirmSaveModal>
        </div>
    );
};
