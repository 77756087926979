import React, { memo } from 'react';
import { MainFilters } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters';
import { useVersionStore } from 'store/version.store';
import { useAssigneeOptionsList } from 'hooks/useAssigneeOptionsList';
import { useQueryGetBoardInit } from 'api/queries/getBoardInit.query';

export const FiltersWrapper = memo(() => {
    const currentVersion = useVersionStore((state) => state.version);
    const assigneeOptions = useAssigneeOptionsList();

    const { data: boardInit } = useQueryGetBoardInit({
        metaDataId: currentVersion?.id,
    });

    return (
        <>
            {boardInit && Object.keys(boardInit).length > 0 && assigneeOptions.length > 0 && (
                <MainFilters boardInit={boardInit} assigneeOptions={assigneeOptions} />
            )}
        </>
    );
});
