import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation, useQuery } from 'react-query';
import qs from 'qs';
import {
    IAssignee,
    IStatus,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

const getLogicCheckerSummary = (params) => {
    return network
        .post(API_URL().LOGIC_CHECKER_SUMMARY, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getLogicCheckerCategoryResults = (params) => {
    return network
        .post(API_URL().LOGIC_CHECKER_CATEGORIES, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getLogicCheckerConfiguration = ({ contractId }: { contractId?: number }) => {
    const querystring = qs.stringify({ contractId }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().LOGIC_CHECKER_CONFIGURATION}${querystring}`;
    return network
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getValidationTypeDrillDown = (params) => {
    return network
        .post(API_URL().LOGIC_CHECKER_VALIDATION_TYPE_DRILLDOWN, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getValidationActivitiesDrillDown = (params) => {
    return network
        .post(API_URL().LOGIC_CHECKER_VALIDATION_ACTIVITIES_DRILLDOWN, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getRowDrillDownActivities = (params) => {
    const url = API_URL().LOGIC_CHECKER_ROW_DRILLDOWN_ACTIVITIES;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const setCheckerConfigParams = (params) => {
    return network
        .post(API_URL().LOGIC_CHECKER_SET_CONFIG_PARAMS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const resetConfigParams = (params) => {
    return network
        .post(API_URL().LOGIC_CHECKER_RESET_CONFIG_PARAMS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const copyQualitySettings = (params) => {
    return network
        .post(API_URL().LOGIC_CHECKER_COPY_QUALITY_SETTINGS, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const setCheckMandatorily = (params) => {
    return network
        .post(API_URL().LOGIC_CHECKER_SET_CHECK_MANDATORY, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const setAssociateCardWithLogicChecker = (params) => {
    return network
        .post(API_URL().LOGIC_CHECKER_SET_ASSOCIATE_CARD_WITH_LOGIC_CHECKER, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetValidationTypeDrillDown = (params) => {
    return useQuery<IValidationTypeDrillDownResponse, QueryError>(
        ['getValidationTypeDrillDown', params],
        () => getValidationTypeDrillDown(params),
        {
            enabled: Boolean(params.componentId),
        },
    );
};

export const useQueryGetValidationActivitiesDrillDown = (params) => {
    return useQuery(['getValidationActivitiesDrillDown', params], () => getValidationActivitiesDrillDown(params), {
        enabled: Boolean(params.componentId),
    });
};

export const useQueryGetRowDrillDownActivities = (params) => {
    return useQuery(['getRowDrillDownActivities', params], () => getRowDrillDownActivities(params), {
        enabled: Boolean(params.checkId) && Boolean(params.columnAccessor) && Boolean(params.rowAccessor),
    });
};

export const useMutationSetCheckerConfigParams = () => {
    return useMutation<any, QueryError, ConfigParams>('setCheckerConfigParams', (params) =>
        setCheckerConfigParams(params),
    );
};

export const useMutationResetConfigParams = () => {
    return useMutation<any, QueryError, IResetConfigParams>('resetConfigParams', (params) => resetConfigParams(params));
};

export const useMutationCopyQualitySettings = () => {
    return useMutation<any, QueryError, ICopyQualitySettings>('copyQualitySettings', (params) =>
        copyQualitySettings(params),
    );
};

export const useQueryGetLogicCheckerSummary = (params) => {
    return useQuery<ILogicCheckerSummaryResponse, QueryError>(
        ['getLogicCheckerSummary', params],
        () => getLogicCheckerSummary(params),
        {
            enabled: Boolean(params.fileMetaDataId),
        },
    );
};

export const useQueryGetLogicCheckerCategoryResults = (params) => {
    return useQuery<ILogicCheckerCategoryResultsResponse, QueryError>(
        ['getLogicCheckerCategoryResults', params],
        () => getLogicCheckerCategoryResults(params),
        {
            enabled: Boolean(params.fileMetaDataId),
        },
    );
};

export const useQueryGetLogicCheckerConfiguration = (params: { contractId?: number }) => {
    return useQuery<ILogicCheckerConfigurationResponse[], QueryError>(
        ['getLogicCheckerConfiguration', params],
        () => getLogicCheckerConfiguration(params),
        {
            enabled: Boolean(params.contractId),
        },
    );
};

export const useMutationSetCheckMandatory = () => {
    return useMutation<
        boolean,
        QueryError,
        {
            checkId: number;
            mandatory: boolean;
            metaDataId: number;
        }
    >((params) => setCheckMandatorily(params));
};

export const useMutationSetAssociateCardWithLogicChecker = () => {
    return useMutation<any, QueryError, ISetAssociateCardWithLogicCheckerPayload>('copyQualitySettings', (params) =>
        setAssociateCardWithLogicChecker(params),
    );
};

export interface ISetAssociateCardWithLogicCheckerPayload {
    metaDataId?: number;
    checkId?: number;
    cardId: number;
}

export interface ILogicCheckerSummaryResponse {
    title: string;
    totalActivities: number;
    totalMilestones: number;
    maxWBSLevel: number;
    overallScore: string;
    overallScoreColor: string;
    notInScoreList: {
        categoryName: string;
        checkName: string;
    }[];
    info: IToolTip;
    inScoreNumber: number;
}

export interface ILogicCheckerCategoryResultsResponse {
    categoriesSummaryHeaders: {
        accessor: string;
        clickable: boolean;
        colorable: boolean;
        header: string;
        order: number;
        sortable: string;
    }[];
    categoriesSummaryRows: {
        category: string;
        complianceColor: string;
        compliantActivities: number;
        compliantActivitiesPersent: string;
        nonCompliantActivities: number;
        nonCompliantActivitiesPersent: string;
        scoreImpact: number;
        validationHeaders: {
            accessor: string;
            clickable: boolean;
            colorable: boolean;
            header: string;
            order: number;
            sortable: string;
        }[];
        validationRows: {
            complianceColor: string;
            compliantActivities: number;
            compliantActivitiesPersent: string;
            nonCompliantActivities: number;
            nonCompliantActivitiesPersent: string;
            scoreImpact: number;
            validationId: number;
            validationName: string;
            dcma: boolean;
            mandatory: boolean;
            associatedCard: {
                cardId: null | number;
                status: IStatus;
                assignee: IAssignee;
            };
        }[];
    }[];
    title: string;
}

export interface IValidationTypeDrillDownResponse {
    headers: {
        accessor: string;
        clickable: boolean;
        colorable: boolean;
        header: string;
        order: number;
        sortable: string;
    }[];
    rows: { [key: string]: unknown }[];
    title: string;
    validationDescription: string;
}

export interface ILogicCheckerConfigurationResponse {
    validationId: number;
    validationName: string;
    thresholds: {
        threshold_id: number;
        threshold_description: string | null;
        upper_limit_value: number;
        upper_limit_operator: '>';
        lower_limit_value: number;
        lower_limit_operator: '<=';
        units: '%';
        color_low_level: 'PASS' | 'FAIL';
        color_high_level: 'PASS' | 'FAIL';
        maxValue: number;
        minValue: number;
        step: number;
    }[];
    mandatory: boolean;
}

export interface ConfigParams {
    configId: number;
    lowerLimit: number;
    upperLimit: number;
    metaDataId: number;
}

export interface IResetConfigParams {
    configId: number;
    metaDataId: number;
}

export interface ICopyQualitySettings {
    fromContractId: number;
    toContractIds: number[];
}
