import React, { useState } from 'react';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import colorsVars from 'styles/colors.module.scss';
import classes from './ClusterActivityRowOptionCell.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useClusterRCFAnalysisStore } from 'store/clusterRCFAnalysis.store';
import { ConfirmSaveModal } from 'components/common/ConfirmSaveModal/ConfirmSaveModal';
import { useQueryState } from 'hooks/useQueryState';
import { useQueryClient } from 'react-query';
import { compareOptions } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo';
import { useMutationDeleteTaskFromCluster } from 'api/mutations/deleteTaskFromCluster.mutation';
const { errorRedColor } = colorsVars;

export const ClusterActivityRowOptionCell = ({ id }: { id: number }) => {
    const selectedClusterId = useClusterRCFAnalysisStore((store) => store.selectedClusterId);
    const [compare] = useQueryState<string>('clusterScope');
    const compareKey = compareOptions.find((item) => item.value === compare)?.value;
    const queryClient = useQueryClient();
    const [show, setShow] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutationDeleteTaskFromCluster } = useMutationDeleteTaskFromCluster();
    const handleDelete = () => {
        if (selectedClusterId?.clusterId && compareKey) {
            mutationDeleteTaskFromCluster(
                {
                    taskIds: [id],
                    clusterId: selectedClusterId?.clusterId,
                    clusterType: compareKey,
                },
                {
                    onSuccess: () => {
                        return queryClient.invalidateQueries('clustersData').then(() => {
                            handleSnackBar('Task removed', 'success');
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                },
            );
        }
        setShow(false);
    };
    return (
        <div className={`${classes.rowOptionsCell}`}>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Top}
                tooltipContent={'Remove Task from Cluster'}
                triggerElement={
                    <MuiIcon
                        icon={'highlight_off'}
                        color={errorRedColor}
                        fontSize="3rem"
                        onClick={() => setShow(true)}
                    />
                }
            />

            <ConfirmSaveModal
                isShowModal={show}
                onHideModal={() => setShow(false)}
                onConfirm={handleDelete}
                actionButtonLabel={'Yes'}
            >
                Are you sure you want to remove this task from the cluster?
            </ConfirmSaveModal>
        </div>
    );
};
