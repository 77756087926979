export type SortValueOptions = 'selectedColumn' | 'rank' | 'statusChange' | 'finishVariance';

export const sortByRank: ISelectOption<SortValueOptions> = {
    value: 'rank',
    label: 'Activity Date',
};

export const sortedByColumn: ISelectOption<SortValueOptions> = {
    value: 'selectedColumn',
    label: 'Selected Column',
};

export const sortByStatus: ISelectOption<SortValueOptions> = {
    value: 'statusChange',
    label: 'Status Changed',
};

export const sortByFinishDateVariance: ISelectOption<SortValueOptions> = {
    value: 'finishVariance',
    label: 'Finish Date Variance',
};

export const sortingOptions: ISelectOption<SortValueOptions>[] = [
    sortedByColumn,
    sortByStatus,
    sortByFinishDateVariance,
    sortByRank,
];
