import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { IFilterValue } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import { orderBy } from 'lodash';
import { FilterNames } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { QueryKeys } from 'api/queryKeys';

const queryFn = () => {
    const url = new URL(API_URL().FETCH_PROGRAM__KEY_ACHIEVEMENTS_TABLE_FILTERS);
    url.searchParams.set('ApplicationComponentType', 'KEY_ACHIEVEMENT');
    return network.get(url.toString()).then((response) => orderBy(response.data, ['order', 'asc']));
};

export const useQueryGetAchievementsFilters = () => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], QueryKeys>({
        queryKey: 'AchievementsFilters',
        queryFn,
    });
};

export interface QueryResponse {
    title: string;
    filterValues: IFilterValue[];
    filterName?: keyof typeof FilterNames;
}
