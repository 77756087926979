import moment from 'moment-timezone';
import { ApexOptions } from 'apexcharts';
import { Aggregation } from 'store/productivityRate.store';
import { Range } from 'components/common/GlobalFilterComponent/components/RangeFilter/rangeFilter';
import constants from 'components/common/Constants/constants';
import { QueryResponse } from 'api/queries/productivityRate.query';
import colorsVars from 'styles/colors.module.scss';

const { runratePastPlanned, runratePastActual, runrateFuturePlanned, runrateFutureActual } = colorsVars;

export const categoriesKeys = [
    { key: 'previousPlanned', label: 'Past Planned', color: runratePastPlanned },
    { key: 'previousActual', label: 'Past Actual', color: runratePastActual },
    { key: 'futurePlanned', label: 'Future Planned', color: runrateFuturePlanned },
    { key: 'futureActual', label: 'Future Actual', color: runrateFutureActual },
];

const rangeDailyOptions: Range[] = ['R_2W', 'R_4W', 'R_3M'];

export const serialize = ({
    data,
    range,
}: {
    data: QueryResponse['chart'] | undefined;
    range: Range | undefined;
}): { categories: string[]; series: ApexAxisChartSeries | ApexNonAxisChartSeries } => {
    if (!data || !range)
        return {
            categories: [],
            series: [],
        };
    const format = rangeDailyOptions.includes(range) ? 'DD MMM YYYY' : 'MMM YYYY';
    const categories = data.map((item) => {
        return moment(item.startDate).format(format);
    });
    const series = categoriesKeys.map((item) => {
        return {
            name: item.label,
            data: data.map((d) => d[item.key]),
        };
    });
    return {
        categories,
        series,
    };
};

interface Props {
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    categories: string[];
    themeStatus: boolean;
    dataPointSelection: (data) => void;
    versionDate: number | null | undefined;
    tooltipFormatter: (data) => string;
    actualKpi: number | undefined;
}

export const getChartConfig = ({
    series,
    categories,
    themeStatus,
    dataPointSelection,
    versionDate,
    tooltipFormatter,
    actualKpi,
}: Props): { options: ApexOptions; series: ApexOptions['series'] } => ({
    options: {
        colors: categoriesKeys.map((item) => item.color),
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                columnWidth: '80%',
            },
        },
        states: {
            active: {
                filter: {
                    type: 'darken',
                },
            },
            hover: {
                filter: {
                    type: 'darken',
                },
            },
        },
        tooltip: {
            x: {
                formatter: function (_value, { dataPointIndex }) {
                    return tooltipFormatter({ dataPointIndex });
                },
            },
        },
        chart: {
            background: themeStatus ? '#000000' : '#ffffff',
            foreColor: themeStatus ? '#FEFEFE' : '#000000',
            toolbar: {
                tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true,
                },
            },
            events: {
                dataPointSelection: (_event, _chartContext, options) => {
                    dataPointSelection(options);
                },

                beforeZoom(chart: any) {
                    if (chart.wheelDelay) {
                        return {
                            xaxis: {
                                min: chart.minX,
                                max: chart.maxX,
                            },
                        };
                    }
                    return undefined;
                },
            },
        },
        xaxis: {
            type: 'datetime',
            categories,
            labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM yyyy',
                    day: 'dd MMM',
                },
            },
        },
        annotations: {
            xaxis: [
                {
                    x: versionDate,
                    borderWidth: 2,
                    strokeDashArray: 0,
                    borderColor: '#775DD0',
                    label: {
                        offsetY: 50,
                        borderColor: '#775DD0',
                        orientation: 'horizontal',
                        style: {
                            color: '#fff',
                            background: '#775DD0',
                        },
                        text: moment(versionDate).format(constants.formats.date.default),
                    },
                },
            ],
            yaxis: [
                {
                    y: actualKpi,
                    width: '100%',
                    strokeDashArray: 5,
                    borderColor: runratePastActual,
                    borderWidth: 3,
                    label: {
                        position: 'right',
                        borderColor: 'transparent',
                        offsetY: -5,
                        text: `Past Actual (${actualKpi} units)`,
                        style: {
                            color: runratePastActual,
                        },
                    },
                },
            ],
        },
    },

    series,
});

export const aggregationOptions: ISelectOption<Aggregation>[] = [
    { label: 'Average', value: 'AVERAGE' },
    { label: 'P50 (Median)', value: 'P50' },
    { label: 'P80 (High)', value: 'P80' },
];
