import React from 'react';
import { IContract } from 'store/projectContract.store';
import { CustomizedButton } from 'components/common';
import { TreeCrumbs } from 'components/common/TreeCrumbs/TreeCrumbs';
import classes from './newSubProgram.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import Icon from 'components/common/Icons/icon';
import { useMutationCreateContract } from 'components/common/TreeProgramFilter/Queries/treeProgramFilterMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { QueryObserverResult, useQueryClient } from 'react-query';
import { QueryResponse as ProgramGeneralHierarchyResponse } from 'api/queries/getProgramGeneralHierarchyList.query';
import {
    UseFormSetValue,
    UseFormGetValues,
    UseFormTrigger,
    Controller,
    Control,
    UseFormClearErrors,
    UseFormSetError,
} from 'react-hook-form';
import { FormData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/copyVersionModal/copyVersionModal';
import { QueryResponse as versionResponse } from 'api/queries/versionList.query';

interface Props {
    projectHierarchyList: ProgramGeneralHierarchyResponse;
    refetchProjectHierarchyList: () => Promise<QueryObserverResult<ProgramGeneralHierarchyResponse, QueryError>>;
    setValue: UseFormSetValue<FormData>;
    getValues: UseFormGetValues<FormData>;
    trigger: UseFormTrigger<FormData>;
    control: Control<FormData, FormData>;
    clearErrors: UseFormClearErrors<FormData>;
    setError: UseFormSetError<FormData>;
    version: versionResponse | null;
    isAddContractMode: boolean;
    setIsAddContractMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewSubProgram = ({
    projectHierarchyList,
    refetchProjectHierarchyList,
    setValue,
    getValues,
    trigger,
    control,
    clearErrors,
    setError,
    version,
    isAddContractMode,
    setIsAddContractMode,
}: Props) => {
    const { project, contract } = getValues();
    const { mutate: mutationCreateContract, isLoading: isLoadingCreateContract } = useMutationCreateContract();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    const onSelectionFinish = ({ project, contract }) => {
        setValue('contract', contract);
        setValue('project', project);
        if (contract) {
            if (contract.id === version?.contractId) {
                setError('contractCheck', {
                    message: 'Target Sub-Program cannot be the same as source Sub-Program.',
                    type: 'validate',
                });
            } else {
                clearErrors('contractCheck');
            }
        }
    };

    const handleCreateNewSubprogram = async () => {
        clearErrors('contractCheck');
        const isValid = await trigger('newSubProgramName');
        if (!isValid) {
            return;
        }
        const newSubProgramName = getValues('newSubProgramName');
        if (project && newSubProgramName !== undefined) {
            mutationCreateContract(
                {
                    contractName: newSubProgramName,
                    projectId: project.id,
                },
                {
                    onSuccess: async (response) => {
                        await refetchProjectHierarchyList();
                        await queryClient.invalidateQueries({ queryKey: 'getGeneralHierarchyList' });
                        const newContract: IContract = {
                            title: response.name,
                            id: response.id,
                            uuid: response.contractUid,
                        };
                        setValue('contract', newContract);
                        handleSnackBar('Sub-Program created successfully', 'success');
                        setIsAddContractMode(false);
                        clearErrors('newSubProgramName');
                    },
                    onError: (error) => {
                        handleSnackBar(error.response?.data || 'Something went wrong', 'error');
                    },
                },
            );
        }
    };

    return (
        <div className={classes.copyToContainer}>
            {isAddContractMode ? (
                <div className={classes.newSubprogramContainer}>
                    <span className={classes.breadcrumbItem}>{project?.title}</span>
                    <Icon name={'header-arrow-right'} size={'2rem'} color={'#8FA3C3'} />
                    <Controller
                        render={({ field }) => (
                            <TextSearchInput
                                {...field}
                                id={'newSubProgramName'}
                                className={classes.versionNameInput}
                                placeholder={'Type Subprogram Name...'}
                                handleClear={() => setValue('newSubProgramName', '')}
                            />
                        )}
                        name={'newSubProgramName'}
                        control={control}
                        rules={{
                            required: 'Sub-Program name is required',
                            minLength: {
                                value: 2,
                                message: 'Sub-Program name is too short',
                            },
                            maxLength: {
                                value: 30,
                                message: 'Sub-Program name should be up to 30 letters',
                            },
                            pattern: {
                                value: /^(?!\s+$).*/,
                                message: 'Sub-Program name cannot be empty',
                            },
                        }}
                    />

                    <div className={classes.newSubprogramActions}>
                        <CustomizedButton
                            size={'small'}
                            color={'primary'}
                            isLoading={isLoadingCreateContract}
                            onClick={handleCreateNewSubprogram}
                        >
                            <MuiIcon icon={'check'} color={'primary'} />
                        </CustomizedButton>

                        <CustomizedButton
                            size={'small'}
                            color={'secondary'}
                            onClick={() => {
                                clearErrors('newSubProgramName');
                                clearErrors('contractCheck');
                                setValue('newSubProgramName', `${version?.contractName ?? ''} - Simulations`);
                                setIsAddContractMode(false);
                            }}
                        >
                            <MuiIcon icon={'close'} color={'secondary'} />
                        </CustomizedButton>
                    </div>
                </div>
            ) : (
                <div className={classes.currentSubprogramContainer}>
                    <div className={classes.treeCrumbsContainer}>
                        <TreeCrumbs
                            selectedNode={contract?.uuid}
                            onSelectionFinish={onSelectionFinish}
                            data={projectHierarchyList}
                            showAddNewButton={false}
                        />
                    </div>
                    <CustomizedButton
                        size={'medium'}
                        color={'secondary'}
                        onClick={() => {
                            clearErrors('contractCheck');
                            setValue('newSubProgramName', `${version?.contractName ?? ''} - Simulations`);
                            setIsAddContractMode(true);
                        }}
                    >
                        New Subprogram...
                    </CustomizedButton>
                </div>
            )}
        </div>
    );
};
