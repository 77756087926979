import classes from './RowsBoard.module.scss';
import {
    buildRowsBoardFilter,
    columnsV8,
    initialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/RowsBoard/RowsBoardColumns.utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import {
    pptxTableNarrativeHeaders,
    serializedOneLevelDataStructureTableToPPTX,
} from 'components/common/ExportDropdown/exportDropdown.utils';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import {
    ISingleSmallCardResponse,
    useQueryListCardsData,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { IRowsBoard } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import {
    useMutationRowsBoardToCsv,
    useMutationRowsBoardToXls,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { CustomizedButton, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import NoData from 'components/common/NoData/noData';
import { useBattleCardsStore } from 'store/battlecards.store';
import { DisplayStyle } from 'components/common/ToggleView/ToggleView';
import { XlsExportModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/RowsBoard/XlsExportModal/XlsExportModal';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import { serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { useReactTableState } from 'hooks/useReactTableState';
import { useAssigneeOptionsList } from 'hooks/useAssigneeOptionsList';
import { useQueryActivityCodesByVersion } from 'api/queries/activityCodesByVersion.query';
import { useQueryGetSingleMilestoneFragnet } from 'api/queries/getMilestoneFragnet.query';
import { useQueryGetSingleWbs } from 'api/queries/getWbs.query';
import { getStringWithoutHash } from 'utilitys/helpers/general';
import useUserHook from 'hooks/useUserHook';
import { TitleWithCounter } from 'components/common/titleWithCounter/titleWithCounter';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { useVersionStore } from 'store/version.store';
import { useHistory } from 'react-router-dom';
import { isFiltersContainStatusChanged } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/RowsBoard/RowsBoard.utils';
import { useMutationExportXerOrMpp } from 'api/mutations/exportXerOrMpp.mutation';
import { useSubscription } from 'react-stomp-hooks';
import { useMutationDownloadCsvFile } from 'api/mutations/downloadCsvFile.mutation';

const RowsBoard = ({
    counters,
    filterData,
    widgetId,
    filter,
    projectId,
    contractId,
    externalComponents,
    editNarrative,
    latestVersionId,
    setSubTitleForExport,
}: IRowsBoard) => {
    const { ability, email } = useUserHook();
    const [isXlsExportModal, setIsXlsExportModal] = useState<boolean>(false);
    const {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState({ initialSorting: widgetId && filter?.filters ? filter?.filters.sortingRequestList : [] });
    const title = 'Activities Segment';
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const history = useHistory();
    const currentVersion = useVersionStore((state) => state.version);
    const [totalPages, setTotalPages] = useState(0);
    const assigneeOptionsList = useAssigneeOptionsList();
    const { displayStyle } = useBattleCardsStore();
    const { sendEventWithDimensions } = useEventWithDimensions();
    // FOR PinBoard Only
    const route = `${getStringWithoutHash(window.location.href)}`;
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const { mutate: downloadCsvFile } = useMutationDownloadCsvFile();

    useSubscription(email ? [`/topic/${email}.notifications.push`] : [], (response) => {
        const { type, message } = JSON.parse(response.body);
        if (type === 'CsvFileReady') {
            setIsDownloadCsv(false);
            const { fileName } = message;
            if (fileName) {
                downloadCsvFile({ fileName, downloadName: `${title}_${subTitle}` });
                sendEventWithDimensions({
                    category: 'Export',
                    action: 'CSV',
                    label: `${title} ${subTitle}`,
                });
            }
        }
    });

    //  reset Pagination on sorting and new filter request
    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [sorting, filterData]);

    const cardsDataFilters = useMemo(() => {
        const filtersObject = filter?.filters.hasOwnProperty('cardCachedFilter')
            ? filter?.filters.cardCachedFilter
            : filter?.filters;
        return widgetId
            ? {
                  ...filter?.filters,
                  cardCachedFilter: {
                      ...filtersObject,
                      filter: {
                          filters: filtersObject.filter?.filters.map((filter) => {
                              if (filter.name === 'VERSION_CARD_BOARD') {
                                  return {
                                      ...filter,
                                      data: [latestVersionId],
                                  };
                              } else {
                                  return filter;
                              }
                          }),
                      },
                  },
                  paging: { ...pagination, page: pagination.page - 1 },
                  sortingRequestList: sorting,
              }
            : {
                  cardCachedFilter: filterData,
                  paging: { ...pagination, page: pagination.page - 1 },
                  sortingRequestList: sorting,
              };
    }, [filterData, filter, widgetId, latestVersionId, pagination, sorting]);

    const pinBoardFilterData = useMemo(() => {
        return {
            cardCachedFilter: filterData,
            paging: { ...pagination, page: pagination.page - 1 },
            sortingRequestList: sorting,
        };
    }, [filterData, pagination, sorting]);

    const activityFragnetTaskVersionHashCode = cardsDataFilters?.cardCachedFilter.filter.filters.find(
        (i) => i.name === 'FRAGNET_MILESTONE_ID_CARD_BOARD',
    );

    const wbsTaskVersionHashCode = cardsDataFilters?.cardCachedFilter.filter.filters.find(
        (i) => i.name === 'WBS_ID_CARD_BOARD',
    );

    const isStatusChangedFilterSelected = isFiltersContainStatusChanged(filterData);

    const { data: cards, isLoading, isFetching } = useQueryListCardsData(cardsDataFilters);
    const { data: activityCodes } = useQueryActivityCodesByVersion({
        latestVersion: latestVersionId,
    });

    const { data: activityFragnet } = useQueryGetSingleMilestoneFragnet({
        latestVersion: latestVersionId,
        taskVersionHashCode: activityFragnetTaskVersionHashCode?.data[0],
    });

    const { data: singleWbs } = useQueryGetSingleWbs({
        latestVersion: latestVersionId,
        taskId: wbsTaskVersionHashCode?.data[0],
    });

    const boundaryRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (cards) {
            setTotalPages(cards.numOfPages);
        }
    }, [cards]);

    // =============================================================================================================

    const pptxData = serializedOneLevelDataStructureTableToPPTX({
        data: cards?.cardList,
        sortBy: { key: 'endDate', sortOrder: 'asc' },
        headers: pptxTableNarrativeHeaders,
    });

    const rowsBoardFilter = useMemo(() => {
        return widgetId
            ? buildRowsBoardFilter({ filter, activityCodes, activityFragnet, assigneeOptionsList, singleWbs })
            : [];
    }, [filter, activityCodes, activityFragnet, assigneeOptionsList, singleWbs]);

    const componentKey = 'battlecardsRowsBoard';
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });

    const { mutate: csvMutate } = useMutationRowsBoardToCsv();
    const { mutate: xlsMutate, isLoading: xlsMutateLoading } = useMutationRowsBoardToXls();
    const { mutate: xerOrMppMutate, isLoading: xerOrMppMutateLoading } = useMutationExportXerOrMpp();
    const [isDownloadCsv, setIsDownloadCsv] = useState<boolean>(false);

    const exportIsLoading = isDownloadCsv || xlsMutateLoading || xerOrMppMutateLoading;

    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();

    const handleAPICsvExportPrivilege = ability.can('view', 'FileDownload');

    const handleAPICsvExport = ({ isCompact = false }: { isCompact?: boolean }) => {
        setIsDownloadCsv(true);
        csvMutate({
            filter: cardsDataFilters.cardCachedFilter,
            fileName: `${title}_${subTitle}`,
            isCompact: isCompact,
        });
    };

    const handleAPIXlsExport = (data) => {
        xlsMutate(
            {
                filter: cardsDataFilters.cardCachedFilter,
                fileName: `${title}_${subTitle}`,
                configRequest: {
                    contractId: data.contractId,
                    isExtractRemainingDuration: data.remainingDurationInDays,
                    isExtractPercentageComplete: data.submittedDurationComplete,
                    userData: data.userData,
                },
            },
            {
                onSuccess: () => {
                    handleSnackBar('File Successfully Downloaded', 'success');

                    sendEventWithDimensions({
                        category: 'Export',
                        action: 'XLS',
                        label: `${title} ${subTitle}`,
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Failed To Download File', 'error');
                },
            },
        );
    };

    const handleExrExport = () => {
        if (latestVersionId) {
            xerOrMppMutate(
                {
                    metaDataId: latestVersionId,
                    exportType: 'XER',
                    fileName: `${title}_${subTitle}`,
                },
                {
                    onSuccess: () => {
                        handleSnackBar('File Successfully Downloaded', 'success');

                        sendEventWithDimensions({
                            category: 'Export',
                            action: 'XER',
                            label: `${title} ${subTitle}`,
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Failed To Save Search', 'error');
                    },
                },
            );
        }
    };

    const handleMppExport = () => {
        if (latestVersionId) {
            xerOrMppMutate(
                {
                    metaDataId: latestVersionId,
                    exportType: 'MPP',
                    fileName: `${title}_${subTitle}`,
                },
                {
                    onSuccess: () => {
                        handleSnackBar('File Successfully Downloaded', 'success');

                        sendEventWithDimensions({
                            category: 'Export',
                            action: 'MPP',
                            label: `${title} ${subTitle}`,
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Failed To Save Search', 'error');
                    },
                },
            );
        }
    };

    const setWidgetColumns = (widgetId: string) => {
        if (tableInstance) {
            mutateSetWidgetColumns(
                { columns: { hiddenColumns: tableInstance.state.hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    useEffect(() => {
        updatedInitialState && setColumnOrder(updatedInitialState.columnOrder ?? []);
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (column) {
                        return {
                            ...column,
                            isVisible: !hiddenColumns.includes(column.id),
                        };
                    }
                    return null;
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    if (displayStyle === DisplayStyle.COLUMNS && !widgetId) return null;
    return (
        <WidgetWithTitle
            id={componentKey}
            title={<TitleWithCounter title={title} counter={cards?.numOfAllCards} />}
            titleComponents={[
                isStatusChangedFilterSelected ? (
                    <CustomizedButton
                        size={'large'}
                        color={'primary'}
                        onClick={() => {
                            if (currentVersion) {
                                history.push(
                                    `/dashboard/program/${excellenceAppsConfig.scheduleSimulation.link}/${currentVersion.versionHashCode}`,
                                );
                            }
                        }}
                    >
                        Run Simulation
                    </CustomizedButton>
                ) : null,
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    title={title}
                    componentKey={componentKey}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={pinBoardFilterData}
                    callBack={setWidgetColumns}
                    localFilters={{ counters }}
                />,
                <ExportDropdown
                    key={'ExportDropdown'}
                    title={title}
                    pptxData={pptxData}
                    ref={boundaryRef}
                    subTitle={subTitle}
                    handleAPICsvExport={handleAPICsvExportPrivilege ? handleAPICsvExport : undefined}
                    handleAPICsvExportCompact={() => {
                        handleAPICsvExport({ isCompact: true });
                    }}
                    handleAPIXlsExport={() => setIsXlsExportModal(true)}
                    handleExrExport={handleExrExport}
                    handleMppExport={handleMppExport}
                />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId ? <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={rowsBoardFilter} /> : null,
            ]}
        >
            <div className={classes.rowsBoardWrapper} ref={boundaryRef}>
                {updatedInitialState && (
                    <>
                        <TableV8<ISingleSmallCardResponse>
                            data={cards?.cardList || []}
                            columns={columnsV8}
                            isLoading={isLoading || isFetching || exportIsLoading}
                            noData={<NoData>No activities match the applied filter</NoData>}
                            sorting={sorting}
                            setSorting={setSorting}
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                            columnOrder={columnOrder}
                            setColumnOrder={setColumnOrder}
                        />
                        <div className={classes.pagination}>
                            <CustomPagination
                                totalPages={totalPages}
                                pagination={pagination}
                                setPagination={setPagination}
                                isDisabled={isFetching}
                            />
                        </div>
                    </>
                )}
            </div>

            <CustomTableColumnsModal
                onHideModal={() => setModalColumnsOpen(false)}
                showModal={modalColumnsOpen}
                widgetId={widgetId}
                componentKey={componentKey}
                tableInstance={tableInstance}
                initialState={initialState}
                columns={columnsV8}
                onChangeOrder={setColumnOrder}
                onChangeVisibility={setColumnVisibility}
            />

            <XlsExportModal
                isXlsExportModal={isXlsExportModal}
                setIsXlsExportModal={setIsXlsExportModal}
                handleAPIXlsExport={handleAPIXlsExport}
            />
        </WidgetWithTitle>
    );
};

export default React.memo(RowsBoard);
