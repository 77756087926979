import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/ChangedActivitiesTable/ChangedActivitiesTable.module.scss';
import {
    buildRowsBoardFilter,
    columnsV8,
    initialState,
    linkToBattleCards,
} from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/ChangedActivitiesTable/ChangedActivitiesTableColumns.utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import {
    pptxTableNarrativeHeaders,
    serializedOneLevelDataStructureTableToPPTX,
} from 'components/common/ExportDropdown/exportDropdown.utils';
import { getStringWithoutHash } from 'utilitys/helpers/general';

//// battle cards related types and api in use
import {
    ISingleSmallCardResponse,
    useQueryListCardsData,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import {
    useMutationRowsBoardToCsv,
    useMutationRowsBoardToXls,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsMutation';
import { useBattleCardsStore } from 'store/battlecards.store';
import { XlsExportModal } from './XlsExportModal/XlsExportModal';

import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { useQueryActivityCodesByVersion } from 'api/queries/activityCodesByVersion.query';
import { useQueryGetSingleMilestoneFragnet } from 'api/queries/getMilestoneFragnet.query';
import { useQueryGetSingleWbs } from 'api/queries/getWbs.query';

import { IRowsBoard } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';

import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { CustomizedButton, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import NoData from 'components/common/NoData/noData';

import { DisplayStyle } from 'components/common/ToggleView/ToggleView';

import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import { serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { useReactTableState } from 'hooks/useReactTableState';
import { useAssigneeOptionsList } from 'hooks/useAssigneeOptionsList';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { Link, useParams } from 'react-router-dom';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { useSimulationCacheKeyStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/simulationCacheKey.store';
import { useVersionStore } from 'store/version.store';
import { useSubscription } from 'react-stomp-hooks';
import useUserHook from 'hooks/useUserHook';
import { useMutationDownloadCsvFile } from 'api/mutations/downloadCsvFile.mutation';

interface Props extends Omit<IRowsBoard, 'filterData'> {
    handleRunSimulation: () => void;
}

export const ChangedActivitiesTable = ({
    widgetId,
    filter,
    projectId,
    contractId,
    externalComponents,
    editNarrative,
    latestVersionId,
    setSubTitleForExport,
    localFilters,
    handleRunSimulation,
}: Props) => {
    const [isXlsExportModal, setIsXlsExportModal] = useState<boolean>(false);
    const {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState({ initialSorting: widgetId && filter?.filters ? filter?.filters.sortingRequestList : [] });
    const { id } = useParams<any>();
    const { email } = useUserHook();
    const { isRunning: isSimulationRunning, resetSimulationSession } = useSimulationCacheKeyStore();
    const currentVersion = useVersionStore((state) => state.version);
    const filterData = useMemo(() => {
        if (projectId && contractId && latestVersionId) {
            return {
                filter: {
                    filters: [
                        {
                            data: [projectId],
                            name: 'PROJECT_CARD_BOARD',
                            operation: null,
                        },
                        {
                            data: [contractId],
                            name: 'CONTRACT_CARD_BOARD',
                            operation: null,
                        },
                        {
                            data: [latestVersionId],
                            name: 'VERSION_CARD_BOARD',
                            operation: null,
                        },
                        {
                            data: ['PROGRAMME_ACTIVITY'],
                            name: 'TYPE_CARD_BOARD',
                            operation: null,
                        },
                        {
                            data: ['INTERIM_CHANGED'],
                            name: 'STATUS_CARD_BOARD',
                            operation: null,
                        },
                    ],
                },
            };
        }
        return {
            filter: {
                filters: [],
            },
        };
    }, [projectId, contractId, latestVersionId]);

    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(-1);
    const assigneeOptionsList = useAssigneeOptionsList();
    const { displayStyle } = useBattleCardsStore();
    const { sendEventWithDimensions } = useEventWithDimensions();
    // FOR PinBoard Only
    const route = `${getStringWithoutHash(window.location.href)}`;
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);

    //  reset Pagination on sorting and new filter request
    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [sorting]);

    const cardsDataFilters = useMemo(() => {
        const filtersObject = filter?.filters.hasOwnProperty('cardCachedFilter')
            ? filter?.filters.cardCachedFilter
            : filter?.filters;
        return widgetId
            ? {
                  ...filter?.filters,
                  cardCachedFilter: {
                      ...filtersObject,
                      filter: {
                          filters: filtersObject.filter?.filters.map((filter) => {
                              if (filter.name === 'VERSION_CARD_BOARD') {
                                  return {
                                      ...filter,
                                      data: [latestVersionId],
                                  };
                              } else {
                                  return filter;
                              }
                          }),
                      },
                  },
                  paging: { ...pagination, page: pagination.page - 1 },
                  sortingRequestList: sorting,
              }
            : {
                  cardCachedFilter: filterData,
                  paging: { ...pagination, page: pagination.page - 1 },
                  sortingRequestList: sorting,
              };
    }, [filterData, filter, widgetId, latestVersionId, pagination, sorting]);

    const activityFragnetTaskVersionHashCode = cardsDataFilters?.cardCachedFilter.filter.filters.find(
        (i) => i.name === 'FRAGNET_MILESTONE_ID_CARD_BOARD',
    );

    const wbsTaskVersionHashCode = cardsDataFilters?.cardCachedFilter.filter.filters.find(
        (i) => i.name === 'WBS_ID_CARD_BOARD',
    );

    const { data: cards, isLoading, isFetching } = useQueryListCardsData(cardsDataFilters);
    const { data: activityCodes } = useQueryActivityCodesByVersion({
        latestVersion: latestVersionId,
    });

    const { data: activityFragnet } = useQueryGetSingleMilestoneFragnet({
        latestVersion: latestVersionId,
        taskVersionHashCode: activityFragnetTaskVersionHashCode?.data[0],
    });

    const { data: singleWbs } = useQueryGetSingleWbs({
        latestVersion: latestVersionId,
        taskId: wbsTaskVersionHashCode?.data[0],
    });

    const boundaryRef = useRef<HTMLDivElement>(null);

    const tableData = useMemo(() => {
        if (cards && cards.cardList.length > 0) {
            return cards.cardList.map((card) => {
                return {
                    ...card,
                    className: classes.activityStatusChanged,
                };
            });
        } else {
            return [];
        }
    }, [cards]);

    useEffect(() => {
        if (cards) {
            setTotalPages(cards.numOfPages);
            setTotalCount(cards.numOfAllCards);
        }
    }, [cards]);

    const pptxData = serializedOneLevelDataStructureTableToPPTX({
        data: cards?.cardList,
        sortBy: { key: 'endDate', sortOrder: 'asc' },
        headers: pptxTableNarrativeHeaders,
    });

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });

    const rowsBoardFilter = useMemo(() => {
        return widgetId
            ? buildRowsBoardFilter({ filter, activityCodes, activityFragnet, assigneeOptionsList, singleWbs })
            : [];
    }, [filter, activityCodes, activityFragnet, assigneeOptionsList, singleWbs]);

    const componentKey = 'changedActivities';
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const title = 'Updates made since latest upload';

    const { mutate: csvMutate, isLoading: csvMutateLoading } = useMutationRowsBoardToCsv();
    const { mutate: xlsMutate, isLoading: xlsMutateLoading } = useMutationRowsBoardToXls();
    const [isDownloadCsv, setIsDownloadCsv] = useState<boolean>(false);

    const exportIsLoading = isDownloadCsv || csvMutateLoading || xlsMutateLoading;

    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: downloadCsvFile } = useMutationDownloadCsvFile();

    useSubscription(email ? [`/topic/${email}.notifications.push`] : [], (response) => {
        const { type, message } = JSON.parse(response.body);
        if (type === 'CsvFileReady') {
            setIsDownloadCsv(false);
            const { fileName } = message;
            if (fileName) {
                downloadCsvFile({ fileName, downloadName: `${title}_${subTitle}` });
                sendEventWithDimensions({
                    category: 'Export',
                    action: 'CSV',
                    label: `${title} ${subTitle}`,
                });
            }
        }
    });

    const handleAPICsvExport = ({ isCompact = false }: { isCompact?: boolean }) => {
        setIsDownloadCsv(true);
        csvMutate({
            filter: cardsDataFilters.cardCachedFilter,
            fileName: `${title}_${subTitle}`,
            isCompact: isCompact,
        });
    };

    const handleAPIXlsExport = (data) => {
        xlsMutate(
            {
                filter: cardsDataFilters.cardCachedFilter,
                fileName: `${title}_${subTitle}`,
                configRequest: {
                    contractId: data.contractId,
                    isExtractRemainingDuration: data.remainingDurationInDays,
                    isExtractPercentageComplete: data.submittedDurationComplete,
                    userData: data.userData,
                },
            },
            {
                onSuccess: () => {
                    handleSnackBar('File Successfully Downloaded', 'success');

                    sendEventWithDimensions({
                        category: 'Export',
                        action: 'XLS',
                        label: `${title} ${subTitle}`,
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Failed To Download File', 'error');
                },
            },
        );
    };

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    const titleWithCount = useMemo(() => {
        if (totalCount === -1) {
            return title;
        }
        if (widgetId) {
            if (localFilters?.counters) {
                return `${title} (${totalCount})`;
            } else {
                return title;
            }
        }
        return `${title} (${totalCount})`;
    }, [widgetId, title, totalCount, localFilters]);

    useEffect(() => {
        updatedInitialState && setColumnOrder(updatedInitialState.columnOrder ?? []);
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (column) {
                        return {
                            ...column,
                            isVisible: column?.id ? !hiddenColumns.includes(column.id) : false,
                        };
                    }
                    return null;
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    useEffect(() => {
        resetSimulationSession();
    }, [currentVersion]);

    if (displayStyle === DisplayStyle.COLUMNS && !widgetId) return null;
    return (
        <>
            <WidgetWithTitle
                id={componentKey}
                className={classes.changedActivitiesContainer}
                title={titleWithCount}
                titleComponents={[
                    <div
                        key="linkToProjectActivities"
                        style={{ alignSelf: 'center' }}
                        className={classes.linkContainer}
                    >
                        {!widgetId && <Link to={linkToBattleCards(id)}>View in {projectConfig.battlecards.title}</Link>}
                    </div>,
                    externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                    <CustomizedTooltip
                        key={'tableColumns'}
                        tooltipPosition={TooltipPosition.Top}
                        tooltipContent={'Customize table columns'}
                        triggerElement={
                            <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                                <Icon name={'tune-filter'} />
                            </CustomizedRoundIcon>
                        }
                    />,
                    <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                    <ExportDropdown
                        key={'ExportDropdown'}
                        title={title}
                        pptxData={pptxData}
                        ref={boundaryRef}
                        subTitle={subTitle}
                        handleAPICsvExport={handleAPICsvExport}
                        handleAPICsvExportCompact={() => {
                            handleAPICsvExport({ isCompact: true });
                        }}
                        handleAPIXlsExport={() => setIsXlsExportModal(true)}
                    />,
                ]}
                titleFilters={[
                    editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                    widgetId ? (
                        <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={rowsBoardFilter} />
                    ) : null,
                ]}
            >
                <div className={classes.rowsBoardWrapper} ref={boundaryRef}>
                    {updatedInitialState && (
                        <>
                            <TableV8<ISingleSmallCardResponse>
                                data={tableData}
                                columns={columnsV8}
                                isLoading={isLoading || isFetching || exportIsLoading}
                                noData={<NoData>No activities match the applied filter</NoData>}
                                sorting={sorting}
                                setSorting={setSorting}
                                columnVisibility={columnVisibility}
                                setColumnVisibility={setColumnVisibility}
                                columnOrder={columnOrder}
                                setColumnOrder={setColumnOrder}
                                manualPagination={false}
                            />
                            <div className={classes.pagination}>
                                <CustomPagination
                                    totalPages={totalPages}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    isDisabled={isFetching}
                                />
                            </div>
                        </>
                    )}
                </div>

                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    widgetId={widgetId}
                    componentKey={componentKey}
                    tableInstance={tableInstance}
                    initialState={initialState}
                    columns={columnsV8}
                    onChangeOrder={setColumnOrder}
                    onChangeVisibility={setColumnVisibility}
                />

                <XlsExportModal
                    isXlsExportModal={isXlsExportModal}
                    setIsXlsExportModal={setIsXlsExportModal}
                    handleAPIXlsExport={handleAPIXlsExport}
                />
            </WidgetWithTitle>
            {widgetId === undefined && (
                <div className={classes.footer}>
                    <CustomizedButton
                        disabled={!cards || cards.cardList?.length === 0 || isSimulationRunning}
                        isLoading={isSimulationRunning}
                        size={'large'}
                        color={'primary'}
                        onClick={() => {
                            handleRunSimulation();
                        }}
                    >
                        Run simulation
                    </CustomizedButton>
                </div>
            )}
        </>
    );
};
