import React from 'react';
import classes from './CellWithWasLabel.module.scss';

export const CellWithWasLabel = ({
    mainValue,
    wasLabel,
}: {
    mainValue: string | React.ReactNode;
    wasLabel: string;
}) => {
    return (
        <div className={classes.dataCell}>
            <div>{mainValue}</div>
            <div className={classes.wasStatus}>{wasLabel}</div>
        </div>
    );
};
