import classes from './VarianceCell.module.scss';

export const VarianceCell = ({ value, className }: { value: number; className?: string }) => {
    return (
        <span
            data-testid={'variance-cell'}
            className={`${value > 0 ? classes.negative : classes.positive} ${className ?? ''}`}
        >
            {value}
        </span>
    );
};
