import classes from './AddProjectModal.module.scss';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase, MyCustomSelect } from 'components/common';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import useUserHook from 'hooks/useUserHook';
import {
    IAllUserGroups,
    useQueryGetAllUserGroups,
} from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { Controller, useForm } from 'react-hook-form';

import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import React, { useMemo } from 'react';
import { orderBy } from 'lodash';
import {
    ICreateProjectPayload,
    useMutationCreateProject,
} from 'components/common/TreeProgramFilter/Queries/treeProgramFilterMutation';
import { findeNodeByUUID, ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { IContract, IProject, useProjectTreeStore } from 'store/projectContract.store';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useBasePath } from 'hooks/useBasePath';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

const groupsMapper = (item: string) => ({ value: item, label: item });
const groupsMapperBe = (item: IAllUserGroups) => ({
    value: item.group_name,
    label: item.group_name,
});

interface IAddProjectModalForm {
    initialContractName: string;
    projectName: string;
    userGroups: ISelectOption<string>[];
}

interface IAddProjectModal {
    showModal: boolean;
    onHideModal: () => void;
    callback?: (project: IProject | null, contract: IContract | null) => void;
}

export const AddProjectModal = ({ showModal, onHideModal, callback }: IAddProjectModal) => {
    const history = useHistory();
    const basePath = useBasePath();

    const { ability, userGroupList } = useUserHook();
    const { customerId } = useUserHook();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();
    const { mutate: mutationCreateProject, isLoading: isLoadingCreateProject } = useMutationCreateProject();
    const { update } = useProjectTreeStore();
    const { data: managementUserGroups } = useQueryGetAllUserGroups({
        enabled: ability.can('view', 'UserManagement'),
        isProjectCreation: true,
    });
    const { sendEventWithDimensions } = useEventWithDimensions();
    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        reset,
    } = useForm<IAddProjectModalForm>({
        defaultValues: {
            projectName: '',
            initialContractName: '',
            userGroups: [],
        },
    });

    const handleSaveUseForm = (value) => {
        const label: string[] = [];
        label.push(`Project: ${value.projectName}`);
        label.push(`Sub-program: ${value.initialContractName === '' ? 'default' : value.initialContractName}`);
        label.push(`User Groups: ${value.userGroups.join(', ')}`);
        sendEventWithDimensions({
            category: 'Project/Sub-program',
            action: 'Create',
            label: label.join('; '),
        });
        handleSaveProject({ ...value, userGroups: value.userGroups.map((item) => item.label) });
        closeModal();
    };

    /**
     * get userGroups from management only if user has Admin or UserManagment in his privilegeMap property
     * if not - get the list from current user userGroupList property
     * */
    const userGroupsOptions = useMemo<ISelectOption<string>[]>(() => {
        const options = managementUserGroups?.map(groupsMapperBe) || userGroupList?.map(groupsMapper) || [];
        return orderBy(options, [(item: any) => item['label'].toLowerCase()], ['asc']);
    }, [managementUserGroups]);

    const closeModal = () => {
        reset();
        onHideModal();
    };

    const handleSaveProjectCallback = ({
        projectUid,
        newData,
    }: {
        projectUid: ProjectTreeNode['uuid'];
        newData?: ProjectTreeNode;
    }) => {
        const projectNode = newData && projectUid ? findeNodeByUUID(newData, projectUid) : null;
        const contractNode = projectNode && projectNode.children ? projectNode.children[0] : null;
        const selectedContract = contractNode
            ? {
                  id: contractNode.id,
                  title: contractNode.name,
                  uuid: contractNode.uuid,
              }
            : null;

        if (projectNode) {
            update({
                project: {
                    id: projectNode.id,
                    title: projectNode.name,
                    uuid: projectNode.uuid,
                },
                contract: selectedContract,
            });
            if (callback) {
                callback(
                    {
                        id: projectNode.id,
                        title: projectNode.name,
                        uuid: projectNode.uuid,
                    },
                    contractNode
                        ? {
                              id: contractNode.id,
                              title: contractNode.name,
                              uuid: contractNode.uuid,
                          }
                        : null,
                );
            }
        }

        update({ parentNode: null });
    };

    const handleSaveProject = (value: Omit<ICreateProjectPayload, 'customerId'>) => {
        mutationCreateProject(
            { ...value, customerId },
            {
                onSuccess: (response) => {
                    onHideModal();
                    queryClient.invalidateQueries({ queryKey: 'getGeneralHierarchyList' }).then(() => {
                        const newData = queryClient.getQueryData<ProjectTreeNode>('getGeneralHierarchyList');
                        handleSaveProjectCallback({ projectUid: response.projectUid, newData });
                        handleSnackBar('Project created successfully', 'success');

                        // should return to base url to show the data for the new project
                        // not for program Library because we need to add the new project and contract ID's to the url
                        if (basePath !== `/dashboard/project/${projectConfig.programLibrary.link}`) {
                            history.push(basePath);
                        }
                    });
                },
                onError: (error) => {
                    handleSnackBar(error.response?.data, 'error');
                },
            },
        );
    };

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={closeModal}>
            <div className={classes.addProjectModalContainer}>
                <form onSubmit={handleSubmit(handleSaveUseForm)} autoComplete="off">
                    <div className={classes.title}>Add Project</div>
                    <div className={classes.inputTitle}>Name *</div>
                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'Project name...'}
                                    isSearch={false}
                                    maxLength={30}
                                    handleClear={() => {
                                        setValue('projectName', '');
                                    }}
                                />
                            )}
                            name={`projectName`}
                            control={control}
                            rules={{
                                required: 'Project Name is Required',
                                minLength: {
                                    value: 2,
                                    message: 'Project Name is too short',
                                },
                                pattern: {
                                    value: /^(?!\s+$).*/,
                                    message: 'Name cannot be empty',
                                },
                            }}
                        />
                    </div>

                    {errors.projectName && <CustomizedFormErrorMessage text={errors.projectName.message} />}
                    <div className={classes.inputTitle}>Initial Sub-Program name</div>

                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <TextSearchInput
                                    {...field}
                                    placeholder={'Project initial Sub-Program name...'}
                                    isSearch={false}
                                    maxLength={100}
                                    handleClear={() => {
                                        setValue('initialContractName', '');
                                    }}
                                />
                            )}
                            name={`initialContractName`}
                            control={control}
                            rules={{
                                minLength: {
                                    value: 2,
                                    message: 'Initial Sub-Program Name is too short',
                                },
                            }}
                        />
                    </div>

                    {errors.initialContractName && (
                        <CustomizedFormErrorMessage text={errors.initialContractName.message} />
                    )}
                    <div className={classes.inputTitle}>Select who can access this project (User Groups)</div>

                    <div className={classes.inputWrapper}>
                        <Controller
                            render={({ field }) => (
                                <MyCustomSelect<ISelectOption<string>, true>
                                    {...field}
                                    options={userGroupsOptions}
                                    isClearable={true}
                                    isMulti
                                />
                            )}
                            name={'userGroups'}
                            control={control}
                            rules={{
                                validate: {
                                    required: (value) => value.length > 0 || 'Must select at least one user group',
                                },
                            }}
                        />
                    </div>

                    {errors.userGroups && <CustomizedFormErrorMessage text={errors.userGroups.message} />}
                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton
                                type={'submit'}
                                size={'large'}
                                color={'primary'}
                                isLoading={isLoadingCreateProject}
                                disabled={isLoadingCreateProject}
                            >
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </form>
            </div>
        </CustomizedModalBase>
    );
};
