import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/exportModal/newVersionExportOption.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import React from 'react';
import { useExportVersionStore } from 'store/exportVersion.store';
import { useQueryGetProgramGeneralHierarchyList } from 'api/queries/getProgramGeneralHierarchyList.query';
import { TreeCrumbs } from 'components/common/TreeCrumbs/TreeCrumbs';

export const NewVersionOption = () => {
    const { name, update, contract, date } = useExportVersionStore();
    const { data: projectHierarchyList, isLoading: loadingGeneralList } =
        useQueryGetProgramGeneralHierarchyList('ProgramLibraryUploadFiles');

    const onSelectionFinish = ({ project, contract }) => {
        update({
            contract,
            project,
        });
    };

    return (
        <div className={classes.newVersionContainer}>
            <div className={classes.newVersionColumnFlex}>
                <div>New Schedule Version</div>
                <div className={classes.newVersionDate}>{`Version Date: ${date}`}</div>
                <div className={classes.newVersionName}>
                    <span>Version Name: </span>
                    <TextSearchInput
                        isSearch={false}
                        placeholder={'Enter version name'}
                        handleClear={() => update({ name: '' })}
                        className={classes.newVersionNameInput}
                        value={name ?? ''}
                        onChange={(e) => update({ name: e.target.value })}
                    />
                </div>
                <div className={classes.newVersionProjectContract}>
                    <span>Save Version In: </span>
                    {!loadingGeneralList &&
                        projectHierarchyList &&
                        projectHierarchyList.children?.length > 0 &&
                        contract !== null && (
                            <TreeCrumbs
                                selectedNode={contract.uuid}
                                onSelectionFinish={onSelectionFinish}
                                data={projectHierarchyList}
                                showAddNewButton={false}
                                showAll={false}
                            />
                        )}
                </div>
            </div>
        </div>
    );
};
