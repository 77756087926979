import React, { PropsWithChildren } from 'react';
import classes from './MainContent.module.scss';

export const MainContent = ({ children }: PropsWithChildren) => {
    return (
        <div data-testid={'main-content'} className={classes.mainContent}>
            {children}
        </div>
    );
};
