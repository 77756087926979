interface Props {
    title: string | undefined;
    counter: number | undefined;
}
export const TitleWithCounter = ({ title, counter }: Props) => {
    return title ? (
        <>
            {title} {counter !== undefined ? `(${counter})` : ''}
        </>
    ) : null;
};
