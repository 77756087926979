import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import {
    activityCodeMapperUID,
    milestoneFragnetMapper,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { widgetFilterTitle } from 'components/Dashboards/Project/Components/CustomDashboard/customDashboard.utils';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { CustomizedTooltip } from 'components/common';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';
import CriticalityScoreCell from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/Cells/criticalityScoreCell';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { PillsCell } from 'components/common/Tables/Cells/PillsCell';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import {
    cardsDataQueryKey,
    ISingleSmallCardResponse,
    listCardsDataQueryKey,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { DetailsCell } from 'components/common/Tables/Cells/DetailsCell';
import {
    TodayMenuOptions,
    VersionDateMenuOptions,
} from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { ColumnDef } from '@tanstack/react-table';
import { customSortWithNullV8 } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';
import { mapper } from 'components/common/GlobalFilterComponent/components/WbsFilter/utils';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';

const { color6: orange, textColor, delayDriverColor } = colorsVars;

const invalidateKeys = [cardsDataQueryKey, listCardsDataQueryKey];

export const initialState: ITableColumnState = {
    hiddenColumns: [
        'currentStartDate',
        'currentFinishDate',
        'lateFinish',
        'lateStart',
        'plannedStartDate',
        'plannedFinishDate',
        'actualStartDate',
        'actualFinishDate',
        'plannedDuration',
        'actualDuration',
        'completedDuration',
        'durationOverrun',
        'numOfPredecessors',
        'numOfSuccessors',
        'projectWeekStart',
        'projectWeekFinish',
        'projectMonthStart',
        'projectMonthFinish',
        'tagList',
        'originalStatus',
        'baselineStartDate',
        'baselineFinishDate',
    ],
    columnOrder: [
        'activity',
        'status',
        'submittedDurationComplete',
        'activityType',
        'details',
        'criticalPath',
        'float',
        'startDate',
        'finishDate',
        'baselineStartDate',
        'baselineFinishDate',
        'remainingDuration',
        'parentName',
        'grandParentName',
        'criticalityScore',
        'tagList',
        'lateStart',
        'lateFinish',
        'plannedStartDate',
        'plannedFinishDate',
        'actualStartDate',
        'actualFinishDate',
        'plannedDuration',
        'actualDuration',
        'completedDuration',
        'durationOverrun',
        'numOfPredecessors',
        'numOfSuccessors',
        'projectWeekStart',
        'projectWeekFinish',
        'projectMonthStart',
        'projectMonthFinish',
        'originalStatus',
    ],
};

export const columnsV8: ColumnDef<ISingleSmallCardResponse>[] = [
    {
        header: 'Activity',
        accessorKey: 'title',
        ...columnsCommonConfig.activity,
        cell: ({ row, table }) => {
            const { taskVersionHashCode, isTracked, isVirtual } = row.original;
            const activityName = row.original.title;
            const activityId =
                row.original.type.id === 2
                    ? row.original.taskActivityId
                    : `C-${typeof row.original.id === 'string' ? row.original.id.split('_')[0] : row.original.id}`;
            const taskId = typeof row.original.id === 'string' ? row.original.id.split('_')[0] : row.original.id;

            const cardIndicators = row.original.cardIndicators;
            const { getToggleSelectedHandler } = row;
            const { toggleAllRowsSelected } = table;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={taskId}
                    isVirtual={isVirtual}
                    cardIndicators={cardIndicators}
                    taskVersionHashCode={taskVersionHashCode}
                    isTracked={isTracked}
                    toggleRowSelected={getToggleSelectedHandler()}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        header: 'Status',
        accessorKey: 'status.label',
        ...columnsCommonConfig.status,
        cell: ({ row }) => {
            const { assignee, startDate, endDate, status, isVirtual, originalStatus, id } = row.original;
            const startDateFormatted = moment(startDate).format(constants.formats.date.default);
            const endDateFormatted = moment(endDate).format(constants.formats.date.default);
            const wasStatus = `${startDateFormatted} - ${endDateFormatted}`;
            const cardType = row.original.type.name;
            const category = row.original.category;
            const isStatusOrDatesChanged = row.original.isStatusOrDatesChanged;

            return (
                <StatusCell
                    isStatusDisabled={true}
                    isAssigneeDisabled={true}
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={id}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    wasStatus={wasStatus}
                    cardType={cardType}
                    category={category}
                    isStatusOrDatesChanged={isStatusOrDatesChanged}
                />
            );
        },
    },
    {
        header: 'Submitted Completion',
        ...columnsCommonConfig.submittedDurationComplete,
        accessorKey: 'submittedDurationComplete',
        cell: ({ getValue }) => {
            const value = getValue();
            return (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${value}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(value)} />}
                />
            );
        },
    },
    {
        header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessorFn: (original) =>
            original.type?.name === 'PROGRAMME_ACTIVITY' ? original.taskActivityType : original.type?.label,
        cell: ({ getValue }) => <TooltipCellWithCopy text={getValue()} />,
    },
    {
        header: 'Details/Last Comment',
        accessorKey: 'details',
        ...columnsCommonConfig.details,
        cell: ({ row }) => {
            const { cardLastComment, details, id, isVirtual, isGenAiDetails } = row.original;
            return (
                <DetailsCell
                    details={details}
                    cardLastComment={cardLastComment}
                    id={id}
                    invalidateQueriesKey={invalidateKeys}
                    isVirtual={isVirtual}
                    isGenAiDetails={isGenAiDetails}
                />
            );
        },
    },
    {
        header: 'Critical Path',
        accessorFn: (row) => (row.cardIndicators.isCriticalPath.isIndicatorOn ? 'Yes' : 'No'),
        ...columnsCommonConfig.criticalPath,
    },
    {
        header: 'Start Date',
        ...columnsCommonConfig.startDate,
        accessorKey: 'startDate',
        cell: ({ getValue, row }) => {
            const changeMapDate = row.original.startVariance;
            const comparedVersion = row.original.baselineStartDate;
            return (
                <DateCell
                    latestVersionDate={getValue()}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        header: 'Finish Date',
        ...columnsCommonConfig.finishDate,
        accessorKey: 'endDate',
        cell: ({ getValue, row }) => {
            const changeMapDate = row.original.finishVariance;
            const comparedVersion = row.original.baselineFinishDate;
            return (
                <DateCell
                    latestVersionDate={getValue()}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        header: 'Baseline Start Date ',
        accessorKey: 'baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Baseline Finish Date',
        accessorKey: 'baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Remaining Duration',
        ...columnsCommonConfig.remainingDuration,
        size: 150,
        accessorKey: 'remainingDurationInDays',
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Immediate Parent Name ',
        ...columnsCommonConfig.parentName,
        accessorKey: 'parentActivity',
        cell: ({ getValue }) => <TooltipCellWithCopy text={getValue()} />,
    },
    {
        header: 'Grand Parent Name',
        ...columnsCommonConfig.grandParentName,
        accessorKey: 'grandParentActivity',
        cell: ({ getValue }) => <TooltipCellWithCopy text={getValue()} />,
    },
    {
        header: 'Criticality Score',
        accessorKey: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        cell: ({ getValue, row }) => {
            const delayDriver = row.original.cardIndicators.isDelayDriver.isIndicatorOn;
            const style = delayDriver ? { color: delayDriverColor, fontWeight: 600 } : {};
            const numOfPredecessors = row.original.numOfPredecessors || row.original.predeccessors?.length;
            const numOfSuccessors = row.original.numOfSuccessors || row.original.successors?.length;
            const precedingActivities = row.original.precedingActivities;
            const succeedingActivites = row.original.succeedingActivites;
            const precedingRisk = row.original.precedingRisk;
            const succeedingRisk = row.original.succeedingRisk;
            return (
                <CriticalityScoreCell
                    succeedingRisk={succeedingRisk}
                    precedingRisk={precedingRisk}
                    numOfSuccessors={numOfSuccessors}
                    numOfPredecessors={numOfPredecessors}
                    succeedingActivites={succeedingActivites}
                    precedingActivities={precedingActivities}
                    style={style}
                    value={getValue()}
                />
            );
        },
        sortingFn: 'basic',
    },
    {
        header: 'Float',
        accessorKey: 'float',
        ...columnsCommonConfig.float,
        sortingFn: customSortWithNullV8,
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Tags',
        accessorKey: 'tagList',
        ...columnsCommonConfig.tagList,
        cell: ({ getValue }) => {
            const list = getValue().map((item) => item.name);
            return list.length > 0 ? <PillsCell list={list} type={'tag'} /> : null;
        },
    },
    {
        header: 'Start Variance',
        ...columnsCommonConfig.lateStart,
        accessorKey: 'startVariance',
        cell: ({ getValue }) => {
            const value = getValue<number>();
            return <VarianceCell value={Math.ceil(value)} />;
        },
    },
    {
        header: 'Finish Variance',
        ...columnsCommonConfig.lateFinish,
        accessorKey: 'finishVariance',
        cell: ({ getValue }) => {
            const value = getValue<number>();
            return <VarianceCell value={Math.ceil(value)} />;
        },
    },
    {
        header: 'Planned Start Date ',
        accessorKey: 'plannedStartDate',
        ...columnsCommonConfig.plannedStartDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Planned Finish Date',
        accessorKey: 'plannedFinishDate',
        ...columnsCommonConfig.plannedFinishDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Actual Start Date',
        accessorKey: 'actualStartDate',
        ...columnsCommonConfig.actualStartDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Actual Finish Date',
        accessorKey: 'actualFinishDate',
        ...columnsCommonConfig.actualFinishDate,
        cell: ({ getValue }) => {
            return <BasicDateCell value={getValue()} />;
        },
    },
    {
        header: 'Planned Duration',
        ...columnsCommonConfig.plannedDuration,
        accessorKey: 'plannedDurationInDays',
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Actual Duration',
        ...columnsCommonConfig.actualDuration,
        accessorKey: 'actualDurationInDays',
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Current Duration',
        ...columnsCommonConfig.completedDuration,
        accessorKey: 'completedDurationInDays',
        cell: ({ getValue }) => {
            const value = getValue<number | null>();
            return value !== null ? Math.ceil(value) : '';
        },
    },
    {
        header: 'Current Duration Overrun',
        ...columnsCommonConfig.durationOverrun,
        accessorKey: 'completedDurationOverrun',
        cell: ({ getValue }) => {
            const value = getValue<number>();
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{Math.ceil(value)}</span>;
        },
    },
    {
        header: 'Pred.',
        accessorKey: 'numOfPredecessors',
        ...columnsCommonConfig.numOfPredecessors,
    },
    {
        header: 'Succ.',
        accessorKey: 'numOfSuccessors',
        ...columnsCommonConfig.numOfSuccessors,
    },
    {
        header: 'Project Week Start ',
        accessorKey: 'projectWeekStart',
        ...columnsCommonConfig.projectWeekStart,
    },
    {
        header: 'Project Week Finish',
        accessorKey: 'projectWeekFinish',
        ...columnsCommonConfig.projectWeekFinish,
    },
    {
        header: 'Project Month Start',
        accessorKey: 'projectMonthStart',
        ...columnsCommonConfig.projectMonthStart,
    },
    {
        header: 'Project Month Finish',
        accessorKey: 'projectMonthFinish',
        ...columnsCommonConfig.projectMonthFinish,
    },
    {
        header: 'Last Submitted Status',
        ...columnsCommonConfig.originalStatus,
        accessorKey: 'originalStatus',
        cell: ({ getValue }) => (getValue() ? getValue<{ id: number; label: string; name: string }>().label : ''),
    },
];

const generateFilterByType = (item, activityCodes, activityFragnet, assigneeOptionsList, singleWbs) => {
    switch (item.name) {
        case 'START_DATE_CARD_BOARD':
        case 'END_DATE_CARD_BOARD':
            if (item.data.length === 0) {
                if (item.type === 'TODAY_REF') {
                    return { ...item, data: [TodayMenuOptions.find((i) => i.value === item.value)?.inputLabel] };
                }

                if (item.type === 'VERSION_DATE_REF') {
                    return { ...item, data: [VersionDateMenuOptions.find((i) => i.value === item.value)?.inputLabel] };
                }
            }
            const newData = item.data
                .map((date) => moment(Number(date)).format(constants.formats.date.defaultForInput))
                .join(' - ');
            return { ...item, data: [newData] };
        case 'TRACKED_CARD_BOARD':
        case 'COMMENT_CARD_BOARD':
        case 'DOCLINK_CARD_BOARD':
        case 'DELAY_DRIVER_CARD_BOARD':
        case 'IMPORTANT_PREDECESSOR_OVERDUE':
        case 'RCF_RISK':
        case 'CONSTRAINT_DAY_SET':
        case 'LONG_DURATION':
        case 'IMPORTANT_LAG_CARD_30_BOARD':
        case 'OVERDUE_CARD_BOARD':
        case 'FINISH_PROG_OVERDUE_CARD_BOARD':
        case 'START_PROG_OVERDUE_CARD_BOARD':
        case 'SET_BACK':
        case 'IMPORTANT_STALLED':
        case 'CRITICAL_PATH_USER_CARD_BOARD':
            return { ...item, data: [item.data[0] === '1' ? 'Yes' : 'No'] };
        case 'ACTIVITY_CODE_CARD_BOARD':
            const filteredActivityCodes = activityCodes
                ? activityCodes.filter((activityCode) => item.data.includes(activityCode.uid))
                : [];
            const activityCodesData = filteredActivityCodes.map(activityCodeMapperUID).map((i) => i.label);
            return { ...item, data: activityCodesData };
        case 'ASSIGNEE_CARD_BOARD':
            const assigneeFound = assigneeOptionsList.find((assignee) => assignee.value === item.data[0]);
            return assigneeFound
                ? { ...item, data: [`${assigneeFound.assignee.firstName} ${assigneeFound.assignee.lastName}`] }
                : item;
        case 'UPDATED_USER_CARD_BOARD':
        case 'DURATION_CARD_BOARD':
        case 'LAG_CARD_BOARD':
        case 'TOTAL_FLOAT_CARD_BOARD':
            return item;
        case 'ACTIVITY_TYPE_BOARD':
            return { ...item, data: item.data.map((i) => activityTypeLabels[i]) };
        case 'TYPE_CARD_BOARD':
            return { ...item, data: item.data.map((i) => cardTypeLabels[i]) };
        case 'WBS_LEVEL_CARD_BOARD':
        case 'SUBMITTED_COMPLETION_CARD_BOARD':
            return { ...item, data: [`${item.data[0]} - ${item.data[1]}%`] };
        case 'TAG_CARD_BOARD':
            return { ...item };
        case 'FRAGNET_MILESTONE_ID_CARD_BOARD':
            const filteredActivityFragnet = activityFragnet
                ? activityFragnet.filter((i) => item.data.includes(i.task_version_hash_code))
                : [];
            const activityFragnetData = filteredActivityFragnet.map(milestoneFragnetMapper).map((i) => i.label);
            return { ...item, data: activityFragnetData };
        case 'WBS_ID_CARD_BOARD':
            const filteredWbs = singleWbs ? singleWbs.filter((i) => item.data.includes(String(i.id))) : [];
            const wbsData = filteredWbs.map(mapper).map((i) => i.label);
            return { ...item, data: wbsData };
        case 'RESOURCE_NAME_CARD_BOARD':
            return { ...item, data: item.label };
        default:
            return { ...item, data: item.data.map(widgetFilterTitle) };
    }
};

interface IFinalFlags {
    data: string[];
    name: string;
}

const generateFlags = (flags) => {
    if (!flags) {
        return [];
    }

    const finalFlags: IFinalFlags[] = [];

    for (const key in flags) {
        if (key === 'endDatesRange') {
            finalFlags.push({ data: [widgetFilterTitle(flags[key])], name: 'END_DATE_CARD_BOARD' });
        }

        if (key === 'startDatesRange') {
            finalFlags.push({ data: [widgetFilterTitle(flags[key])], name: 'START_DATE_CARD_BOARD' });
        }

        if (key === 'commentRange') {
            finalFlags.push({ data: [widgetFilterTitle(flags[key])], name: 'COMMENT_CARD_BOARD' });
        }
    }

    return finalFlags;
};

export const buildRowsBoardFilter = ({ filter, activityCodes, activityFragnet, assigneeOptionsList, singleWbs }) => {
    const filtersObject = filter?.filters.hasOwnProperty('cardCachedFilter')
        ? filter?.filters.cardCachedFilter
        : filter?.filters;
    const excludingFilters = ['PROJECT_CARD_BOARD', 'CONTRACT_CARD_BOARD', 'VERSION_CARD_BOARD'];
    const generateFilters = filtersObject.filter.filters.map((item) =>
        generateFilterByType(item, activityCodes, activityFragnet, assigneeOptionsList, singleWbs),
    );

    const flags = generateFlags(filter?.filters.flags);

    return [...flags, ...generateFilters].filter((item) => {
        return !excludingFilters.includes(item.name);
    });
};

export const activityTypeLabels = {
    TASK_DEPENDENT: 'Task',
    START_MILESTONE: 'Milestone',
    FINISH_MILESTONE: 'Milestone',
    WBS: 'WBS',
    LEVEL_OF_EFFORT: 'Level Of Effort(LOE)',
    RESOURCE_DEPENDENT: 'Resource dependent',
};

const cardTypeLabels = {
    KC_INSIGHT: 'Recommendation',
    PROGRAMME_ACTIVITY: 'Program Activity',
    USER_GENERATED: 'User Action',
};

export const linkToBattleCards = (version: number) => {
    return `/dashboard/project/${projectConfig.battlecards.link}/${version}?cardType=PROGRAMME_ACTIVITY&status=INTERIM_CHANGED`;
};
