import { Controller, UseFormReturn } from 'react-hook-form';
import React, { memo, useEffect, useMemo } from 'react';
import produce from 'immer';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { useQueryState } from 'hooks/useQueryState';
import { IState } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { useVersionStore } from 'store/version.store';
import { useQueryGetResourceListForChart } from 'api/queries/getResourceListForChart.query';

interface Props {
    methods?: UseFormReturn<any, any>;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<IState>>;
    handleFilters?: () => void;
}

export const ResourceFilter = memo(({ methods, name, setState, handleFilters }: Props) => {
    const { version } = useVersionStore();
    const [queryStringValue] = useQueryState('resource');
    const { getValueFromStore } = useGlobalFilterDataStore();
    const storeValue = getValueFromStore<number>('resource');

    const { data, isFetching } = useQueryGetResourceListForChart({
        latestVersion: version?.id,
    });

    const options = useMemo<ISelectOption<number>[]>(() => (data ? data : []), [data]);

    const formValue = methods?.getValues(name);
    const isValue = formValue || queryStringValue || storeValue;

    useEffect(() => {
        if (setState) {
            if (!isValue) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.resource.isReady = true;
                        draft.resource.isEmpty = true;
                        return draft;
                    });
                });
            }
            if (isValue) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.resource.isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, data, setState]);

    const handleChange = (value: ISelectOption<number> | null) => {
        methods?.setValue(name, value ? value.value : null);
        handleFilters && handleFilters();
    };

    const handleValue = (value) => {
        if (!value) {
            return null;
        }

        return data?.find((item) => item.value === value) || null;
    };

    return (
        <div className={classes.selectWrapper}>
            <span className={classes.title}>Resource Name</span>
            <div className={classes.activityCodesSelectWrapper}>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<number>>
                            {...field}
                            options={options}
                            id={'global-filter-component-resource'}
                            isClearable={true}
                            isLoading={isFetching}
                            onChange={handleChange}
                            value={handleValue(field.value)}
                            placeholder={'Select resource...'}
                        />
                    )}
                    name={name}
                    control={methods?.control}
                />
            </div>
        </div>
    );
});
