import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation } from 'react-query';

const mutationFn = ({ metaDataId, simulationDate }: QueryParams) => {
    const url = API_URL().RUN_SCHEDULE_SIMULATION;
    return network.post(url, { metaDataId, simulationDate }).then((response) => response.data);
};

export const useMutationRunCpmSimulation = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    metaDataId: number;
    simulationDate: number;
}
