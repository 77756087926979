import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { useMemo } from 'react';
import classes from 'components/common/selectedRangeLabel/selectedRangeLabel.module.scss';

interface Props {
    selectedEndDate: number | null;
    selectedStartDate: number | null;
    seriesLabel: string | null;
}
export const SelectedRangeLabel = ({ selectedEndDate, selectedStartDate, seriesLabel }: Props) => {
    const dateRange = useMemo(() => {
        const startDate = moment(selectedStartDate).format(constants.formats.date.default);
        const endDate = moment(selectedEndDate).format(constants.formats.date.default);
        return selectedStartDate && selectedEndDate ? `${startDate} - ${endDate}` : null;
    }, [selectedStartDate, selectedEndDate]);

    return dateRange ? (
        <div className={classes.container} data-testid={'selected-range-label'}>
            {dateRange ? (
                <div>
                    Date range: <span className={classes.value}>{dateRange}</span>
                </div>
            ) : null}{' '}
            {seriesLabel ? (
                <div>
                    Series: <span className={classes.value}>{seriesLabel}</span>
                </div>
            ) : null}
        </div>
    ) : null;
};
