import { findeNodeByUUID, ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { useQueryGetGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import classes from './ProjectTree.module.scss';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { IContract, IProject, useProjectTreeStore } from 'store/projectContract.store';
import { AddProjectModal } from 'components/Dashboards/Program/ProgramWrapper/DashboardComponents/Common/ProgramProjectFilter/AddProjectModal/AddProjectModal';
import { AddSubProgramModal } from 'components/Dashboards/Program/ProgramWrapper/DashboardComponents/Common/ProgramProjectFilter/AddSubProgramModal/AddSubProgramModal';
import { HeaderBreadcrumbs } from 'components/common/TreeProgramFilter/ProjectTree/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { ItemsList } from 'components/common/TreeProgramFilter/ProjectTree/ItemsList/ItemsList';
import { last } from 'lodash';
import { useBasePath } from 'hooks/useBasePath';
import { useHistory } from 'react-router-dom';

interface IProjectTree {
    isAllOption?: boolean;
    callback?: (project: IProject | null, contract: IContract | null) => void;
    isDisabled?: boolean;
}

export const ProjectTree = memo(({ isAllOption = false, callback, isDisabled = false }: IProjectTree) => {
    const { parentNode, contract, project, modalType, update } = useProjectTreeStore();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(null);
    const basePath = useBasePath();
    const history = useHistory();

    const { data } = useQueryGetGeneralHierarchyList();

    const handleDropdownOpen = useCallback(
        ({ event, parent }: { event: React.MouseEvent<HTMLElement>; parent: ProjectTreeNode | null }) => {
            update({ currentList: parent?.children || [], parentNode: parent });

            setDropdownAnchor(event.currentTarget);
            setDropdownOpen((isOpen) => !isOpen);
        },
        [data],
    );

    const onCreateNewProjectOrSubProgram = () => {
        if (parentNode && parentNode.uuid !== '0-root') {
            update({ modalType: 'subProgram' });
        } else {
            update({ modalType: 'project' });
        }
    };

    const onHideModal = useCallback(() => {
        update({ modalType: null });
        setDropdownOpen(false);
    }, []);

    useEffect(() => {
        if (!isAllOption && !contract) {
            const projectParent = data && project ? findeNodeByUUID(data, project.uuid) : null;
            const lastContract = last(projectParent?.children);
            update({
                contract: lastContract
                    ? {
                          id: lastContract.id,
                          title: lastContract.name,
                          uuid: lastContract.uuid,
                      }
                    : null,
            });

            history.push(basePath);
        }
    }, [isAllOption, contract, project, data]);

    return (
        <div className={classes.projectTreeContainer}>
            <HeaderBreadcrumbs data={data} handleDropdownOpen={handleDropdownOpen} isDisabled={isDisabled} />
            <ItemsList
                dropdownAnchor={dropdownAnchor}
                dropdownOpen={dropdownOpen}
                setDropdownOpen={setDropdownOpen}
                onCreateNewProjectOrSubProgram={onCreateNewProjectOrSubProgram}
                isAllOption={isAllOption}
                data={data}
                callback={callback}
            />

            <AddProjectModal onHideModal={onHideModal} showModal={modalType === 'project'} callback={callback} />

            <AddSubProgramModal onHideModal={onHideModal} showModal={modalType === 'subProgram'} callback={callback} />
        </div>
    );
});
