import React, { useEffect, useMemo, useState } from 'react';
import { PrioritiesMatrix } from 'components/common/PrioritiesMatrix/PrioritiesMatrix';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';

import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { LoaderContainer } from 'components/common';
import { UseQuerystring, useQuerystring } from 'hooks/useQuerystring';
import {
    BATTLECARDS_FILTERS,
    getTagsQueryFilter,
    initialMatrixFilter,
    MATRIX_COMPONENTS,
    PRIORITIES_MATRIX_COMPONENT_HEIGHT,
    PRIORITIES_MATRIX_FILTERS,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/PrioritiesMatrixSection/PrioritiesMatrixWrapper/prioritiesMatrixComponent.utils';
import { useParams } from 'react-router-dom';
import { ProgramStatusUrlParams } from '../../programStatusSummaryReportTypes';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import {
    IComponentProps,
    ILocalFilters,
    ITypeAndRole,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { find } from 'lodash';
import NoData from 'components/common/NoData/noData';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { SubProgramPreferencesLink } from 'components/common/SubProgramPreferencesLink/subProgramPreferencesLink';
import useUserHook from 'hooks/useUserHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { List } from 'components/common/ListWrapper/list';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/style/table.module.scss';
import { useQueryState } from 'hooks/useQueryState';
import qs from 'qs';
import { getStringWithoutHash } from 'utilitys/helpers/general';
import { useQueryGetMatrixData } from 'api/queries/getMatrixData.query';
import { useQueryPrioritiesMatrixCards } from 'api/queries/prioritiesMatrixCards.query';

const CARD_TYPES = {
    itemRecommendation: 'KC_INSIGHT',
    itemProgramActivities: 'PROGRAMME_ACTIVITY',
    itemUserActivities: 'USER_GENERATED',
};

export interface LocalFilters extends ILocalFilters {
    tagsQuery: { tags: null | string; excludeTags: null | string } | null;
}

interface Props extends IComponentProps {
    localFilters: LocalFilters;
}

const PrioritiesMatrixComponent = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    localFilters,
    setSubTitleForExport,
}: Props) => {
    const { email } = useUserHook();
    const [selectedTypeAndRole, setSelectedTypeAndRole] = useState<ITypeAndRole>(
        widgetId && localFilters && localFilters.selectedTypeAndRole
            ? {
                  type: localFilters.selectedTypeAndRole.type,
                  role: localFilters.selectedTypeAndRole.role,
              }
            : {
                  type: 'doNowComponent',
                  role: 'itemProgramActivities',
              },
    );
    const [matrixItemId, setMatrixItemId] = useState<number>(3);
    const [filtersTitle, setFiltersTitle] = useState<string>('Urgent');
    const [isOverdueFilter, setIsOverdueFilter] = useState<any>(widgetId ? localFilters?.isOverdueFilter : false);
    const [isDelayDriverFilter, setIsDelayDriverFilter] = useState<any>(
        widgetId ? localFilters?.isDelayDriverFilter : false,
    );
    const [isAssignedToMeFilter, setIsAssignedToMeFilter] = useState<any>(
        widgetId ? localFilters?.isAssignedToMeFilter : false,
    );

    const [isCriticalPathFilter, setIsCriticalPathFilter] = useState<any>(
        widgetId ? localFilters?.isCriticalPathFilter : false,
    );
    const { id: versionId } = useParams<ProgramStatusUrlParams>();
    const { getQueryKey }: UseQuerystring = useQuerystring();
    const [matrixFilter, setMatrixFilter] = useQueryState('matrixFilter');

    const UrgentData = find(filter?.filters, { name: FilterNames.NUM_OF_DAYS_TO_BE_URGENT })?.data || [];
    const numOfDaysToBeUrgent = widgetId ? Number(UrgentData[0]) : Number(matrixFilter);

    useEffect(() => {
        if (!matrixFilter && !widgetId) {
            setMatrixFilter(initialMatrixFilter);
        }
    }, [matrixFilter, widgetId]);

    const urgentFilter = PRIORITIES_MATRIX_FILTERS.find((filter) => filter.value === matrixFilter);
    const filters = widgetId
        ? filter?.filters
        : getFiltersData({
              [FilterNames.ACTIVITY_CODE_CARD_BOARD]: globalFilterData?.activityCodes
                  ?.filter((item) => !item.value.isExclude)
                  .map((i) => i.value.name),
              [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                  ?.filter((item) => item.value.isExclude)
                  .map((i) => i.value.name),
              [FilterNames.FRAGNET_MILESTONE_ID_CARD_BOARD]: globalFilterData?.milestoneFragnet,
              [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                  ?.filter((item) => !item.value.isExclude)
                  .map((i) => i.value.name),
              [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                  ?.filter((item) => item.value.isExclude)
                  .map((i) => i.value.name),
              [FilterNames.NUM_OF_DAYS_TO_BE_URGENT]: matrixFilter ? [matrixFilter] : [],
          });

    const { activityCodes, excludeActivityCodes, milestoneFragnet } = useMemo(() => {
        const activityCodesValue = find(filter?.filters, { name: FilterNames.ACTIVITY_CODE_CARD_BOARD });
        const excludeActivityCodesValue = find(filter?.filters, { name: FilterNames.ACTIVITY_CODES_EXCLUDE });
        const milestoneFragnetValue = find(filter?.filters, { name: FilterNames.FRAGNET_MILESTONE_ID_CARD_BOARD });
        return {
            activityCodes: activityCodesValue ? [activityCodesValue] : [],
            excludeActivityCodes: excludeActivityCodesValue ? [excludeActivityCodesValue] : [],
            milestoneFragnet: milestoneFragnetValue ? [milestoneFragnetValue] : [],
        };
    }, [filter]);

    const { generateFilters: formattedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });

    const fetchFilters = useMemo(
        () =>
            widgetId
                ? [...activityCodes, ...milestoneFragnet, ...excludeActivityCodes]
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODE_CARD_BOARD]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_MILESTONE_ID_CARD_BOARD]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH_ID_CARD_BOARD]: globalFilterData?.wbs,
                  }),

        [activityCodes, globalFilterData],
    );
    const { data: matrixData, isLoading } = useQueryGetMatrixData({
        versionId: latestVersionId,
        numOfDaysToBeUrgent: numOfDaysToBeUrgent,
        filter: {
            filters: fetchFilters,
        },
    });

    const { data: cards, isLoading: isloadingCardsData } = useQueryPrioritiesMatrixCards({
        versionId: latestVersionId,
        itemId: matrixItemId,
        numOfDaysToBeUrgent: numOfDaysToBeUrgent,
        filter: {
            filters: fetchFilters,
        },
    });

    const fetchCardsData = ({ itemId }) => {
        setMatrixItemId(itemId);
    };

    const setFilterTitle = (componentType: string) => {
        const title = MATRIX_COMPONENTS[componentType].urgent ? 'Urgent' : ' Not Urgent';
        setFiltersTitle(title);
    };
    const getIsOverdueAndIsDelayDriverFilter = () => {
        if (isDelayDriverFilter && isOverdueFilter && isCriticalPathFilter) {
            return 'DELAY_DRIVER_CARD_BOARD,OVERDUE_CARD_BOARD,CRITICAL_PATH_USER_CARD_BOARD';
        } else if (isDelayDriverFilter && isOverdueFilter) {
            return 'DELAY_DRIVER_CARD_BOARD,OVERDUE_CARD_BOARD';
        } else if (isDelayDriverFilter && isCriticalPathFilter) {
            return 'DELAY_DRIVER_CARD_BOARD,CRITICAL_PATH_USER_CARD_BOARD';
        } else if (isOverdueFilter && isCriticalPathFilter) {
            return 'OVERDUE_CARD_BOARD,CRITICAL_PATH_USER_CARD_BOARD';
        } else if (isDelayDriverFilter) {
            return 'DELAY_DRIVER_CARD_BOARD';
        } else if (isOverdueFilter) {
            return 'OVERDUE_CARD_BOARD';
        } else if (isCriticalPathFilter) {
            return 'CRITICAL_PATH_USER_CARD_BOARD';
        }

        return null;
    };

    const getWbsQueryFilter = () => {
        if (localFilters?.wbsQuery) {
            return localFilters?.wbsQuery;
        } else if (getQueryKey({ key: 'wbs' })) {
            return getQueryKey({ key: 'wbs' });
        }

        return null;
    };

    const getAssignedToMeQueryFilter = () => {
        if (isAssignedToMeFilter) {
            const assignedId = cards?.find((item) => item.assignee.userName === email);
            return assignedId ? assignedId?.assignee.userName : null;
        }

        return null;
    };

    const milestoneFilter = filters?.find((filter) => filter.name === FilterNames.FRAGNET_MILESTONE_ID_CARD_BOARD);
    const milestoneFragnetHash = milestoneFilter ? milestoneFilter.data?.[0] : undefined;

    const activityCodesFilter = filters?.find((filter) => filter.name === FilterNames.ACTIVITY_CODE_CARD_BOARD);
    const activityCodesFilterHash = activityCodesFilter ? activityCodesFilter.data?.join(',') : undefined;

    const excludeActivityCodesFilter = filters?.find((filter) => filter.name === FilterNames.ACTIVITY_CODES_EXCLUDE);
    const excludeActivityCodesFilterHash = excludeActivityCodesFilter
        ? excludeActivityCodesFilter.data?.join(',')
        : undefined;

    const getLinkToBattlecards = () => {
        if (matrixData) {
            const isUrgent = MATRIX_COMPONENTS[selectedTypeAndRole.type].urgent;
            const important = MATRIX_COMPONENTS[selectedTypeAndRole.type].important ? 'IMPORTANT' : 'NOT_IMPORTANT';
            const filterData = widgetId ? UrgentData[0] : matrixFilter;
            const urgent =
                filterData && (isUrgent ? BATTLECARDS_FILTERS[filterData][0] : BATTLECARDS_FILTERS[filterData][1]);
            const riskIndicators = getIsOverdueAndIsDelayDriverFilter();
            const isAssignedToMe = getAssignedToMeQueryFilter();
            const tagsQuery = getTagsQueryFilter({ localFilters });
            const wbsQuery = getWbsQueryFilter();

            const querystring = qs.stringify(
                {
                    important: important,
                    urgent: urgent,
                    cardType: CARD_TYPES[selectedTypeAndRole.role],
                    riskIndicators: riskIndicators,
                    assignee: isAssignedToMe,
                    activityCodes: activityCodesFilterHash,
                    excludeActivityCodes: excludeActivityCodesFilterHash,
                    tags: tagsQuery.tags,
                    excludeTags: tagsQuery.excludeTags,
                    wbs: wbsQuery,
                    activityFragnet: milestoneFragnetHash,
                },
                { addQueryPrefix: true, skipNulls: true, encode: true },
            );

            return `/dashboard/project/${projectConfig.battlecards.link}/${
                versionId || localFilters?.id
            }${querystring}`;
        }

        return '';
    };

    const linkToBattlecards = getLinkToBattlecards();
    //=========================

    const localFiltersToAdd: ILocalFilters = {
        isOverdueFilter: isOverdueFilter,
        isDelayDriverFilter: isDelayDriverFilter,
        isCriticalPathFilter: isCriticalPathFilter,
        isAssignedToMeFilter: isAssignedToMeFilter,
        activityCodesQuery: getQueryKey({ key: 'activityCodes' }),
        tagsQuery: getTagsQueryFilter({ localFilters }),
        wbsQuery: getQueryKey({ key: 'wbs' }),
        selectedTypeAndRole: selectedTypeAndRole,
        id: versionId,
    };

    const title = 'Priorities Matrix';
    const componentKey = 'prioritiesMatrix';
    const id = 'prioritiesMatrix';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const { sendEventWithDimensions } = useEventWithDimensions();
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    const handleFilterClick = (filter: ISelectOption<string>) => {
        const value = filter.value;
        sendEventWithDimensions({
            category: 'Priorities Matrix',
            action: 'Range Selection',
            label: value,
        });
        setMatrixFilter(value);
    };
    return (
        <WidgetWithTitle
            title={title}
            tooltip={matrixData?.info}
            id={id}
            titleComponents={[
                !widgetId && (
                    <div className={classes.filters} key={'ListWrapper'}>
                        <div className={classes.filtersWrapper}>
                            <div className={classes.filterTitle}>{`${filtersTitle}:`}</div>
                            <List<string>
                                onChange={handleFilterClick}
                                options={PRIORITIES_MATRIX_FILTERS}
                                value={urgentFilter}
                            />
                        </div>
                    </div>
                ),
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <SubProgramPreferencesLink key={'subProgramPreferences'} />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={filters}
                    localFilters={localFiltersToAdd}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown title={title} key={'export'} subTitle={subTitle} />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formattedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                ) : null,
            ]}
        >
            {isLoading && !matrixData && (
                <LoaderContainer height={PRIORITIES_MATRIX_COMPONENT_HEIGHT}>
                    <OverlayWithSpinner />
                </LoaderContainer>
            )}
            {!isLoading && !matrixData && <NoData />}
            {matrixData && (
                <PrioritiesMatrix
                    buttons={matrixData}
                    cards={cards}
                    isCardsLoading={isloadingCardsData}
                    fetchCards={fetchCardsData}
                    setFilterTitle={setFilterTitle}
                    selected={selectedTypeAndRole}
                    setSelected={setSelectedTypeAndRole}
                    isOverdueFilter={isOverdueFilter}
                    setIsOverdueFilter={setIsOverdueFilter}
                    isAssignedToMeFilter={isAssignedToMeFilter}
                    setIsAssignedToMeFilter={setIsAssignedToMeFilter}
                    isDelayDriverFilter={isDelayDriverFilter}
                    setIsDelayDriverFilter={setIsDelayDriverFilter}
                    isCriticalPathFilter={isCriticalPathFilter}
                    setIsCriticalPathFilter={setIsCriticalPathFilter}
                    linkToBattlecards={linkToBattlecards}
                />
            )}
        </WidgetWithTitle>
    );
};
export default React.memo(PrioritiesMatrixComponent);
