import { InformationBoxes } from 'components/common/informationBoxes/informationBoxes';
import localClasses from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourcesAnalysisChart/components/boxes.module.scss';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import React from 'react';
import { QueryResponse } from 'api/queries/getResourceChart.query';
import { useResourceAnalysisStore } from 'store/resourceAnalysis.store';
import { lowerCase, startCase } from 'lodash';

export const Boxes = ({ data }: { data: QueryResponse | undefined }) => {
    const { aggregation } = useResourceAnalysisStore();
    const aggregationLabel =
        aggregation?.value === 'AVERAGE' ? startCase(lowerCase(aggregation?.value)) : aggregation?.value;
    return (
        <InformationBoxes className={localClasses.row}>
            <InformationBoxes.Box>
                <div className={localClasses.box}>
                    <div className={localClasses.title}>Planned Budget ({aggregationLabel})</div>
                    <div data-testid={'information-box-info'} className={`${localClasses.info} ${localClasses.planed}`}>
                        {data?.budgetKpi.activities.toLocaleString()} units
                    </div>
                    <div>(As of {moment(data?.budgetKpi.date).format(constants.formats.date.default)})</div>
                </div>
            </InformationBoxes.Box>
            <InformationBoxes.Box>
                <div className={localClasses.box}>
                    <div className={localClasses.title}>Actual ({aggregationLabel})</div>
                    <div data-testid={'information-box-info'} className={`${localClasses.info} ${localClasses.actual}`}>
                        {data?.actualKpi.activities.toLocaleString()} units
                    </div>
                    <div>(As of {moment(data?.actualKpi.date).format(constants.formats.date.default)})</div>
                </div>
            </InformationBoxes.Box>
            <InformationBoxes.Box>
                <div className={localClasses.box}>
                    <div className={localClasses.title}>Remaining ({aggregationLabel})</div>
                    <div data-testid={'information-box-info'} className={`${localClasses.info} ${localClasses.future}`}>
                        {data?.remainingKpi.activities.toLocaleString()} units
                    </div>
                    <div>(As of {moment(data?.remainingKpi.date).format(constants.formats.date.default)})</div>
                </div>
            </InformationBoxes.Box>
        </InformationBoxes>
    );
};
