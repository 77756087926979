import {
    serializeData,
    serializeOptions,
} from 'components/Dashboards/Portfolio/PortfolioOverview/components/timelineAndDensity/timelineAndDensity.utils';
import { HeatmapChart } from 'components/common/charts/heatmapChart/heatmapChart';
import { useThemeStatusStore } from 'store/themeStatus.store';

import { IData } from 'api/queries/timelineAndDensity.query';

interface Props {
    mainData: IData[];
    data: IData[];
    isFirst?: boolean;
    height: number;
    callback?: (value: number | null) => void;
    todayLineDate?: number;
    isShowDensity: boolean;
    isBaseline: boolean;
}

export const HeatmapWrapper = ({
    mainData,
    data,
    isFirst = false,
    height,
    callback,
    todayLineDate,
    isShowDensity,
    isBaseline,
}: Props) => {
    const { themeStatus } = useThemeStatusStore();
    const series = serializeData({ mainData, data });
    const options = serializeOptions({
        mainData,
        themeStatus,
        showXaxisLabels: isFirst,
        callback,
        todayLineDate,
        isShowDensity,
        isBaseline,
    });
    return <HeatmapChart series={series} options={options} height={height} />;
};
