import classes from './CustomTooltip.module.scss';

interface IData {
    color: string;
    count: number;
    label: string;
}

export const CustomTooltip = ({ data }: { data: IData[] }) => {
    return (
        <div className={classes.customTooltipContainer}>
            {data.map((item, index) => (
                <div key={index} className={classes.wrapper} data-testid="item">
                    <span
                        data-testid="color-box"
                        className={classes.colorBox}
                        style={{ backgroundColor: item.color }}
                    />
                    <span data-testid="item-label">{`${item.count} ${item.label}`}</span>
                </div>
            ))}
        </div>
    );
};
