import { network } from 'services/network';
import { useMutation } from 'react-query';

const mutationFn = (params: QueryParams) => {
    return network
        .post(`https://${params.domain}/v1.0/admin/bo/login`, {
            username: params.username,
            password: params.password,
        })
        .then((response) => {
            return response.data;
        });
};
export const useMutationExternalLogin = () => {
    return useMutation<QueryResponse, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    username: string;
    password: string;
    domain: string;
}

export interface QueryResponse {
    accessToken: string;
    email: string;
}
