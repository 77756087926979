import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulationWarning/SimulationWarning.module.scss';
import { QueryResponse } from 'api/queries/getSchedulingSimulationResults.query';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import React, { useState } from 'react';
import Icon from 'components/common/Icons/icon';

const SHOW_CONTENT = 'header-arrow-down';
const HIDE_CONTENT = 'header-arrow-top';

interface Props {
    notification: SettingsAndWarnings;
}

export interface SettingsAndWarnings {
    warnings: QueryResponse['warnings'];
    settings: QueryResponse['settings'];
}

export const SimulationWarning = ({ notification }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => setIsOpen(!isOpen);
    const iconName = isOpen ? HIDE_CONTENT : SHOW_CONTENT;

    return (
        <div className={classes.simulationWarningsContainer}>
            <div>
                <div className={classes.headerContainer}>
                    <MuiIcon icon={'errorOutline-icon'} fontSize="2.5rem" color="#5f6368" />
                    <h1 className={classes.headline}>
                        Note some applied settings and issues found in schedule file may affect simulation outcome
                    </h1>
                    <Icon
                        data-testid="toggleShowBtn"
                        className={classes.button}
                        name={iconName}
                        size="2rem"
                        onClick={toggleIsOpen}
                    />
                </div>
            </div>
            {isOpen && (
                <>
                    <br />
                    <h2 className={classes.subHeadline}>The following relevant settings were applied:</h2>
                    {notification.settings.length > 0 && (
                        <div className={classes.content}>
                            <ol className={classes.orderedListContainer}>
                                {notification.settings.map((setting) => {
                                    const content = `${setting.key} ${setting.value}`;
                                    return (
                                        <li key={setting.key} data-testid="settingLi">
                                            {content}
                                        </li>
                                    );
                                })}
                            </ol>
                        </div>
                    )}
                    {notification.warnings.length > 0 && (
                        <>
                            <h2 className={classes.subHeadline}>The following warnings were found in schedule file:</h2>
                            <div className={classes.content}>
                                <ol className={classes.orderedListContainer}>
                                    {notification.warnings.map((warning) => {
                                        const link = warning?.details ? (
                                            <span>
                                                {` See more`}{' '}
                                                <a href={warning.details} target={'_blank'}>
                                                    details
                                                </a>
                                            </span>
                                        ) : null;

                                        const content = `${warning.key} ${warning.value}`;
                                        return link ? (
                                            <li key={warning.key} data-testid="warningLi">
                                                <span>{content}</span>
                                                <span>{link}</span>
                                            </li>
                                        ) : (
                                            <li key={warning.key}>{content}</li>
                                        );
                                    })}
                                </ol>
                            </div>
                        </>
                    )}
                    <div className={classes.disclaimer}>
                        * Foresight's CPM implementation is based on common CPM standards. Final results may slightly
                        vary from Primavera or MS-Project results due to the items above.
                    </div>
                </>
            )}
        </div>
    );
};
