import React, { useEffect, useMemo, useState } from 'react';
import classes from './hasCommentsFilter.module.scss';
import { noop } from 'lodash';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { Popover } from '@mui/material';
import { MyCustomSelect, OptionsWithAssignee } from 'components/common/MyCustomSelect/myCustomSelect';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { SideMenu } from 'components/common/CustomFilters/DateRangeFilter/SideMenu/SideMenu';
import { MainContent } from 'components/common/CustomFilters/DateRangeFilter/MainContent/MainContent';
import {
    defaultValuesByTabOption,
    FixedDateMenuOptions,
    TabOptions,
    TodayMenuOptions,
    TSideOption,
    VersionDateMenuOptions,
} from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import { Header } from 'components/common/CustomFilters/DateRangeFilter/Hedear/Header';
import { IAssigneeOptionsList, useAssigneeOptionsList } from 'hooks/useAssigneeOptionsList';
import { IFields } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters.types';
import { listWithCurrentUserBorder } from 'components/common/MyCustomSelect/myCustomSelect.style';

const getDefaultActiveSideMenuOption = (value): string => {
    if (Array.isArray(value)) {
        return 'FIXED_DATE';
    }

    if (value) {
        return value;
    } else {
        return 'ANYTIME';
    }
};

interface Data {
    value?: string | string[] | undefined;
    type?: TabOptions | undefined;
    commenter: IAssigneeOptionsList | null;
    mentioned: IAssigneeOptionsList | null;
}

interface Props {
    onSelection: (data: Data) => void;
    existingData: Data;
    inputDefaultValue: string;
    fixedDateMenuOptions: { value: string; label: string; dateValue: number[] }[];
    todayMenuOptions: { value: string; label: string; inputLabel: string }[];
    versionDateMenuOptions: { value: string; label: string; inputLabel: string }[];
    watch: UseFormWatch<IFields>;
    setValue: UseFormSetValue<IFields>;
}

export const HasCommentsFilter = ({
    onSelection,
    existingData,
    inputDefaultValue = '',
    fixedDateMenuOptions = FixedDateMenuOptions,
    todayMenuOptions = TodayMenuOptions,
    versionDateMenuOptions = VersionDateMenuOptions,
    watch,
    setValue,
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const [inputLabel, setInputLabel] = useState(inputDefaultValue);

    const [activeTab, setActiveTab] = useState<TabOptions>(existingData.type ?? 'TODAY_REF');
    const [activeSideMenuOption, setActiveSideMenuOption] = useState<string>(
        getDefaultActiveSideMenuOption(existingData.value),
    );
    const [rangeDate, setRangeDate] = useState(
        Array.isArray(existingData.value) && existingData.value.length === 2 ? existingData.value.map(Number) : [],
    );

    const [commenter, setCommenter] = useState<IAssigneeOptionsList | null>(null);
    const [mentioned, setMentioned] = useState<IAssigneeOptionsList | null>(null);

    useEffect(() => {
        setInputLabel(inputDefaultValue);
    }, [inputDefaultValue]);

    useEffect(() => {
        if (existingData.type !== activeTab) {
            setActiveSideMenuOption(defaultValuesByTabOption[activeTab]);
        } else {
            setActiveSideMenuOption(getDefaultActiveSideMenuOption(existingData.value));
        }
    }, [activeTab]);

    useEffect(() => {
        existingData.type && setActiveTab(existingData.type);
        setActiveSideMenuOption(getDefaultActiveSideMenuOption(existingData.value));
        setCommenter(existingData.commenter);
        setMentioned(existingData.mentioned);
    }, [existingData]);

    const sideMenuOptions = useMemo((): TSideOption<string>[] => {
        switch (activeTab) {
            case 'FIXED_DATE':
                return fixedDateMenuOptions;
            case 'TODAY_REF':
                return todayMenuOptions;
            case 'VERSION_DATE_REF':
                return versionDateMenuOptions;
            default:
                return fixedDateMenuOptions;
        }
    }, [activeTab, fixedDateMenuOptions, todayMenuOptions, versionDateMenuOptions]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
        setRangeDate([]);
        setCommenter(existingData.commenter);
        setMentioned(existingData.mentioned);
    };

    const handleApply = (value, label) => {
        const commenterLabel = commenter ? ` Commenter: ${watch('commenter')?.label}` : undefined;
        const mentionedLabel = mentioned ? ` Mentioned: ${watch('mentioned')?.label}` : undefined;
        const filterLabel = [`${label}`, commenterLabel, mentionedLabel].filter(Boolean).join();
        setInputLabel(filterLabel);
        onSelection({
            value: value,
            type: activeTab,
            commenter: commenter,
            mentioned: mentioned,
        });

        handlePopoverClose();
    };

    const handleClear = () => {
        setInputLabel('');
        setActiveTab('TODAY_REF');
        setValue('commenter', null);
        setValue('mentioned', null);
        onSelection({
            value: undefined,
            type: undefined,
            commenter: null,
            mentioned: null,
        });
    };

    const options = useAssigneeOptionsList(false);

    return (
        <div className={classes.hasCommentsFilterContainer}>
            <TextSearchInput
                id={'battlecards-filters-has-comments'}
                placeholder={'Select date range...'}
                value={inputLabel}
                handleClear={handleClear}
                onClick={handleMenuOpen}
                autoComplete={'off'}
                className={classes.inputWidth}
                onChange={noop}
                style={{ width: `${inputLabel.length}rem` }}
            />

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                open={menuOpen}
            >
                <div className={classes.popoverContentWrapper}>
                    <div className={classes.selectContainer}>
                        <div className={classes.mainTitle}>Find Comments</div>
                        <div className={classes.selectWrapper}>
                            <span className={classes.title}>Commenter</span>
                            <MyCustomSelect<IAssigneeOptionsList>
                                value={commenter}
                                options={options}
                                isClearable={true}
                                styles={listWithCurrentUserBorder}
                                components={{
                                    Option: OptionsWithAssignee,
                                }}
                                onChange={(value) => setCommenter(value)}
                            />
                        </div>
                        <div className={classes.selectWrapper}>
                            <span className={classes.title}>Mentioned</span>
                            <MyCustomSelect<IAssigneeOptionsList>
                                value={mentioned}
                                options={options}
                                isClearable={true}
                                styles={listWithCurrentUserBorder}
                                components={{
                                    Option: OptionsWithAssignee,
                                }}
                                onChange={(value) => setMentioned(value)}
                            />
                        </div>
                    </div>
                    <div>
                        <Header activeTab={activeTab} setActiveTab={setActiveTab} />
                        <div className={classes.dateRangeWrapper}>
                            <SideMenu
                                menuOptions={sideMenuOptions}
                                activeSideMenuOption={activeSideMenuOption}
                                setActiveSideMenuOption={setActiveSideMenuOption}
                            />
                            <MainContent
                                rangeDate={rangeDate}
                                setRangeDate={setRangeDate}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                onApply={handleApply}
                                activeSideMenuOption={activeSideMenuOption}
                                setActiveSideMenuOption={setActiveSideMenuOption}
                                menuOptions={sideMenuOptions}
                                existingData={existingData}
                                handlePopoverClose={handlePopoverClose}
                            />
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    );
};
