import { useVersionStore } from 'store/version.store';
import { useBattleCardsStore } from 'store/battlecards.store';
import useUserHook from 'hooks/useUserHook';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import { useState } from 'react';
import { generateUUID } from 'utilitys/helpers/general';
import { useSubscription } from 'react-stomp-hooks';
import {
    cardsDataQueryKey,
    listCardsDataQueryKey,
    useQueryCountersData,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { BulkAssign } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/bulkAssign/bulkAssign';
import { mapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/MainFilters/MainFilters.utils';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { useQueryGetBoardInit } from 'api/queries/getBoardInit.query';

export const BulkAssignWrapper = () => {
    const currentVersion = useVersionStore((state) => state.version);
    const filterData = useBattleCardsStore((state) => state.filterData);

    const { email } = useUserHook();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    // state ===================================================================================================
    const [bulkAssigneeToUserUniqIdNotification, setBulkAssigneeToUserUniqIdNotification] =
        useState<number>(generateUUID());
    const [bulkAssigneeToTagsUniqIdNotification, setBulkAssigneeToTagsUniqIdNotification] =
        useState<number>(generateUUID());
    const [bulkRemoveTagsUniqIdNotification, setBulkRemoveTagsUniqIdNotification] = useState<number>(generateUUID());
    const [bulkDeleteCardsUniqIdNotification, setBulkDeleteCardsUniqIdNotification] = useState<number>(generateUUID());
    const [bulkRollbackUniqIdNotification, setBulkRollbackUniqIdNotification] = useState<number>(generateUUID());

    const [isBulkLoading, setIsBulkLoading] = useState<boolean>(false);

    useSubscription(email ? [`/topic/${email}.notifications.push`] : [], (response) => {
        const { type, message } = JSON.parse(response.body);
        if (type === 'BulkAssignUserStatus' && Number(message) === bulkAssigneeToUserUniqIdNotification) {
            refecthBySubscription();
            handleSnackBar('Bulk request submitted', 'success');
            setBulkAssigneeToUserUniqIdNotification(generateUUID());
            setIsBulkLoading(false);
        }
        if (type === 'BulkAssignTagsStatus' && Number(message) === bulkAssigneeToTagsUniqIdNotification) {
            refecthBySubscription();
            handleSnackBar('Bulk request submitted', 'success');
            setBulkAssigneeToTagsUniqIdNotification(generateUUID());
            setIsBulkLoading(false);
        }
        if (type === 'BulkRemoveTagsStatus' && Number(message) === bulkRemoveTagsUniqIdNotification) {
            refecthBySubscription();
            handleSnackBar('Bulk request submitted', 'success');
            setBulkRemoveTagsUniqIdNotification(generateUUID());
            setIsBulkLoading(false);
        }
        if (type === 'BulkDeleteCardStatus' && Number(message) === bulkDeleteCardsUniqIdNotification) {
            refecthBySubscription();
            handleSnackBar('Bulk request submitted', 'success');
            setBulkDeleteCardsUniqIdNotification(generateUUID());
            setIsBulkLoading(false);
        }
        if (type === 'BulkRollbackInterimStatus' && Number(message) === bulkRollbackUniqIdNotification) {
            refecthBySubscription();
            handleSnackBar('Bulk request submitted', 'success');
            setBulkRollbackUniqIdNotification(generateUUID());
            setIsBulkLoading(false);
        }
    });

    const { data: boardInit } = useQueryGetBoardInit({
        metaDataId: currentVersion?.id,
    });

    const { data: cardsCounters, refetch: cardsCountersRefetch } = useQueryCountersData(filterData);

    const refecthBySubscription = () => {
        if (filterData.filter.filters.length > 0) {
            cardsCountersRefetch();
            return Promise.all([
                queryClient.invalidateQueries([cardsDataQueryKey]),
                queryClient.invalidateQueries([listCardsDataQueryKey]),
            ]);
        }
    };

    const tagsOptions = boardInit ? boardInit.tags.map(mapper) : [];

    return (
        <>
            {isBulkLoading && <OverlayWithSpinner />}
            <BulkAssign
                tagsOptions={tagsOptions}
                searchedFilterData={filterData}
                bulkAssigneeToUserUniqIdNotification={bulkAssigneeToUserUniqIdNotification}
                bulkAssigneeToTagsUniqIdNotification={bulkAssigneeToTagsUniqIdNotification}
                bulkRemoveTagsUniqIdNotification={bulkRemoveTagsUniqIdNotification}
                bulkDeleteCardsUniqIdNotification={bulkDeleteCardsUniqIdNotification}
                bulkRollbackUniqIdNotification={bulkRollbackUniqIdNotification}
                setIsBulkLoading={setIsBulkLoading}
                cardsCounters={cardsCounters}
                versionDbId={currentVersion?.id}
            />
        </>
    );
};
