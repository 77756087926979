import { ConfirmSaveModal, MyCustomSelect } from 'components/common';
import {
    preferredSPIMethodOptions,
    TPreferredSPIMethodO,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferences.utils';
import React, { useEffect, useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { Project } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferencesTypes';
import { useQueryGetPrefferedSPIMethod } from 'api/queries/getPrefferedSPIMethod.query';
import { useMutationPrefferedSPIMethod } from 'api/mutations/setPrefferedSPIMethod.mutation';

export const PreferredSPIMethod = ({ project }: { project: Project }) => {
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<ISelectOption<TPreferredSPIMethodO> | null>(null);
    const { handleSnackBar } = useCustomSnackBar();
    const { data, refetch } = useQueryGetPrefferedSPIMethod({
        projectId: project?.id,
    });

    const { mutate, isLoading: mutateLoading } = useMutationPrefferedSPIMethod();

    useEffect(() => {
        if (data) {
            const value = preferredSPIMethodOptions.find((item) => item.value === data) || null;
            if (value) {
                setSelectedValue(value);
            }
        }
    }, [data]);

    const currentValue = preferredSPIMethodOptions.find((item) => item.value === data) || null;

    const handleChange = (value: ISelectOption<TPreferredSPIMethodO> | null) => {
        setShowConfirmModal(true);
        setSelectedValue(value);
    };

    const handleHideModal = () => {
        setShowConfirmModal(false);
        setSelectedValue(currentValue);
    };

    const handleSave = () => {
        setShowConfirmModal(false);
        if (selectedValue && project) {
            const payload = {
                projectId: project.id,
                selectedOption: selectedValue.value,
            };

            mutate(payload, {
                onSuccess: () => handleSnackBar('Preferred SPI Method has changed', 'success'),
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
                onSettled: () => refetch(),
            });
        }
    };

    return (
        <>
            <span>Preferred SPI Method:</span>
            <MyCustomSelect<ISelectOption<TPreferredSPIMethodO>>
                value={selectedValue}
                options={preferredSPIMethodOptions}
                onChange={handleChange}
                isClearable={false}
            />
            <ConfirmSaveModal
                isShowModal={showConfirmModal}
                onHideModal={handleHideModal}
                onConfirm={handleSave}
                isLoading={mutateLoading}
            >
                You have made changes. Would you like to save them?
            </ConfirmSaveModal>
        </>
    );
};
