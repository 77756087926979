import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import classes from './SimulationNote.module.scss';

interface Props {
    note: string;
}

export const SimulationNote = ({ note = '' }: Props) => {
    return (
        <div className={classes.simulationNoteContainer}>
            <MuiIcon icon={'errorOutline-icon'} fontSize="2.5rem" color="#5f6368" />
            <span>{note}</span>
        </div>
    );
};
