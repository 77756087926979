import { ChangedActivitiesTable } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/ChangedActivitiesTable/ChangedActivitiesTable';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';

interface Props {
    handleRunSimulation: () => void;
}

export const ChangedActivitiesTableWrapper = ({ handleRunSimulation }: Props) => {
    const { id } = useParams<any>();
    const { versionList } = useHashmapConverter(id);
    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const latestVersionId = versionList[0]?.versionDbId;

    return (
        <ChangedActivitiesTable
            handleRunSimulation={handleRunSimulation}
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
        />
    );
};
