import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import qs from 'qs';

const queryFn = ({ customerId, projectId, contractId }: QueryParams) => {
    const querystring = qs.stringify({ customerId, projectId, contractId }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().GET_STORED_TAGS}${querystring}`;
    return network.get(url).then((response) => {
        return response.data;
    });
};

export const useQueryGetStoredTags = (params: QueryParams) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['getStoredTags', params],
        queryFn: () => queryFn(params),
        enabled: params.enabled && Boolean(params.customerId) && Boolean(params.projectId),
    });
};

interface QueryParams {
    enabled: boolean;
    customerId?: number;
    projectId?: number;
    contractId?: number;
}

interface QueryResponse {
    count: number;
    name: string;
    tagId: number;
    projectId: number;
    projectName: string | null;
}
