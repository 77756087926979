import classes from './treeProgramTopHeader.module.scss';
import React, { useEffect } from 'react';
import { ProgramTopHeader } from 'components/common/ProgramTopHeader/ProgramTopHeader';
import { ProjectTree } from 'components/common/TreeProgramFilter/ProjectTree/ProjectTree';
import { IContract, IProject, useProjectTreeStore } from 'store/projectContract.store';
import { VersionSelect } from 'components/common/TreeProgramFilter/VersionSelect/VersionSelect';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useQueryGetVersionsList } from 'api/queries/getProjectVersionsList.query';

interface Props {
    pageTitle: React.ReactNode;
    children?: React.ReactNode;
    versionSelectorEnabled?: boolean;
    showAll?: boolean;
    showBack?: boolean;
    isDisabled?: boolean;
    showBackLink?: string;
    callback?: (project: IProject | null, contract: IContract | null) => void;
}

export const TreeProgramTopHeader = ({
    pageTitle,
    children,
    versionSelectorEnabled = true,
    showAll = false,
    showBack = false,
    isDisabled = false,
    showBackLink,
    callback,
}: Props) => {
    const { contract, project, update } = useProjectTreeStore();

    const { id } = useParams<{ id: string }>();
    const { versionList } = useHashmapConverter(id);

    useEffect(() => {
        if (versionList.length > 0) {
            update({
                project: {
                    id: versionList[0].projectId,
                    title: versionList[0].projectName,
                    uuid: versionList[0].projectUuid,
                },
                contract: {
                    id: versionList[0].contractId,
                    title: versionList[0].contractName,
                    uuid: versionList[0].contractUuid,
                },
            });
        }
    }, [versionList]);

    const { data: versionsListData } = useQueryGetVersionsList({
        projectId: project?.id,
        contractId: contract?.id,
    });

    return (
        <div data-testid={'program-top-header'} className={classes.treeProgramTopHeader}>
            <ProgramTopHeader pageTitle={pageTitle} showBack={showBack} showBackLink={showBackLink} />
            <div data-testid={'header-content'} className={classes.headerContent}>
                <ProjectTree isAllOption={showAll} callback={callback} isDisabled={isDisabled} />

                {versionSelectorEnabled && versionsListData && <VersionSelect versionsList={versionsListData} />}

                {children && <div className={classes.actions}>{children}</div>}
            </div>
        </div>
    );
};
