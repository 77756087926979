import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import qs from 'qs';

const queryFn = (params: QueryParams) => {
    const querystring = qs.stringify(params, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().GET_PROJECT_DATES_FOR_RESOURCE_CHART}${querystring}`;
    return network.get(url).then((response) => {
        return response.data;
    });
};

export const useQueryGetProjectDatesForResourceChart = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['getProjectDatesForResourceChart', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.metaDataId) && Boolean(params.comparedMetaDataId),
    });
};

interface QueryParams {
    metaDataId: number | undefined;
    comparedMetaDataId: number | undefined;
}

export interface QueryResponse {
    endProjectDate: number;
    startProjectDate: number;
}
