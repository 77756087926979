import { useQuery } from 'react-query';
import qs from 'qs';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { QueryKeys } from 'api/queryKeys';

const queryFn = ({ latestVersion, filter, taskId }: QueryParams) => {
    const querystring = qs.stringify(
        { metaDataId: latestVersion, filter, taskId },
        { addQueryPrefix: true, skipNulls: true },
    );
    const url = `${API_URL().GET_WBS}${querystring}`;

    return network.post(url).then((response) => {
        return response.data;
    });
};
export const useQueryGetWbs = (params: Pick<QueryParams, 'latestVersion' | 'filter'>) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['getWbs', params],
        queryFn: () =>
            queryFn({
                latestVersion: params.latestVersion as number,
                filter: params.filter,
            }),
        enabled: Boolean(params.latestVersion),
    });
};

export const useQueryGetSingleWbs = (params: Pick<QueryParams, 'latestVersion' | 'taskId'>) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['getSingleWbs', params],
        queryFn: () =>
            queryFn({
                latestVersion: params.latestVersion as number,
                taskId: params.taskId,
            }),
        enabled: Boolean(params.latestVersion) && Boolean(params.taskId),
    });
};

interface QueryParams {
    latestVersion?: number;
    filter?: string;
    taskId?: string | null;
}

export interface QueryResponse {
    activity_id: string;
    id: number;
    name: string;
    task_version_hash_code: string;
    wbs_level: number;
}
