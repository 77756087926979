import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { DurationCell } from 'components/common/Tables/Cells/DurationCell';
import { FloatCell } from 'components/common/Tables/Cells/FloatCell';
import { CriticalPathCell } from 'components/common/Tables/Cells/CriticalPathCell';
import { CriticalityScoreCell } from 'components/common/Tables/Cells/CriticalityScoreCell';
import { getStatusLabel } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { PredecessorsCell } from 'components/common/Tables/Cells/PredecessorsCell';
import { SuccessorsCell } from 'components/common/Tables/Cells/SuccessorsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import colorsVars from 'styles/colors.module.scss';
import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { DeletedStatusCell } from 'components/common/Tables/Cells/DeletedStatusCell';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';

const { color6: orange, textColor } = colorsVars;

export const initialState: ITableColumnState = {
    hiddenColumns: [
        'details',
        'predecessors',
        'successors',
        'activityType',
        'lateFinish',
        'lateStart',
        'plannedStartDate',
        'plannedFinishDate',
        'actualStartDate',
        'actualFinishDate',
        'plannedDuration',
        'actualDuration',
        'remainingDuration',
        'completedDuration',
        'durationOverrun',
        'projectWeekStart',
        'projectWeekFinish',
        'projectMonthStart',
        'projectMonthFinish',
        'parentName',
        'grandParentName',
    ],
    sortBy: [{ id: 'duration', desc: true }],
};

export const columns = [
    {
        Header: 'Activity',
        accessor: 'comparedVersion.activityName',
        ...columnsCommonConfig.activity,
        Cell: ({ row, toggleAllRowsSelected }) => {
            const activityName = row.original.comparedVersion?.activityName;
            const activityId = row.original.comparedVersion?.activityId;
            const taskId = row.original.comparedVersion?.cardBoardViewResponse?.id;
            const cardIndicators = row.original.comparedVersion?.cardBoardViewResponse?.cardIndicators;
            const { toggleRowSelected } = row;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={taskId}
                    cardIndicators={cardIndicators}
                    toggleRowSelected={toggleRowSelected}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                />
            );
        },
    },
    {
        Header: 'Status',
        accessor: 'comparedVersion.activityStatus',
        ...columnsCommonConfig.status,
        Cell: ({ row }) => {
            const assignee = row.original.comparedVersion?.cardBoardViewResponse?.assignee;
            const previousStatus = row.original.comparedVersion?.activityStatus;
            const wasStatus = `Was: ${getStatusLabel(previousStatus)}`;

            return <DeletedStatusCell assignee={assignee} wasStatus={wasStatus} />;
        },
    },
    {
        Header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessor: 'comparedVersion.cardBoardViewResponse.taskActivityType',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Start Date',
        accessor: 'comparedVersion.startDate',
        ...columnsCommonConfig.startDate,
        Cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.startDate}
                    changeMapDate={row.original.changeMap?.startDate}
                    comparedVersion={row.original.comparedVersion?.startDate}
                    isChangeMap={row.original.changeMap}
                />
            );
        },
    },
    {
        Header: 'Finish Date',
        accessor: 'comparedVersion.finishDate',
        ...columnsCommonConfig.finishDate,
        Cell: ({ row }) => {
            return (
                <DateCell
                    latestVersionDate={row.original.latestVersion?.finishDate}
                    changeMapDate={row.original.changeMap?.finishDate}
                    comparedVersion={row.original.comparedVersion?.finishDate}
                    isChangeMap={row.original.changeMap}
                />
            );
        },
    },
    {
        Header: 'Duration',
        ...columnsCommonConfig.duration,
        accessor: 'comparedVersion.duration',
        Cell: ({ row }) => {
            return (
                <DurationCell
                    changeMap={row.original.changeMap}
                    changeMapDuration={row.original.changeMap?.duration}
                    latestVersionDuration={row.original.latestVersion?.duration}
                    comparedVersionDuration={row.original.comparedVersion?.duration}
                />
            );
        },
    },
    {
        Header: 'Criticality Score',
        accessor: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        Cell: ({ row }) => {
            return <CriticalityScoreCell row={row} />;
        },
    },
    {
        Header: 'Float',
        ...columnsCommonConfig.float,
        accessor: 'comparedVersion.float',
        Cell: ({ row }) => {
            return <FloatCell row={row} />;
        },
    },
    {
        Header: 'Critical Path',
        accessor: (row) =>
            row.latestVersion?.cardBoardViewResponse?.cardIndicators.isCriticalPath.isIndicatorOn ? 'Yes' : 'No',
        ...columnsCommonConfig.criticalPath,
        Cell: ({ row, value }) => {
            return <CriticalPathCell row={row} value={value} />;
        },
    },
    {
        Header: 'Pred.',
        accessor: 'comparedVersion.predecessors',
        ...columnsCommonConfig.predecessors,
        Cell: ({ row }) => {
            return <PredecessorsCell row={row} />;
        },
    },
    {
        Header: 'Succ.',
        accessor: 'comparedVersion.successors',
        ...columnsCommonConfig.successors,
        Cell: ({ row }) => {
            return <SuccessorsCell row={row} />;
        },
    },
    {
        Header: 'Finish Variance',
        ...columnsCommonConfig.lateFinish,
        accessor: 'comparedVersion.cardBoardViewResponse.finishVariance',
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: 'Start Variance',
        ...columnsCommonConfig.lateStart,
        accessor: 'comparedVersion.cardBoardViewResponse.startVariance',
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: 'Planned Start Date ',
        ...columnsCommonConfig.plannedStartDate,
        accessor: 'comparedVersion.cardBoardViewResponse.plannedStartDate',
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Planned Finish Date',
        ...columnsCommonConfig.plannedFinishDate,
        accessor: 'comparedVersion.cardBoardViewResponse.plannedFinishDate',
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Actual Start Date',
        ...columnsCommonConfig.actualStartDate,
        accessor: 'comparedVersion.cardBoardViewResponse.actualStartDate',
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Actual Finish Date',
        ...columnsCommonConfig.actualFinishDate,
        accessor: 'comparedVersion.cardBoardViewResponse.actualFinishDate',
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Planned Duration',
        ...columnsCommonConfig.plannedDuration,
        accessor: 'comparedVersion.cardBoardViewResponse.plannedDurationInDays',
    },
    {
        Header: 'Actual Duration',
        ...columnsCommonConfig.actualDuration,
        accessor: 'comparedVersion.cardBoardViewResponse.actualDurationInDays',
    },
    {
        Header: 'Remaining Duration',
        ...columnsCommonConfig.remainingDuration,
        accessor: 'comparedVersion.cardBoardViewResponse.remainingDurationInDays',
    },
    {
        Header: 'Current Duration',
        ...columnsCommonConfig.completedDuration,
        accessor: 'comparedVersion.cardBoardViewResponse.completedDurationInDays',
    },
    {
        Header: 'Current Duration Overrun',
        ...columnsCommonConfig.durationOverrun,
        accessor: 'comparedVersion.cardBoardViewResponse.completedDurationOverrun',
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Project Week Start ',
        ...columnsCommonConfig.projectWeekStart,
        accessor: 'comparedVersion.cardBoardViewResponse.projectWeekStart',
    },
    {
        Header: 'Project Week Finish',
        ...columnsCommonConfig.projectWeekFinish,
        accessor: 'comparedVersion.cardBoardViewResponse.projectWeekFinish',
    },
    {
        Header: 'Project Month Start',
        ...columnsCommonConfig.projectMonthStart,
        accessor: 'comparedVersion.cardBoardViewResponse.projectMonthStart',
    },
    {
        Header: 'Project Month Finish',
        ...columnsCommonConfig.projectMonthFinish,
        accessor: 'comparedVersion.cardBoardViewResponse.projectMonthFinish',
    },
    {
        Header: 'Immediate Parent Name ',
        ...columnsCommonConfig.parentName,
        accessor: 'comparedVersion.cardBoardViewResponse.parentActivity',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Grand Parent Name',
        ...columnsCommonConfig.grandParentName,
        accessor: 'comparedVersion.cardBoardViewResponse.grandParentActivity',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
];
