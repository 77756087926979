import { TabOptions } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import { isEqual } from 'lodash';

export const buildPayloadByKey = {
    project: {
        build: (value) => {
            return {
                data: [value],
                name: 'PROJECT_CARD_BOARD',
                operation: null,
            };
        },
    },
    contract: {
        build: (value) => {
            return {
                data: [value],
                name: 'CONTRACT_CARD_BOARD',
                operation: null,
            };
        },
    },
    version: {
        build: (value) => {
            return {
                data: [value],
                name: 'VERSION_CARD_BOARD',
                operation: null,
            };
        },
    },
    cardActivity: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: value.map((item) => item.value),
                name: 'ACTIVITY_TYPE_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            if (value) {
                return options.filter((item) => value.includes(item.value));
            }

            return null;
        },
    },
    assignee: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'ASSIGNEE_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },
    status: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: value.map((item) => item.value),
                name: 'STATUS_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            if (value) {
                return options.filter((item) => value.includes(item.value));
            }

            return null;
        },
    },
    riskIndicators: {
        build: (value) => {
            return value.map((item) => {
                return {
                    data: ['1'],
                    name: item.value,
                    operation: null,
                };
            });
        },
        getValueByQuery: ({ value, options }) => {
            if (value) {
                return options.filter((item) => value.includes(item.value));
            }

            return null;
        },
    },
    important: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'IMPORTANT_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },
    urgent: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'URGENT_TYPE_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },
    tags: {
        build: (value) => {
            if (value.length === 0) return null;

            const tags = {
                data: value.filter((item) => !item.value.isExclude).map((item) => item.value.name),
                name: 'TAG_CARD_BOARD',
                operation: null,
            };

            const excludeTags = {
                data: value.filter((item) => item.value.isExclude).map((item) => item.value.name),
                name: 'TAG_CARD_BOARD_EXCLUDE',
                operation: null,
            };

            if (tags.data.length === 0 && excludeTags.data.length === 0) {
                return null;
            }

            if (tags.data.length > 0 && excludeTags.data.length === 0) {
                return tags;
            }

            if (tags.data.length === 0 && excludeTags.data.length > 0) {
                return excludeTags;
            }

            return [{ ...tags }, { ...excludeTags }];
        },
        getValueByQuery: ({ tagsValue, excludeTagsValue, options }) => {
            if (tagsValue && !excludeTagsValue) {
                return options.filter((item) => tagsValue.includes(item.value.name));
            }
            if (!tagsValue && excludeTagsValue) {
                return options
                    .filter((item) => excludeTagsValue.includes(item.value.name))
                    .map((i) => ({ label: i.label, value: { name: i.value.name, isExclude: true } }));
            }
            if (tagsValue && excludeTagsValue) {
                return [
                    ...options.filter((item) => tagsValue.includes(item.value.name)),
                    ...options
                        .filter((item) => excludeTagsValue.includes(item.value.name))
                        .map((i) => ({ label: i.label, value: { name: i.value.name, isExclude: true } })),
                ];
            }

            return null;
        },
    },
    cardSearch: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: [value],
                name: 'TEXT_SEARCH_CARD_BOARD',
                operation: null,
            };
        },
    },
    finishDate: {
        build: (value) => {
            if (value.value.length === 0) return null;

            return {
                data: value.value,
                name: 'END_DATE_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, flagValue }): { value: string[]; type?: TabOptions } => {
            if (value) {
                return { value: value.split(','), type: 'FIXED_DATE' };
            }

            if (flagValue) {
                if (flagValue.includes('VERSION_DATE')) {
                    return { value: [], type: 'VERSION_DATE_REF' };
                }

                return { value: [], type: 'TODAY_REF' };
            }

            return { value: [], type: 'TODAY_REF' };
        },
    },

    activityCodes: {
        build: (value) => {
            if (value.length === 0) return null;

            const activityCodes = {
                data: value.filter((item) => !item.value.isExclude).map((item) => item.value.name),
                name: 'ACTIVITY_CODE_CARD_BOARD',
                operation: null,
            };

            const excludeActivityCodes = {
                data: value.filter((item) => item.value.isExclude).map((item) => item.value.name),
                name: 'ACTIVITY_CODES_EXCLUDE',
                operation: null,
            };

            if (activityCodes.data.length === 0 && excludeActivityCodes.data.length === 0) {
                return null;
            }

            if (activityCodes.data.length > 0 && excludeActivityCodes.data.length === 0) {
                return activityCodes;
            }

            if (activityCodes.data.length === 0 && excludeActivityCodes.data.length > 0) {
                return excludeActivityCodes;
            }

            return [{ ...activityCodes }, { ...excludeActivityCodes }];
        },
        getValueByQuery: ({ activityCodesValue, excludeActivityCodesValue, options }) => {
            if (activityCodesValue && !excludeActivityCodesValue) {
                return options.filter((item) => activityCodesValue.includes(item.value.name.toString()));
            }
            if (!activityCodesValue && excludeActivityCodesValue) {
                return options
                    .filter((item) => excludeActivityCodesValue.includes(item.value.name.toString()))
                    .map((i) => ({ label: i.label, value: { name: i.value.name, isExclude: true } }));
            }
            if (activityCodesValue && excludeActivityCodesValue) {
                return [
                    ...options.filter((item) => activityCodesValue.includes(item.value.name.toString())),
                    ...options
                        .filter((item) => excludeActivityCodesValue.includes(item.value.name.toString()))
                        .map((i) => ({ label: i.label, value: { name: i.value.name, isExclude: true } })),
                ];
            }

            return null;
        },
    },

    wbs: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: [value],
                name: 'WBS_ID_CARD_BOARD',
                operation: null,
            };
        },
    },

    // ======================= Optional ===========================

    activityFragnet: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: [value.value],
                name: 'FRAGNET_MILESTONE_ID_CARD_BOARD',
                operation: null,
            };
        },
    },

    activityForwardFragnet: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: [value.value],
                name: 'FRAGNET_FORWARD_SEARCH',
                operation: null,
            };
        },
    },

    cardType: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: value.map((item) => item.value),
                name: 'TYPE_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            if (value) {
                return options.filter((item) => value.includes(item.value));
            }

            return null;
        },
    },

    completionPlanSegment: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'FRAGNET_MILESTONE_ID_COMPLETION_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },

    duration: {
        build: (value) => {
            return {
                data: value,
                name: 'DURATION_CARD_BOARD',
                operation: null,
            };
        },
    },

    float: {
        build: (value) => {
            return {
                data: value,
                name: 'TOTAL_FLOAT_CARD_BOARD',
                operation: null,
            };
        },
    },

    hasAttachments: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'DOCLINK_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },

    hasComments: {
        build: (value) => {
            if (value.value.length === 0) return null;

            return {
                data: value.value,
                name: 'COMMENT_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, flagValue }): { value: string[]; type?: TabOptions } => {
            if (value) {
                return { value: value.split(','), type: 'FIXED_DATE' };
            }

            if (flagValue) {
                if (flagValue.includes('VERSION_DATE')) {
                    return { value: [], type: 'VERSION_DATE_REF' };
                }

                return { value: [], type: 'TODAY_REF' };
            }

            return { value: [], type: 'TODAY_REF' };
        },
    },

    lagLead: {
        build: (value) => {
            return {
                data: value.value,
                name: 'LAG_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            if (value) {
                return options.find((item) => isEqual(item.value, value.split(',')));
            }

            return null;
        },
    },

    relatedActivity: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: [value],
                name: 'ACTIVITY_NAME_CARD_BOARD',
                operation: null,
            };
        },
    },

    resourceType: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: value.map((item) => item.value),
                name: 'RESOURCE_TYPE_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },

    startDate: {
        build: (value) => {
            if (value.value.length === 0) return null;

            return {
                data: value.value,
                name: 'START_DATE_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, flagValue }): { value: string[]; type?: TabOptions } => {
            if (value) {
                return { value: value.split(','), type: 'FIXED_DATE' };
            }

            if (flagValue) {
                if (flagValue.includes('VERSION_DATE')) {
                    return { value: [], type: 'VERSION_DATE_REF' };
                }

                return { value: [], type: 'TODAY_REF' };
            }

            return { value: [], type: 'TODAY_REF' };
        },
    },

    submittedCompletion: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: value,
                name: 'SUBMITTED_COMPLETION_CARD_BOARD',
                operation: null,
            };
        },
    },

    trackedActivity: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'TRACKED_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },

    updatedBy: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'UPDATED_USER_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },

    commenter: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'COMMENTER_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },

    mentioned: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'MENTIONED_USER_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === value) || null;
        },
    },

    wbsLevel: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: value,
                name: 'WBS_LEVEL_CARD_BOARD',
                operation: null,
            };
        },
    },

    resourceName: {
        build: (value) => {
            if (value.length === 0) return null;

            return {
                data: value.map((item) => item.value),
                name: 'RESOURCE_NAME_CARD_BOARD',
                operation: null,
                label: value.map((item) => item.label),
            };
        },
        getValueByQuery: ({ value, options }) => {
            if (value) {
                return options.filter((item) => value.map(Number).includes(item.value));
            }

            return null;
        },
    },

    cluster: {
        build: (value) => {
            return {
                data: [value.value],
                name: 'CLUSTER_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, options }) => {
            return options.find((item) => item.value === Number(value)) || null;
        },
    },

    baselineStartDate: {
        build: (value) => {
            if (value.value.length === 0) return null;

            return {
                data: value.value,
                name: 'BASELINE_START_DATE_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, flagValue }): { value: string[]; type?: TabOptions } => {
            if (value) {
                return { value: value.split(','), type: 'FIXED_DATE' };
            }

            if (flagValue) {
                if (flagValue.includes('VERSION_DATE')) {
                    return { value: [], type: 'VERSION_DATE_REF' };
                }

                return { value: [], type: 'TODAY_REF' };
            }

            return { value: [], type: 'TODAY_REF' };
        },
    },

    baselineEndDate: {
        build: (value) => {
            if (value.value.length === 0) return null;

            return {
                data: value.value,
                name: 'BASELINE_END_DATE_CARD_BOARD',
                operation: null,
            };
        },
        getValueByQuery: ({ value, flagValue }): { value: string[]; type?: TabOptions } => {
            if (value) {
                return { value: value.split(','), type: 'FIXED_DATE' };
            }

            if (flagValue) {
                if (flagValue.includes('VERSION_DATE')) {
                    return { value: [], type: 'VERSION_DATE_REF' };
                }

                return { value: [], type: 'TODAY_REF' };
            }

            return { value: [], type: 'TODAY_REF' };
        },
    },
};
