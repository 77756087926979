import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';

import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { useQuerystring } from 'hooks/useQuerystring';
import { useQueryGetKeyAchievementsData } from 'api/queries/getKeyAchievementstableData.query';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import classes from './keyAchievementsTableWrapper.module.scss';
import { useQueryGetAchievementsFilters } from 'api/queries/getKeyAchievementsFilters.query';
import {
    ALL_FILTER_BUTTON,
    TIME_FILTERS,
    timeFilterInitialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/TableSection/KeyAchievementsTableWrapper/keyAchievementsTable.consts';
import { find, without } from 'lodash';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import NoData from 'components/common/NoData/noData';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { TableFooter } from 'components/Dashboards/Project/Components/VersionCompare/TableFotter/TableFooter';
import {
    columns,
    initialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/TableSection/KeyAchievementsTableWrapper/utils';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { smallPageSize } from 'components/Dashboards/Project/Components/VersionCompare/utils/table.utils';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { List } from 'components/common/ListWrapper/list';
import { useQueryState } from 'hooks/useQueryState';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import { getStringWithoutHash } from 'utilitys/helpers/general';

export const KeyAchievementsTableComponent = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter = {
        filters: [],
    },
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const tableRef = useRef(null);
    const id = 'keyAchievements';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const componentKey = 'keyAchievements';
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const { sendEventWithDimensions } = useEventWithDimensions();
    const { addQueryKey, getQueryKey } = useQuerystring();
    const { data: filtersData } = useQueryGetAchievementsFilters();
    const [tableTimeFilter, setTableTimeFilter] = useQueryState('tableTimeFilter');
    const { handleSnackBar } = useCustomSnackBar();
    const defaultFilter = useMemo(
        () => filtersData?.find((item) => item?.filterValues.some((filter) => filter.default)),
        [filtersData],
    );

    const activityFilterName = useMemo(() => defaultFilter?.filterName, [defaultFilter]);

    const selectedFilters = useMemo(
        () => (activityFilterName ? without(getQueryKey({ key: activityFilterName })?.split(','), 'ALL') : []),
        [getQueryKey, activityFilterName],
    );

    const formattedFiltersData = filtersData
        ? filtersData.map((filter) => ({
              label: filter.title,
              value: filter.filterValues[0].name,
          }))
        : [];

    const ACTIVITY_TYPE_FILTERS =
        formattedFiltersData.length > 0 ? [...formattedFiltersData, ALL_FILTER_BUTTON] : [ALL_FILTER_BUTTON];
    const activityTypeFilter =
        ACTIVITY_TYPE_FILTERS.find((filter) => filter.value === selectedFilters[0]) || ALL_FILTER_BUTTON;
    const TimePeriodData = find(filter?.filters, { name: FilterNames.TIME_PERIOD })?.data || [];

    const timePeriod = widgetId ? TimePeriodData[0] : tableTimeFilter;
    const timePeriodFilter = TIME_FILTERS.find((filter) => filter.value === timePeriod);

    const filterDataObj = {
        [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
            ?.filter((item) => !item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
            ?.filter((item) => item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
        [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
            ?.filter((item) => !item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
            ?.filter((item) => item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
    };

    const fetchFiltersDataObj = {
        [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
            ?.filter((item) => !item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
            ?.filter((item) => item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
        [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
            ?.filter((item) => !item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
            ?.filter((item) => item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
    };

    if (activityFilterName) {
        filterDataObj[FilterNames[activityFilterName]] = selectedFilters;
        fetchFiltersDataObj[FilterNames[activityFilterName]] = selectedFilters;
    }

    if (tableTimeFilter) {
        filterDataObj[FilterNames.TIME_PERIOD] = [tableTimeFilter];
    }

    const filters = widgetId ? filter?.filters : getFiltersData(filterDataObj);
    const activityCodes = find(filter?.filters, { name: FilterNames.ACTIVITY_CODES })
        ? [find(filter?.filters, { name: FilterNames.ACTIVITY_CODES })]
        : [];
    const excludeActivityCodes = find(filter?.filters, { name: FilterNames.ACTIVITY_CODES_EXCLUDE })
        ? [find(filter?.filters, { name: FilterNames.ACTIVITY_CODES_EXCLUDE })]
        : [];
    const milestoneFragnet = find(filter?.filters, { name: FilterNames.FRAGNET_SEARCH })
        ? [find(filter?.filters, { name: FilterNames.FRAGNET_SEARCH })]
        : [];

    const tags = find(filter?.filters, { name: FilterNames.TAG_CARD_BOARD })
        ? [find(filter?.filters, { name: FilterNames.TAG_CARD_BOARD })]
        : [];
    const excludeTags = find(filter?.filters, { name: FilterNames.TAG_CARD_BOARD_EXCLUDE })
        ? [find(filter?.filters, { name: FilterNames.TAG_CARD_BOARD_EXCLUDE })]
        : [];
    const wbs = find(filter?.filters, { name: FilterNames.WBS_SEARCH })
        ? [find(filter?.filters, { name: FilterNames.WBS_SEARCH })]
        : [];
    const selected = find(filter?.filters, { name: activityFilterName ? FilterNames[activityFilterName] : null })
        ? [find(filter?.filters, { name: activityFilterName ? FilterNames[activityFilterName] : null })]
        : [];

    const fetchFilters = useMemo(
        () =>
            widgetId
                ? [
                      ...activityCodes,
                      ...excludeActivityCodes,
                      ...milestoneFragnet,
                      ...tags,
                      ...excludeTags,
                      ...wbs,
                      ...selected,
                  ]
                : getFiltersData(fetchFiltersDataObj),

        [activityCodes, milestoneFragnet, selected, globalFilterData, selectedFilters, wbs],
    );

    const { data, isLoading, isFetching } = useQueryGetKeyAchievementsData({
        compareVersionId: compareVersionId,
        latestVersionId: latestVersionId,
        timePeriod,
        filterName: activityFilterName,
        filters: fetchFilters,
    });

    const { generateFilters: formattedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const showFilters = filtersData && activityFilterName;

    const [pageSize, setPageSize] = useState(smallPageSize);

    const activeData = useMemo(() => {
        return data && data.periodItemsList && data.periodItemsList.length > 0
            ? data.periodItemsList?.[0].itemsList
            : [];
    }, [data]);
    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();

    const title = `Key Achievements (${activeData.length})`;

    useEffect(() => {
        if (!tableTimeFilter && !widgetId) {
            setTableTimeFilter(timeFilterInitialState);
        }
    }, [tableTimeFilter, widgetId]);

    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    useEffect(() => {
        if (activityFilterName && !getQueryKey({ key: activityFilterName }) && !widgetId) {
            const value = defaultFilter?.filterValues.map((filter) => filter.name).toString();
            if (value) {
                addQueryKey({
                    key: activityFilterName,
                    value,
                });
            }
        }
    }, [activityFilterName, defaultFilter, getQueryKey, addQueryKey]);

    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const handleLastFilterClick = (filter: ISelectOption<string>) => {
        const value = filter.value;
        sendEventWithDimensions({
            category: 'Key Achievements',
            action: 'Range Selection',
            label: value,
        });
        setTableTimeFilter(value);
    };

    const handleActivityTypeFilterClick = (filter: ISelectOption<string>) => {
        const value = filter.value;
        sendEventWithDimensions({
            category: 'Key Achievements',
            action: 'Range Selection',
            label: value,
        });

        activityFilterName && addQueryKey({ key: activityFilterName, value });
    };
    const setWidgetColumns = (widgetId: string) => {
        if (tableRef.current) {
            const { hiddenColumns, columnOrder } = tableRef.current.state;
            mutateSetWidgetColumns(
                { columns: { hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };
    return (
        <WidgetWithTitle
            title={title}
            tooltip={data?.info}
            id={id}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formattedFilter.length > 0 && (
                    <WidgetFilters key={'WidgetFilters'} widgetId={widgetId} filters={formattedFilter} />
                ),
            ]}
            titleComponents={[
                <>
                    {showFilters && (
                        <>
                            <div className={classes.listWrapper}>
                                {!widgetId && (
                                    <div className={classes.filtersWrapper}>
                                        <div className={classes.filterTitle}>{'Last:'}</div>
                                        <List<string>
                                            onChange={handleLastFilterClick}
                                            options={TIME_FILTERS}
                                            value={timePeriodFilter}
                                        />
                                    </div>
                                )}
                                {!widgetId && (
                                    <div className={classes.filtersWrapper}>
                                        <div className={classes.filterTitle}>{'Activity type:'}</div>
                                        <List<string>
                                            onChange={handleActivityTypeFilterClick}
                                            options={ACTIVITY_TYPE_FILTERS}
                                            value={activityTypeFilter}
                                        />
                                    </div>
                                )}
                            </div>
                            {externalComponents && <div key={'externalComponents'}>{externalComponents}</div>}
                            <CustomizedTooltip
                                key={'tableColumns'}
                                tooltipPosition={TooltipPosition.Top}
                                tooltipContent={'Customize table columns'}
                                triggerElement={
                                    <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                                        <Icon name={'tune-filter'} />
                                    </CustomizedRoundIcon>
                                }
                            />
                            <AddWidget
                                key={'AddWidget'}
                                componentKey={componentKey}
                                title={title}
                                projectId={projectId}
                                contractId={contractId}
                                widgetId={widgetId}
                                route={route}
                                filters={filters}
                                callBack={setWidgetColumns}
                            />
                            <ShareInsightButton title={title} link={route} />
                        </>
                    )}
                </>,
                <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
            ]}
        >
            <div className={classes.tableWrapperContainer}>
                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={(instance) => (tableRef.current = instance)}
                        data={activeData}
                        columns={columns}
                        initialState={updatedInitialState}
                        pageSize={pageSize}
                        noData={<NoData>No Changes To Display</NoData>}
                        isLoading={isLoading || isFetching}
                    />
                )}

                {activeData.length > smallPageSize && <TableFooter setPageSize={setPageSize} pageSize={pageSize} />}
            </div>

            {tableRef?.current && (
                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    widgetId={widgetId}
                    componentKey={componentKey}
                    tableInstance={tableRef.current}
                    initialState={initialState}
                    columns={columns}
                />
            )}
        </WidgetWithTitle>
    );
};
