import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import { CompareOptions } from 'components/Dashboards/ExcellenceApps/ClusterRCFAnalysis/CompareTo/CompareTo';

const queryFn = (params: QueryParams) => {
    return network
        .post(API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTERS_LIST, {
            paFileMetaDataIds: params.idList,
            projectId: params.projectId,
            clusterType: params.compare,
        })
        .then((response) => {
            return response.data;
        });
};
export const useQueryClustersList = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['clustersList', params],
        queryFn: () => queryFn(params),
        enabled: params.idList.length > 0 && Boolean(params.compare),
        staleTime: 300000,
    });
};

interface QueryParams {
    idList: number[];
    projectId: number | undefined;
    compare: CompareOptions;
}

interface QueryResponse {
    clusterList: {
        clusterId: number;
        clusterName: string;
        completedDurationHigh: number | null;
        completedDurationTypical: number | null;
        completedOverrunDaysHigh: number | null;
        completedOverrunDaysTypical: number | null;
        completedOverrunRatioHigh: number | null;
        completedOverrunRatioTypical: number | null;
        fileMetaData: number | null;
        plannedDurationHigh: number | null;
        plannedDurationTypical: number | null;
    }[];
}
