import { CustomizedConfirmDeleteModal } from 'components/common';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryGetGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useVersionStore } from 'store/version.store';
import { useBattleCardsStore } from 'store/battlecards.store';
import { initialFiltersState } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { IContract, IProject, useProjectTreeStore } from 'store/projectContract.store';
import { useQueryClient } from 'react-query';
import { findeNodeByUUID, ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { last } from 'lodash';
import { useMutationDeleteSubProgram } from 'api/mutations/deleteSubProgram.mutation';

interface Props {
    handleModalClose: () => void;
    isShowModal: boolean;
    callback?: (project: IProject | null, contract: IContract | null) => void;
}

export const ConfirmDeleteSubProgramModal = ({ handleModalClose, isShowModal, callback }: Props) => {
    const { setVersion } = useVersionStore();
    const { project, contract, update } = useProjectTreeStore();
    const { data: projectHierarchyList } = useQueryGetGeneralHierarchyList();
    const queryClient = useQueryClient();
    const { setFilterData } = useBattleCardsStore();
    const { mutate, isLoading } = useMutationDeleteSubProgram();
    const { handleSnackBar } = useCustomSnackBar();

    const { sendEventWithDimensions } = useEventWithDimensions();

    const handleDeleteSubProgram = () => {
        if (contract) {
            const projectNode =
                (projectHierarchyList && project && findeNodeByUUID(projectHierarchyList, project?.uuid)) || null;
            const subProgramList = projectNode?.children;
            if (subProgramList && subProgramList.length === 1) {
                handleModalClose();
                handleSnackBar(`Can't delete sub-program. Project must have at least one sub-program`, 'error');
            } else {
                mutate(
                    { id: contract?.id },
                    {
                        onSuccess: () => {
                            handleSnackBar('Sub Program deleted successfully', 'success');
                            Promise.all([
                                queryClient.invalidateQueries('getGeneralHierarchyList'),
                                queryClient.invalidateQueries('GetProgramGeneralHierarchyList'),
                            ]).then(() => {
                                const newData = queryClient.getQueryData<ProjectTreeNode>('getGeneralHierarchyList');
                                const projectNode =
                                    (newData && project && findeNodeByUUID(newData, project?.uuid)) || null;
                                if (projectNode) {
                                    const lastContract: ProjectTreeNode | undefined = last(projectNode?.children);
                                    update({
                                        contract: lastContract
                                            ? {
                                                  id: lastContract.id,
                                                  title: lastContract.name,
                                                  uuid: lastContract.uuid,
                                              }
                                            : null,
                                    });

                                    if (callback) {
                                        callback(
                                            project ? { ...project } : null,
                                            lastContract
                                                ? {
                                                      id: lastContract.id,
                                                      title: lastContract.name,
                                                      uuid: lastContract.uuid,
                                                  }
                                                : null,
                                        );
                                    }
                                }
                            });

                            setVersion(null);
                            setFilterData(initialFiltersState);

                            sendEventWithDimensions({
                                category: 'Library',
                                action: 'Delete Sub-Program',
                                label: contract?.title,
                            });
                        },
                        onError: (error) => {
                            handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                        },
                        onSettled: () => handleModalClose(),
                    },
                );
            }
        }
    };

    return (
        <CustomizedConfirmDeleteModal
            onConfirm={handleDeleteSubProgram}
            hideModal={handleModalClose}
            isShowModal={isShowModal}
            isLoading={isLoading}
            text={`Are you sure you want to delete this sub-program (${contract?.title})?`}
        />
    );
};
