import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const mutationFn = (params: QueryParams) => {
    return network
        .post(`${API_URL().FETCH_PROGRAM_DASHBOARD_RCF_RESET_CLUSTERS_LIST}`, params)
        .then((response) => response.data);
};

export const useMutationResetCluster = () => {
    return useMutation<unknown, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    clusterId: number;
    clusterType: string;
}
