import classes from './SideMenu.module.scss';
import { TSideOption } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import React from 'react';

interface Props<T> extends React.HTMLProps<HTMLDivElement> {
    menuOptions: TSideOption<T>[];
    activeSideMenuOption: T;
    setActiveSideMenuOption: React.Dispatch<React.SetStateAction<T>>;
}

export const SideMenu = <T extends string>({
    menuOptions,
    activeSideMenuOption,
    setActiveSideMenuOption,
    className,
}: Props<T>) => {
    return (
        <div className={`${classes.sideMenuContainer} ${className ?? ''}`}>
            <ul>
                {menuOptions.map((item) => (
                    <li
                        data-testid={item.value}
                        key={item.value}
                        onClick={() => setActiveSideMenuOption(item.value)}
                        className={`${activeSideMenuOption === item.value ? classes.active : ''}`}
                    >
                        {item.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};
