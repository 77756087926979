import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedWbsTable/SimulatedWbsTable.module.scss';
import {
    columnsV8,
    initialState,
    buildWbsPayloadFilter,
} from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedWbsTable/SimulatedWbsTable.utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IRowsBoard } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { CustomizedButton, CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import NoData from 'components/common/NoData/noData';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import { serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { useReactTableState } from 'hooks/useReactTableState';
import {
    QueryResponse,
    useQueryGetSchedulingSimulationResults,
} from 'api/queries/getSchedulingSimulationResults.query';
import { SimulatedActivitiesFilters } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesFilter/SimulatedActivitiesFilters';
import { useSimulatedActivitiesFiltersStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesFilter/simulatedActivitiesFilter.store';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { ExportSimulationModal } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/exportModal/ExportSimulationModal';
import { useSimulationCacheKeyStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/simulationCacheKey.store';
import { SettingsAndWarnings } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulationWarning/SimulationWarning';

interface Props extends Omit<IRowsBoard, 'filterData' | 'widgetId'> {
    metaDataId: number;
    simulationDate: number;
    setNote: React.Dispatch<React.SetStateAction<string>>;
    setNotification: React.Dispatch<React.SetStateAction<SettingsAndWarnings | null>>;
}

const SimulatedWbsTable = ({ externalComponents, metaDataId, simulationDate, setNote, setNotification }: Props) => {
    const {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState({
        initialPagination: {
            size: 50,
            page: 1,
        },
        initialSorting: [],
    });

    const { search } = useSimulatedActivitiesFiltersStore();
    const [showExportModal, setShowExportModal] = useState(false);
    const { handleSnackBar } = useCustomSnackBar();
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState(0);
    const { sessionKey, cacheIsReady } = useSimulationCacheKeyStore();
    const filters = useMemo(() => buildWbsPayloadFilter(search), [search]);
    let prevMetaDataId = useRef<number>(metaDataId);

    const { data, isLoading, isFetching, error, status } = useQueryGetSchedulingSimulationResults({
        metaDataId: prevMetaDataId.current === metaDataId ? metaDataId : null,
        filter: {
            filters: filters,
        },
        simulationDate: simulationDate,
        paging: {
            ...pagination,
            page: pagination.page - 1,
        },
        sorting,
        key: sessionKey,
        cacheIsReady,
    });

    const tableData = useMemo(() => {
        if (data && data.changedActivities?.length > 0 && status === 'success') {
            return data.changedActivities.map((activity) => {
                if (activity.wbsLevel === 0) {
                    return {
                        ...activity,
                        className: classes.wbsLevelZero,
                    };
                } else {
                    return {
                        ...activity,
                        className: classes.wbsLevelAboveZero,
                    };
                }
            });
        }

        return [];
    }, [data]);

    const boundaryRef = useRef<HTMLDivElement>(null);
    const componentKey = 'simulationWbsTable';
    const updatedInitialState = useTableCurrentState({ initialState, componentKey });
    const title = 'Simulation Summary';

    const handleExportModalOpen = () => {
        setShowExportModal(true);
    };

    useEffect(() => {
        updatedInitialState && setColumnOrder(updatedInitialState.columnOrder ?? []);
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    const titleWithCount = useMemo(() => {
        if (data != undefined && data.totalChanges > 0) return `${title} (${data.totalChanges})`;
        return `${title} (0)`;
    }, [title, data]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns: string[]) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (column && column.id) {
                        return {
                            ...column,
                            isVisible: !hiddenColumns.includes(column.id),
                        };
                    }
                    return null;
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    // Pagination refers to the UI pagination. For API the pagination we will subtract 1 from pagination.page.
    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [filters, sorting]);

    useEffect(() => {
        if (data) {
            if (data.changedActivities && data.changedActivities.length > 0) {
                setTotalPages(data.totalPages);
            } else {
                setTotalPages(0);
            }
            setNote(data.note ?? '');
            setNotification({
                warnings: data.warnings ?? [],
                settings: data.settings ?? [],
            });
        }
    }, [data]);

    useEffect(() => {
        if (error !== null && error.response) {
            handleSnackBar(error.response.data !== '' ? error.response.data : 'Unknown error has occurred', 'error');
        }
    }, [error]);

    return (
        <WidgetWithTitle
            className={classes.simulationContainer}
            tooltip={data?.info}
            id={componentKey}
            title={titleWithCount}
            titleFilters={
                <div className={classes.titleFilters}>
                    <SimulatedActivitiesFilters />
                </div>
            }
            titleComponents={[
                <CustomizedButton
                    key={'export'}
                    disabled={isLoading || isFetching || error !== null || !cacheIsReady}
                    size={'large'}
                    color={'primary'}
                    onClick={() => handleExportModalOpen()}
                >
                    Save Simulation Output As...
                </CustomizedButton>,
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
            ]}
        >
            <div className={classes.simulationWrapper} ref={boundaryRef}>
                <TableV8<QueryResponse['changedActivities'][0]>
                    data={tableData}
                    columns={columnsV8}
                    isLoading={isLoading || isFetching || !cacheIsReady}
                    noData={<NoData>No activities match the applied filter</NoData>}
                    sorting={sorting}
                    setSorting={setSorting}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                />
                <div className={classes.pagination}>
                    <CustomPagination
                        totalPages={totalPages}
                        pagination={pagination}
                        setPagination={setPagination}
                        isDisabled={isFetching || isLoading}
                    />
                </div>
            </div>

            <CustomTableColumnsModal
                onHideModal={() => setModalColumnsOpen(false)}
                showModal={modalColumnsOpen}
                componentKey={componentKey}
                tableInstance={tableInstance}
                initialState={initialState}
                columns={columnsV8}
                onChangeOrder={setColumnOrder}
                onChangeVisibility={setColumnVisibility}
            />

            <ExportSimulationModal
                metaDataId={metaDataId}
                onHideModal={() => setShowExportModal(false)}
                showModal={showExportModal}
                simulationDate={simulationDate}
            />
        </WidgetWithTitle>
    );
};
export default React.memo(SimulatedWbsTable);
