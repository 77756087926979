import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import { ISingleSmallCardResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';

const queryFn = ({ compareVersionId, latestVersionId, timePeriod, filters }: QueryParams) => {
    const params = {
        compareVersionId: compareVersionId,
        latestVersionId: latestVersionId,
        timePeriod: timePeriod,
        filter: {
            filters,
        },
        withDetails: true,
    };

    return network.post(API_URL().FETCH_PROGRAM__KEY_ACHIEVEMENTS_TABLE_DATA, params).then((response) => {
        return response.data;
    });
};

export const useQueryGetKeyAchievementsData = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['keyAchievementsDataTable', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.latestVersionId) && Boolean(params.compareVersionId) && Boolean(params.filterName),
        placeholderData: {
            title: 'Key Achievements',
            periodItemsList: [],
            info: {
                infoList: '',
                link: null,
            },
            typeId: null,
        },
    });
};

interface QueryParams {
    compareVersionId: number | undefined;
    filterName?: string;
    latestVersionId: number | undefined;
    timePeriod: number;
    filters?: any;
}

interface QueryResponse {
    title: string;
    periodItemsList: {
        id: string;
        summary: null;
        itemsList: {
            order: number;
            latestVersion: {
                predecessors: number;
                successors: number;
                startDate: number;
                finishDate: number;
                cardBoardViewResponse: ISingleSmallCardResponse;
                id: number;
                activityStatus: string;
                taskVersionHashCode: string;
                trackedActivity: boolean;
                activityId: string;
                activityName: string;
                criticalityScore: number;
                float: number | null;
                cardDetails: string | null;
                duration: number;
            };
            comparedVersion: {
                predecessors: number;
                successors: number;
                startDate: number;
                finishDate: number;
                id: number;
                activityStatus: string;
                taskVersionHashCode: string;
                trackedActivity: boolean;
                activityId: string;
                criticalPath: boolean;
                activityName: string;
                criticalityScore: number;
                float: number | null;
                cardDetails: string | null;
                duration: number;
            } | null;
            changeMap: {
                successors: number;
                predecessors: number;
                startDate: number;
                criticalPath: null;
                criticalityScore: number;
                finishDate: number;
                float: number | null;
                duration: number | null;
                activityStatus: string;
            } | null;
        };
    }[];
    info: IToolTip;
    typeId: null;
}
