import { CustomizedConfirmDeleteModal } from 'components/common';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryGetGeneralHierarchyList } from 'components/common/TreeProgramFilter/Queries/useQueryGetProgramGeneralHierarchyList';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useVersionStore } from 'store/version.store';
import { useBattleCardsStore } from 'store/battlecards.store';
import { initialFiltersState } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { IContract, IProject, useProjectTreeStore } from 'store/projectContract.store';
import { ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { useQueryClient } from 'react-query';
import { last } from 'lodash';
import { useMutationDeleteProject } from 'api/mutations/DeleteProject.mutate';

interface Props {
    handleModalClose: () => void;
    isShowModal: boolean;
    callback?: (project: IProject | null, contract: IContract | null) => void;
}

export const ConfirmDeleteProjectModal = ({ handleModalClose, isShowModal, callback }: Props) => {
    const { setVersion } = useVersionStore();

    const { project, update } = useProjectTreeStore();
    const { data: projectHierarchyList } = useQueryGetGeneralHierarchyList();
    const queryClient = useQueryClient();

    const { setFilterData } = useBattleCardsStore();

    const { mutate, isLoading } = useMutationDeleteProject();
    const { handleSnackBar } = useCustomSnackBar();
    const { sendEventWithDimensions } = useEventWithDimensions();

    const handleDeleteProject = () => {
        const projectList = projectHierarchyList?.children;
        if (projectList && projectList.length === 1) {
            handleModalClose();
            handleSnackBar(`Can't delete project. Customer must have at least one project`, 'error');
        } else {
            mutate(
                { id: project?.id },
                {
                    onSuccess: () => {
                        handleSnackBar('Project deleted successfully', 'success');
                        queryClient.invalidateQueries({ queryKey: 'getGeneralHierarchyList' }).then(() => {
                            const newData = queryClient.getQueryData<ProjectTreeNode>('getGeneralHierarchyList');
                            const lastProject: ProjectTreeNode | undefined = last(newData?.children);
                            if (lastProject) {
                                const lastContract: ProjectTreeNode | undefined = last(lastProject?.children);

                                update({
                                    project: {
                                        id: lastProject.id,
                                        title: lastProject.name,
                                        uuid: lastProject.uuid,
                                    },
                                    contract: lastContract
                                        ? {
                                              id: lastContract.id,
                                              title: lastContract.name,
                                              uuid: lastContract.uuid,
                                          }
                                        : null,
                                });

                                if (callback) {
                                    callback(
                                        {
                                            id: lastProject.id,
                                            title: lastProject.name,
                                            uuid: lastProject.uuid,
                                        },
                                        lastContract
                                            ? {
                                                  id: lastContract.id,
                                                  title: lastContract.name,
                                                  uuid: lastContract.uuid,
                                              }
                                            : null,
                                    );
                                }
                            }
                        });

                        setVersion(null);
                        setFilterData(initialFiltersState);

                        sendEventWithDimensions({
                            category: 'Library',
                            action: 'Delete Project',
                            label: project?.title,
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                    onSettled: () => handleModalClose(),
                },
            );
        }
    };

    return (
        <CustomizedConfirmDeleteModal
            onConfirm={handleDeleteProject}
            hideModal={handleModalClose}
            isShowModal={isShowModal}
            isLoading={isLoading}
            text={`Are you sure you want to delete this project (${project?.title})?`}
        />
    );
};
