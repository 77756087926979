import React, { useState } from 'react';
import Icon from 'components/common/Icons/icon';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import colorsVars from 'styles/colors.module.scss';
const { white } = colorsVars;

const copyMessageStyle = {
    backgroundColor: 'rgba(31, 161, 81, 0.85)',
    border: 'none',
    padding: '10px',
    color: white,
};

const errorMessageStyle = {
    width: 'initial',
};

const ContentCopiedElement = ({ message }) => (
    <div>
        <Icon name="Governance-top-right-tick" size={'1.6rem'} /> {message}
    </div>
);

const CopyValue = ({ onHoverMessage, copySuccessMessage, value, trigger }) => {
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const { sendEventWithDimensions } = useEventWithDimensions();

    const copyToClipboard = () => {
        sendEventWithDimensions({
            category: 'Copy URL to clipboard',
            action: 'Copy',
            label: value,
        });
        navigator.clipboard.writeText(value).then(
            function () {
                setShowCopyMessage(true);
                setTimeout(() => setShowCopyMessage(false), 1500);
            },
            function (err) {
                console.error('Async: Could not copy text: ', err);
            },
        );
    };

    return (
        <CustomizedTooltip
            triggerElement={trigger({ copyToClipboard })}
            tooltipContent={
                showCopyMessage ? <ContentCopiedElement message={copySuccessMessage} /> : <div>{onHoverMessage}</div>
            }
            contentStyles={showCopyMessage ? copyMessageStyle : errorMessageStyle}
        />
    );
};

export default CopyValue;
