import classes from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/activityCompletionPlanComponent/FragnetSummary.module.scss';
import React from 'react';
import { CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { ScheduleToolTip } from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/activityCompletionPlanComponent/scheduleToolTip';
import { roundNumber } from 'utilitys/helpers/general';
import { InformationBoxes } from 'components/common/informationBoxes/informationBoxes';
import { getScheduleAdherenceColorClass } from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/activityCompletionPlanComponent/activitiesPredecessors.utils';
import { QueryResponse } from 'api/queries/activityCompletionPlan.query';

interface ISummaryRowProps {
    data: QueryResponse;
}
export const FragnetSummary = ({ data }: ISummaryRowProps) => {
    const plannedFinishDate = moment(data.planned_finish_date).format(constants.formats.date.default);
    const plannedFinishDateChange = data.planned_finish_date_change;
    const baselineFinishDate = data?.baseline_finish_date
        ? `Baseline ${moment(data.baseline_finish_date).format(constants.formats.date.default)}`
        : 'Baseline';
    const plannedFinishDateChangeTitle = plannedFinishDateChange ? `${plannedFinishDateChange}d` : '';
    const scheduleCriticalyAdherence = roundNumber(data.criticality_schedule_adherence.scheduleAdherence);
    const blockingPredecessors = `${data.blocking_predecessors}%`;
    const delayingPredecessors = `${data.delaying_predecessors}%`;
    const getScheduleAdherenceColor = getScheduleAdherenceColorClass(
        data.criticality_schedule_adherence.scheduleAdherence,
    );

    return (
        <InformationBoxes>
            <InformationBoxes.Box>
                <div data-testid={'summary-row-item'} className={classes.box}>
                    <div className={classes.smallTitle}>Finish Date</div>
                    <div className={classes.dataValueContainer}>
                        <div className={classes.title}>{plannedFinishDate}</div>
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Bottom}
                            tooltipContent={`Compared to ${baselineFinishDate}`}
                            triggerElement={
                                <div className={`${classes.plannedFinishDateChange}`}>
                                    {plannedFinishDateChange && plannedFinishDateChange > 0 && '+'}
                                    {plannedFinishDateChangeTitle}
                                </div>
                            }
                        />
                    </div>
                </div>
            </InformationBoxes.Box>
            <InformationBoxes.Box>
                <div data-testid={'summary-row-item'} className={classes.box}>
                    <div className={classes.smallTitle}>Schedule Performance</div>
                    <div className={classes.dataValueContainer}>
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Bottom}
                            tooltipContent={<ScheduleToolTip data={data} />}
                            triggerElement={
                                <div className={`${classes.title} ${classes[getScheduleAdherenceColor]}`}>
                                    {scheduleCriticalyAdherence}
                                </div>
                            }
                        />
                    </div>
                </div>
            </InformationBoxes.Box>
            <InformationBoxes.Box>
                <div data-testid={'summary-row-item'} className={classes.box}>
                    <div className={classes.smallTitle}>% Blockers Predecessors</div>
                    <div className={classes.dataValueContainer}>
                        <div className={`${classes.title} ${classes.red}`}>{blockingPredecessors}</div>
                    </div>
                </div>
            </InformationBoxes.Box>
            <InformationBoxes.Box>
                <div data-testid={'summary-row-item'} className={classes.box}>
                    <div className={classes.smallTitle}>% Delayed Predecessors</div>
                    <div className={classes.dataValueContainer}>
                        <div className={`${classes.title} ${classes.yellow}`}>{delayingPredecessors}</div>
                    </div>
                </div>
            </InformationBoxes.Box>
        </InformationBoxes>
    );
};
