import { RecommendationCell } from 'components/common/Tables/Cells/RecommendationCell';
import { customSortByLatestVersion } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { ITableColumnState } from 'hooks/useTableCurrentState';

export const initialState: ITableColumnState = {
    hiddenColumns: ['details'],
    sortBy: [
        { id: 'latestVersionCategory', desc: true },
        { id: 'latestVersionSeverity', desc: false },
    ],
};

export const columns = [
    {
        Header: 'Recommendation',
        accessor: 'title',
        ...columnsCommonConfig.recommendation,
        Cell: ({ row, toggleAllRowsSelected }) => {
            const { title, id } = row.original;
            const { toggleRowSelected } = row;
            return (
                <RecommendationCell
                    title={title}
                    id={id}
                    toggleRowSelected={toggleRowSelected}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                />
            );
        },
    },
    {
        Header: 'Status',
        accessor: 'status.label',
        ...columnsCommonConfig.status,
        Cell: ({ row }) => {
            const assignee = row.original.assignee;
            const status = row.original.status;
            const isVirtual = row.original.isVirtual;
            const originalStatus = row.original.originalStatus;
            const taskId = row.original.id;
            const cardType = row.original.type.name;
            const category = row.original.category;
            const isStatusOrDatesChanged = row.original.isStatusOrDatesChanged;

            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={taskId}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={['recomendationTableData']}
                    cardType={cardType}
                    category={category}
                    isStatusOrDatesChanged={isStatusOrDatesChanged}
                />
            );
        },
    },
    {
        Header: 'Category',
        ...columnsCommonConfig.latestVersionCategory,
        accessor: (original) => original.category.label,
    },
    {
        Header: 'Severity',
        ...columnsCommonConfig.latestVersionSeverity,
        accessor: 'severity',
    },
    {
        Header: 'Start Date',
        ...columnsCommonConfig.startDate,
        accessor: 'startDate',
        Cell: ({ value }) => <div>{moment(value).format(constants.formats.date.default)}</div>,
    },
    {
        Header: 'Finish Date',
        ...columnsCommonConfig.finishDate,
        accessor: 'endDate',
        Cell: ({ value }) => <div>{moment(value).format(constants.formats.date.default)}</div>,
    },
    {
        Header: 'Duration',
        accessor: 'duration',
        ...columnsCommonConfig.duration,
        Cell: ({ value }) => {
            return <div>{value} days</div>;
        },
        sortType: customSortByLatestVersion,
    },
];
