import React, { ReactNode } from 'react';
import TableTab from 'components/Dashboards/ExcellenceApps/CompletionPlanPage/TableTab/tableTab';
import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivityTabs/simulatedActivitiesTabs.module.scss';

export type TabKeys = 'wbs' | 'activities';

export const generateTabs = (): {
    value: TabKeys;
    label: ReactNode;
}[] => {
    return [
        {
            value: 'wbs',
            label: <TableTab title={`WBS Level Changes`} className={classes.title} />,
        },
        {
            value: 'activities',
            label: <TableTab title={`Activities Level Changes`} className={classes.title} />,
        },
    ];
};
