import React from 'react';
import { CustomizedDropdownMenu, CustomizedTooltip } from 'components/common/index';
import classes from 'components/common/VersionCompareSelectorSection/VersionCompareSelector/versionCompareSelector.module.scss';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { IProgramVersion } from 'components/common/VersionCompareSelectorSection/VersionCompareSelectorWrapper/VersionCompareSelectorWrapper';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';

interface VersionCompareSelectorProps {
    versionsList?: IProgramVersion[];
    onVersionSelect: (version: IProgramVersion) => void;
    selectedItem: string;
    isLoading: boolean;
}

const SelectedTitle = ({ value }) => {
    return (
        <>
            <CustomizedTooltip
                tooltipPosition={TooltipPosition.Top}
                tooltipContent={value}
                triggerElement={<div data-testid={'compare-to'} className={classes.selectedVersion}>{value}</div>}
            />
        </>
    );
};

export const NotMemoizedVersionCompareSelector = ({
    versionsList,
    onVersionSelect,
    selectedItem,
    isLoading,
}: VersionCompareSelectorProps) => {
    const selected = versionsList?.find((i) => i.versionHashCode === Number(selectedItem));

    return isLoading ? null : (
        <div className={classes.versionCompareSelector}>
            <span className={classes.selectedTitle}>Compare to: </span>
            <CustomizedDropdownMenu
                triggerElement={
                    <SelectedTitle
                        value={
                            versionsList && versionsList.length ? prepareVersionSelectTitle(selected) : 'No versions'
                        }
                    />
                }
            >
                {versionsList?.map((item: IProgramVersion, index) => {
                    return (
                        <div onClick={() => onVersionSelect(item)} key={index}>
                            {prepareVersionSelectTitle(item)}
                        </div>
                    );
                })}
            </CustomizedDropdownMenu>
            <Icon name="header-arrow-down" size="2rem" color="#8FA3C3" />
        </div>
    );
};

export const VersionCompareSelector = React.memo(NotMemoizedVersionCompareSelector);
