import { useState } from 'react';
import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { useMutationSwitchUser } from 'api/mutations/switchUser.mutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryGetUserDetail } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useLoginHook } from 'components/Login/loginHook';

export const SwitchUser = ({ userEmail }) => {
    const [email, setEmail] = useState<string | undefined>(undefined);
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate } = useMutationSwitchUser();

    const { data: user } = useQueryGetUserDetail({
        email,
    });

    useLoginHook({ user, isSwitchUser: true });

    const switchUser = () => {
        if (userEmail) {
            mutate(
                { userName: userEmail },
                {
                    onSuccess: (response) => {
                        setEmail(response?.email);
                        // handleSnackBar('Status changed successfully', 'success');
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                    },
                },
            );
        }
    };

    return (
        <div className={classes.adminActionWrapper}>
            <span className={classes.changePasswordTitle} onClick={switchUser}>
                Login As
            </span>
        </div>
    );
};
