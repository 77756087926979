import React from 'react';
import { useForm } from 'react-hook-form';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { LoginComponent } from 'components/Login/LoginComponent';
import { ILoginForm } from 'components/Login/login';
import { useMutationGetUserDomain } from 'api/mutations/getUserDomain.mutation';
import { useMutationExternalLogin } from 'api/mutations/externalLogin.mutation';

export const ExternalRedirect = () => {
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateGetUserDomain, isLoading: isLoadingGetUserDomain } = useMutationGetUserDomain();
    const { mutate: mutateExternalLogin, isLoading: isLoadingExternalLogin } = useMutationExternalLogin();
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<ILoginForm>();
    const login = (data: ILoginForm) => {
        mutateGetUserDomain(
            { username: data.username },
            {
                onSuccess: (response) => {
                    if (response.domain) {
                        mutateExternalLogin(
                            { username: data.username, password: data.password, domain: response.domain },
                            {
                                onSuccess: (responseLogin) => {
                                    window.location.href = `https://${response.domain}/externalLogin?email=${data.username}&token=${responseLogin.accessToken}`;
                                },
                                onError: (error) => {
                                    handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                                },
                            },
                        );
                    }
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                },
            },
        );
    };

    return (
        <LoginComponent
            title={'Sign in'}
            handleSubmit={handleSubmit}
            login={login}
            errors={errors}
            register={register}
            control={control}
            error={undefined}
            isLoading={isLoadingGetUserDomain || isLoadingExternalLogin}
            enableSSO={false}
        />
    );
};
