import React from 'react';
import { CustomizedModalBase } from 'components/common';
import {
    FileExtensions,
    IUploadFiledFetch,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';
import { XerModalContent } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/modalContent/xerModalContent';
import { MppModalContent } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/modalContent/mppModalContent';
import { PlaniswereModalContent } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/modalContent/planiswereModalContent';
import { useProgramLibraryStore } from 'store/programLibrary.store';
import { useQueryClient } from 'react-query';
import JSZip from 'jszip';
import {
    UploadProjectsPayload,
    UploadXerPayload,
    useMutationUploadProjectFile,
    useMutationUploadXerFile,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import useUserHook from 'hooks/useUserHook';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';

export const RenderUploadFilesModal = () => {
    const modalShow = useProgramLibraryStore((state) => state.modalShow);
    const details = useProgramLibraryStore((state) => state.details);
    const isUploading = useProgramLibraryStore((state) => state.isUploading);
    const { update } = useProgramLibraryStore();
    const mutationUploadXerFile = useMutationUploadXerFile();
    const mutationUploadProjectFile = useMutationUploadProjectFile();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const queryClient = useQueryClient();
    const { username } = useUserHook();
    const { handleSnackBar } = useCustomSnackBar();

    const handleUploadFiledFetch = ({ details, contract, project }: IUploadFiledFetch) => {
        if (details.file && username) {
            update({ isUploading: true });
            const fileName = `${details.file.name}.zip`;
            const zip = new JSZip();
            zip.file(details.file.name, details.file);
            zip.generateAsync({
                type: 'blob',
                compression: 'DEFLATE',
                compressionOptions: {
                    level: 9,
                },
            }).then((blob) => {
                const versions = details.versions.filter((item) => item.download);
                const IntegratedProjectsPayload: UploadProjectsPayload = {
                    projectId: project.id,
                    contractId: contract ? contract.id : null,
                    contractName: contract ? contract.title : 'All',
                    fileName,
                    uploadingUser: username,
                    file: blob,
                    versions,
                    customVersionName: details.customVersionName,
                    isBaseline: details.isBaseline,
                    isIntegratedProjects: details.isIntegratedProjects,
                    warningCheck: false,
                    language: details.language ? details.language.value : null,
                    runCPM: details.runCPM ?? false,
                };

                const regularPayload: UploadXerPayload = {
                    projectId: project.id,
                    contractId: contract ? contract.id : null,
                    contractName: contract ? contract.title : 'All',
                    fileName,
                    uploadingUser: username,
                    file: blob,
                    versions,
                    isBaseline: [details.type === 'xer' ? details.isBaseline : details.versions[0].isBaseline],
                    versionId: [details.versions[0].versionId],
                    plf_file: details.plf,
                    anotherFile: details.relationsFile,
                    language: details.language ? details.language.value : null,
                    isTransformMPP: details.isTransformMPP,
                };

                if (details.isIntegratedProjects) {
                    mutationUploadProjectFile.mutate(IntegratedProjectsPayload, {
                        onSuccess: () => {
                            handleSnackBar('File uploaded. Processing… ', 'success');
                        },
                        onError: (error) => {
                            let errorMessage = '';
                            switch (error.response.status) {
                                case 409:
                                    errorMessage = error.response.data;
                                    break;
                                case 403:
                                    errorMessage = 'Unauthorized request, please contact support.';
                                    break;
                                default:
                                    errorMessage = 'Error uploading file, please contact support';
                            }
                            handleSnackBar(errorMessage, 'error');
                        },
                        onSettled: () => {
                            sendEventWithDimensions({
                                category: 'Library',
                                action: 'File upload',
                                label: details.file?.name,
                            });
                            update({ modalShow: false });
                            return queryClient.invalidateQueries('getProjectVersions').then(() => {
                                update({ isUploading: false });
                            });
                        },
                    });
                } else {
                    mutationUploadXerFile.mutate(regularPayload, {
                        onSuccess: () => {
                            handleSnackBar('File uploaded. Processing… ', 'success');
                        },
                        onError: (error) => {
                            let errorMessage = '';
                            switch (error.response.status) {
                                case 409:
                                    errorMessage = error.response.data;
                                    break;
                                case 403:
                                    errorMessage = 'Unauthorized request, please contact support.';
                                    break;
                                default:
                                    errorMessage = 'Error uploading file, please contact support';
                            }
                            handleSnackBar(errorMessage, 'error');
                        },
                        onSettled: () => {
                            sendEventWithDimensions({
                                category: 'Library',
                                action: 'File upload',
                                label: details.file?.name,
                            });
                            update({ modalShow: false });
                            return queryClient.invalidateQueries('getProjectVersions').then(() => {
                                update({ isUploading: false });
                            });
                        },
                    });
                }
            });
        }
    };

    return (
        <CustomizedModalBase
            isModalOpen={modalShow}
            handleModalClose={() => update({ modalShow: false })}
            isModalBlock={isUploading}
        >
            {details?.type === FileExtensions.xer && (
                <XerModalContent
                    details={details}
                    isUploading={isUploading}
                    handleFetchData={handleUploadFiledFetch}
                    onClose={() => update({ modalShow: false })}
                />
            )}
            {details?.type === FileExtensions.mpp && (
                <MppModalContent
                    details={details}
                    isUploading={isUploading}
                    handleFetchData={handleUploadFiledFetch}
                    onClose={() => update({ modalShow: false })}
                />
            )}
            {details?.type === FileExtensions.xlsx && (
                <PlaniswereModalContent
                    details={details}
                    isUploading={isUploading}
                    handleFetchData={handleUploadFiledFetch}
                    onClose={() => update({ modalShow: false })}
                />
            )}
        </CustomizedModalBase>
    );
};
