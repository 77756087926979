import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/style/table.module.scss';
import localClasses from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityRate/productivityRate.module.scss';
import { MyCustomSelect } from 'components/common';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import React, { useEffect, useMemo } from 'react';
import { IComponentProps, IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { Aggregation, useProductivityRateStore } from 'store/productivityRate.store';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useQueryProductivityRate } from 'api/queries/productivityRate.query';

import {
    aggregationOptions,
    categoriesKeys,
    getChartConfig,
    serialize,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityRate/productivityRate.utils';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { Boxes } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityRate/components/boxes';
import { BarChart } from 'components/common/charts/barChart/barChart';
import { getStringWithoutHash } from 'utilitys/helpers/general';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { rangeOptions } from 'components/common/GlobalFilterComponent/components/RangeFilter/rangeFilter';
import { useQueryGetProjectDatesForResourceChart } from 'api/queries/getProjectDatesForResourceChart.query';
import { getInputLabel } from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.utils';

export const ProductivityRate = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    localFilters,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
}: IComponentProps) => {
    const id = 'productivityRate';
    const componentKey = 'productivityRate';
    const title = 'Run Rate | Chart';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const {
        aggregation,
        update,
        dataPointIndex: dataPointIndexFromStore,
        seriesIndex: seriesIndexFromStore,
    } = useProductivityRateStore();
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const { themeStatus } = useThemeStatusStore();

    const filters: IFilter[] = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                      [FilterNames.PRODUCTIVITY_PRE_DEFINED]: globalFilterData?.productivityPreDefined,
                      [FilterNames.ACTIVITY_TYPE_FILTER]: globalFilterData?.activityType,
                  }),

        [filter, globalFilterData],
    );

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: { filters },
        latestVersionId,
    });

    const { data: minMaxDates } = useQueryGetProjectDatesForResourceChart({
        metaDataId: latestVersionId,
        comparedMetaDataId: compareVersionId,
    });

    const dateRangeFilterValue = useMemo(() => {
        return globalFilterData?.dateRangeFilter;
    }, [globalFilterData?.dateRangeFilter]);

    const { startDate, endDate } = useMemo(() => {
        const value = widgetId && filter ? localFilters?.range : dateRangeFilterValue;
        if (value && minMaxDates) {
            if (Array.isArray(value)) {
                if (value[0] && value[1]) {
                    return {
                        startDate: value[0],
                        endDate: value[1],
                    };
                } else {
                    return {
                        startDate: minMaxDates?.startProjectDate,
                        endDate: minMaxDates?.endProjectDate,
                    };
                }
            }
        }
        return {
            startDate: null,
            endDate: null,
        };
    }, [widgetId, filter, localFilters?.range, dateRangeFilterValue, minMaxDates]);

    const range = useMemo(() => {
        const value = widgetId && filter ? localFilters?.range : dateRangeFilterValue;
        return Array.isArray(value) ? 'R_CUSTOM' : value;
    }, [widgetId, filter, localFilters?.range, dateRangeFilterValue]);

    const { data, isFetching } = useQueryProductivityRate({
        actualMetaDataId: widgetId && filter ? filter?.filters.actualMetaDataId : latestVersionId,
        comparedMetaDataId: widgetId && filter ? filter?.filters.comparedMetaDataId : compareVersionId,
        range,
        startDate,
        endDate,
        aggregation: aggregation?.value || localFilters?.aggregation,
        filter: {
            filters: filters,
        },
    });

    const pinBoardFilterData = useMemo(() => {
        return {
            actualMetaDataId: latestVersionId,
            comparedMetaDataId: compareVersionId,
            mainFilters: filters,
        };
    }, [filters, latestVersionId, compareVersionId]);

    const dataPointSelection = ({ dataPointIndex, seriesIndex }: { dataPointIndex: number; seriesIndex: number }) => {
        const isToggle = dataPointIndex === dataPointIndexFromStore && seriesIndex === seriesIndexFromStore;
        const series = categoriesKeys[seriesIndex].label;
        const value = categoriesKeys[seriesIndex].key;
        if (data?.chart) {
            update({
                selectedValue: isToggle ? null : value,
                selectedSeries: isToggle ? null : series,
                selectedStartDate: isToggle ? data?.startChartDate : data?.chart[dataPointIndex]?.startDate,
                selectedEndDate: isToggle ? data?.endChartDate : data?.chart[dataPointIndex]?.endDate,
                dataPointIndex: isToggle ? null : dataPointIndex,
                seriesIndex: isToggle ? null : seriesIndex,
            });
        }
    };

    const tooltipFormatter = ({ dataPointIndex }: { dataPointIndex: number }) => {
        if (dataPointIndex === undefined || !data || !data.chart) return '';
        const { startDate, endDate } = data.chart[dataPointIndex];
        return `${moment(startDate).format(constants.formats.date.default)} - ${moment(endDate).format(constants.formats.date.default)}`;
    };

    const { options, series } = getChartConfig({
        ...serialize({ data: data?.chart, range: globalFilterData?.dateRangeFilter || localFilters?.range }),
        themeStatus,
        versionDate: data?.calculatedVersionDate,
        dataPointSelection,
        tooltipFormatter,
        actualKpi: data?.pastActual?.activities,
    });

    useEffect(() => {
        if (data) {
            update({ selectedStartDate: data.startChartDate, selectedEndDate: data.endChartDate });
        }
    }, [data]);

    const rangeFilterValue = useMemo(() => {
        if (!range) return [];
        if (range === 'R_CUSTOM') {
            return [getInputLabel({ startDate, endDate })];
        }
        return [rangeOptions.find((item) => item.value === range)?.label || ''];
    }, [range, startDate, endDate]);
    const allFormattedFilters = [
        ...formatedFilter,
        {
            name: 'Date Range',
            data: rangeFilterValue,
        },
        {
            name: 'Aggregation',
            data: localFilters?.aggregation
                ? [aggregationOptions.find((item) => item.value === localFilters?.aggregation)?.label]
                : [],
        },
    ];

    return (
        <WidgetWithTitle
            id={id}
            title={title}
            tooltip={data?.info}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={allFormattedFilters} />
                ) : (
                    <div key={'filters'} className={classes.filters}>
                        <div className={classes.selectWrapper}>
                            <div className={classes.title}>Aggregation</div>
                            <MyCustomSelect<ISelectOption<Aggregation>>
                                value={aggregation}
                                options={aggregationOptions}
                                onChange={(value) => update({ aggregation: value })}
                            />
                        </div>
                    </div>
                ),
            ]}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    localFilters={{
                        range: globalFilterData?.dateRangeFilter,
                        aggregation: aggregation?.value,
                    }}
                    filters={pinBoardFilterData}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
            ]}
        >
            <div className={localClasses.container}>
                <Boxes data={data} />
            </div>
            <div className={localClasses.container}>
                {options && (
                    <BarChart
                        options={options}
                        series={series}
                        isFetching={isFetching}
                        noData={!isFetching && !data}
                        height={600}
                    />
                )}
            </div>
        </WidgetWithTitle>
    );
};
