import SimulatedTable from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedTables';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';

interface Props {
    simulationDate: number;
    handleBackClick: () => void;
}

export const SimulatedActivitiesTableWrapper = ({ simulationDate, handleBackClick }: Props) => {
    const { id } = useParams<any>();
    const { versionList } = useHashmapConverter(id);
    const projectId = versionList[0]?.projectId;
    const contractId = versionList[0]?.contractId;
    const latestVersionId = versionList[0]?.versionDbId;

    return (
        <SimulatedTable
            simulationDate={simulationDate}
            handleBackClick={handleBackClick}
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
        />
    );
};
