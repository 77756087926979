import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import PageContainer from 'components/common/PageContainer/PageContainer';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { TreeProgramTopHeader } from 'components/common';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';
import { SimulationSteps } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulationSteps/SimulationSteps';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { listCardsDataQueryKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { queryKey as runSimulationQueryKey } from 'api/queries/getSchedulingSimulationResults.query';
import { TitleWithBeta } from 'components/common/titleWithBeta/titleWithBeta';

export const ScheduleSimulation = () => {
    useFullStoryPageView({ pageName: excellenceAppsConfig.scheduleSimulation.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader
                            pageTitle={<TitleWithBeta title={excellenceAppsConfig.scheduleSimulation.title} />}
                        />
                    </VersionSection>
                    <GlobalFilterButton />
                </VersionWrapper>
            </HeaderWrapper>
            <MainContent>
                <SimulationSteps />
            </MainContent>
            <MainCardRoutingModal queryKeys={[runSimulationQueryKey, listCardsDataQueryKey]} />
        </PageContainer>
    );
};
