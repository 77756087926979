import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';

const queryFn = () => {
    const url = new URL(API_URL().GET_SERVER_TIME);
    url.searchParams.set('name', location.hostname);
    return network.post(url.toString()).then((response) => {
        return response.data;
    });
};
export const useQueryServerTime = () => {
    return useQuery<QueryResponse, QueryError, QueryResponse, QueryKeys>({
        queryKey: 'getServerTime',
        queryFn: () => queryFn(),
        cacheTime: 0,
        placeholderData: {
            time: new Date().valueOf(),
        },
    });
};

interface QueryResponse {
    time: number;
}
