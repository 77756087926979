import { TooltipCellWithCopy } from 'components/common/Tables/Cells/tooltipCellWithCopy';
import { ActivityCell } from 'components/common/Tables/Cells/ActivityCell';
import CriticalityScoreCell from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/Cells/criticalityScoreCell';
import { Link } from 'react-router-dom';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import React from 'react';
import colorsVars from 'styles/colors.module.scss';
import { StatusCell } from 'components/common/Tables/Cells/StatusCell';
import { DetailsCell } from 'components/common/Tables/Cells/DetailsCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { TrackedUntrackedActionCellWrapper } from 'components/common/TrackedUntrackedAction/trackedUntrackedActionCellWrapper/trackedUntrackedActionCellWrapper';
import { CustomizedTooltip } from 'components/common';
import { CustomizedRoundProgressBar } from 'components/common/CustomizedRoundProgressBar/CustomizedRoundProgressBar';
import { ITableColumnState } from 'hooks/useTableCurrentState';
import { customSortWithNull } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';
import { BasicDateCell } from 'components/common/Tables/Cells/basicDateCell';
import { ProgramCriticalAnalysisQueryKeys } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { DateCell } from 'components/common/Tables/Cells/DateCell';
import { VarianceCell } from 'components/common/Tables/Cells/VarianceCell';

const { color6: orange, delayDriverColor, textColor } = colorsVars;

const invalidateKeys = ProgramCriticalAnalysisQueryKeys;

export const columns = [
    {
        Header: 'Cluster Name',
        accessor: 'clusterName',
        ...columnsCommonConfig.clusterName,
        Cell: ({ row, value }) => {
            if (row.isGrouped) return <strong>{value}</strong>;
            return value;
        },
    },
    {
        Header: 'Activity Name',
        accessor: 'activityName',
        ...columnsCommonConfig.activity,
        Cell: ({ row, toggleAllRowsSelected }) => {
            if (row.isGrouped) return null;
            const { activityName, activityId, taskVersionHashCode } = row.original;
            const { id, cardIndicators, isTracked, isVirtual } = row.original.cardBoardViewResponse;
            const { toggleRowSelected } = row;

            return (
                <ActivityCell
                    activityName={activityName}
                    activityId={activityId}
                    taskId={id}
                    cardIndicators={cardIndicators}
                    isVirtual={isVirtual}
                    toggleRowSelected={toggleRowSelected}
                    toggleAllRowsSelected={toggleAllRowsSelected}
                    trackedUntrackedActionCell={
                        <TrackedUntrackedActionCellWrapper
                            isTracked={isTracked}
                            taskVersionHashCode={taskVersionHashCode}
                            invalidateQueriesKey={invalidateKeys}
                        />
                    }
                />
            );
        },
    },
    {
        Header: 'Status',
        accessor: 'cardBoardViewResponse.status.label',
        ...columnsCommonConfig.status,
        Cell: ({ row }) => {
            if (row.isGrouped) return null;
            const { assignee, id, isVirtual, status, originalStatus, category, isStatusOrDatesChanged } =
                row.original.cardBoardViewResponse;
            const cardType = row.original.cardBoardViewResponse?.type?.name;
            return (
                <StatusCell
                    assignee={assignee}
                    status={status}
                    isVirtual={isVirtual}
                    taskId={id}
                    originalStatus={originalStatus}
                    invalidateQueriesKey={invalidateKeys}
                    cardType={cardType}
                    category={category}
                    isStatusOrDatesChanged={isStatusOrDatesChanged}
                />
            );
        },
    },
    {
        Header: 'Planned Duration',
        accessor: 'plannedDuration',
        ...columnsCommonConfig.plannedDuration,
        aggregate: 'median',
        Aggregated: ({ value }) => (
            <div>
                Median (days)
                <br /> <strong>{Math.round(value * 100) / 100}</strong>
            </div>
        ),
    },
    {
        Header: 'Forecasted Duration',
        accessor: 'forecastDuration',
        ...columnsCommonConfig.forecastDuration,
        aggregate: 'median',
        Aggregated: (props) => {
            const row = props.row;
            const subRow = row.leafRows[0].original;
            const { clusterId } = subRow;
            const hashCode = subRow.forecastDuration.taskHashCode;
            const versionHashCode = hashCode ? `/${hashCode.versionHashCode}` : '';
            return (
                <div>
                    Median (days)
                    <br />{' '}
                    <CustomizedTooltip
                        triggerElement={
                            <Link
                                to={`/dashboard/program/${excellenceAppsConfig.durationForecast.link}${versionHashCode}?clusterId=${clusterId}`}
                            >
                                <strong>{Math.round(props.value * 100) / 100}</strong>
                            </Link>
                        }
                        tooltipContent={'Click to see cluster details'}
                    />
                </div>
            );
        },
        Cell: ({ value, row }) => {
            if (row.isGrouped) return null;
            const { clusterId } = row.original;
            const hashCode = value.taskHashCode;
            const versionHashCode = hashCode ? `/${hashCode.versionHashCode}` : '';
            return (
                <CustomizedTooltip
                    triggerElement={
                        <Link
                            to={`/dashboard/program/${excellenceAppsConfig.durationForecast.link}${versionHashCode}?clusterId=${clusterId}`}
                        >
                            {value}
                        </Link>
                    }
                    tooltipContent={'Click to see cluster details'}
                />
            );
        },
    },
    {
        Header: 'Forecasted Overrun',
        accessor: 'forecastOverrun',
        aggregate: 'sum',
        ...columnsCommonConfig.forecastOverrun,
        Aggregated: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return (
                <div>
                    Total (days)
                    <br />{' '}
                    <span style={{ color }}>
                        <strong>{Math.round(value)}</strong>
                    </span>
                </div>
            );
        },
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Submitted Completion',
        ...columnsCommonConfig.submittedDurationComplete,
        accessor: 'cardBoardViewResponse.submittedDurationComplete',
        Cell: ({ row, value }) => {
            if (row.isGrouped) return null;
            return (
                <CustomizedTooltip
                    tooltipContent={`Submitted Completion: ${value}%`}
                    triggerElement={<CustomizedRoundProgressBar value={Number(value)} />}
                />
            );
        },
    },
    {
        Header: 'Details/Last Comment',
        accessor: 'cardBoardViewResponse.details',
        ...columnsCommonConfig.details,
        Cell: ({ row }) => {
            if (row.isGrouped) return null;
            const { details, cardLastComment, id, isVirtual, isGenAiDetails } = row.original.cardBoardViewResponse;
            return (
                <DetailsCell
                    details={details}
                    cardLastComment={cardLastComment}
                    id={id}
                    isVirtual={isVirtual}
                    invalidateQueriesKey={invalidateKeys}
                    isGenAiDetails={isGenAiDetails}
                />
            );
        },
    },
    {
        Header: 'Activity Type',
        ...columnsCommonConfig.activityType,
        accessor: 'activityType',
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Criticality Score',
        accessor: 'criticalityScore',
        ...columnsCommonConfig.criticalityScore,
        Cell: ({ value, row }) => {
            if (row.isGrouped) return null;
            const delayDriver = row.original.delayDriver;
            const style = delayDriver ? { color: delayDriverColor, fontWeight: 600 } : {};
            const numOfPredecessors = row.original.numOfPredecessors || row.original.predeccessors?.length;
            const numOfSuccessors = row.original.numOfSuccessors || row.original.successors?.length;
            const precedingActivities = row.original.cardBoardViewResponse?.precedingActivities;
            const succeedingActivites = row.original.cardBoardViewResponse?.succeedingActivites;
            const precedingRisk = row.original.cardBoardViewResponse?.precedingRisk;
            const succeedingRisk = row.original.cardBoardViewResponse?.succeedingRisk;
            return (
                <CriticalityScoreCell
                    succeedingRisk={succeedingRisk}
                    precedingRisk={precedingRisk}
                    numOfSuccessors={numOfSuccessors}
                    numOfPredecessors={numOfPredecessors}
                    succeedingActivites={succeedingActivites}
                    precedingActivities={precedingActivities}
                    style={style}
                    value={value}
                />
            );
        },
        sortType: 'basic',
    },
    {
        Header: 'Float',
        accessor: 'float',
        ...columnsCommonConfig.float,
        sortType: customSortWithNull,
    },
    {
        Header: 'Critical Path',
        ...columnsCommonConfig.criticalPath,
        accessor: (row) => (row.criticalPath ? 'Yes' : 'No'),
    },
    {
        Header: 'Start Date',
        accessor: 'currentStartDate',
        ...columnsCommonConfig.currentStartDate,
        Cell: ({ value, row }) => {
            if (row.isGrouped) return null;
            const changeMapDate = row.original.cardBoardViewResponse.startVariance;
            const comparedVersion = row.original.baselineStartDate;
            return (
                <DateCell
                    latestVersionDate={value}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        Header: 'Finish Date',
        accessor: 'currentFinishDate',
        ...columnsCommonConfig.currentFinishDate,
        Cell: ({ value, row }) => {
            if (row.isGrouped) return null;
            const changeMapDate = row.original.cardBoardViewResponse.finishVariance;
            const comparedVersion = row.original.baselineFinishDate;
            return (
                <DateCell
                    latestVersionDate={value}
                    changeMapDate={changeMapDate}
                    comparedVersion={comparedVersion}
                    isChangeMap={true}
                />
            );
        },
    },
    {
        Header: 'Baseline Start Date ',
        accessor: 'baselineStartDate',
        ...columnsCommonConfig.baselineStartDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Baseline Finish Date',
        accessor: 'baselineFinishDate',
        ...columnsCommonConfig.baselineFinishDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Planned Start Date ',
        accessor: 'plannedStartDate',
        ...columnsCommonConfig.plannedStartDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Planned Finish Date',
        accessor: 'plannedFinishDate',
        ...columnsCommonConfig.plannedFinishDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Actual Start Date',
        accessor: 'actualStartDate',
        ...columnsCommonConfig.actualStartDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Actual Finish Date',
        accessor: 'actualFinishDate',
        ...columnsCommonConfig.actualFinishDate,
        Cell: ({ value }) => {
            return <BasicDateCell value={value} />;
        },
    },
    {
        Header: 'Actual Duration',
        accessor: 'actualDuration',
        ...columnsCommonConfig.actualDuration,
    },
    {
        Header: 'Remaining Duration',
        accessor: 'remainingDuration',
        ...columnsCommonConfig.remainingDuration,
    },
    {
        Header: 'Current/ Completed Duration',
        accessor: 'completedDuration',
        ...columnsCommonConfig.completedDuration,
    },
    {
        Header: 'Current Duration Overrun',
        accessor: 'durationOverun',
        ...columnsCommonConfig.durationOverrun,
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Completed Duration Overrun',
        accessor: 'overrunDays',
        ...columnsCommonConfig.overrunDays,
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Completed Overrun %',
        accessor: 'overrunPercent',
        ...columnsCommonConfig.overrunPercent,
        Cell: ({ value }) => {
            const color = value > 0 ? orange : textColor;
            return <span style={{ color }}>{value}</span>;
        },
    },
    {
        Header: 'Pred.',
        accessor: 'numOfPredecessors',
        ...columnsCommonConfig.numOfPredecessors,
    },
    {
        Header: 'Succ.',
        accessor: 'numOfSuccessors',
        ...columnsCommonConfig.numOfSuccessors,
    },
    {
        Header: 'Project Week Start ',
        accessor: 'projectWeekStart',
        ...columnsCommonConfig.projectWeekStart,
    },
    {
        Header: 'Project Week Finish',
        accessor: 'projectWeekFinish',
        ...columnsCommonConfig.projectWeekFinish,
    },
    {
        Header: 'Project Month Start',
        accessor: 'projectMonthStart',
        ...columnsCommonConfig.projectMonthStart,
    },
    {
        Header: 'Project Month Finish',
        accessor: 'projectMonthFinish',
        ...columnsCommonConfig.projectMonthFinish,
    },
    {
        Header: 'Immediate Parent Name ',
        accessor: 'parentName',
        ...columnsCommonConfig.parentName,
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
        aggregate: 'count',
        Aggregated: () => '',
        disableGroupBy: false,
    },
    {
        Header: 'Grand Parent Name',
        accessor: 'grandParentName',
        ...columnsCommonConfig.grandParentName,
        Cell: ({ value }) => <TooltipCellWithCopy text={value} />,
    },
    {
        Header: 'Start Variance',
        accessor: 'cardBoardViewResponse.startVariance',
        ...columnsCommonConfig.lateStart,
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
    {
        Header: 'Finish Variance',
        accessor: 'cardBoardViewResponse.finishVariance',
        ...columnsCommonConfig.lateFinish,
        Cell: ({ value }) => {
            return <VarianceCell value={value} />;
        },
    },
];

export const initialState: ITableColumnState = {
    sortBy: [
        {
            id: 'forecastOverrun',
            desc: true,
        },
    ],
    hiddenColumns: ['details', 'activityType', 'lateStart', 'lateFinish'],
    groupBy: ['clusterName'],
};

export const defaultPeriod = '-1';
export const TOP_OVERDUE_OVERRUN_FILTERS: ISelectOption<string>[] = [
    {
        label: '3M',
        value: '3',
    },
    {
        label: '6M',
        value: '6',
    },
    {
        label: '12M',
        value: '12',
    },
    {
        label: 'ALL',
        value: '-1',
    },
];
