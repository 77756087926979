import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import produce from 'immer';
import {
    IAssignee,
    ICardIndicators,
    ICategory,
    IStatus,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { CardType } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { ICardLastComment } from 'components/common/Tables/Cells/DetailsCell';

export const cardsDataQueryKey = 'cardsData';
export const listCardsDataQueryKey = 'listCardsData';

const getCardsData = (params) => {
    // delete unnecessary props for Api (this props needed only for FE)
    const fixedParams = produce(params, (draft) => {
        draft.filter.filters.forEach((item) => {
            if (item.hasOwnProperty('currentValue')) delete item.currentValue;
            if (item.hasOwnProperty('isSelected')) delete item.isSelected;
            return item;
        });
    });
    // ==========================================================================
    const url = API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_ALL_CARDS;

    return network
        .post(url, fixedParams)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getListCardsData = (params) => {
    // delete unnecessary props for Api (this props needed only for FE)
    const fixedParams = produce(params, (draft) => {
        draft.cardCachedFilter.filter.filters.forEach((item) => {
            if (item.hasOwnProperty('currentValue')) delete item.currentValue;
            if (item.hasOwnProperty('isSelected')) delete item.isSelected;
            return item;
        });
    });
    // ==========================================================================
    const url = API_URL().GET_LIST_FILTERED_CARDS;

    return network
        .post(url, fixedParams)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getCountersData = (params) => {
    const url = API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_COUNTERS;

    return network
        .post(url, params)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryCardsData = (params) => {
    return useQuery<ISingleSmallCardResponse[], QueryError>([cardsDataQueryKey, params], () => getCardsData(params), {
        enabled: Boolean(params) && params?.filter.filters.length > 0,
    });
};

export const useQueryListCardsData = (params) => {
    return useQuery<{ cardList: ISingleSmallCardResponse[]; numOfAllCards: number; numOfPages: number }, QueryError>(
        [listCardsDataQueryKey, params],
        () => getListCardsData(params),
        {
            enabled: Boolean(params) && params?.cardCachedFilter.filter.filters.length > 0,
        },
    );
};

export const useQueryCountersData = (params) => {
    return useQuery<ICountersDataResponse, QueryError>(['CountersData', params], () => getCountersData(params), {
        enabled: Boolean(params) && params?.filter.filters.length > 0,
    });
};

export interface ISingleSmallCardResponse {
    id: number;
    isVirtual: boolean;
    type: {
        id: number;
        name: CardType;
        label: string;
    };
    taskActivityType: string;
    category: ICategory;
    title: string;
    parentActivity: string;
    startDate: number;
    endDate: number;
    dueDate: number | null;
    duration: number;
    assignee: IAssignee | null;
    rank: number;
    criticalityScore: number;
    float: number | null;
    tagList: ITagList[];
    versionDate: number;
    isTracked: boolean;
    details: string;
    isStartOverdue: boolean;
    isFinishOverdue: boolean;
    cardIndicators: ICardIndicators;
    status: IStatus;
    finishVariance: number;
    startVariance: number;
    taskVersionHashCode: string;
    originalStatus: IStatus | null;
    precedingActivities: number | null;
    succeedingActivites: number | null;
    precedingRisk: string | null;
    succeedingRisk: string | null;
    cardLastComment: ICardLastComment | null;
    submittedDurationComplete: number;
    plannedStartDate: number | null;
    plannedFinishDate: number | null;
    actualStartDate: number | null;
    actualFinishDate: number | null;
    completedDurationOverrun: number | null;
    grandParentActivity: string | null;
    baselineFinishDate: number | null;
    baselineStartDate: number | null;
    taskId?: number;
    metaDataId: number;
    taskHashCode: string;
    numOfPredecessors: number | null;
    numOfSuccessors: number | null;
    projectWeekStart: number | null;
    projectWeekFinish: number | null;
    projectMonthStart: number | null;
    projectMonthFinish: number | null;
    taskActivityId: string | null;
    plannedDurationInDays: number | null;
    actualDurationInDays: number | null;
    remainingDurationInDays: number | null;
    completedDurationInDays: number | null;
    currentDurationOverrun: number | null;
    isInterimChanged: boolean;
    severity: string | null;
    overrunPercent: number | null;
    orderInPage: number;
    uniqId: number;
    allBudgetResourceUnit: number | null;
    allActualResourceUnit: number | null;
    allResourceNameList: string[] | null;
    budgetResourceUnit: number | null;
    actualResourceUnit: number | null;
    remainingResourceUnit: null;
    resourceName: string | null;
    resourceType: string | null;
    isGenAiDetails: boolean;
    isStatusOrDatesChanged: boolean;
}

export interface ICountersDataResponse {
    kcInsightCompletedCount: number;
    kcInsightInProgressCount: number;
    kcInsightTodoCount: number;
    milestoneCompletedCount: number;
    milestoneInProgressCount: number;
    milestoneTodoCount: number;
    tdaCompletedCount: number;
    tdaInProgressCount: number;
    tdaTodoCount: number;
    userGeneratedCompletedCount: number;
    userGeneratedInProgressCount: number;
    userGeneratedTodoCount: number;
    wbsCompletedCount: number;
    wbsInProgressCount: number;
    wbsTodoCount: number;
    loeCompletedCount: number;
    loeInProgressCount: number;
    loeTodoCount: number;
}
