import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutationGetMFACode } from 'api/mutations/getMFACode.mutation';
import { encrypt, GENERIC_ERROR } from 'components/Login/login.utils';
import { useQueryGetUserDetail } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useQueryState } from 'hooks/useQueryState';
import { LoginComponent } from 'components/Login/LoginComponent';
import { useLoginStore } from 'store/login.store';
import { useLoginHook } from 'components/Login/loginHook';
import { useMutationLogin } from 'api/mutations/login.mutation';
import { useQueryServerTime } from 'api/queries/serverTime.query';
import { useMutationValidateLogin } from 'api/mutations/validateLogin.mutation';
import { useQueryGetAccessToken } from 'api/queries/getAccessToken.query';
import { useQueryCheckMFAEnabled } from 'api/queries/checkMFAEnabled.query';

export interface ILoginForm {
    password: string;
    username: string;
    mfaCode: null;
}

const Login = () => {
    const url = new URL(document.URL);
    const history = useHistory();
    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<ILoginForm>({
        defaultValues: {
            password: '',
            username: '',
            mfaCode: null,
        },
    });
    const { update } = useLoginStore();
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [emailAfterMfa, setEmailAfterMfa] = useState<string | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const { data: serverTime } = useQueryServerTime();
    const { mutate: loginMutation, isLoading: isLoadingLogin } = useMutationLogin();
    const { mutate: validateLoginMutation, isLoading: isLoadingValidateLogin } = useMutationValidateLogin();
    const { mutate: getMFACodeMutation, isLoading: isLoadingGetMFACode } = useMutationGetMFACode(); // send mfa code to user by email
    const [xyz] = useQueryState('xyz');
    const { data: activeDirectoryUser, isLoading: isLoadingActiveDirectoryUser } = useQueryGetAccessToken({
        uuid: xyz,
    });
    const { data: user, isLoading: isLoadingUserDetail } = useQueryGetUserDetail({
        email: emailAfterMfa || activeDirectoryUser?.username,
    });

    const { data: mfa } = useQueryCheckMFAEnabled({ email, password });

    useLoginHook({ user, isSwitchUser: false });

    useEffect(() => {
        if (mfa) {
            const time = serverTime?.time as number;
            const payload = time !== 0 ? { encrypt: encrypt(JSON.stringify(watch()), time), time } : watch();
            if (mfa.mfaType === 'off') {
                loginMutation(payload, {
                    onSuccess: (response) => {
                        if (response.email) {
                            setEmailAfterMfa(response.email);
                            setError(undefined);
                        } else {
                            setError(GENERIC_ERROR);
                        }
                    },
                    onError: () => {
                        setError(GENERIC_ERROR);
                    },
                });
            } else {
                validateLoginMutation(payload, {
                    onSuccess: (response) => {
                        if (response.valid) {
                            if (mfa.mfaType === 'email') {
                                update({ username: watch('username'), password: watch('password') });
                                getMFACodeMutation(
                                    { email: watch('username') },
                                    {
                                        onSuccess: () => {
                                            history.push(`/mfa?type=${mfa.mfaType}`);
                                        },
                                        onError: () => {
                                            setError(GENERIC_ERROR);
                                        },
                                    },
                                );
                            }
                            if (['microsoft', 'google'].includes(mfa.mfaType)) {
                                update({ username: watch('username'), password: watch('password') });
                                history.push(
                                    mfa.mfaInit ? `/mfaSignUp?type=${mfa.mfaType}` : `/mfa?type=${mfa.mfaType}`,
                                );
                            }
                            setError(undefined);
                        } else {
                            setError(GENERIC_ERROR);
                        }
                    },
                    onError: () => {
                        setError(GENERIC_ERROR);
                    },
                });
            }
        }
    }, [mfa, serverTime]);

    const login = (details: ILoginForm): void => {
        setEmail(details.username);
        setPassword(details.password);
    };

    const enableSSO = window['ENV_VARIABLES']['REACT_APP_SHOW_AAD_SSO'].indexOf(url.hostname) > -1;

    return (
        <LoginComponent
            title={'Sign in'}
            handleSubmit={handleSubmit}
            login={login}
            errors={errors}
            register={register}
            control={control}
            error={error}
            isLoading={
                isLoadingActiveDirectoryUser ||
                isLoadingUserDetail ||
                isLoadingLogin ||
                isLoadingGetMFACode ||
                isLoadingValidateLogin
            }
            enableSSO={enableSSO}
        />
    );
};

export default Login;
