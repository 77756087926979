import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';

const mutationFn = (params) => {
    const url = API_URL().FETCH_PROGRAM_DASHBOARD_RCF_CLUSTER_DATA_SAVE_SEARCH_WORD;

    return network.post(url, params).then((response) => {
        return response.data;
    });
};

export const useMutationSaveSearchWord = () => {
    return useMutation<unknown, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    customerId: number | undefined;
    paFileMetaDataIds: number[];
    projectId: number | undefined;
    query: string | undefined;
}
