import { formatValueForGraph } from 'utilitys/helpers/general';
import { GraphData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCrossVersions/components/programCrossVersions';

interface DataSeries {
    name: string;
    data: number[];
    labels: { text: string; displayValue: string; value: number }[];
}

export const serializeData = (data: GraphData['dataset']) => {
    if (!data) return [];

    const dataset: DataSeries[] = [];
    Object.keys(data).forEach((key) => {
        if (data[key].title && data[key].data.length) {
            const name = data[key].seriesname || data[key].title;
            const series = {
                data: data[key].data.map((i) => i.value),
                name: name,
                labels: data[key].data.map((i) => ({ text: i.text, displayValue: i.displayValue, value: i.value })),
            };
            dataset.push(series);
        }
    });
    return dataset;
};

const categoryLabelMapper = (item: DataSeries['labels'][0]) => item.text.split(':')[0].trim();
const tooltipLabelMapper = (item: DataSeries['labels'][0]) => `${item.text}, ${item.value} (${item.displayValue})`;

const getLabel = (series: DataSeries[], mapper: (value: DataSeries['labels'][0]) => string) => {
    if (!series) return [];

    const lengths = series.map((a) => a.labels.length);
    const longestIndex = lengths.indexOf(Math.max(...lengths));
    return longestIndex > -1 ? series[longestIndex].labels.map(mapper) : [];
};

export const serializeOptions = ({
    series,
    themeStatus,
}: {
    series: DataSeries[];
    themeStatus: boolean;
}): ApexCharts.ApexOptions => {
    const categories = getLabel(series, categoryLabelMapper);
    const tooltipLabels = getLabel(series, tooltipLabelMapper);

    return {
        chart: {
            background: themeStatus ? '#000000' : '#ffffff',
            foreColor: themeStatus ? '#FEFEFE' : '#000000',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true,
                },
            },
            events: {
                beforeZoom(chart: any) {
                    if (chart.wheelDelay) {
                        return {
                            xaxis: {
                                min: chart.minX,
                                max: chart.maxX,
                            },
                        };
                    }
                    return undefined;
                },
            },
        },
        legend: {
            showForSingleSeries: true,
            itemMargin: {
                horizontal: 10,
                vertical: 25,
            },
        },
        xaxis: {
            type: 'datetime',
            categories: categories,
            labels: {
                datetimeUTC: false,
            },
        },
        stroke: {
            width: 4,
        },
        yaxis: {
            min: 0,
            tickAmount: 5,
            show: true,
            labels: {
                style: {
                    fontSize: '1.2rem',
                },
                formatter: (value) => {
                    return formatValueForGraph(value);
                },
            },
        },
        tooltip: {
            theme: themeStatus ? 'dark' : 'light',
            y: {
                formatter: function (value, { seriesIndex }) {
                    return `${tooltipLabels[seriesIndex]}, ${value}`;
                },
            },
        },
    };
};
