import { useMutation } from 'react-query';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';

const downloadCsvFile = (params: QueryParams) => {
    const url = `${API_URL().DOWNLOAD_CSV_FILE}?fileName=${params.fileName}`;

    return network.get(url).then((response) => {
        const blob = new Blob([response.data], {
            type: 'text/plain',
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${params.downloadName}.csv`;
        a.click();

        return true;
    });
};

export const useMutationDownloadCsvFile = () => {
    return useMutation<unknown, QueryError, QueryParams>((params) => downloadCsvFile(params));
};

interface QueryParams {
    fileName: string;
    downloadName: string;
}
