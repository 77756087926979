import PageContainer from 'components/common/PageContainer/PageContainer';
import { HeaderWrapper } from 'components/common/PageContainer/HeaderWrapper';
import { VersionWrapper } from 'components/common/PageContainer/versionWrapper';
import { VersionSection } from 'components/common/PageContainer/versionSection';
import { TreeProgramTopHeader, VersionCompareSelectorWrapper } from 'components/common';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { GlobalFilterButton } from 'components/common/GlobalFilterButton/GlobalFilterButton';
import { GlobalFilterComponent } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { MilestoneFragnetFilter } from 'components/common/GlobalFilterComponent/components/MilestoneFragnetFilter/MilestoneFragnetFilter';
import { TagsFilter } from 'components/common/GlobalFilterComponent/components/TagsFilter/TagsFilter';
import { ActivityCodesFilter } from 'components/common/GlobalFilterComponent/components/ActivityCodesFilter/ActivityCodesFilter';
import { WbsFilter } from 'components/common/GlobalFilterComponent/components/WbsFilter/wbsFilter';
import { MainContent } from 'components/common/PageContainer/MainContent';
import { MainCardRoutingModal } from 'components/common/MainCardRoutingModal/mainCardRoutingModal';
import { useFullStoryPageView } from 'hooks/useFullstoryPageView';
import { ProductivityRateWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityRate/productivityRateWrapper';
import { ProductivityTableWrapper } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityTable/productivityTableWrapper';
import { ActivityTypeFilter } from 'components/common/GlobalFilterComponent/components/ActivityTypeFilter/activityTypeFilter';
import { ProductivityPreDefinedFilter } from 'components/common/GlobalFilterComponent/components/productivityPreDefinedFilter/productivityPreDefinedFilter';
import { DateRangeFilter } from 'components/common/GlobalFilterComponent/components/dateRangeFilter/dateRangeFilter';

export const ProductivityPage = () => {
    useFullStoryPageView({ pageName: projectConfig.productivity.title });

    return (
        <PageContainer isResponsive={true}>
            <HeaderWrapper>
                <VersionWrapper>
                    <VersionSection>
                        <TreeProgramTopHeader pageTitle={projectConfig.productivity.title}>
                            <ShareInsightButton title={projectConfig.productivity.title} link={window.location.href} />
                            <GlobalFilterButton />
                        </TreeProgramTopHeader>
                        <VersionCompareSelectorWrapper isBaselineSelected={true} allowSingleVersion={true} />
                    </VersionSection>
                </VersionWrapper>
                <GlobalFilterComponent
                    defaultValues={{
                        dateRangeFilter: 'R_4W',
                        productivityPreDefined: null,
                        activityType: 'ALL',
                        milestoneFragnet: null,
                        tags: [],
                        activityCodes: [],
                        wbs: null,
                    }}
                >
                    <DateRangeFilter name={'dateRangeFilter'} />
                    <ProductivityPreDefinedFilter name={'productivityPreDefined'} />
                    <ActivityTypeFilter name={'activityType'} />
                    <MilestoneFragnetFilter name={'milestoneFragnet'} />
                    <TagsFilter name={'tags'} />
                    <ActivityCodesFilter name={'activityCodes'} />
                    <WbsFilter name={'wbs'} />
                </GlobalFilterComponent>
            </HeaderWrapper>

            <MainContent>
                <ProductivityRateWrapper />
                <ProductivityTableWrapper />
            </MainContent>
            <MainCardRoutingModal queryKeys={['getStoredTags']} />
        </PageContainer>
    );
};
