import { OverallSummary } from 'components/Dashboards/ExcellenceApps/FinishDatePredictionPage/components/OverallSummaryComponent/OverallSummary';
import { useParams } from 'react-router-dom';
import { ProgramStatusUrlParams } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { useProjectTreeStore } from 'store/projectContract.store';

export const OverallSummaryWrapper = () => {
    const { project, contract } = useProjectTreeStore();
    const projectId = project?.id;
    const contractId = contract?.id;
    const hashcode = Object.values(useParams<ProgramStatusUrlParams>()).filter(Boolean).join(',');
    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const globalFilterData = useGlobalFilterDataStore((store) => store.globalFilterData);

    return (
        <OverallSummary
            projectId={projectId}
            contractId={contractId}
            latestVersionId={latestVersionId}
            globalFilterData={globalFilterData}
        />
    );
};
