import localClasses from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityRate/components/boxes.module.scss';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import React from 'react';
import { QueryResponse } from 'api/queries/productivityRate.query';
import { InformationBoxes } from 'components/common/informationBoxes/informationBoxes';
import { useProductivityRateStore } from 'store/productivityRate.store';
import { lowerCase, startCase } from 'lodash';

export const Boxes = ({ data }: { data: QueryResponse | undefined }) => {
    const { aggregation } = useProductivityRateStore();
    const aggregationLabel =
        aggregation?.value === 'AVERAGE' ? startCase(lowerCase(aggregation?.value)) : aggregation?.value;
    return (
        <InformationBoxes className={localClasses.row}>
            <InformationBoxes.Box>
                <div className={localClasses.box}>
                    <div className={localClasses.title}>Past Planned ({aggregationLabel})</div>
                    <div data-testid={'planed-box-info'} className={`${localClasses.info} ${localClasses.planed}`}>
                        {data?.pastPlanned?.activities} Activities / {data?.pastPlanned?.rangeLabel}
                    </div>
                    <div>(As of {moment(data?.pastPlanned?.date).format(constants.formats.date.default)})</div>
                </div>
            </InformationBoxes.Box>
            <InformationBoxes.Box>
                <div className={localClasses.box}>
                    <div className={localClasses.title}>Past Actual ({aggregationLabel})</div>
                    <div data-testid={'actual-box-info'} className={`${localClasses.info} ${localClasses.actual}`}>
                        {data?.pastActual?.activities} Activities / {data?.pastPlanned?.rangeLabel}
                    </div>
                    <div>(As of {moment(data?.pastActual?.date).format(constants.formats.date.default)})</div>
                </div>
            </InformationBoxes.Box>
            <InformationBoxes.Box>
                <div className={localClasses.box}>
                    <div className={localClasses.title}>Future Planned ({aggregationLabel})</div>
                    <div data-testid={'future-box-info'} className={`${localClasses.info} ${localClasses.future}`}>
                        {data?.futurePlanned?.activities} Activities / {data?.pastPlanned?.rangeLabel}
                    </div>
                    <div>(As of {moment(data?.futurePlanned?.date).format(constants.formats.date.default)})</div>
                </div>
            </InformationBoxes.Box>
        </InformationBoxes>
    );
};
