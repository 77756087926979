import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';

const mutationFn = ({ id }: QueryParams) => {
    const preparedURL = new URL(API_URL().DELETE_SUB_PROGRAM);
    id && preparedURL.searchParams.set('id', id.toString());
    return network.delete(preparedURL.toString()).then((response) => response.data);
};
export const useMutationDeleteSubProgram = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    id?: number;
}
