import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { QueryKeys } from 'api/queryKeys';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const queryFn = ({ versionId, numOfDaysToBeUrgent, filter }: QueryParams) => {
    const params = {
        metaDataId: versionId,
        numOfDaysToBeUrgent: numOfDaysToBeUrgent,
        filter,
    };
    return network.post(API_URL().FETCH_PROGRAM__STATUS_PRIORITIES_MATRIX_DATA, params).then((response) => {
        return response.data;
    });
};

export const useQueryGetMatrixData = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['useQueryGetMatrixData', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.versionId) && Boolean(params.numOfDaysToBeUrgent),
        cacheTime: 0,
    });
};

interface QueryParams {
    versionId: number | undefined;
    numOfDaysToBeUrgent: number | null;
    filter: { filters: IFilter[] };
}

export interface QueryResponse {
    doNowComponent: {
        title: string;
        itemRecommendation: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
        itemUserActivities: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
        itemProgramActivities: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
    };
    doNextComponent: {
        title: string;
        itemRecommendation: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
        itemUserActivities: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
        itemProgramActivities: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
    };
    prioritizeComponent: {
        title: string;
        itemRecommendation: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
        itemUserActivities: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
        itemProgramActivities: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
    };
    laterComponent: {
        title: string;
        itemRecommendation: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
        itemUserActivities: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
        itemProgramActivities: {
            title: string;
            matrixItemIndex: number;
            numOfItem: number;
            numOfOverdue: number;
            numOfDelayDriver: number;
            numOfCriticalPath: number;
            numOfAssignedToMe: number;
        };
    };
    info: IToolTip;
}
