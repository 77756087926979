import classes from 'components/Dashboards/widgets/keyActivities/components/filter.module.scss';
import { MyCustomSelect } from 'components/common';
import React, { useEffect, useMemo } from 'react';
import {
    useMutationsSetKeyActivitiesTags,
    useQueryGetKeyActivitiesTags,
} from 'components/Dashboards/widgets/keyActivities/queries/useQueryKeyActivities';
import useUserHook from 'hooks/useUserHook';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import {
    statusOptions,
    moreFilterOptions,
    nextDateOptions,
} from 'components/Dashboards/widgets/keyActivities/components/filter.utils';
import { List } from 'components/common/ListWrapper/list';
import { Controller } from 'react-hook-form';
import { orderBy } from 'lodash';
import { useQueryGetStoredTags } from 'api/queries/getStoredTags.query';
import { useProjectTreeStore } from 'store/projectContract.store';

export const Filter = ({ contractId, control, setValue }) => {
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateSetTags } = useMutationsSetKeyActivitiesTags();
    const { ability, customerId } = useUserHook();
    const { project, contract } = useProjectTreeStore();
    const { data: tagsList } = useQueryGetStoredTags({
        enabled: true,
        customerId,
        projectId: project?.id,
        contractId: contract?.id,
    });

    const { data: tagsFromBe, refetch } = useQueryGetKeyActivitiesTags({
        contractId,
    });

    const handleSaveTags = (value: readonly ISelectOption<number>[]) => {
        mutateSetTags(
            {
                contractId,
                tagIdList: value.map((item) => item.value),
            },
            {
                onSuccess: () => {
                    setValue('tags', value);
                    refetch().then(() => {
                        handleSnackBar('Note Key Activities Tag selection effects all viewers of this page', 'success');
                    });
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                },
            },
        );
    };

    const tagsOptions = useMemo<ISelectOption<number>[]>(
        () =>
            tagsList
                ? orderBy(
                      tagsList.map((item) => ({ label: item.name, value: item.tagId })),
                      [(item) => item.label.toLowerCase()],
                      ['asc'],
                  )
                : [],
        [tagsList],
    );

    useEffect(() => {
        if (tagsOptions.length > 0 && tagsFromBe) {
            setValue(
                'tags',
                tagsOptions.filter((item) => tagsFromBe?.map((item) => item.tagId).includes(item.value)),
            );
        }
    }, [tagsOptions, tagsFromBe]);

    return (
        <div className={classes.filter}>
            <div className={classes.item}>
                <div className={classes.title}>Tags</div>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<number>, true>
                            {...field}
                            options={tagsOptions}
                            onChange={handleSaveTags}
                            isMulti={true}
                            id={'key-activities-tags'}
                            placeholder={'Select a tag which identifies the program’s key activities...'}
                            isDisabled={!ability.can('view', 'KeyAcivitiesSelectTag')}
                        />
                    )}
                    name="tags"
                    control={control}
                />
            </div>
            <div className={classes.item}>
                <div className={classes.title}>Name</div>
                <div className={classes.filterTextSearch}>
                    <Controller
                        render={({ field }) => (
                            <TextSearchInput
                                {...field}
                                isSearch={false}
                                className={classes.textInput}
                                placeholder="Search Activities by Name"
                                maxLength={200}
                                handleClear={() => {
                                    setValue('name', '');
                                }}
                            />
                        )}
                        defaultValue={''}
                        name="name"
                        control={control}
                    />
                </div>
            </div>
            <div className={classes.item}>
                <div className={classes.title}>Status</div>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<string>, true>
                            {...field}
                            options={statusOptions}
                            isClearable={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti={true}
                            id={'key-activities-status'}
                            placeholder={'Select Status...'}
                            components={{
                                Option: InputOption,
                                ValueContainer: ValueContainer,
                            }}
                        />
                    )}
                    name="status"
                    control={control}
                />
            </div>
            <div className={classes.item}>
                <div className={classes.title}>More Filters</div>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<string>, true>
                            {...field}
                            options={moreFilterOptions}
                            isClearable={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti={true}
                            id={'key-activities-more-filters'}
                            placeholder={'Select More Filters...'}
                            components={{
                                Option: InputOption,
                                ValueContainer: ValueContainer,
                            }}
                        />
                    )}
                    name="moreFilter"
                    control={control}
                />
            </div>
            <div className={classes.item}>
                <div className={classes.title}>Next</div>
                <div className={classes.filtersWrapper}>
                    <Controller
                        render={({ field }) => <List<string> {...field} options={nextDateOptions} />}
                        name="nextDate"
                        control={control}
                    />
                </div>
            </div>
        </div>
    );
};
