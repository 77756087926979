import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { CustomizedButton, CustomizedModalBase } from 'components/common';
import classes from './ExportSimulationModal.module.scss';
import { SimulationExportOptions } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/exportModal/exportSimulationOptions';
import { useExportVersionStore } from 'store/exportVersion.store';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { useMutationDownloadSimulatedVersion } from 'api/mutations/downloadSimulatiedVersion.mutation';
import { useVersionStore } from 'store/version.store';
import { useEffect } from 'react';
import { getInitialExportName } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/exportModal/ExportSimulationModal.utils';
import { useSimulationCacheKeyStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/simulationCacheKey.store';
import { useMutationExportNewVersion } from 'api/mutations/exportSimulatedVersionToProjectLibrary.mutation';
import { useHistory } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { useProjectTreeStore } from 'store/projectContract.store';

interface Props {
    showModal: boolean;
    onHideModal: () => void;
    metaDataId: number;
    simulationDate: number;
}

export const ExportSimulationModal = ({ showModal, onHideModal, metaDataId, simulationDate }: Props) => {
    const currentVersion = useVersionStore((state) => state.version);
    const { name, selectedOption, contract, project, update } = useExportVersionStore();
    const { project: storedProject, contract: storedContract } = useProjectTreeStore();
    const { sessionKey } = useSimulationCacheKeyStore();
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate: mutateDownloadSimulation, isLoading: isLoadingDownloadSimulation } =
        useMutationDownloadSimulatedVersion();
    const { mutate: mutateExportNewVersion, isLoading: isLoadingExportNewVersion } = useMutationExportNewVersion();
    const history = useHistory();

    const onSave = () => {
        if (sessionKey !== null && project !== null && contract !== null) {
            if (selectedOption === `MPP` || selectedOption === `XER`) {
                const fileExtension = selectedOption === 'MPP' ? '.mpp.xml' : 'xer';
                mutateDownloadSimulation(
                    {
                        metaDataId,
                        exportTypeRequest: selectedOption,
                        fileName: `${name}.${fileExtension}`,
                        simulationDate: simulationDate,
                        key: sessionKey,
                    },
                    {
                        onSuccess: () => {
                            handleSnackBar(`New version saved successfully`, 'success');
                            onHideModal();
                        },
                        onError: (error) => {
                            handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                            onHideModal();
                        },
                    },
                );
            } else if (selectedOption === 'SIMULATED') {
                if (project.id && contract.id && name !== null) {
                    mutateExportNewVersion(
                        {
                            metaDataId,
                            projectId: project?.id,
                            contractId: contract?.id,
                            versionDate: simulationDate,
                            versionName: name,
                            key: sessionKey,
                        },
                        {
                            onSuccess: () => {
                                handleSnackBar(
                                    `New version successfully saved in ${project.title} > ${contract.title}`,
                                    'success',
                                );
                                onHideModal();
                                history.push(
                                    `/dashboard/project/${projectConfig.programLibrary.link}/${project.id}/${contract.id}`,
                                );
                            },
                            onError: (error) => {
                                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                                onHideModal();
                            },
                        },
                    );
                }
            }
        }
    };

    const onCancel = () => {
        onHideModal();
    };

    useEffect(() => {
        if (showModal && currentVersion) {
            const nameForExportVersion = getInitialExportName(currentVersion, simulationDate);
            update({
                date: moment(simulationDate).format(constants.formats.date.default),
                project: storedProject,
                contract: storedContract,
                name: nameForExportVersion,
                selectedOption: currentVersion.fileType === 'MPP' ? 'MPP' : 'SIMULATED',
                currentVersionFileType: currentVersion.fileType === 'MPP' ? 'MPP' : 'XER',
            });
        }
    }, [showModal]);

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={onCancel}>
            <div className={classes.modalContainer}>
                <h1 className={classes.title}>Save Simulation Output As</h1>
                <div className={classes.content}>
                    <SimulationExportOptions />
                </div>

                <div className={classes.footer}>
                    <div className={classes.actions}>
                        <CustomizedButton size={'large'} color={'secondary'} onClick={onCancel}>
                            Cancel
                        </CustomizedButton>
                        <CustomizedButton
                            isLoading={isLoadingDownloadSimulation || isLoadingExportNewVersion}
                            disabled={!name || isLoadingDownloadSimulation || isLoadingExportNewVersion}
                            size={'large'}
                            color={'primary'}
                            onClick={onSave}
                        >
                            Save
                        </CustomizedButton>
                    </div>
                </div>
            </div>
        </CustomizedModalBase>
    );
};
