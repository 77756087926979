import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/Tags/components/tags.module.scss';
import { Controller, useForm } from 'react-hook-form';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { CustomizedButton, CustomizedFormErrorMessage } from 'components/common';
import useUserHook from 'hooks/useUserHook';
import { ITagsForm } from 'components/Settings/Components/Tags/tags.utils.type';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useHistory, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useQueryGetStoredTags } from 'api/queries/getStoredTags.query';
import { useMutationUpdateTag } from 'api/mutations/updateTag.mutation';
import { useProjectTreeStore } from 'store/projectContract.store';

export const TagsEdit = () => {
    const { ability, customerId } = useUserHook();
    const { project } = useProjectTreeStore();
    const { data } = useQueryGetStoredTags({
        enabled: ability.can('view', 'ManageProjects'),
        customerId,
        projectId: project?.id,
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<ITagsForm>();
    const { handleSnackBar } = useCustomSnackBar();
    const { mutate, isLoading } = useMutationUpdateTag();
    const { tagId } = useParams<{ tagId: string }>();
    const tag = useMemo(() => data?.find((item) => item.tagId === Number(tagId)), [data, tagId]);
    const history = useHistory();

    const handleSave = (data) => {
        if (!customerId || !tag) return null;
        mutate(
            {
                customerId,
                oldName: tag.name,
                newName: data.name.trim(),
                projectId: tag.projectId,
            },
            {
                onSuccess: () => {
                    handleSnackBar('Successfully updated!', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error.response.data || 'Something went wrong, please try again', 'error');
                },
                onSettled: () => {
                    history.goBack();
                },
            },
        );
    };

    const handleCancel = () => {
        history.goBack();
    };

    return (
        <WidgetWithTitle title={'Edit Tag'}>
            {tag && (
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className={classes.container}>
                        <div className={classes.row}>
                            <div className={classes.label}>Project</div>
                            <div>{tag.projectName === null ? 'Portfolio' : tag.projectName}</div>
                        </div>
                        <div className={classes.row}>
                            <label htmlFor={'tag-name'} className={classes.label}>
                                Tag Name
                            </label>
                            <div className={classes.inputWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <TextSearchInput
                                            {...field}
                                            id={'tag-name'}
                                            isSearch={false}
                                            className={classes.input}
                                            placeholder="Enter tag name..."
                                            maxLength={60}
                                            handleClear={() => {
                                                setValue('name', '');
                                            }}
                                        />
                                    )}
                                    defaultValue={tag.name}
                                    name="name"
                                    control={control}
                                    rules={{
                                        validate: {
                                            min: (v) => v.length >= 2 || 'must be at least 2 characters',
                                            max: (v) => v.length < 60 || 'must be no more than 60 characters',
                                        },
                                    }}
                                />
                                {errors.name && <CustomizedFormErrorMessage text={errors.name.message} />}
                            </div>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.actionWrapper}>
                                <CustomizedButton type="submit" isLoading={isLoading} disabled={isLoading}>
                                    Save
                                </CustomizedButton>
                                <CustomizedButton onClick={handleCancel} color={'secondary'}>
                                    Cancel
                                </CustomizedButton>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </WidgetWithTitle>
    );
};
