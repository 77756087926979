import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { Controller } from 'react-hook-form';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import React, { useEffect } from 'react';
import { useQueryState } from 'hooks/useQueryState';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import produce from 'immer';

interface Props {
    methods?: any;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<any>>;
    handleFilters?: () => void;
    className?: string;
}

export type ActivityType = 'ALL' | 'MILESTONE' | 'TASK';

export const activityTypeOptions: ISelectOption<ActivityType>[] = [
    { label: 'All', value: 'ALL' },
    { label: 'Milestone', value: 'MILESTONE' },
    { label: 'Task', value: 'TASK' },
];

export const ActivityTypeFilter = ({ methods, name, setState, handleFilters, className }: Props) => {
    const [queryStringValue] = useQueryState(name);
    const { getValueFromStore } = useGlobalFilterDataStore();
    const storeValue = getValueFromStore<ActivityType>(name);

    const formValue = methods?.getValues(name);
    const isValue = formValue || queryStringValue || storeValue;

    useEffect(() => {
        if (setState) {
            if (!isValue) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft[name].isReady = true;
                        draft[name].isEmpty = true;
                        return draft;
                    });
                });
            }
            if (isValue) {
                methods?.setValue(name, isValue);
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft[name].isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, setState]);

    const handleChange = (option: ISelectOption<string> | null) => {
        methods?.setValue(name, option ? option.value : null);
        handleFilters && handleFilters();
    };

    const handleValue = (value) => {
        if (!value) {
            return null;
        }
        return activityTypeOptions.find((item) => item.value === isValue) || null;
    };

    return (
        <div className={`${classes.selectWrapper} ${className ?? ''}`}>
            <span className={classes.title}>Activity Type</span>
            <div className={classes.activityCodesSelectWrapper}>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<ActivityType>>
                            {...field}
                            options={activityTypeOptions}
                            id={'global-filter-component-activity-type'}
                            onChange={handleChange}
                            value={handleValue(field.value)}
                        />
                    )}
                    name={name}
                    control={methods?.control}
                />
            </div>
        </div>
    );
};
