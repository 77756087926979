import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';
import { CustomizedButton, MyCustomSelect } from 'components/common';
import { EmailInput } from 'components/Settings/Components/UserProfile/components/EmailInput';
import { FirstNameInput } from 'components/Settings/Components/UserProfile/components/FirstNameInput';
import { LastNameInput } from 'components/Settings/Components/UserProfile/components/LastNameInput';
import { PhoneAndTitleInputs } from 'components/Settings/Components/UserProfile/components/PhoneAndTitleInputs';
import { UpdateImageInput } from 'components/Settings/Components/UserProfile/components/updateImageInput';
import { UserGroups } from 'components/Settings/Components/UserProfile/components/UserGroups';
import { ChangePassword } from 'components/Settings/Components/UserProfile/components/ChangePassword';
import React, { useEffect, useMemo, useState } from 'react';
import useUserHook from 'hooks/useUserHook';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    TMfaType,
    useMutationUpdateUser,
    useQueryGetUserDetail,
} from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import {
    IAllUserGroups,
    useQueryGetAllUserGroups,
} from 'components/Settings/Components/UserGroups/queries/userGroupsQuery';
import { Controller, useForm } from 'react-hook-form';
import { MFA_OPTIONS, USER_STATUS_OPTIONS } from 'components/Settings/Components/UserProfile/userProfile.constants';
import { trimObjectValues } from 'utilitys/helpers/general';
import { Prompt } from 'react-router';
import { useUserStore } from 'store/user.store';
import { TimezoneSelector } from 'components/common/TimezoneSelector/TimezoneSelector';
import { IUserProfileForm } from 'components/Settings/Components/UserProfile/userProfile.utils.type';

const groupsMapper = (item: IAllUserGroups): ISelectOption<number> => ({
    value: item.group_id,
    label: item.group_name,
});

export const MyProfile = () => {
    const { email, image, ability } = useUserHook();
    const { update } = useUserStore();
    const isUserManagement = ability.can('view', 'UserManagement');
    const { handleSnackBar } = useCustomSnackBar();
    const [modalShow, setModalShow] = useState<boolean>(false);
    const { mutate, isLoading } = useMutationUpdateUser();
    const { data: userProfile, refetch: refetchUserProfile } = useQueryGetUserDetail({ email });
    const { data: userGroups } = useQueryGetAllUserGroups({ enabled: isUserManagement });
    const options = useMemo<ISelectOption<number>[]>(
        () => (userGroups ? userGroups?.map(groupsMapper) : []),
        [userGroups],
    );
    const {
        formState: { isDirty, errors, isSubmitted },
        register,
        control,
        reset,
        handleSubmit,
    } = useForm<IUserProfileForm>();

    const callback = () => {
        refetchUserProfile().then((response) => {
            update({ user: response.data });
        });
    };

    useEffect(() => {
        if (userProfile) {
            reset({
                email: userProfile.email,
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                phone: userProfile.phone,
                title: userProfile.title,
                status: USER_STATUS_OPTIONS.find(
                    (option) => option.value.toLowerCase() === userProfile.status.toLowerCase(),
                ),
                userGroups:
                    options.length > 0
                        ? userProfile.userGroupList.map((item) => options.find((option) => option.label === item))
                        : [],
                mfa: MFA_OPTIONS.find((item) => item.value === userProfile.mfa),
            });
        }
    }, [userProfile, options]);

    const onSubmit = (data: IUserProfileForm) => {
        if (userProfile) {
            const trimmedData = trimObjectValues(data) as IUserProfileForm;
            const payload = {
                userData: {
                    ...trimmedData,
                    username: trimmedData.email,
                    status: trimmedData.status.value.toUpperCase(),
                    mfa: trimmedData.mfa?.value,
                },
                userGroups: isUserManagement
                    ? trimmedData.userGroups.map((item) => item.label)
                    : userProfile.userGroupList,
            };

            mutate(payload, {
                onSuccess: () => {
                    handleSnackBar('Successfully saved!', 'success');
                },
                onError: (error) => {
                    handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                },
            });
        }
    };

    const onUserLeavePage = (): string => {
        return 'You have unsaved changes, are you sure you want to leave?';
    };

    return (
        <WidgetWithTitle id={'userProfileForm_FormTitle'} title={'Edit User'} className={classes.UserProfileContainer}>
            <div className={classes.userProfileForm}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.buttonsWrapper}>
                        <div className={classes.buttonsWrapperLeft}>Please complete all mandatory fields and save.</div>
                        <div className={classes.buttonsWrapperRight}>
                            <CustomizedButton
                                size={'large'}
                                color={'primary'}
                                type="submit"
                                id="userProfileFormButtons_Save"
                                isLoading={isLoading}
                                disabled={isLoading}
                            >
                                Save
                            </CustomizedButton>

                            <CustomizedButton
                                size={'large'}
                                color={'secondary'}
                                id="userProfileFormButtons_Cancel"
                                onClick={() => reset()}
                            >
                                Revert Changes
                            </CustomizedButton>
                        </div>
                    </div>
                    <div className={classes.formInputsWrapper}>
                        <EmailInput register={register} errors={errors} />
                        <FirstNameInput register={register} errors={errors} />
                        <LastNameInput register={register} errors={errors} />
                        <PhoneAndTitleInputs register={register} errors={errors} />
                        {userProfile && email && (
                            <UpdateImageInput
                                email={email}
                                fullName={`${userProfile.firstName} ${userProfile.lastName}`}
                                imageName={image || ''}
                                callback={callback}
                            />
                        )}
                    </div>

                    {isUserManagement && (
                        <div className={classes.passwordAndUserGroupSection}>
                            <UserGroups errors={errors} control={control} />
                        </div>
                    )}
                    {userProfile?.mfa !== 'off' && (
                        <div className={classes.passwordAndUserGroupSection}>
                            <div className={classes.associatedRolesContainer}>
                                <label>Two-factor Authentication Method</label>
                                <Controller
                                    render={({ field }) => (
                                        <MyCustomSelect<ISelectOption<TMfaType>> {...field} options={MFA_OPTIONS} />
                                    )}
                                    name="mfa"
                                    control={control}
                                />
                            </div>
                        </div>
                    )}
                    <TimezoneSelector />
                    <div className={classes.bottomActions}>
                        <ChangePassword
                            setModalShow={setModalShow}
                            modalShow={modalShow}
                            handleModalClose={() => setModalShow(false)}
                            userEmail={userProfile?.email}
                        />
                    </div>
                </form>
            </div>

            <Prompt message={onUserLeavePage} when={isDirty && !isSubmitted} />
        </WidgetWithTitle>
    );
};
