export const isFiltersContainStatusChanged = (filterList: {
    filter: { filters: { data: string | number[]; name: string } }[];
}): boolean => {
    if (filterList?.filter && filterList?.filter.filters) {
        const filtersObj = filterList?.filter.filters;
        const statusFilter = filtersObj.find((filterArr) => filterArr.name === 'STATUS_CARD_BOARD');
        if (!statusFilter) return false;
        return statusFilter.data.findIndex((status: string) => status === 'INTERIM_CHANGED') > -1;
    }
    return false;
};
