import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { QueryKeys } from 'api/queryKeys';
import { Aggregation } from 'store/productivityRate.store';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { ActivityType } from 'components/common/GlobalFilterComponent/components/ActivityTypeFilter/activityTypeFilter';
import { RangeWithCustom } from 'components/common/GlobalFilterComponent/components/dateRangeFilter/dateRangeFilter';

const queryFn = (params) => {
    return network.post(API_URL().GET_RUN_RATE_ANALYSIS, params).then((response) => response.data);
};

export const useQueryProductivityRate = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['productivityRate', params],
        queryFn: () => queryFn(params),
        enabled:
            Boolean(params.actualMetaDataId) &&
            Boolean(params.comparedMetaDataId) &&
            Boolean(params.range) &&
            Boolean(params.range === 'R_CUSTOM' ? params.startDate && params.endDate : true) &&
            Boolean(params.aggregation),
        cacheTime: 0,
    });
};

interface QueryParams {
    actualMetaDataId: number | undefined;
    comparedMetaDataId: number | undefined;
    range: RangeWithCustom | undefined;
    startDate: number | null;
    endDate: number | null;
    activityType: ActivityType | undefined;
    aggregation: Aggregation | undefined;
    filter: {
        filters: IFilter[];
    };
}

export interface QueryResponse {
    chart:
        | {
              endDate: number;
              futureActual: number;
              futurePlanned: number;
              previousActual: number;
              previousPlanned: number;
              startDate: number;
          }[]
        | null;
    futurePlanned: {
        activities: number;
        date: number;
        rangeLabel: string;
    } | null;
    pastActual: {
        activities: number;
        date: number;
        rangeLabel: string;
    } | null;
    pastPlanned: {
        activities: number;
        date: number;
        rangeLabel: string;
    } | null;
    startChartDate: number | null;
    endChartDate: number | null;
    calculatedVersionDate: number | null;
    info: IToolTip;
}
