import classes from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.module.scss';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import { Popover } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    getInputLabel,
    getSubmitValue,
    getYearOptions,
} from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.utils';
import moment from 'moment-timezone';
import { DateRangeFilterContent } from 'components/common/CustomFilters/TableDateRangeFilter/dateRangeFilterContent';

export interface IFields {
    fromMonth: ISelectOption<number> | null;
    fromYear: ISelectOption<number> | null;
    toMonth: ISelectOption<number> | null;
    toYear: ISelectOption<number> | null;
}

interface Props {
    onChange: (value: { startDate: number | null; endDate: number | null }) => void;
    minDate: number;
    maxDate: number;
    value: { startDate: number | null; endDate: number | null };
    isDisabled: boolean;
    dateRangeOption: TDateRangeOption;
    setDateRangeOption: (value: TDateRangeOption) => void;
}

export type TDateRangeOption = 'dateRange' | 'wholeProject';

export const TableDateRangeFilter = ({
    onChange,
    value,
    dateRangeOption,
    setDateRangeOption,
    minDate,
    maxDate,
    isDisabled = false,
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch,
    } = useForm<IFields>({
        values: {
            fromMonth: { value: moment.utc(minDate).month(), label: moment.utc(minDate).format('MMMM') },
            fromYear: { value: moment.utc(minDate).year(), label: moment.utc(minDate).format('YYYY') },
            toMonth: { value: moment.utc(maxDate).month(), label: moment.utc(maxDate).format('MMMM') },
            toYear: { value: moment.utc(maxDate).year(), label: moment.utc(maxDate).format('YYYY') },
        },
    });

    const inputLabel = useMemo(() => {
        return getInputLabel(value);
    }, [value]);

    const yearOptions = useMemo(() => {
        return getYearOptions({ minDate, maxDate });
    }, [minDate, maxDate]);

    const handleMenuOpen = (event: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
        if (!isDisabled) {
            setAnchorEl(event.currentTarget);
            setMenuOpen(true);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const submitForm = (data: IFields) => {
        onChange(
            getSubmitValue({
                dateRangeOption,
                toMonth: data.toMonth?.value,
                fromMonth: data.fromMonth?.value,
                fromYear: data.fromYear?.value,
                toYear: data.toYear?.value,
            }),
        );
        setMenuOpen(false);
    };

    return (
        <div className={classes.tableDateRangeFilterContainer}>
            <div data-testid={'table-date-range-label'} className={classes.label} onClick={handleMenuOpen}>
                {inputLabel}
            </div>

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                open={menuOpen}
                sx={{
                    '.MuiPopover-paper': {
                        overflow: 'visible',
                    },
                    '.MuiPopover-root': {
                        overflow: 'visible',
                    },
                }}
            >
                <div data-testid={'date-range-dialog'} className={classes.dateRangePopoverContentWrapper}>
                    <form onSubmit={handleSubmit(submitForm)} className={classes.form}>
                        <DateRangeFilterContent
                            dateRangeOption={dateRangeOption}
                            setDateRangeOption={setDateRangeOption}
                            control={control}
                            yearOptions={yearOptions}
                            errors={errors}
                            clearErrors={clearErrors}
                            minDate={minDate}
                            maxDate={maxDate}
                            watch={watch}
                        />

                        <div className={classes.footer}>
                            <div className={classes.actionButtons}>
                                <CustomizedButton size={'large'} color={'secondary'} onClick={handlePopoverClose}>
                                    Cancel
                                </CustomizedButton>
                                <CustomizedButton type={'submit'} size={'large'} color={'primary'}>
                                    Apply
                                </CustomizedButton>
                            </div>
                        </div>
                    </form>
                </div>
            </Popover>
        </div>
    );
};
