import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/style/table.module.scss';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import NoData from 'components/common/NoData/noData';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useReactTableState } from 'hooks/useReactTableState';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { SelectedRangeLabel } from 'components/common/selectedRangeLabel/selectedRangeLabel';
import {
    columnsV8,
    initialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourceAnalysisTable/resourceAnalysisTable.utils';
import { useQueryGetResourceTable, QueryResponse } from 'api/queries/getResourceTable.query';
import { useVersionStore } from 'store/version.store';
import { getStringWithoutHash } from 'utilitys/helpers/general';
import { useResourceAnalysisStore } from 'store/resourceAnalysis.store';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { TitleWithCounter } from 'components/common/titleWithCounter/titleWithCounter';
import { useMutationCsvExportResourceTable } from 'api/mutations/csvExportResourceTable';
import { aggregationOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourcesAnalysisChart/resourcesAnalysisChart.utils';
import { rangeOptions } from 'components/common/GlobalFilterComponent/components/RangeFilter/rangeFilter';
import { quickAnswerFilterOptions } from 'components/common/GlobalFilterComponent/components/quickAnswerFilter/quickAnswerFilter';
import { resourceTypeOptions } from 'components/common/GlobalFilterComponent/components/resourceTypeFilter/resourceTypeFilter';
import { useQueryGetProjectDatesForResourceChart } from 'api/queries/getProjectDatesForResourceChart.query';
import { getInputLabel } from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.utils';

export const ResourceAnalysisTable = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
}) => {
    const title = 'Resource Analysis | Activities Drilldown';
    const id = 'resourceAnalysisTable';
    const componentKey = 'resourceAnalysisTable';

    const {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState({ initialSorting: widgetId && filter?.filters ? filter?.filters.sortingRequestList : [] });
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const [totalPages, setTotalPages] = useState(0);
    const { handleSnackBar } = useCustomSnackBar();
    const { version } = useVersionStore();
    const { update, selectedSeries, aggregation, selectedEndDate, selectedStartDate, selectedValue } =
        useResourceAnalysisStore();

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),
        [filter, globalFilterData],
    );

    const { data: minMaxDates } = useQueryGetProjectDatesForResourceChart({
        metaDataId: latestVersionId,
        comparedMetaDataId: compareVersionId,
    });

    const dateRangeFilterValue = useMemo(() => {
        return globalFilterData?.dateRangeFilter;
    }, [globalFilterData?.dateRangeFilter]);

    const { startDate, endDate } = useMemo(() => {
        const value = widgetId && filter ? filter?.filters.range : dateRangeFilterValue;
        if (value && minMaxDates) {
            if (Array.isArray(value)) {
                if (value[0] && value[1]) {
                    return {
                        startDate: value[0],
                        endDate: value[1],
                    };
                } else {
                    return {
                        startDate: minMaxDates?.startProjectDate,
                        endDate: minMaxDates?.endProjectDate,
                    };
                }
            }
        }
        return {
            startDate: null,
            endDate: null,
        };
    }, [widgetId, filter?.filters.range, dateRangeFilterValue, minMaxDates]);

    const range = useMemo(() => {
        const value = widgetId && filter ? filter?.filters.range : dateRangeFilterValue;
        return Array.isArray(value) ? 'R_2W' : value;
    }, [widgetId, filter, filter?.filters.range, dateRangeFilterValue]);

    const { data, isLoading, isFetching } = useQueryGetResourceTable({
        metaDataId: widgetId && filter ? filter?.filters.metaDataId : version ? version.id : latestVersionId,
        comparedMetaDataId: widgetId && filter ? filter?.filters.comparedMetaDataId : compareVersionId ?? null,
        range,
        resourceId: widgetId && filter ? filter?.filters.resourceId : globalFilterData?.resource,
        filter: {
            filters,
        },
        startDate,
        endDate,
        sortingRequestList: widgetId && filter ? filter?.filters.sortingRequestList : sorting,
        paging: { ...pagination, page: pagination.page - 1 },
        barId: widgetId && filter ? filter?.filters.barId : selectedValue,
        aggregation: widgetId && filter ? filter?.filters.aggregation : aggregation.value,
        resourceType: widgetId && filter ? filter?.filters.resourceType : globalFilterData?.resourceType,
        quickAnswerFilter: widgetId && filter ? filter?.filters.quickAnswerFilter : globalFilterData?.quickAnswerFilter,
    });

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: widgetId && filter ? { filters: filter?.filters.mainFilters || [] } : undefined,
        latestVersionId,
    });

    const route = `${getStringWithoutHash(window.location.href)}#${id}`;

    const pinBoardFilterData = useMemo(() => {
        return {
            metaDataId: version ? version.id : latestVersionId,
            comparedMetaDataId: compareVersionId ?? null,
            sortingRequestList: sorting,
            aggregation: aggregation.value,
            resourceType: globalFilterData?.resourceType,
            quickAnswerFilter: globalFilterData?.quickAnswerFilter,
            range: globalFilterData?.dateRangeFilter,
            resourceId: globalFilterData?.resource,
            barId: selectedValue,
            mainFilters: filters,
        };
    }, [sorting, aggregation, globalFilterData, version, compareVersionId, selectedValue, filters]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (column && column.id) {
                        return {
                            ...column,
                            isVisible: !hiddenColumns.includes(column.id),
                        };
                    }
                    return null;
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();
    const { mutate: mutateExportCsv, isLoading: exportCsvLoading } = useMutationCsvExportResourceTable();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const setWidgetColumns = (widgetId: string) => {
        if (tableInstance) {
            mutateSetWidgetColumns(
                { columns: { hiddenColumns: tableInstance.state.hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };

    const handleAPICsvExport = () => {
        if (latestVersionId) {
            mutateExportCsv(
                {
                    metaDataId:
                        widgetId && filter ? filter?.filters.metaDataId : version ? version.id : latestVersionId,
                    comparedMetaDataId:
                        widgetId && filter ? filter?.filters.comparedMetaDataId : compareVersionId ?? null,
                    range,
                    resourceId: widgetId && filter ? filter?.filters.resourceId : globalFilterData?.resource,
                    filter: {
                        filters,
                    },
                    sortingRequestList: widgetId && filter ? filter?.filters.sortingRequestList : sorting,
                    paging: { ...pagination, page: pagination.page - 1 },
                    barId: widgetId && filter ? filter?.filters.barId : selectedValue,
                    aggregation: widgetId && filter ? filter?.filters.aggregation : aggregation.value,
                    resourceType: widgetId && filter ? filter?.filters.resourceType : globalFilterData?.resourceType,
                    quickAnswerFilter:
                        widgetId && filter ? filter?.filters.quickAnswerFilter : globalFilterData?.quickAnswerFilter,
                    fileName: `${title}_${subTitle}`,
                },
                {
                    onSuccess: () => {
                        handleSnackBar('File Successfully Downloaded', 'success');

                        sendEventWithDimensions({
                            category: 'Export',
                            action: 'CSV',
                            label: `${title} ${subTitle}`,
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Failed To Save Search', 'error');
                    },
                },
            );
        }
    };

    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [
        sorting,
        globalFilterData?.resourceType,
        globalFilterData?.quickAnswerFilter,
        globalFilterData?.range,
        globalFilterData?.resource,
        selectedValue,
        aggregation.value,
        filters,
    ]);

    useEffect(() => {
        update({
            selectedValue: null,
            selectedSeries: null,
            selectedStartDate: null,
            selectedEndDate: null,
            dataPointIndex: null,
            seriesIndex: null,
        });
    }, [globalFilterData]);

    useEffect(() => {
        updatedInitialState &&
            setColumnOrder(updatedInitialState.columnOrder ?? columnsV8.map((item) => item.id as string));
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    useEffect(() => {
        if (data) {
            setTotalPages(data.numOfPages);
        }
    }, [data]);

    const filteredRows = useMemo(() => data?.cardList || [], [data]);

    const rangeFilterValue = useMemo(() => {
        if (widgetId && filter) {
            const value = widgetId && filter ? filter?.filters.range : null;
            if (Array.isArray(value)) {
                return [getInputLabel({ startDate: value[0], endDate: value[1] })];
            } else {
                return [rangeOptions.find((item) => item.value === value)?.label || ''];
            }
        }
        return [];
    }, [widgetId, filter, filter?.filters.range]);

    const allFormattedFilters = [
        ...formatedFilter,
        {
            name: 'Aggregation',
            data: aggregation.value ? [aggregationOptions.find((item) => item.value === aggregation.value)?.label] : [],
        },
        {
            name: 'Resource Type',
            data:
                widgetId && filter.filters?.resourceType
                    ? [resourceTypeOptions.find((item) => item.value === filter.filters.resourceType)?.label]
                    : [],
        },
        {
            name: 'Quick Answer Filter',
            data:
                widgetId && filter.filters?.quickAnswerFilter
                    ? [quickAnswerFilterOptions.find((item) => item.value === filter.filters.quickAnswerFilter)?.label]
                    : [],
        },
        {
            name: 'Date Range',
            data: rangeFilterValue,
        },
    ];

    return (
        <WidgetWithTitle
            id={id}
            tooltip={data?.info}
            title={<TitleWithCounter title={title} counter={data?.numOfAllCards} />}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={allFormattedFilters} />
                ) : (
                    <div className={classes.filters} key={'selectedRangeLabel'}>
                        <SelectedRangeLabel
                            selectedEndDate={selectedEndDate}
                            selectedStartDate={selectedStartDate}
                            seriesLabel={selectedSeries}
                        />
                    </div>
                ),
            ]}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'CustomizTableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={pinBoardFilterData}
                    callBack={setWidgetColumns}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown
                    key={'export'}
                    title={title}
                    handleAPICsvExport={handleAPICsvExport}
                    subTitle={subTitle}
                />,
            ]}
        >
            <div className={classes.tableContainer}>
                {updatedInitialState && (
                    <>
                        <TableV8<QueryResponse['cardList'][0]>
                            data={filteredRows}
                            columns={columnsV8}
                            isLoading={isLoading || isFetching || exportCsvLoading}
                            noData={<NoData>No activities match the applied filter</NoData>}
                            sorting={sorting}
                            setSorting={setSorting}
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                            columnOrder={columnOrder}
                            setColumnOrder={setColumnOrder}
                        />

                        <div className={classes.pagination}>
                            <CustomPagination
                                totalPages={totalPages}
                                pagination={pagination}
                                setPagination={setPagination}
                                isDisabled={isFetching}
                            />
                        </div>
                    </>
                )}
            </div>

            <CustomTableColumnsModal
                onHideModal={() => setModalColumnsOpen(false)}
                showModal={modalColumnsOpen}
                widgetId={widgetId}
                componentKey={componentKey}
                tableInstance={tableInstance}
                initialState={initialState}
                columns={columnsV8}
                onChangeOrder={setColumnOrder}
                onChangeVisibility={setColumnVisibility}
            />
        </WidgetWithTitle>
    );
};
