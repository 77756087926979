import classes from './ItemsList.module.scss';
import Icon from 'components/common/Icons/icon';
import { Breadcrumbs } from '@mui/material';
import { Dropdown } from 'components/common/TreeCrumbs/Dropdown/Dropdown';
import { findeNodeByUUID, ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { IContract, IProject, useProjectTreeStore } from 'store/projectContract.store';
import { useBasePath } from 'hooks/useBasePath';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { sortBy } from 'lodash';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';

interface IItemsList {
    dropdownAnchor: HTMLElement | null;
    dropdownOpen: boolean;
    setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onCreateNewProjectOrSubProgram: () => void;
    isAllOption: boolean;
    data: ProjectTreeNode | undefined;
    callback?: (project: IProject | null, contract: IContract | null) => void;
}

export const ItemsList = ({
    dropdownAnchor,
    dropdownOpen,
    setDropdownOpen,
    onCreateNewProjectOrSubProgram,
    isAllOption,
    data,
    callback,
}: IItemsList) => {
    const [searchValue, setSearchValue] = useState('');

    const basePath = useBasePath();
    const history = useHistory();
    const { parentNode, currentList, update, project, contract } = useProjectTreeStore();
    const { reset } = useGlobalFilterDataStore();

    const handleListItem = (item: ProjectTreeNode | null) => {
        if (item === null && parentNode && parentNode.uuid !== '0-root') {
            // ALL Option was selected
            update({
                project: {
                    id: parentNode.id,
                    title: parentNode.name,
                    uuid: parentNode.uuid,
                },
                contract: null,
                parentNode: null,
            });
            if (callback) {
                callback(
                    {
                        id: parentNode.id,
                        title: parentNode.name,
                        uuid: parentNode.uuid,
                    },
                    null,
                );
            }

            reset();
            setDropdownOpen(false);
        } else if (item?.children) {
            // Project Option was selected
            update({ currentList: item.children, parentNode: item });
        } else if (item) {
            // Contract Option was selected
            const projectNode = data && item.parentUUID ? findeNodeByUUID(data, item.parentUUID) : null;
            if (projectNode) {
                update({
                    project: {
                        id: projectNode.id,
                        title: projectNode.name,
                        uuid: projectNode.uuid,
                    },
                    contract: {
                        id: item.id,
                        title: item.name,
                        uuid: item.uuid,
                    },
                    parentNode: null,
                });
                if (callback) {
                    callback(
                        {
                            id: projectNode.id,
                            title: projectNode.name,
                            uuid: projectNode.uuid,
                        },
                        {
                            id: item.id,
                            title: item.name,
                            uuid: item.uuid,
                        },
                    );
                }
            }

            reset();
            setDropdownOpen(false);
            history.push(basePath);
        }
        setSearchValue('');
    };

    const onBackToParentClick = () => {
        if (parentNode && !parentNode.parentUUID) {
            return;
        }

        if (parentNode?.parentUUID === '0-root' && data?.children) {
            update({ currentList: data.children, parentNode: data });
        } else if (parentNode?.children) {
            update({ currentList: parentNode.children });
        }
    };

    return (
        <Dropdown
            anchor={dropdownAnchor}
            handleClose={() => {
                setDropdownOpen(false);
                update({ parentNode: null });
            }}
            isOpen={dropdownOpen}
        >
            <div data-testid={'dropdown-content'} className={classes.dropdownContent}>
                <div data-testid={'dropdown-content-header'} className={classes.dropdownContentHeader}>
                    <Icon onClick={onBackToParentClick} name={'header-arrow-left'} size={'1.6rem'} color={'#8FA3C3'} />
                    <Breadcrumbs
                        sx={{
                            '& .MuiBreadcrumbs-li': { color: '#8FA3C3', fontSize: '1.6rem', padding: '2px' },
                        }}
                        onClick={onBackToParentClick}
                        separator={<Icon name={'header-arrow-right'} size={'1.6rem'} color={'#8FA3C3'} />}
                    >
                        <div>{parentNode && parentNode.uuid !== '0-root' ? parentNode.name : 'Select'}</div>
                    </Breadcrumbs>

                    <div className={classes.closeButton} onClick={() => setDropdownOpen(false)}>
                        <Icon name={'filter-x-icon'} size={'2rem'} color={'#8FA3C3'} />
                    </div>
                </div>
                <hr className={classes.separator} />

                <TextSearchInput
                    value={searchValue}
                    handleClear={() => setSearchValue('')}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={'Search...'}
                />

                <ul>
                    {parentNode && parentNode.uuid !== '0-root' && isAllOption && (
                        <li key={'all'} className={classes.listContainer}>
                            <div
                                className={`${classes.listItem} ${classes.all} ${!contract ? classes.active : ''}`}
                                onClick={() => handleListItem(null)}
                            >
                                All
                            </div>
                        </li>
                    )}

                    {sortBy(currentList, 'name')
                        .filter((i) => i.name.toLowerCase().includes(searchValue.toLowerCase()))
                        .map((item) => (
                            <li key={item.id} className={classes.listContainer}>
                                <div
                                    className={`${classes.listItem} ${item.uuid === project?.uuid || item.uuid === contract?.uuid ? classes.active : ''}`}
                                    onClick={() => handleListItem(item)}
                                >
                                    {item.name}
                                    {item.children && (
                                        <Icon name={'header-arrow-right'} size={'1.8rem'} color={'#8FA3C3'} />
                                    )}
                                </div>
                            </li>
                        ))}
                </ul>
                <hr className={classes.separator} />
                <div className={classes.addNewButton} onClick={() => onCreateNewProjectOrSubProgram()}>
                    Add New {parentNode && parentNode.uuid !== '0-root' ? 'Sub-Program' : 'Project'}
                </div>
            </div>
        </Dropdown>
    );
};
