import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation } from 'react-query';

const mutationFn = (params: QueryParams) => {
    return network.post(API_URL().GET_USER_EXT_DOMAIN, { username: params.username }).then((response) => {
        return response.data;
    });
};
export const useMutationGetUserDomain = () => {
    return useMutation<QueryResponse, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    username: string;
}

interface QueryResponse {
    domain: string;
}
