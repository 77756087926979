import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useMutation } from 'react-query';

const mutationFn = (params: QueryParams) => {
    const url = API_URL().EXPORT_NEW_VERSION;
    return network.post(url, params).then((response) => response.data);
};

export const useMutationExportNewVersion = () => {
    return useMutation<unknown, QueryError, QueryParams>({ mutationFn: (params) => mutationFn(params) });
};

interface QueryParams {
    metaDataId: number;
    contractId: number;
    projectId: number;
    versionName: string;
    versionDate: number;
    key: string;
}
