import { useQueryGetProgramGeneralHierarchyList } from 'api/queries/getProgramGeneralHierarchyList.query';
import { useQueryVersionList } from 'api/queries/versionList.query';
import { useCopyVersionStore } from 'store/copyVersion.store';

export const useVersionDetails = () => {
    const { versionHashCode } = useCopyVersionStore();
    const { data: version, isLoading: isLoadingState } = useQueryVersionList({
        hashList: versionHashCode ? [versionHashCode] : [],
    });
    const { data: projectHierarchyList, refetch: refetchProjectHierarchyList } =
        useQueryGetProgramGeneralHierarchyList('ProgramLibraryUploadFiles');

    return {
        projectHierarchyList,
        refetchProjectHierarchyList,
        isLoadingState,
        version: version && version.length > 0 ? version[0] : null,
    };
};
