import { Data } from 'components/common/CustomizedStatusBar/CustomizedStatusBar';
import colorsVars from 'styles/colors.module.scss';
import { IStatus } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
const { todoCardBackground, inProgressCardBackground, doneCardBackground } = colorsVars;

export const getDataForStatusBar = (data: IStatus['label'][]): Data => {
    const obj: Data = {
        done: {
            label: 'Done',
            color: doneCardBackground,
            count: 0,
        },
        inProgress: {
            label: 'In Progress',
            color: inProgressCardBackground,
            count: 0,
        },
        todo: {
            label: 'To Do',
            color: todoCardBackground,
            count: 0,
        },
    };

    data.forEach((item) => {
        switch (item) {
            case 'To Do':
                obj.todo.count = obj.todo.count + 1;
                break;
            case 'In Progress':
                obj.inProgress.count = obj.inProgress.count + 1;
                break;
            case 'Done':
                obj.done.count = obj.done.count + 1;
                break;
        }
    });

    return obj;
};
