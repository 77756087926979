import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import Icon from '../Icons/icon';
import classes from './customizedModalBase.module.scss';
import { customScroll } from 'styles/variables';
import colorsVars from 'styles/colors.module.scss';

const { modalBaseBackground, textColor, modalBaseBorderColor, white, black } = colorsVars;

interface CustomizedModalProps {
    isModalOpen: boolean;
    handleModalClose: () => void;
    modalStyles?: Record<string, unknown>;
    isModalBlock?: boolean;
    rootStyles?: Record<string, unknown>;
    stickyLightTheme?: boolean;
    children: React.ReactNode;
}

export const CustomizedModalBase = ({
    isModalOpen,
    handleModalClose,
    children,
    modalStyles,
    isModalBlock = false,
    rootStyles,
    stickyLightTheme = false,
}: CustomizedModalProps) => {
    const handleClose = () => {
        if (!isModalBlock) {
            handleModalClose();
        }
    };

    return (
        <Dialog
            sx={{
                '.MuiDialog-paper': {
                    overflow: 'visible',
                    borderRadius: '2rem',
                    border: `solid 1px ${modalBaseBorderColor}`,
                    backgroundColor: stickyLightTheme ? white : modalBaseBackground,
                    padding: '8px',
                    color: stickyLightTheme ? black : textColor,
                    ...modalStyles,
                },
                '.MuiDialogContent-root': {
                    overflow: 'visible',
                    ...customScroll,
                    ...rootStyles,
                },
            }}
            onClose={handleClose}
            maxWidth={'xl'}
            open={isModalOpen}
            disableScrollLock
            transitionDuration={350}
            disableEscapeKeyDown={isModalBlock}
            closeAfterTransition={false}
        >
            <DialogTitle>
                <div onClick={handleClose} id={'closeModalIcon'} className={classes.closeModalButton}>
                    <Icon name={'filter-x-icon'} size={'2.4rem'} />
                </div>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};
