import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import {
    firstEnterUpdateProjectTreeStore,
    ProjectTreeNode,
} from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { useProjectTreeStore } from 'store/projectContract.store';

export const getGeneralList = () => {
    const project = useProjectTreeStore.getState().project;
    const contract = useProjectTreeStore.getState().contract;
    const update = useProjectTreeStore.getState().update;

    return network
        .get(API_URL().FETCH_PROGRAM_GENERAL_HIERARCHY_LIST)
        .then((response) => {
            firstEnterUpdateProjectTreeStore({ project, contract, update, data: response.data });

            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const useQueryGetGeneralHierarchyList = () => {
    return useQuery<ProjectTreeNode, QueryError>(['getGeneralHierarchyList'], () => getGeneralList());
};
