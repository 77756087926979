import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { Controller } from 'react-hook-form';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import React, { useEffect } from 'react';
import { useQueryState } from 'hooks/useQueryState';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import produce from 'immer';

interface Props {
    methods?: any;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<any>>;
    handleFilters?: () => void;
    className?: string;
}

export type ProductivityPreDefinedFilter =
    | 'did_over_performed'
    | 'expect_over_performed'
    | 'did_under_performed'
    | 'expect_under_performed'
    | 'activities_completed'
    | 'activities_missed';

export const productivityPreDefinedFilterOptions: ISelectOption<ProductivityPreDefinedFilter>[] = [
    { label: 'Over Performed (Past Actual greater than Past Planned)', value: 'did_over_performed' },
    { label: 'Under Performed (Past Actual less than Past Planned)', value: 'did_under_performed' },
    { label: 'Expected To Over Perform (Future Planned less than Past Actual KPI)', value: 'expect_over_performed' },
    {
        label: 'Expected To Under Perform (Future Planned greater than Past Actual KPI)',
        value: 'expect_under_performed',
    },
    { label: 'Completed Activities', value: 'activities_completed' },
    { label: 'Missed Activities', value: 'activities_missed' },
];

export const ProductivityPreDefinedFilter = ({ methods, name, setState, handleFilters, className }: Props) => {
    const [queryStringValue] = useQueryState(name);
    const { getValueFromStore } = useGlobalFilterDataStore();
    const storeValue = getValueFromStore<Range>(name);

    const formValue = methods?.getValues(name);
    const isValue = formValue || queryStringValue || storeValue;

    useEffect(() => {
        if (setState) {
            if (!isValue) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft[name].isReady = true;
                        draft[name].isEmpty = true;
                        return draft;
                    });
                });
            }
            if (isValue) {
                methods?.setValue(name, isValue);
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft[name].isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, setState]);

    const handleChange = (option: ISelectOption<string> | null) => {
        methods?.setValue(name, option ? option.value : null);
        handleFilters && handleFilters();
    };

    const handleValue = (value) => {
        if (!value) {
            return null;
        }
        return productivityPreDefinedFilterOptions.find((item) => item.value === isValue) || null;
    };

    return (
        <div className={`${classes.selectWrapper} ${className ?? ''}`}>
            <span className={classes.title}>Quick Answers</span>
            <div className={classes.activityCodesSelectWrapper}>
                <Controller
                    render={({ field }) => (
                        <MyCustomSelect<ISelectOption<ProductivityPreDefinedFilter>>
                            {...field}
                            options={productivityPreDefinedFilterOptions}
                            id={'global-filter-component-productivity-pre-defined-filter'}
                            onChange={handleChange}
                            value={handleValue(field.value)}
                            isClearable={true}
                        />
                    )}
                    name={name}
                    control={methods?.control}
                />
            </div>
        </div>
    );
};
