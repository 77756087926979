import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesTable.module.scss';
import React, { useEffect, useState } from 'react';
import { IRowsBoard } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { CustomizedButton } from 'components/common';
import { useVersionStore } from 'store/version.store';
import { SimulatedActivitiesTabs } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivityTabs/simulatedActivitiesTabs';
import SimulatedTaskActivitiesTable from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedTaskActivitiesTable/SimulatedTaskActivitiesTable';
import SimulatedWbsTable from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedWbsTable/SimulatedWbsTable';
import { useSimulatedActivitiesFiltersStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesFilter/simulatedActivitiesFilter.store';
import {
    SettingsAndWarnings,
    SimulationWarning,
} from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulationWarning/SimulationWarning';
import { useSimulationCacheKeyStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/simulationCacheKey.store';
import { SimulationNote } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulationNote/SimulationNote';
import { useQueryClient } from 'react-query';
import { queryKey } from 'api/queries/getSchedulingSimulationResults.query';

interface Props extends Omit<IRowsBoard, 'filterData'> {
    simulationDate: number;
    handleBackClick: () => void;
}

const SimulatedTables = ({ projectId, contractId, handleBackClick, simulationDate }: Props) => {
    const currentVersion = useVersionStore((state) => state.version);
    const metaDataId = currentVersion?.id as number;
    const [notification, setNotification] = useState<SettingsAndWarnings | null>(null);
    const [note, setNote] = useState<string>('');
    const { activeTab } = useSimulatedActivitiesFiltersStore();
    const { resetSimulationSession, cacheIsReady } = useSimulationCacheKeyStore();
    const queryClient = useQueryClient();

    useEffect(() => {
        return () => {
            resetSimulationSession();
            queryClient.removeQueries({ queryKey, exact: false });
        };
    }, []);

    return (
        <>
            {cacheIsReady && notification && <SimulationWarning notification={notification} />}
            {cacheIsReady && note && <SimulationNote note={note} />}
            <SimulatedActivitiesTabs />

            {activeTab === 'wbs' && (
                <SimulatedWbsTable
                    projectId={projectId}
                    contractId={contractId}
                    simulationDate={simulationDate}
                    metaDataId={metaDataId}
                    setNote={setNote}
                    setNotification={setNotification}
                />
            )}
            {activeTab === 'activities' && (
                <SimulatedTaskActivitiesTable
                    projectId={projectId}
                    contractId={contractId}
                    simulationDate={simulationDate}
                    metaDataId={metaDataId}
                    setNote={setNote}
                    setNotification={setNotification}
                />
            )}

            <div className={classes.footer}>
                <CustomizedButton size={'large'} color={'secondary'} onClick={handleBackClick}>
                    Back
                </CustomizedButton>
            </div>
        </>
    );
};

export default React.memo(SimulatedTables);
