import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import classes from 'components/common/Modals/CustomTableColumns/draggableList.module.scss';
import { CheckboxComponent } from 'components/common/CheckboxComponent/checkboxComponent';
import colorsVars from 'styles/colors.module.scss';
import Icon from 'components/common/Icons/icon';
import { getLabelFromColumnHeader } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal.utils';
const { tableReorderModalCheckbox, colorGray } = colorsVars;

/**
 * Draggable column.
 * @param {string[]}  column - current table.
 * @param {number}  index - column index.
 * @param {(number) => void}  onRemoveItem - remove the column from list handler.
 * @returns  the JSX Component
 * */

export interface IDraggableListItemProps {
    column: any;
    index: number;
    onHideShowItem: (e: React.ChangeEvent, value: string) => void;
}
export const DraggableListItem = ({ column, index, onHideShowItem }: IDraggableListItemProps) => {
    return (
        <Draggable draggableId={column.id} isDragDisabled={column.isDisableReorder} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`${classes.listItem} ${snapshot.isDragging ? classes.draggingListItem : ''}
                    ${column.isDisableReorder ? classes.DisableReorderListItem : ''}`}
                >
                    <div className={classes.listItemWrapper}>
                        <div data-testid={'list-item-title'} className={classes.listItemTitleWrapper}>
                            <CheckboxComponent
                                disabled={column.isDisableVisibility}
                                checked={column.isVisible}
                                onChange={(e) => onHideShowItem(e, column)}
                                sx={{
                                    '& .MuiSvgIcon-root': { fontSize: '2rem' },
                                    color: tableReorderModalCheckbox,
                                    padding: 0,
                                    '&.Mui-checked': { color: tableReorderModalCheckbox },
                                    '&.Mui-disabled': { color: colorGray },
                                }}
                            />

                            <span className={classes.listItemTitle}>{getLabelFromColumnHeader(column.Header)}</span>
                        </div>
                        <Icon
                            name={'reorder-widget'}
                            color={
                                snapshot.isDragging
                                    ? colorsVars.organizeCustomDashboardBlue
                                    : colorsVars.organizeCustomDashboardGray
                            }
                            size={'1.8rem'}
                            title={'Reorder Widget'}
                        />
                    </div>
                </div>
            )}
        </Draggable>
    );
};
