import { last } from 'lodash';
import { IContract, IProject } from 'store/projectContract.store';
import { QueryResponse } from 'api/queries/getProgramGeneralHierarchyList.query';

export const selectLastSubProgram = ({
    projectHierarchyList,
    storedProject,
}: {
    projectHierarchyList: QueryResponse;
    storedProject: IProject;
}): IContract | undefined => {
    const p = projectHierarchyList?.children.find((c) => c.id === storedProject?.id);
    if (p) {
        const contract: any = last(p.children);
        if (contract) {
            return {
                id: contract.id,
                title: contract.name,
                uuid: contract.uuid,
            };
        }
    }
};
