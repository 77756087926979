import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { range } from 'lodash';
import { TDateRangeOption } from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter';

export const monthOptions = moment.months().map((item) => ({ value: moment.utc(item, 'MMMM').month(), label: item }));

export type ValidationRule = true | string;

interface DateRangeValidationProps {
    startDate: number;
    endDate: number;
    minDate: number | undefined;
    maxDate: number | undefined;
}
export const dateRangeValidation = ({
    startDate,
    endDate,
    minDate,
    maxDate,
}: DateRangeValidationProps): ValidationRule => {
    const startYear = moment.utc(startDate).year();
    const startMonth = moment.utc(startDate).month();
    const endYear = moment.utc(endDate).year();
    const endMonth = moment.utc(endDate).month();

    const minStartYear = moment.utc(minDate).year();
    const minStartMonth = moment.utc(minDate).month();
    const maxEndYear = moment.utc(maxDate).year();
    const maxEndMonth = moment.utc(maxDate).month();

    let message = '';
    let condition = true;

    if (startYear === minStartYear) {
        if (startMonth < minStartMonth) {
            message = `Start date cannot be earlier than ${moment(minDate).format(constants.formats.date.fullMonthAndYear)}  `;
            condition = false;
        }
    }

    if (endYear === maxEndYear) {
        if (endMonth > maxEndMonth) {
            message = `End date cannot be after ${moment(maxDate).format(constants.formats.date.fullMonthAndYear)}  `;
            condition = false;
        }
    }

    return condition || message;
};

interface GetInputLabelProps {
    startDate: number | null;
    endDate: number | null;
}
export const getInputLabel = (value: GetInputLabelProps) => {
    if (value.startDate) {
        const fromMonthValue = moment.utc(value.startDate).format('MMMM');
        const fromYearValue = moment.utc(value.startDate).format('YYYY');
        const toMonthValue = moment.utc(value.endDate).format('MMMM');
        const toYearValue = moment.utc(value.endDate).format('YYYY');
        return `From: ${fromMonthValue} ${fromYearValue} To: ${toMonthValue} ${toYearValue}`;
    } else {
        return 'Whole Range';
    }
};

interface GetYearOptionsProps {
    minDate: number | undefined;
    maxDate: number | undefined;
}
export const getYearOptions = ({ minDate, maxDate }: GetYearOptionsProps) => {
    if (!minDate || !maxDate) return [];

    const startYear = moment(minDate).year();
    const endYear = moment(maxDate).year();

    const arr = range(startYear, endYear, 1);
    arr.push(endYear);

    return arr.map((item) => ({
        value: item,
        label: item.toString(),
    }));
};

export interface FromYearValidationProps {
    fromYear: number | undefined;
    toYear: number | undefined;
    dateRangeOption: TDateRangeOption;
}
export const fromYearValidation = ({ dateRangeOption, toYear, fromYear }: FromYearValidationProps): ValidationRule => {
    if (dateRangeOption === 'wholeProject') return true;
    return !(fromYear && toYear && fromYear > toYear) || 'From Year cannot be later than To Year';
};

interface FromMonthValidation {
    dateRangeOption: TDateRangeOption;
    fromYear: number | undefined;
    toYear: number | undefined;
    toMonth: number | undefined;
    fromMonth: number | undefined;
}

export const fromMonthValidation = ({
    dateRangeOption,
    fromYear,
    toYear,
    toMonth,
    fromMonth,
}: FromMonthValidation): ValidationRule => {
    if (dateRangeOption === 'wholeProject') return true;

    return (
        !(
            fromYear !== undefined &&
            toYear !== undefined &&
            toMonth !== undefined &&
            fromMonth !== undefined &&
            fromYear === toYear &&
            toMonth < fromMonth
        ) || 'From Month cannot be later than To Month'
    );
};

interface GetSubmitValueProps {
    dateRangeOption: TDateRangeOption;
    fromYear: number | undefined;
    toYear: number | undefined;
    toMonth: number | undefined;
    fromMonth: number | undefined;
}

export const getSubmitValue = ({ dateRangeOption, fromYear, toYear, toMonth, fromMonth }: GetSubmitValueProps) => {
    const startDate = moment.utc(`${fromMonth ? fromMonth + 1 : 1} ${fromYear}`, 'M yyyy').valueOf();
    const endDate = moment
        .utc(`${toMonth ? toMonth + 1 : 1} ${toYear}`, 'M yyyy')
        .endOf('month')
        .valueOf();

    return {
        startDate: dateRangeOption === 'wholeProject' ? null : startDate,
        endDate: dateRangeOption === 'wholeProject' ? null : endDate,
    };
};
