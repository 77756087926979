import qs from 'qs';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';

const queryFn = ({ contractId }: QueryParams) => {
    const querystring = qs.stringify({ contractId }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().GET_TAGS_PER_CONTRACT}${querystring}`;
    return network.get(url).then((response) => {
        return response.data;
    });
};
export const useQueryGetTagsPerContract = (params: QueryParams) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['getTagsPerContract', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.contractId),
    });
};

interface QueryParams {
    contractId: number | undefined;
}

export interface QueryResponse {
    id: number;
    name: string;
}
