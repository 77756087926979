import classes from 'components/common/Tables/Cells/DateCell.module.scss';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';

interface IDateCellProps {
    latestVersionDate?: number;
    changeMapDate?: number;
    comparedVersion?: number | null;
    isChangeMap?: boolean | null;
    wasLabel?: string;
}

export const DateCell = ({
    latestVersionDate,
    changeMapDate,
    comparedVersion,
    isChangeMap,
    wasLabel = 'Was',
}: IDateCellProps) => {
    const delayTime = changeMapDate && changeMapDate >= 0 ? 'afterTime' : 'beforeTime';

    const date = latestVersionDate || comparedVersion;
    const tooltipContent = !changeMapDate
        ? 'No Change'
        : `${wasLabel}: ${moment(comparedVersion).format(constants.formats.date.default)}`;
    return (
        <div className={classes.dateCell} data-testid={'date-cell'}>
            <div className={classes.date} data-testid={'date'}>
                {date ? moment(date).format(constants.formats.date.default) : 'N/A'}
            </div>

            {isChangeMap && (
                <CustomizedTooltip
                    tooltipPosition={TooltipPosition.Bottom}
                    tooltipContent={tooltipContent}
                    triggerElement={
                        <div className={classes.delay} data-testid={'delay'}>
                            {!changeMapDate ? (
                                <div className={`${classes.delay} ${classes.noChange}`} data-testid={'noChange'}>
                                    No Change
                                </div>
                            ) : (
                                <div className={`${classes.delay} ${classes[delayTime]}`} data-testid={'delayTime'}>
                                    {changeMapDate > 0 && '+'}
                                    {changeMapDate}d
                                </div>
                            )}
                        </div>
                    }
                />
            )}
        </div>
    );
};
