import { create, StateCreator } from 'zustand';

import { IVersion } from 'api/queries/getProjectVersionsList.query';

interface Props {
    version: IVersion | null;
    versionList: IVersion[];
    setVersion: (value: IVersion | null) => void;
    setVersionList: (value: IVersion[]) => void;
}

const state: StateCreator<Props> = (set) => ({
    version: null,
    versionList: [],
    setVersion: (value) => set(() => ({ version: value })),
    setVersionList: (value) => set(() => ({ versionList: value })),
});

export const useVersionStore = create(state);
