import { Controller, UseFormReturn } from 'react-hook-form';
import React, { memo, useEffect, useMemo } from 'react';
import { activityCodeMapperUID } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import produce from 'immer';
import { MenuList, MyCustomSelect, OptionsWithExclude } from 'components/common/MyCustomSelect/myCustomSelect';
import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { FilterLabels } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useQueryState } from 'hooks/useQueryState';
import { IState } from 'components/common/GlobalFilterComponent/GlobalFilterComponent';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import { useParams } from 'react-router-dom';
import { ProgramStatusUrlParams } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import useHashmapConverter from 'hooks/useHashmapConverter';
import { orderBy } from 'lodash';
import { listStyleWithExclude } from 'components/common/MyCustomSelect/myCustomSelect.style';
import { useQueryActivityCodesByVersion } from 'api/queries/activityCodesByVersion.query';

interface IActivityCodesFilterProps {
    methods?: UseFormReturn<any, any>;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<IState>>;
    handleFilters?: () => void;
}
type Value = { name: number; isExclude: boolean; alias: string | null };

export const ActivityCodesFilter = memo(({ methods, name, setState, handleFilters }: IActivityCodesFilterProps) => {
    const hashcode = Object.values(useParams<ProgramStatusUrlParams>()).filter(Boolean).join(',');
    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;

    const [queryStringValue] = useQueryState('activityCodes');
    const { getValueFromStore } = useGlobalFilterDataStore();
    const storeValue = getValueFromStore<ISelectOption<Value>[]>('activityCodes');

    const { data: activityCodes } = useQueryActivityCodesByVersion({
        latestVersion: latestVersionId,
    });

    const options = useMemo<ISelectOption<Value>[]>(
        () =>
            activityCodes
                ? orderBy(
                      activityCodes.map(activityCodeMapperUID),
                      [(item: any) => item['label'].toLowerCase()],
                      ['asc'],
                  )
                : [],
        [activityCodes],
    );

    const isValue = useMemo(() => {
        if (queryStringValue && Array.isArray(queryStringValue) && queryStringValue.length > 0) {
            return queryStringValue.map((item) => {
                return {
                    ...item,
                    value: { ...item.value, name: Number(item.value.name), isExclude: item.value.isExclude === 'true' },
                };
            });
        } else {
            return storeValue;
        }
    }, [queryStringValue, storeValue]);

    useEffect(() => {
        if (setState) {
            if (!isValue || isValue.length === 0) {
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.activityCodes.isReady = true;
                        draft.activityCodes.isEmpty = true;
                        return draft;
                    });
                });
            }
            if (isValue && isValue.length > 0) {
                methods?.setValue(name, isValue);
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft.activityCodes.isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, setState, methods, name]);

    const handleChange = (value: readonly ISelectOption<Value>[]) => {
        methods?.setValue(name, value);
        handleFilters && handleFilters();
    };

    return (
        <div className={classes.selectWrapper}>
            <span className={classes.title}>{FilterLabels.ACTIVITY_CODES}</span>
            <div data-testid={'activity-codes-filter'} className={classes.activityCodesSelectWrapper}>
                <Controller
                    render={({ field }) => {
                        return (
                            <MyCustomSelect<ISelectOption<Value>, true>
                                {...field}
                                options={options}
                                isMulti
                                components={{
                                    MenuList: MenuList,
                                    Option: OptionsWithExclude,
                                }}
                                isOptionDisabled={() => field.value.length >= 3}
                                value={field.value}
                                onChange={handleChange}
                                placeholder={'Select Activity Codes...'}
                                styles={listStyleWithExclude}
                            />
                        );
                    }}
                    name={name}
                    control={methods?.control}
                />
            </div>
        </div>
    );
});
