import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase } from 'components/common';
import classes from './copyVersionModal.module.scss';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { TreeCrumbs } from 'components/common/TreeCrumbs/TreeCrumbs';
import constants from 'components/common/Constants/constants';
import moment from 'moment-timezone';
import { useMutationCopyVersion } from 'api/mutations/copyVersion.mutation';
import { NewSubProgram } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/copyVersionModal/newSubProgram';
import { useCopyVersionStore } from 'store/copyVersion.store';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import {
    buildContractObj,
    buildProjectObj,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/copyVersionModal/copyVersionModal.utils';
import { Controller, useForm } from 'react-hook-form';
import { IContract, IProject } from 'store/projectContract.store';
import { useVersionDetails } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/RenderLibraryTable/copyVersionModal/useVersionDetails';

interface Props {
    onHideModal: () => void;
}

export interface FormData {
    metaDataId: number | null;
    project: IProject | null;
    contract: IContract | null;
    versionName: string | undefined;
    newSubProgramName: string | undefined;
    contractCheck: string;
}

export const CopyVersionModal = ({ onHideModal }: Props) => {
    const { versionId, showModal } = useCopyVersionStore();
    const { projectHierarchyList, refetchProjectHierarchyList, isLoadingState, version } = useVersionDetails();
    const [isAddContractMode, setIsAddContractMode] = useState<boolean>(false);
    const { mutate: mutateCopyVersion, isLoading: isLoadingCopyVersion } = useMutationCopyVersion();
    const { handleSnackBar } = useCustomSnackBar();
    const history = useHistory();
    const { path } = useRouteMatch();

    const {
        control,
        watch,
        reset,
        setValue,
        getValues,
        trigger,
        formState: { errors },
        setError,
        clearErrors,
        handleSubmit,
    } = useForm<FormData>({
        defaultValues: {
            metaDataId: null,
            project: null,
            contract: null,
            versionName: '',
            contractCheck: '',
            newSubProgramName: '',
        },
    });

    const { project, contract } = watch();

    useEffect(() => {
        const getProjectHierrarchy = async () => {
            await refetchProjectHierarchyList();
        };

        if (version) {
            getProjectHierrarchy();
            reset({
                metaDataId: versionId,
                project: buildProjectObj(version),
                contract: buildContractObj(version),
                versionName: version.versionName,
                contractCheck: '',
                newSubProgramName: `${version.contractName} - Simulations`,
            });
        }
    }, [version, versionId]);

    const onClose = () => {
        onHideModal();
        reset({
            metaDataId: null,
            project: null,
            contract: null,
            versionName: '',
            contractCheck: '',
            newSubProgramName: '',
        });
        setIsAddContractMode(false);
    };

    const handleCopyVersion = (values: FormData) => {
        const { metaDataId, project, contract, versionName } = values;
        if (metaDataId && project && contract && versionName) {
            mutateCopyVersion(
                { metaDataId, projectId: project.id, contractId: contract.id, versionName },
                {
                    onSuccess: () => {
                        handleSnackBar(`Copy version to ${project?.title} > ${contract?.title}`, 'success');
                        const newLocation = generatePath(path, {
                            projectId: project?.id,
                            contractId: contract?.id,
                        });
                        history.push(newLocation);
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || `something went wrong`, 'error');
                    },
                },
            );
        }
        onClose();
    };

    const validateContractCheck = () => {
        if (getValues('contract')?.id === version?.contractId) {
            setError('contractCheck', {
                message: 'Target Sub-Program cannot be the same as source Sub-Program.',
                type: 'validate',
            });
        }
    };

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={onClose}>
            <div className={classes.modalContainer}>
                <form
                    onSubmit={(e) => {
                        validateContractCheck();
                        handleSubmit(handleCopyVersion)(e);
                    }}
                >
                    <h1 className={classes.title}>Copy Version</h1>
                    <h2 className={classes.subTitle}>Copy the selected version to another sub-program</h2>
                    <div className={classes.row}>
                        <label htmlFor={'versionDate'} className={classes.label}>
                            Version Date:
                        </label>
                        <div>
                            {version && (
                                <span id={'versionDate'}>
                                    {version && moment(version.versionDate).format(constants.formats.date.default)}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className={classes.row}>
                        <label htmlFor={'versionName'} className={classes.label}>
                            Version Name:
                        </label>
                        <div>
                            <Controller
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextSearchInput
                                            {...field}
                                            id={'versionName'}
                                            maxLength={100}
                                            className={classes.versionNameInput}
                                            placeholder={'Type Version Name...'}
                                            handleClear={() => setValue('versionName', '')}
                                        />
                                    );
                                }}
                                name={'versionName'}
                                rules={{
                                    required: 'Version Name is Required',
                                    minLength: {
                                        value: 2,
                                        message: 'Version Name is too short',
                                    },
                                    pattern: {
                                        value: /^(?!\s+$).*/,
                                        message: 'Version cannot be empty',
                                    },
                                }}
                            />
                            <div>
                                {errors.versionName && <CustomizedFormErrorMessage text={errors.versionName.message} />}
                            </div>
                        </div>
                    </div>

                    <div className={classes.row}>
                        <label htmlFor={'currentLocation'} className={classes.label}>
                            Current Location:
                        </label>
                        <div id={'currentLocation'}>
                            {project && contract && projectHierarchyList && (
                                <TreeCrumbs
                                    selectedNode={contract.uuid}
                                    data={projectHierarchyList}
                                    isDisabled={true}
                                />
                            )}
                        </div>
                    </div>

                    <div className={classes.row}>
                        <label htmlFor={'copyTo'} className={classes.label}>
                            Copy to:
                        </label>
                        <div id={'copyTo'}>
                            {project && contract && projectHierarchyList && (
                                <NewSubProgram
                                    projectHierarchyList={projectHierarchyList}
                                    refetchProjectHierarchyList={refetchProjectHierarchyList}
                                    setValue={setValue}
                                    getValues={getValues}
                                    trigger={trigger}
                                    control={control}
                                    clearErrors={clearErrors}
                                    version={version}
                                    setError={setError}
                                    isAddContractMode={isAddContractMode}
                                    setIsAddContractMode={setIsAddContractMode}
                                />
                            )}

                            <div>
                                {errors.contractCheck && (
                                    <CustomizedFormErrorMessage text={errors.contractCheck.message} />
                                )}
                                {errors.newSubProgramName && (
                                    <CustomizedFormErrorMessage text={errors.newSubProgramName.message} />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={classes.footer}>
                        <div className={classes.actions}>
                            <CustomizedButton size={'medium'} color={'secondary'} onClick={onClose}>
                                Cancel
                            </CustomizedButton>
                            <CustomizedButton
                                size={'medium'}
                                color={'primary'}
                                name={'copyAndGoToLibrary'}
                                isLoading={isLoadingCopyVersion}
                                type="submit"
                            >
                                Copy & Open Library
                            </CustomizedButton>
                        </div>
                    </div>
                </form>
                {isLoadingState && <OverlayWithSpinner />}
            </div>
        </CustomizedModalBase>
    );
};
