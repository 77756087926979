import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/exportModal/exportSimulationOptions.module.scss';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import { NewVersionOption } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/exportModal/newVersionExportOption';
import { useExportVersionStore } from 'store/exportVersion.store';

export const SimulationExportOptions = () => {
    const { selectedOption, update, currentVersionFileType } = useExportVersionStore();

    const handleExportOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        update({ selectedOption: event.target.value });
    };

    const exportAsXERTitle = `Export as XER`;
    const exportAsMSProjectTitle = `Export as MS-Project XML`;

    const controlProps = (item: string) => ({
        checked: selectedOption === item,
        onChange: handleExportOptionChange,
        value: item,
        name: 'export-option',
    });

    return (
        <div className={classes.exportOptionsContainer}>
            {currentVersionFileType === 'XER' && (
                <>
                    <label>
                        <RadioComponent {...controlProps('SIMULATED')} /> <NewVersionOption />
                    </label>
                    <label>
                        <RadioComponent {...controlProps('XER')} /> {exportAsXERTitle}
                    </label>
                    <label>
                        <RadioComponent {...controlProps('MPP')} /> {exportAsMSProjectTitle}
                    </label>
                </>
            )}
            {currentVersionFileType === 'MPP' && (
                <>
                    <label>
                        <RadioComponent {...controlProps('MPP')} /> {exportAsMSProjectTitle}
                    </label>
                    <label>
                        <RadioComponent {...controlProps('XER')} /> {exportAsXERTitle}
                    </label>
                </>
            )}
        </div>
    );
};
