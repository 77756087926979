import { PreferredSPIMethod } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/PreferredSPIMethod/preferredSPIMethod';

export const mapper = (i) => ({ id: i.id, value: i, label: i.versionName });

export type TDefaultBaseLinePerVersion = 'LATEST_BASELINE' | 'OLDEST_BASELINE';

export const defaultBaseLinePerVersionList: ISelectOption<TDefaultBaseLinePerVersion>[] = [
    {
        value: 'LATEST_BASELINE',
        label: 'Latest Baseline',
    },
    {
        value: 'OLDEST_BASELINE',
        label: 'Oldest Baseline',
    },
];

export const findValue = (defaultBaseLinePerVersion: TDefaultBaseLinePerVersion | null) =>
    defaultBaseLinePerVersionList.find((item) => item.value === defaultBaseLinePerVersion) || null;

export const uniqueActivityKeyList: ISelectOption<string>[] = [
    {
        value: 'ActivityId',
        label: 'Activity Id',
    },
    {
        value: 'ActivityType',
        label: 'Activity Type',
    },
    {
        value: 'Name',
        label: 'Name',
    },
    {
        value: 'ParentName',
        label: 'Parent Name',
    },
    {
        value: 'FullPathParentNames',
        label: 'Full Path Parent Names',
    },
    {
        value: 'Uuid',
        label: 'UUID',
    },
    {
        value: 'WbsLevel',
        label: 'WBS Level',
    },
];

export type TBaselineComparable = 'Planned' | 'Current';

export type TPreferredSPIMethodO = 'CompletionWeighted' | 'CriticalityWeighted';

export const baselineComparableProgressOptions: ISelectOption<TBaselineComparable>[] = [
    {
        value: 'Planned',
        label: 'Planned',
    },
    {
        value: 'Current',
        label: 'Current',
    },
];

export const preferredSPIMethodOptions: ISelectOption<TPreferredSPIMethodO>[] = [
    {
        value: 'CompletionWeighted',
        label: 'Completion Weighted',
    },
    {
        value: 'CriticalityWeighted',
        label: 'Criticality Weighted',
    },
];
