import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { TPreferredSPIMethodO } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferences.utils';

const queryFn = (params: QueryParams) => {
    return network.post(API_URL().GET_HEATMAP_SUMMARY, params).then((response) => {
        return response.data;
    });
};
export const useQueryTimelineAndDensity = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['timelineAndDensity', params],
        queryFn: () => queryFn(params),
    });
};

interface QueryParams {
    filter: {
        filters: IFilter[];
    };
}

export interface IData {
    endDate: number;
    startDate: number;
    score: number;
    percentile: number;
    numOfTasksPerMonth: number;
    numOfDelayDrivers: number;
}

export interface IHeatMapPortfolioProjectResponse {
    id: string;
    data: IData[];
    baselineData: IData[];
    label: string;
    versionHashCode: number;
    completionPercent: number;
    spiCriticality: number;
    spiMilestone: number;
    preferredSpiMethod: TPreferredSPIMethodO | null;
}

export interface QueryResponse {
    heatMapPortfolioProjectResponse: IHeatMapPortfolioProjectResponse[];
    info: IToolTip;
}
