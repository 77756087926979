import classes from './TopHeaderCustomComponent.module.scss';
import React from 'react';
import { pageTitle as pageTitleService } from 'services/pageTitle';
import { useHistory } from 'react-router-dom';
import { TreeCrumbs } from 'components/common/TreeCrumbs/TreeCrumbs';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import { useProjectTreeStore } from 'store/projectContract.store';
import { useQueryGetProgramGeneralHierarchyList } from 'api/queries/getProgramGeneralHierarchyList.query';

type Props = {
    pageTitle: string;
    children?: React.ReactNode;
};

export const TopHeaderCustomComponent = ({ pageTitle, children }: Props) => {
    pageTitle.length > 0 && pageTitleService.set(pageTitle);
    const history = useHistory();
    const { data: projectHierarchyList } = useQueryGetProgramGeneralHierarchyList('TreeProgramFilter');
    const { project: storedProject } = useProjectTreeStore();
    const list = projectHierarchyList
        ? {
              ...projectHierarchyList,
              children: projectHierarchyList?.children.map((item) => ({ ...item, children: null })),
          }
        : undefined;

    const rest: { id?: string } = {};
    if (pageTitle) {
        rest.id = 'page-title';
    }

    return (
        <div className={classes.treeProgramTopHeader}>
            <div className={classes.pageTitle} {...rest}>
                <MuiIcon icon={'arrow_back'} className={classes.back} onClick={() => history.goBack()} />
                {pageTitle}
            </div>
            <div className={classes.headerContent}>
                Project:
                {list && (
                    <TreeCrumbs
                        selectedNode={storedProject?.uuid}
                        data={list}
                        showAddNewButton={false}
                        showAll={false}
                        isDisabled={true}
                    />
                )}
                {children && <div className={classes.actions}>{children}</div>}
            </div>
        </div>
    );
};
