import React, { useCallback, useEffect, useState } from 'react';
import { ChangedActivitiesTableWrapper } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/ChangedActivitiesTable/ChangedActivitiesTableWrapper';
import { SimulatedActivitiesTableWrapper } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesTableWrapper';
import { CustomizedDatePicker } from 'components/common/CustomizedDatePicker/CustomizedDatePicker';
import moment from 'moment-timezone';
import classes from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulationSteps/SimulationSteps.module.scss';
import constants from 'components/common/Constants/constants';
import { useQueryLatestDateOfProgram } from 'api/queries/getLatestDateOfProgram.query';
import { useVersionStore } from 'store/version.store';
import { useSimulatedActivitiesFiltersStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/SimulatedActivitiesFilter/simulatedActivitiesFilter.store';
import { useSubscription } from 'react-stomp-hooks';
import { useMutationRunCpmSimulation } from 'api/mutations/runCpmSimulation.mutation';
import useUserHook from 'hooks/useUserHook';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useSimulationCacheKeyStore } from 'components/Dashboards/ExcellenceApps/ScheduleSimulation/components/SimulatedActivitiesTable/simulationCacheKey.store';
import useEventWithDimensions from 'hooks/useEventWithDimensions';

const STEPS = {
    CURRENT_STATUS: 1,
    SIMULATION: 2,
};

const NOTIFICATION_TYPE = 'CpmCalculationCompletion';

const SimulationSteps = () => {
    const [step, setStep] = useState<number>(STEPS.CURRENT_STATUS);
    const { sendEventWithDimensions } = useEventWithDimensions();
    const [simulationDate, setSimulationDate] = useState<number | null>(null); // Date to use with the simulation API
    const currentVersion = useVersionStore((state) => state.version);
    const {
        update: updateSimulationSession,
        sessionKey,
        cacheIsReady,
        resetSimulationSession,
    } = useSimulationCacheKeyStore();
    const metaDataId = currentVersion?.id as number;
    const { update: updateActivitiesFilter } = useSimulatedActivitiesFiltersStore();
    const versionDate = currentVersion?.versionDate;
    const currentDate = versionDate ? moment(versionDate).format(constants.formats.date.default) : '';
    const { data: latestDateOfProgram, isLoading } = useQueryLatestDateOfProgram(metaDataId);
    const { mutate: mutateRunCpm } = useMutationRunCpmSimulation();
    const { handleSnackBar } = useCustomSnackBar();
    const { email } = useUserHook();

    const restartSteps = () => {
        setStep(STEPS.CURRENT_STATUS);
        updateActivitiesFilter({ activeTab: 'wbs' });
    };

    useSubscription(email ? [`/topic/${email}.notifications.push`] : [], (response) => {
        const { type, message } = JSON.parse(response.body);
        if (type === NOTIFICATION_TYPE) {
            if (message.value === 'CPM calculation successfully completed.') {
                if (message.key === sessionKey) {
                    updateSimulationSession({
                        cacheIsReady: true,
                        isRunning: false,
                    });
                }
            } else {
                handleSnackBar(message.value || 'Something went wrong ', 'error');
                resetSimulationSession();
            }
        }
    });

    const handleRunSimulation = useCallback(() => {
        if (simulationDate) {
            updateSimulationSession({ isRunning: true });
            mutateRunCpm(
                {
                    metaDataId,
                    simulationDate,
                },
                {
                    onSuccess: (response: string) => {
                        updateSimulationSession({ sessionKey: response });
                        sendEventWithDimensions({
                            category: 'Schedule Simulation',
                            action: 'Run simulation',
                            label: 'Run simulation',
                        });
                    },
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                        updateSimulationSession({ isRunning: false });
                    },
                },
            );
        }
    }, [metaDataId, simulationDate]);

    useEffect(() => {
        const today = moment().valueOf();
        if (versionDate && latestDateOfProgram) {
            if (moment(today).isBetween(versionDate, latestDateOfProgram)) {
                setSimulationDate(today);
            } else {
                setSimulationDate(versionDate);
            }
        }
    }, [latestDateOfProgram, currentVersion]);

    useEffect(() => {
        restartSteps();
    }, [currentVersion]);

    useEffect(() => {
        if (cacheIsReady) {
            setStep(STEPS.SIMULATION);
        }
    }, [cacheIsReady]);

    return (
        <div className={classes.simulationPage}>
            <div className={classes.simulationSettings}>
                <div className={classes.dateContainer}>
                    <span className={classes.dateLabel}>Current Data Date:</span>
                    <div className={step === 1 ? classes.currentDateStep1 : ''}>{currentDate}</div>
                </div>
                {!isLoading && versionDate && simulationDate ? (
                    <div className={classes.simulationDateContainer}>
                        <div className={classes.dateContainer}>
                            <span className={classes.dateLabel}>Simulation Data Date:</span>
                            {step === 1 ? (
                                <CustomizedDatePicker
                                    minDate={versionDate}
                                    maxDate={latestDateOfProgram}
                                    value={simulationDate}
                                    handleChange={(value) => {
                                        const dateIsEqualToVersionDate = !moment(value).isAfter(versionDate, 'day'); // versionDate is the minimum date the user can select so there is no date before it
                                        if (dateIsEqualToVersionDate) {
                                            setSimulationDate(versionDate);
                                        } else {
                                            setSimulationDate(value.valueOf());
                                        }
                                    }}
                                />
                            ) : (
                                <span>{moment(simulationDate).format(constants.formats.date.default)}</span>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
            <hr className={classes.horizontalLine} />
            <div>
                {step === STEPS.CURRENT_STATUS && (
                    <ChangedActivitiesTableWrapper handleRunSimulation={handleRunSimulation} />
                )}
                {step === STEPS.SIMULATION && simulationDate !== null && (
                    <SimulatedActivitiesTableWrapper
                        simulationDate={simulationDate}
                        handleBackClick={() => {
                            restartSteps();
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export { SimulationSteps };
