import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import {
    IActivityCode,
    IActivityType,
    IAssignee,
    ICardImportant,
    ICardType,
    ICardUrgent,
    ICategory,
    IProjectContractVersions,
    IStatus,
    IStoredFilters,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { IVersion } from 'api/queries/getProjectVersionsList.query';

const queryFn = (params: QueryParams) => {
    const url = `${API_URL().FETCH_PROGRAM_SCOPE_TO_GO_GET_BOARD_INIT}?metaDataId=${params.metaDataId}`;

    return network.get(url).then((response) => {
        return response.data;
    });
};
export const useQueryGetBoardInit = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['boardInit', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.metaDataId),
    });
};

interface QueryParams {
    metaDataId?: IVersion['id'];
}

export interface QueryResponse {
    activityCode: IActivityCode[];
    assignee: IAssignee[];
    cardActivityType: IActivityType[];
    cardImportant: ICardImportant[];
    cardType: ICardType[];
    cardUrgent: ICardUrgent[];
    category: ICategory[];
    projectContractVersions: IProjectContractVersions[];
    status: IStatus[];
    storedFilters: IStoredFilters[];
    tags: ITagList[];
    fragnetMilestone: {
        id: number;
        activity_id: string;
        name: string;
        activity_status: string;
        activity_type: string;
        preceding_activities: number;
        task_version_hash_code: string;
    }[];
    fragnetCompletion: {
        name: string;
        label: string;
    }[];
    commentRange: CommentRange[];
    startEndDateRange: StartEndDateRange[];
    resourceType: {
        name: string;
        label: string;
    }[];
    cardResource: {
        value: number;
        label: string;
        count: number;
    }[];
    clusters: {
        value: number;
        label: string;
    }[];
}

type CommentRange =
    | 'FIXED_DATE'
    | 'TODAY_REF_LAST_7_DAYS'
    | 'TODAY_REF_LAST_14_DAYS'
    | 'TODAY_REF_LAST_30_DAYS'
    | 'TODAY_REF_LAST_90_DAYS'
    | 'VERSION_DATE_REF_LAST_7_DAYS'
    | 'VERSION_DATE_REF_LAST_14_DAYS'
    | 'VERSION_DATE_REF_LAST_30_DAYS'
    | 'VERSION_DATE_REF_LAST_90_DAYS'
    | 'ANYTIME';

type StartEndDateRange =
    | 'FIXED_DATE'
    | 'TODAY_REF_LAST_7_DAYS'
    | 'TODAY_REF_LAST_14_DAYS'
    | 'TODAY_REF_LAST_30_DAYS'
    | 'TODAY_REF_LAST_90_DAYS'
    | 'TODAY_REF_NEXT_7_DAYS'
    | 'TODAY_REF_NEXT_14_DAYS'
    | 'TODAY_REF_NEXT_30_DAYS'
    | 'TODAY_REF_NEXT_90_DAYS'
    | 'VERSION_DATE_REF_LAST_7_DAYS'
    | 'VERSION_DATE_REF_LAST_14_DAYS'
    | 'VERSION_DATE_REF_LAST_30_DAYS'
    | 'VERSION_DATE_REF_LAST_90_DAYS'
    | 'VERSION_DATE_REF_NEXT_7_DAYS'
    | 'VERSION_DATE_REF_NEXT_14_DAYS'
    | 'VERSION_DATE_REF_NEXT_30_DAYS'
    | 'VERSION_DATE_REF_NEXT_90_DAYS';
