import { useQuery } from 'react-query';
import { QueryKeys } from 'api/queryKeys';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { SortingState } from '@tanstack/react-table';
import { Pagination } from 'components/common/pagination/customPagination';
import {
    IAssignee,
    ICardIndicators,
    ICategory,
    IStatus,
    ITagList,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';
import { ICardLastComment } from 'components/common/Tables/Cells/DetailsCell';
import { CardType } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import { Range } from 'components/common/GlobalFilterComponent/components/RangeFilter/rangeFilter';
import { Aggregation } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourcesAnalysisChart/resourcesAnalysisChart.utils';
import { ResourceType } from 'components/common/GlobalFilterComponent/components/resourceTypeFilter/resourceTypeFilter';
import { QuickAnswerFilter } from 'components/common/GlobalFilterComponent/components/quickAnswerFilter/quickAnswerFilter';
import { IVersion } from 'api/queries/getProjectVersionsList.query';

const queryFn = (params: QueryParams) => {
    return network.post(API_URL().GET_RESOURCE_TABLE, params).then((response) => {
        return response.data;
    });
};

export const useQueryGetResourceTable = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['getResourceTable', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.metaDataId) && Boolean(params.comparedMetaDataId),
        cacheTime: 0,
    });
};

interface QueryParams {
    metaDataId: IVersion['id'] | null;
    comparedMetaDataId: IVersion['id'] | null;
    range: Range | undefined;
    filter: {
        filters: IFilter[];
    };
    resourceId: number | null;
    sortingRequestList: SortingState;
    paging: Pagination;
    barId: string | null;
    resourceType?: ResourceType;
    aggregation: Aggregation;
    quickAnswerFilter?: QuickAnswerFilter;
    startDate: number | undefined;
    endDate: number | undefined;
}

export interface QueryResponse {
    cardList: {
        id: number;
        metaDataId: number;
        criticalityScore: number;
        float: number;
        taskHashCode: string;
        taskVersionHashCode: string;
        plannedStartDate: number;
        plannedFinishDate: number;
        actualStartDate: number;
        actualFinishDate: number | null;
        numOfPredecessors: number;
        numOfSuccessors: number;
        projectWeekStart: number;
        projectWeekFinish: number;
        projectMonthStart: number;
        projectMonthFinish: number;
        status: IStatus;
        originalStatus: IStatus;
        taskActivityId: string;
        parentActivity: string;
        grandParentActivity: string;
        plannedDurationInDays: number;
        actualDurationInDays: number;
        remainingDurationInDays: number;
        completedDurationInDays: number;
        taskActivityType: string;
        isVirtual: boolean;
        category: ICategory;
        title: string;
        startDate: number;
        endDate: number;
        dueDate: number | null;
        duration: number;
        rank: number;
        tagList: ITagList[];
        versionDate: number;
        isStartOverdue: boolean;
        isFinishOverdue: boolean;
        taskId: number;
        startVariance: number;
        finishVariance: number;
        currentDurationOverrun: number;
        completedDurationOverrun: number;
        isInterimChanged: boolean;
        overrunPercent: number;
        assignee: IAssignee | null;
        details: string;
        cardLastComment: ICardLastComment;
        cardIndicators: ICardIndicators;
        submittedDurationComplete: number;
        isTracked: boolean;
        precedingActivities: number;
        succeedingActivites: number;
        precedingRisk: string;
        succeedingRisk: string;
        type: {
            id: number;
            name: CardType;
            label: string;
        };
        baselineStartDate: number;
        baselineFinishDate: number;
        orderInPage: number;
        uniqId: number;
        allBudgetResourceUnit: null;
        allActualResourceUnit: null;
        allResourceNameList: string[];
        actualResourceUnit: number;
        remainingResourceUnit: number;
        resourceName: string;
        resourceType: string;
        comparedBudgetResourceUnit: number;
        isGenAiDetails: boolean;
    }[];
    numOfAllCards: number;
    numOfPages: number;
    info: IToolTip;
}
