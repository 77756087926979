import React, { useEffect, useState } from 'react';
import classes from './PrioritiesMatrix.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { MatrixBox } from 'components/common/PrioritiesMatrix/MatrixBox/MatrixBox';
import NoData from 'components/common/NoData/noData';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { Pills } from 'components/common/pills/pills';
import Icon from 'components/common/Icons/icon';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { IType, IRole, ITypeAndRole } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

import useUserHook from 'hooks/useUserHook';
import { Cards } from 'components/common/PrioritiesMatrix/components/cards/cards';
import { ProgramStatusSummaryReportQueryKeys } from 'components/Dashboards/Project/Components/VersionCompare/queries/tableDataQuery';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';

import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import { QueryResponse as ButtonsInterface } from 'api/queries/getMatrixData.query';
import { QueryResponse as CardsInterface } from 'api/queries/prioritiesMatrixCards.query';

interface Props {
    cards: CardsInterface[] | undefined;
    buttons: ButtonsInterface;
    isCardsLoading: boolean;
    fetchCards: ({ itemId }: { itemId: number }) => void;
    setFilterTitle: (componentType: string) => void;
    selected: ITypeAndRole;
    setSelected: React.Dispatch<React.SetStateAction<ITypeAndRole>>;
    isOverdueFilter: boolean;
    setIsOverdueFilter: React.Dispatch<React.SetStateAction<boolean>>;
    isDelayDriverFilter: boolean;
    setIsDelayDriverFilter: React.Dispatch<React.SetStateAction<boolean>>;
    isCriticalPathFilter: boolean;
    setIsCriticalPathFilter: React.Dispatch<React.SetStateAction<boolean>>;
    isAssignedToMeFilter: boolean;
    setIsAssignedToMeFilter: React.Dispatch<React.SetStateAction<boolean>>;
    linkToBattlecards: string;
}

export const PrioritiesMatrix = ({
    cards,
    buttons,
    isCardsLoading,
    fetchCards,
    setFilterTitle,
    selected,
    setSelected,
    isOverdueFilter,
    setIsOverdueFilter,
    isDelayDriverFilter,
    setIsDelayDriverFilter,
    isCriticalPathFilter,
    setIsCriticalPathFilter,
    isAssignedToMeFilter,
    setIsAssignedToMeFilter,
    linkToBattlecards,
}: Props) => {
    const { email } = useUserHook();
    const { pathname, search } = useLocation();
    const { sendEventWithDimensions } = useEventWithDimensions();

    const [filteredCardsData, setFilteredCardsData] = useState(cards);

    useEffect(() => {
        if (cards) {
            handlePillsFilter();
        }
    }, [cards, isOverdueFilter, isDelayDriverFilter, isCriticalPathFilter, isAssignedToMeFilter]);

    const handleMatrixButtonClick = ({ type, role, itemId }: { type: IType; role: IRole; itemId: number }) => {
        // reset pills filters
        setIsOverdueFilter(false);
        setIsDelayDriverFilter(false);
        setIsCriticalPathFilter(false);
        setIsAssignedToMeFilter(false);
        // ======================================

        const label = `${buttons[type].title} ${buttons[type][role].title}`;
        sendEventWithDimensions({
            category: 'Priorities Matrix',
            action: 'Cell Selection',
            label,
        });
        setSelected({ type, role });
        setFilterTitle(type);
        fetchCards({ itemId });
    };

    const selectedTypeWithRole = buttons[selected.type][selected.role];

    const handleOverdueFilter = () => {
        setIsOverdueFilter(!isOverdueFilter);
    };

    const handleDelayDriverFilter = () => {
        setIsDelayDriverFilter(!isDelayDriverFilter);
    };

    const handleCriticalPathFilter = () => {
        setIsCriticalPathFilter(!isCriticalPathFilter);
    };

    const handleAssignedToMeFilter = () => {
        setIsAssignedToMeFilter(!isAssignedToMeFilter);
    };

    const handlePillsFilter = () => {
        const filtersObj = {
            isOverdue: isOverdueFilter,
            isDelayDriver: isDelayDriverFilter,
            isCriticalPath: isCriticalPathFilter,
        };

        const keys = Object.keys(filtersObj).filter((x) => filtersObj[x]);

        const filteredCards = cards?.filter((item) => {
            return isAssignedToMeFilter
                ? item.assignee.userName === email && keys.every((k) => item?.cardIndicators[k]?.isIndicatorOn)
                : keys.every((k) => item?.cardIndicators[k]?.isIndicatorOn);
        });

        setFilteredCardsData(filteredCards);
    };

    return (
        <div className={classes.PrioritiesMatrix}>
            <div className={classes.matrix}>
                <div className={classes.top}>
                    <div className={classes.first} />
                    <div className={classes.title}>Urgent</div>
                    <div className={classes.title}>Not Urgent</div>
                </div>
                <div className={classes.main}>
                    <div className={classes.title}>
                        <span>Important</span>
                    </div>
                    <div key={buttons.doNowComponent.title} className={`${classes.linksBox} ${classes.doNow}`}>
                        <MatrixBox
                            buttons={buttons.doNowComponent}
                            type={'doNowComponent'}
                            setSelected={handleMatrixButtonClick}
                            selected={selected}
                            number={1}
                        />
                    </div>
                    <div key={buttons.doNextComponent.title} className={`${classes.linksBox} ${classes.doNext}`}>
                        <MatrixBox
                            buttons={buttons.doNextComponent}
                            type={'doNextComponent'}
                            setSelected={handleMatrixButtonClick}
                            selected={selected}
                            number={2}
                        />
                    </div>
                </div>
                <div className={classes.main}>
                    <div className={classes.title}>
                        <span>Not Important</span>
                    </div>
                    <div
                        key={buttons.prioritizeComponent.title}
                        className={`${classes.linksBox} ${classes.prioritize}`}
                    >
                        <MatrixBox
                            buttons={buttons.prioritizeComponent}
                            type={'prioritizeComponent'}
                            setSelected={handleMatrixButtonClick}
                            selected={selected}
                            number={3}
                        />
                    </div>
                    <div key={buttons.laterComponent.title} className={`${classes.linksBox} ${classes.later}`}>
                        <MatrixBox
                            buttons={buttons.laterComponent}
                            type={'laterComponent'}
                            setSelected={handleMatrixButtonClick}
                            selected={selected}
                            number={4}
                        />
                    </div>
                </div>
            </div>
            <div data-testid={'cards'} className={classes.cards}>
                <div data-testid={'cards-header'} className={`${classes.cardsHeader} ${classes[selected.type]}`}>
                    <div className={classes.rowActions}>
                        <span>Preview</span>
                        <Link to={linkToBattlecards}>View in {projectConfig.battlecards.title}</Link>
                    </div>
                    <div data-testid={'cards-header-title'} className={classes.cardsHeaderTitle}>
                        <span className={classes.cardsHeaderTitleType}>{buttons[selected.type].title}</span> |{' '}
                        {selectedTypeWithRole.title} ({selectedTypeWithRole.numOfItem})
                    </div>
                    <div className={classes.pills}>
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Top}
                            tooltipContent={'Overdue'}
                            triggerElement={
                                <Pills
                                    type={'risk'}
                                    className={`${classes.pillWrapper} ${isOverdueFilter ? classes.activeFilter : ''} ${
                                        classes[selected.type]
                                    }`}
                                    onClick={handleOverdueFilter}
                                >
                                    <Icon name="clock" size="1.2rem" color="#334359" />
                                    <span className={classes.pillsTitle}>Overdue</span>
                                    <span>{selectedTypeWithRole.numOfOverdue}</span>
                                </Pills>
                            }
                        />
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Top}
                            tooltipContent={'Delay Drivers'}
                            triggerElement={
                                <Pills
                                    type={'info'}
                                    className={`${classes.pillWrapper} ${
                                        isDelayDriverFilter ? classes.activeFilter : ''
                                    } ${classes[selected.type]}`}
                                    onClick={handleDelayDriverFilter}
                                >
                                    <Icon name="hour-glass" size="1.2rem" color="#334359" />
                                    <span className={classes.pillsTitle}>Delay Drivers</span>
                                    <span>{selectedTypeWithRole.numOfDelayDriver}</span>
                                </Pills>
                            }
                        />
                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Top}
                            tooltipContent={'Critical path'}
                            triggerElement={
                                <Pills
                                    type={'info'}
                                    className={`${classes.pillWrapper} ${
                                        isCriticalPathFilter ? classes.activeFilter : ''
                                    } ${classes[selected.type]}`}
                                    onClick={handleCriticalPathFilter}
                                >
                                    <Icon name="union" size="1.2rem" color="#334359" />
                                    <span className={classes.pillsTitle}>Critical path</span>
                                    <span>{selectedTypeWithRole.numOfCriticalPath}</span>
                                </Pills>
                            }
                        />

                        <CustomizedTooltip
                            tooltipPosition={TooltipPosition.Top}
                            tooltipContent={'Assigned to me'}
                            triggerElement={
                                <Pills
                                    type={'user'}
                                    className={`${classes.pillWrapper} ${
                                        isAssignedToMeFilter ? classes.activeFilter : ''
                                    } ${classes[selected.type]}`}
                                    onClick={handleAssignedToMeFilter}
                                >
                                    <MuiIcon icon={'add_task'} fontSize={'1.5rem'} />
                                    <span className={classes.pillsTitle}>Assigned to me</span>
                                    <span>{selectedTypeWithRole.numOfAssignedToMe}</span>
                                </Pills>
                            }
                        />
                    </div>
                </div>
                <div className={`${classes.cardsContainer} ${isCardsLoading && classes.isLoading}`}>
                    {isCardsLoading && <OverlayWithSpinner />}
                    {filteredCardsData?.length === 0 && <NoData />}
                    {!isCardsLoading && filteredCardsData && (
                        <Cards
                            queryKeys={ProgramStatusSummaryReportQueryKeys}
                            filteredCardsData={filteredCardsData}
                            pathname={pathname}
                            search={search}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
