import { find, head } from 'lodash';
import { prepareVersionSelectTitle } from 'components/common/TreeProgramFilter/VersionSelect/versionSelect.utils';
import { IWidget } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { useQueryGetProjectVersionsList } from 'api/queries/getProjectVersionsList.query';

const compareToPreviousVersion: IWidget['componentKey'][] = [
    'topFinishDateChanges',
    'versionSummary',
    'myVersionCompareTrackedActivities',
    'topStartDateChanges',
    'topStatusChanges',
    'largestAddedActivities',
    'largestDeletedActivities',
    'criticalPathAdded',
    'criticalPathRemoved',
    'largestFloatChanges',
    'largestDurationVariance',
    'topRelationshipsTypeChanges',
    'topRelationshipsCountChanges',
];

type Props = {
    projectId?: number | null;
    contractId?: number | null;
    componentKey: IWidget['componentKey'];
};
export const useCustomLatestCompareToVersions = ({ projectId, contractId, componentKey }: Props) => {
    const { data: versionsList, isLoading } = useQueryGetProjectVersionsList({
        projectId: projectId,
        contractId: contractId,
    });

    if (!isLoading && versionsList) {
        const latestVersion = find(versionsList, { isLatest: true });
        const compareVersion = find(versionsList, { isDesignatedBaseline: true });
        const previousVersion = head(versionsList.filter((item) => !item.isLatest));

        return {
            latestVersionId: latestVersion?.id,
            compareVersionId: compareToPreviousVersion.includes(componentKey)
                ? previousVersion?.id
                : compareVersion?.id,
            latestVersionTitle: prepareVersionSelectTitle(latestVersion),
            compareVersionTitle: prepareVersionSelectTitle(
                compareToPreviousVersion.includes(componentKey) ? previousVersion : compareVersion,
            ),
        };
    }
    return {
        latestVersionId: undefined,
        compareVersionId: undefined,
        latestVersionTitle: '',
        compareVersionTitle: '',
    };
};
