import React, { useEffect, useState } from 'react';
import classes from 'components/Login/login.module.scss';
import { CustomizedFormErrorMessage } from 'components/common';
import { useHistory } from 'react-router-dom';
import Layout from 'components/publicLayout/layout';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { Controller, useForm } from 'react-hook-form';
import { encrypt } from 'components/Login/login.utils';
import {
    useMutationPasswordReset,
    useQueryPasswordPatternData,
} from 'components/Password/queries/useQueryResetPassword';
import { useQueryState } from 'hooks/useQueryState';
import { PasswordInput } from 'components/common/passwordInput/passwordInput';
import { NewButton } from 'components/common/CustomizeButton/newButton';
import { useQueryServerTime } from 'api/queries/serverTime.query';

interface IResetPasswordForm {
    password: string;
    token: string;
    passwordConfirmation: string;
}

export const ResetPassword = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { handleSnackBar } = useCustomSnackBar();
    const history = useHistory();
    const { data: serverTime } = useQueryServerTime();
    const [mail] = useQueryState('mail');
    const [token] = useQueryState('token');
    const [mode] = useQueryState('mode');
    const { data: passwordPatternData, error: patternFetchError } = useQueryPasswordPatternData({
        email: mail as string | undefined,
        token: token as string | undefined,
    });

    const { mutate } = useMutationPasswordReset();

    const {
        handleSubmit,
        formState: { errors },
        watch,
        clearErrors,
        control,
    } = useForm<IResetPasswordForm>({
        defaultValues: {
            password: '',
            passwordConfirmation: '',
        },
    });

    useEffect(() => {
        if (!mail && !token) {
            history.push('/login');
        }
    }, [mail, token]);

    const handleSaveUseForm = (value) => {
        setLoading(true);
        const params = {
            password: value.password,
            token: token,
        };

        const time = serverTime?.time as number;
        const payload = time !== 0 ? { encrypt: encrypt(JSON.stringify(params), time), time } : params;

        mutate(payload, {
            onSuccess: () => {
                handleSnackBar('Password was successfully changed', 'success');
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
            },
            onSettled: () => {
                setLoading(true);
                history.push('/login');
            },
        });
    };

    const disableCopyPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        return false;
    };

    return (
        <Layout>
            <div className={classes.loginFormWrapper}>
                <div className={classes.loginFormTitleWrapper}>{mode === 'reset' ? 'Reset' : 'Set'} your password</div>

                <div className={classes.loginFormSubTitle}>
                    Password pattern{' '}
                    {passwordPatternData
                        ? passwordPatternData.message
                        : 'must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters'}
                </div>

                <form onSubmit={handleSubmit(handleSaveUseForm)} autoComplete="off">
                    <div className={classes.formWrapper}>
                        <div className={classes.inputsContainer}>
                            <div className={classes.inputWrapper}>
                                <label>New Password</label>
                                <Controller
                                    render={({ field }) => (
                                        <PasswordInput
                                            className={`${errors.password ? classes.invalid : ''} ${classes.loginInput}`}
                                            {...field}
                                            placeholder={'Enter New Password'}
                                            onClick={() => clearErrors(['password', 'passwordConfirmation'])}
                                        />
                                    )}
                                    name={'password'}
                                    rules={{
                                        required: 'No password provided.',
                                        pattern: {
                                            value: new RegExp(passwordPatternData?.pattern || ''),
                                            message: 'Password does not meet requirements',
                                        },
                                    }}
                                    control={control}
                                />
                                {errors.password && <CustomizedFormErrorMessage text={errors.password.message} />}
                            </div>
                            <div className={classes.inputWrapper}>
                                <label>Confirm Password</label>
                                <Controller
                                    render={({ field }) => (
                                        <PasswordInput
                                            className={`${errors.passwordConfirmation ? classes.invalid : ''} ${classes.loginInput}`}
                                            {...field}
                                            placeholder={'Enter Confirm Password'}
                                            onClick={() => clearErrors(['password', 'passwordConfirmation'])}
                                            onPaste={disableCopyPaste}
                                            onCopy={disableCopyPaste}
                                        />
                                    )}
                                    name={'passwordConfirmation'}
                                    rules={{
                                        required: 'No Password Confirmation provided.',
                                        validate: (value) =>
                                            value === watch('password') ||
                                            'New Password and Confirm password do not match',
                                    }}
                                    control={control}
                                />
                                {errors.passwordConfirmation && (
                                    <CustomizedFormErrorMessage text={errors.passwordConfirmation.message} />
                                )}
                                {patternFetchError && (
                                    <CustomizedFormErrorMessage
                                        text={
                                            <>
                                                <span>Password reset link has expired. Please contact </span>
                                                <a href="mailto:cs@foresight.works"> support.</a>
                                            </>
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.actionsWrapper}>
                            <NewButton
                                color="login"
                                variant="contained"
                                className={classes.submit}
                                disabled={loading}
                                loading={loading}
                                type={'submit'}
                            >
                                {mode === 'reset' ? 'Reset' : 'Set'}
                            </NewButton>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
};
