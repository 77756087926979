import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/uploadVersionWarningModal.module.scss';
import { CustomizedButton, CustomizedModalBase } from 'components/common';
import { useProgramLibraryStore } from 'store/programLibrary.store';
import { useQueryGetProjectVersions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/queries/useQueryProgramLibrary';
import { useProjectTreeStore } from 'store/projectContract.store';
import Alert from '@mui/material/Alert';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';
import colorsVars from 'styles/colors.module.scss';
const { warning } = colorsVars;

export const UploadVersionWarningModal = () => {
    const { project, contract } = useProjectTreeStore();
    const { data: projectVersions } = useQueryGetProjectVersions({
        projectId: project?.id,
        contractId: contract?.id,
    });
    const showWarningModal = useProgramLibraryStore((state) => state.showWarningModal);
    const details = useProgramLibraryStore((state) => state.details);
    const { update } = useProgramLibraryStore();

    const fileName = details?.file?.name;
    const previousFileType =
        projectVersions && projectVersions.length
            ? projectVersions[projectVersions.length - 1].fileType.toLowerCase()
            : '';
    const latestFileType = details?.type;

    const handleClose = () => {
        update({ details: null, showWarningModal: false });
    };
    const handleContinue = () => {
        update({ showWarningModal: false, modalShow: true });
    };
    return (
        <CustomizedModalBase isModalOpen={showWarningModal} handleModalClose={handleClose}>
            <div className={classes.modalContent}>
                <Alert
                    icon={<MuiIcon icon={'warning_amber'} fontSize={'3.2rem'} color={warning} />}
                    severity={'warning'}
                    sx={{ fontSize: '2.2rem' }}
                >
                    New Version Upload
                </Alert>
                <p className={classes.text}>
                    Latest upload ({fileName}) does not match previous upload file format in this sub-program. Latest is{' '}
                    <strong>{latestFileType}</strong> format vs previous <strong>{previousFileType}</strong> format.
                </p>
                <p className={classes.text}>Would you like to continue with this upload?</p>
                <div className={classes.footer}>
                    <div className={classes.container}>
                        <CustomizedButton size={'medium'} color={'secondary'} onClick={handleClose}>
                            Cancel
                        </CustomizedButton>

                        <CustomizedButton
                            size={'medium'}
                            color={'warning'}
                            component={'label'}
                            onClick={handleContinue}
                        >
                            Continue Upload
                        </CustomizedButton>
                    </div>
                </div>
            </div>
        </CustomizedModalBase>
    );
};
