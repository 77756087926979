import React, { useEffect, useState, useRef } from 'react';
import classes from 'components/common/WidgetWithTitle/WidgetWithTitle.module.scss';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { TooltipIcon } from 'components/common/TooltipIcon/tooltipIcon';

interface Props {
    children: React.ReactNode | React.ReactNode[];
    titleComponents?: React.ReactNode | React.ReactNode[];
    titleFilters?: React.ReactNode | React.ReactNode[];
    title?: React.ReactNode;
    tooltip?: IToolTip;
    className?: string;
    id?: string;
}

export const WidgetWithTitle = ({
    children,
    titleComponents,
    titleFilters,
    title = '',
    tooltip,
    className,
    id,
}: Props) => {
    const [titleComponentsWithProps, setTitleComponentsWithProps] = useState<
        React.ReactNode | React.ReactNode[] | null
    >(null);
    const boundaryRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (boundaryRef?.current) {
            const clonedElements = React.Children.map(titleComponents, (child) => {
                if (React.isValidElement(child)) {
                    return child.type === ExportDropdown ? React.cloneElement(child, { ref: boundaryRef }) : child;
                }
                return child;
            });
            setTitleComponentsWithProps(clonedElements);
        }
    }, [boundaryRef, titleComponents]);

    const titleFiltersArray = titleFilters ? React.Children.toArray(titleFilters) : [];

    return (
        <div
            data-testid={'widget-with-title'}
            className={`${classes.WidgetWithTitle} ${className || ''}`}
            ref={boundaryRef}
            id={id}
        >
            <div className={classes.titleArea}>
                <div data-testid={'title-components'} className={classes.titleComponents}>
                    <h3 id={id ? `${id}_title` : undefined}>{title}</h3>
                    {(tooltip || titleComponentsWithProps) && (
                        <div className={classes.endOfLine}>
                            {titleComponentsWithProps}
                            {tooltip && <TooltipIcon tooltip={tooltip} />}
                        </div>
                    )}
                </div>
                {titleFiltersArray.length > 0 && <div className={classes.titleFilters}>{titleFilters}</div>}
            </div>
            <div data-testid={'area-content'} className={classes.contentArea}>
                {children}
            </div>
        </div>
    );
};
