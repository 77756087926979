import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
    getPaginationRowModel,
    RowModel,
    PaginationState,
} from '@tanstack/react-table';
import NoData from 'components/common/NoData/noData';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classes from 'components/common/Tables/TableV8/tableV8.module.scss';
import { Filter } from './Filter';
import OverlayWithSpinner from 'components/common/OverlayWithSpinner/overlayWithSpinner';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import scssVariables from 'styles/variables.module.scss';
import { TUseReactTableState } from 'hooks/useReactTableState';

const { mainTableMaxHeight } = scssVariables;

const noDataSize = 200;

interface Props<TData> extends Partial<TUseReactTableState> {
    columns: ColumnDef<TData, unknown>[];
    data: TData[];
    defaultColumn?: Partial<ColumnDef<TData>>;
    noData?: ReactElement;
    isLoading?: boolean;
    maxHeight?: number;
    manualSorting?: boolean;
    sortDescFirst?: boolean;
    manualPagination?: boolean;
}

export const TableV8 = <TData,>({
    columns,
    data,
    defaultColumn = { enableColumnFilter: false, enableGrouping: false, size: 150, minSize: 0 },
    noData = <NoData />,
    isLoading = false,
    maxHeight = parseInt(mainTableMaxHeight),
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    columnOrder,
    setColumnOrder,
    manualSorting = true,
    sortDescFirst = false,
    manualPagination = true,
}: Props<TData>) => {
    const ref = useRef<HTMLDivElement>(null);
    const table = useReactTable({
        data,
        columns,
        defaultColumn: { ...defaultColumn },
        filterFns: {},
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        sortDescFirst,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            columnOrder,
        },
        initialState: {
            pagination: {
                pageSize: 50,
                pageIndex: 0,
            },
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
        getPaginationRowModel: getPaginationRowModel(),
        manualSorting,
        manualPagination,
    });
    const { rows } = table.getRowModel();
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const [tableWidth, setTableWidth] = useState<number | string>(0);
    const [tableHeight, setTableHeight] = useState<number | string>(0);

    useEffect(() => {
        if (ref.current) {
            const totalWidth = table.getTotalSize();
            setTableWidth(totalWidth > ref.current.clientWidth ? totalWidth : '100%');
        }
    }, [table.getTotalSize()]);

    useEffect(() => {
        if (tableContainerRef.current && !isLoading) {
            const clientHeight = tableContainerRef.current.clientHeight;
            const currentHeight = data.length === 0 ? noDataSize : Math.min(clientHeight, maxHeight);
            setTableHeight(currentHeight);
        }
    }, [data, isLoading]);

    useEffect(() => {
        const resizeHandler = () => {
            if (ref.current) {
                const totalWidth = table.getTotalSize();
                setTableWidth(totalWidth > ref.current.clientWidth ? totalWidth : '100%');
            }
            if (tableContainerRef.current) {
                const clientHeight = tableContainerRef.current.clientHeight;
                const currentHeight = Math.min(clientHeight, maxHeight);
                setTableHeight(currentHeight);
            }
        };
        addEventListener('resize', resizeHandler);
        return () => {
            removeEventListener('resize', resizeHandler);
        };
    }, []);

    return (
        <div className={classes.MainTable}>
            <div ref={ref}>
                <div
                    className={classes.container}
                    style={{
                        overflow: 'auto', //our scrollable table container
                        position: 'relative', //needed for sticky header
                        height: tableHeight, //should be a fixed height
                        contain: 'strict',
                        minHeight: '150px',
                    }}
                >
                    <div
                        ref={tableContainerRef}
                        style={{
                            width: tableWidth,
                            position: 'relative',
                        }}
                    >
                        <div className={classes.sticky}>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <StickyRow key={headerGroup.id} headerGroup={headerGroup} />
                            ))}
                        </div>
                        <div>
                            {rows.map((row, index) => {
                                return <TableRow key={row.id} row={row} index={index} />;
                            })}
                        </div>
                    </div>
                    {!isLoading && rows.length === 0 && noData}
                    {isLoading && <OverlayWithSpinner />}
                </div>
            </div>
        </div>
    );
};

const StickyRow = ({ headerGroup }) => {
    return (
        <div>
            <div data-testid={'table-header-row'} className={classes.headerRow}>
                {headerGroup.headers.map((header) => {
                    return (
                        <div
                            data-testid={'header-cell'}
                            key={header.id}
                            style={{
                                width: header.getSize() !== 0 ? header.getSize() : 150,
                                flex: header.column.columnDef.meta?.isFixedSize ? 'none' : `${header.getSize()} 0 auto`,
                            }}
                            className={classes.headerCell}
                        >
                            {header.isPlaceholder ? null : (
                                <>
                                    <div
                                        data-testid={'can-sort'}
                                        className={header.column.getCanSort() ? classes.canSort : ''}
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        <span className={`${classes.sort} ${classes[header.column.getIsSorted()]}`}>
                                            {header.column.getIsSorted() && (
                                                <CustomizedTooltip
                                                    tooltipPosition={TooltipPosition.Top}
                                                    tooltipContent={'Toggle Sort By'}
                                                    triggerElement={<Icon name="arrow_up" size="2.4rem" color="#fff" />}
                                                />
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        {header.column.getCanFilter() ? (
                                            <div>
                                                <Filter column={header.column} />
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const TableRow = ({ row, index }) => {
    const { getIsSelected } = row;
    return (
        <div data-testid={'table-row-container'} className={classes.tableRowContainer}>
            <div
                data-testid={'table-row'}
                key={row.id}
                className={`${classes.tableRow} ${getIsSelected() ? classes.selected : ''} ${index % 2 === 0 ? classes.even : classes.odd} ${
                    row.original?.className ?? ''
                }`}
            >
                {row.getVisibleCells().map((cell) => (
                    <div
                        data-testid={'data-cell'}
                        key={cell.id}
                        style={{
                            width: cell.column.getSize() !== 0 ? cell.column.getSize() : 150,
                            flex: cell.column.columnDef.meta?.isFixedSize ? 'none' : `${cell.column.getSize()} 0 auto`,
                        }}
                        className={classes.dataCell}
                    >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                ))}
            </div>
        </div>
    );
};
