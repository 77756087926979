import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { CustomizedRoundIcon, CustomizedTooltip, MyCustomSelect } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import classes from './myPrioritiesWidget.module.scss';
import {
    columns,
    getEndDatesRangeQueryStringValue,
    getRiskIndicatorsQueryStringValue,
    initialState,
    moreOptionsListItems,
    NEXT_DATE_FILTERS,
    statusOptions,
} from 'components/Dashboards/widgets/myPriorities/utils';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import React, { useMemo, useRef, useState } from 'react';
import { IRowsBoard } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import {
    pptxTableNarrativeHeaders,
    serializedOneLevelDataStructureTableToPPTX,
} from 'components/common/ExportDropdown/exportDropdown.utils';
import { useQueryCardsData } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { useMutationRowsBoardToCsv } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsMutation';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { orderBy } from 'lodash';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import NoData from 'components/common/NoData/noData';
import { Link, useParams } from 'react-router-dom';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import { ProgramStatusUrlParams } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramStatusSummaryReport/programStatusSummaryReportTypes';
import useUserHook from 'hooks/useUserHook';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { List } from 'components/common/ListWrapper/list';
import moment from 'moment-timezone';
import qs from 'qs';
import { useSubscription } from 'react-stomp-hooks';
import { useMutationDownloadCsvFile } from 'api/mutations/downloadCsvFile.mutation';
import { CustomizedStatusBar } from 'components/common/CustomizedStatusBar/CustomizedStatusBar';
import { getDataForStatusBar } from 'components/common/CustomizedStatusBar/CustomizedStatusBar.utils';

export const MyPrioritiesWidget = ({ filterData, projectId, contractId, latestVersionId }: IRowsBoard) => {
    const [status, setStatus] = useState<readonly ISelectOption<string>[]>([
        {
            id: '1',
            value: 'TODO',
            label: 'To Do',
        },
        {
            id: '2',
            value: 'IN_PROGRESS',
            label: 'In Progress',
        },
    ]);
    const [moreFilters, setMoreFilters] = useState<readonly ISelectOption<string>[]>([]);
    const [nextDateValue, setNextDateValue] = useState<ISelectOption<string>>({
        label: 'ALL',
        value: '-1',
    });

    const { username, email } = useUserHook();
    const { id: versionId } = useParams<ProgramStatusUrlParams>();

    const { sendEventWithDimensions } = useEventWithDimensions();
    const tableRef = useRef(null);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const cardsDataFilters = filterData;
    const { data: cards, isLoading } = useQueryCardsData(cardsDataFilters);
    const boundaryRef = useRef<HTMLDivElement>(null);

    const componentKey = 'myPriorities';
    const title = 'My Priorities';
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });

    const getNextDateCalculation = (selectedDateFilter, startDate, endDate) => {
        const periodStart = moment().utc().valueOf();
        const periodEnd = moment().utc().add(selectedDateFilter, 'days').valueOf();
        return moment(startDate).isBetween(periodStart, periodEnd) || moment(endDate).isBetween(periodStart, periodEnd);
    };

    const filteredCards = useMemo(() => {
        const statusArray = status.map((item) => item.value);
        const moreFiltersArray = moreFilters.map((item) => item.value);

        if (cards) {
            const tempCards = cards
                .filter((item) => statusArray.includes(item.status.name))
                .filter((item) => (moreFiltersArray.includes('LateFinishDate') ? item.finishVariance > 0 : true))
                .filter((item) => (moreFiltersArray.includes('LateStartDate') ? item.startVariance > 0 : true))
                .filter((item) =>
                    moreFiltersArray.includes('CriticalPath') ? item.cardIndicators.isCriticalPath.isIndicatorOn : true,
                )
                .filter((item) =>
                    moreFiltersArray.includes('Milestones') ? item.taskActivityType === 'Milestone' : true,
                )
                .filter((item) =>
                    nextDateValue.value !== '-1'
                        ? getNextDateCalculation(nextDateValue.value, item.startDate, item.endDate)
                        : true,
                );
            return orderBy(tempCards, ['rank', 'criticalityScore'], ['asc', 'desc']);
        }

        return [];
    }, [cards, status, moreFilters, nextDateValue]);
    const mutation = useMutationRowsBoardToCsv();

    const pptxData = serializedOneLevelDataStructureTableToPPTX({
        data: filteredCards,
        sortBy: { key: 'endDate', sortOrder: 'asc' },
        headers: pptxTableNarrativeHeaders,
    });

    const [isDownloadCsv, setIsDownloadCsv] = useState<boolean>(false);
    const { mutate: downloadCsvFile } = useMutationDownloadCsvFile();

    useSubscription(email ? [`/topic/${email}.notifications.push`] : [], (response) => {
        const { type, message } = JSON.parse(response.body);
        if (type === 'CsvFileReady') {
            setIsDownloadCsv(false);
            const { fileName } = message;
            if (fileName) {
                downloadCsvFile({ fileName, downloadName: `${title}_${subTitle}` });
                sendEventWithDimensions({
                    category: 'Export',
                    action: 'CSV',
                    label: `${title} ${subTitle}`,
                });
            }
        }
    });

    const handleAPICsvExport = ({ isCompact = false }: { isCompact?: boolean }) => {
        setIsDownloadCsv(true);
        mutation.mutate({
            filter: cardsDataFilters,
            fileName: `${title}_${subTitle}`,
            isCompact: isCompact,
        });
    };

    const updatedInitialState = useTableCurrentState({ initialState, componentKey });

    const titleWithCount = `${title} (${filteredCards.length})`;

    const getLinkToBattlecards = () => {
        const querystring = qs.stringify(
            {
                endDatesRange: getEndDatesRangeQueryStringValue(nextDateValue.value),
                cardActivity: moreFilters.find((i) => i.value === 'Milestones') ? 'START_MILESTONE' : null,
                riskIndicators: moreFilters
                    .filter((i) => i.value !== 'Milestones')
                    .map((item) => getRiskIndicatorsQueryStringValue(item.value))
                    .join(','),
                status: status.map((item) => item.value).join(','),
                assignee: username,
            },
            { addQueryPrefix: true, skipNulls: true, encode: false },
        );

        return `/dashboard/project/${projectConfig.battlecards.link}/${versionId}${querystring}`;
    };

    const statusBarData = getDataForStatusBar(filteredCards.map((item) => item.status.label));

    return (
        <WidgetWithTitle
            id={componentKey}
            title={
                <div className={classes.statusBar}>
                    <div>{titleWithCount}</div>{' '}
                    <div className={classes.statusBarWrapper}>
                        <CustomizedStatusBar data={statusBarData} />
                    </div>
                </div>
            }
            titleComponents={[
                <div className={classes.linkWrapper} key={'Link'}>
                    <Link to={getLinkToBattlecards}>View in {projectConfig.battlecards.title}</Link>
                </div>,
                <CustomizedTooltip
                    key={'tableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <ExportDropdown
                    key={'Export'}
                    title={title}
                    pptxData={pptxData}
                    ref={boundaryRef}
                    subTitle={subTitle}
                    handleAPICsvExport={handleAPICsvExport}
                    handleAPICsvExportCompact={() => {
                        handleAPICsvExport({ isCompact: true });
                    }}
                />,
            ]}
            titleFilters={[
                <div key={'filters'} className={classes.filters}>
                    <div className={classes.selectWrapper}>
                        <div className={classes.title}>Status</div>
                        <MyCustomSelect<ISelectOption<string>, true>
                            value={status}
                            options={statusOptions}
                            onChange={setStatus}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti={true}
                            placeholder={'Select Status...'}
                            components={{
                                Option: InputOption,
                                ValueContainer: ValueContainer,
                            }}
                        />
                    </div>
                    <div className={classes.selectWrapper}>
                        <div className={classes.title}>More filters</div>
                        <MyCustomSelect<ISelectOption<string>, true>
                            value={moreFilters}
                            options={moreOptionsListItems}
                            onChange={(value) => setMoreFilters(value)}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isMulti={true}
                            placeholder={'Select More Filters...'}
                            components={{
                                Option: InputOption,
                                ValueContainer: ValueContainer,
                            }}
                        />
                    </div>

                    <div className={classes.selectWrapper}>
                        <div className={classes.title}>Next</div>
                        <div className={classes.filtersWrapper}>
                            <List<string>
                                onChange={setNextDateValue}
                                options={NEXT_DATE_FILTERS}
                                value={nextDateValue}
                            />
                        </div>
                    </div>
                </div>,
            ]}
        >
            <div className={classes.rowsBoardWrapper} ref={boundaryRef}>
                {updatedInitialState && (
                    <MainTable
                        getInstanceCallback={(instance) => (tableRef.current = instance)}
                        data={filteredCards}
                        columns={columns}
                        initialState={updatedInitialState}
                        uniqueKey={'id'}
                        maxHeight={500}
                        isLoading={isLoading || isDownloadCsv}
                        noData={
                            <NoData>
                                No activities are assigned to you yet. Find and assign activities in{' '}
                                <Link to={`/dashboard/project/${projectConfig.battlecards.link}`}>
                                    {projectConfig.battlecards.title}
                                </Link>
                                .
                            </NoData>
                        }
                    />
                )}
            </div>
            {tableRef?.current && (
                <CustomTableColumnsModal
                    onHideModal={() => setModalColumnsOpen(false)}
                    showModal={modalColumnsOpen}
                    componentKey={componentKey}
                    tableInstance={tableRef.current}
                    initialState={initialState}
                    columns={columns}
                />
            )}
        </WidgetWithTitle>
    );
};
