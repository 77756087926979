import produce from 'immer';
import {
    IDashboard,
    IWidget,
    IPermissionToVewWidget,
    IDeleteWidgetFromDashboard,
    IAddWidgetToDashboard,
    IEditDashboardWidgetDescription,
    IGetCurrentDashboardId,
    IGetPermissionsToViewComponent,
} from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

import { searchInTree, UuidExtensionName } from 'components/common/TreeCrumbs/treeCrumbs.utils';
import { find, lowerCase, startCase } from 'lodash';
import { QueryResponse as ActivityCodesByVersion } from 'api/queries/activityCodesByVersion.query';
import { QueryResponse as GetMilestoneFragnet } from 'api/queries/getMilestoneFragnet.query';

/**
 * Delete widget by widgetId dashboard by dashboardId.
 *
 * @param {IDashboard[]} dashboard - list of all pinboards (get from API like JSON object).
 * @param {string}  widgetId - unique ID created when adding a new widget to a specific pinboard.
 * @returns  updated dashboard
 * */
export const deleteWidgetFromDashboard = ({
    dashboard,
    widgetId,
}: IDeleteWidgetFromDashboard): IDashboard | undefined => {
    if (!widgetId || !dashboard) return undefined;

    return produce(dashboard, (draft) => {
        const filteredWidgets = [...draft.widgets.filter((widget: IWidget) => widget.id !== widgetId)];
        const widgets = filteredWidgets.map(mapperItemBySequence);
        return {
            id: draft?.id,
            name: draft?.name,
            widgets,
        };
    });
};

/**
 * Add new widget to the dashboard by dashboardId.
 *
 * @param {keyof IHashmap}  componentKey - unique string determines with component is rendering.
 * @param {string}  elementId -  unique id determines which tableConfig property to use tableWidgetWrapper (optional only for tables).
 * @param {number}  projectId - widget project ID (optional for pages without project & contract).
 * @param {number}  contractId - widget contract ID (optional for pages without project & contract).
 * @param {IDashboard} dashboard - current dashboard.
 * @param {string}  route - url of original widget place - used in pinboard widgets in link back to original component location in the application.
 * @param {IFilterLegacy[]} filters = list of filters used in the API for fetching data.
 * @param {ILocalFilters}  localFilters - list of filters used to filter fetched data from API in the component.
 * @returns  new list of all pinboards (JSON) using immer
 * */
export const addWidgetToDashboard = ({
    componentKey,
    title,
    projectId,
    contractId,
    elementId,
    dashboard,
    route,
    filters = [],
    localFilters,
}: IAddWidgetToDashboard): IDashboard | undefined => {
    if (!dashboard) return undefined;
    const length = dashboard?.widgets.length;

    const widgets = [
        ...dashboard?.widgets,
        {
            componentKey,
            projectId,
            contractId,
            title,
            route,
            localFilters: JSON.stringify(localFilters),
            payload: JSON.stringify({
                elementId,
                filter: {
                    filters: filters,
                },
            }),
            sequence: length,
        },
    ];
    return {
        id: dashboard?.id,
        name: dashboard?.name,
        widgets,
    };
};
/**
 * Edit widget narrative description by widgetId.
 * @param {string}  dashboardId - unique id of specific dashboard
 * @param {string}  description - widget narrative description .
 * @param {string}  widgetId - unique ID created when adding a new widget to a specific pinboard.
 * @returns  new list of all pinboards (JSON) using immer
 * */
export const editDashboardWidgetDescription = ({
    dashboard,
    description,
    widgetId,
}: IEditDashboardWidgetDescription): IDashboard | undefined => {
    if (!widgetId) return undefined;

    return produce(dashboard, (draft) => {
        const widgets = draft.widgets.map((widget: IWidget) => {
            return widget.id !== widgetId ? widget : { ...widget, description };
        });
        return { ...draft, widgets };
    });
};

/**
 * reorder widgets list
 * @param {<T>[]>} list - list of items
 * @param {number} startIndex - current location index of item in list
 * @param {number} endIndex - destination index of item in list
 */
export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    if (list.length === 0) {
        return [];
    }
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

/**
 * modify string to human friendly format. Example: ACTIVE_CODE => Active Code.
 * @param data
 */
export const widgetFilterTitle = (data?: string | number): string | number | undefined =>
    typeof data === 'string' ? startCase(lowerCase(data)) : data;

/**
 * Print activityCode information by uid
 * @param {string} item
 * @param {ActivityCodesByVersion[]} activityCodes
 * @returns activityCode string.
 */
export const activityCodesTag = ({
    item,
    activityCodes,
}: {
    item: number;
    activityCodes?: ActivityCodesByVersion[];
}): string => {
    const activityCode = activityCodes && find(activityCodes, { uid: item });
    if (!activityCode)
        return 'Selected item not found in this version - Click ‘Open Source Component’ to amend filter.';
    return activityCode?.alias
        ? `${activityCode?.alias}: ${activityCode?.description} `
        : `${activityCode?.scope}: ${activityCode?.typeName}: ${activityCode?.description} `;
};

/**
 * Print milestoneFragnet information by uid
 * @param {string} item
 * @param {GetMilestoneFragnet[]} milestoneFragnet
 * @returns milestoneFragnet string.
 */
export const milestoneFragnetTag = ({
    item,
    milestoneFragnet,
}: {
    item: number;
    milestoneFragnet?: GetMilestoneFragnet[];
}): string => {
    const milestone = milestoneFragnet && find(milestoneFragnet, { task_version_hash_code: String(item) });
    if (!milestone) return 'Selected item not found in this version - Click ‘Open Source Component’ to amend filter.';
    return `${milestone.name} (ID: ${
        milestone.activity_id.length > 20 ? milestone.activity_id.slice(0, 20) + '...' : milestone.activity_id
    }) #${milestone.preceding_activities}`;
};

/**
 * Get specific dashboard.
 * @param {IDashboard[]} dashboards - list of all pinboards (get from API like JSON object).
 * @param  projectHierarchyList -  projects list  user have permission to see.
 * @param {string}  dashboardId - unique id of specific dashboard (optional).
 * @param {string}  selectedDashboardId - dashboardId (optional) from local storage.
 * @returns  current.
 * */
export const getCurrentDashboardId = ({
    dashboards,
    dashboardId,
    selectedDashboardId,
}: IGetCurrentDashboardId): IDashboard | undefined => {
    let current;
    if (dashboardId) current = find(dashboards, { id: Number(dashboardId) });
    if (selectedDashboardId && !current) current = find(dashboards, { id: Number(selectedDashboardId) });
    if (dashboards.length > 0 && !current) current = dashboards[0];
    return current;
};

/**
 * Get Permissions To View Widget Component.
 * @param  projectHierarchyList -  projects list  user have permission to see.
 * @param {number}  projectId - widget project ID (optional for pages without project & contract).
 * @param permission - permission from hashmap (only for pages without project & contract).
 * @returns  boolean if user can see this widget.
 * */
export const getPermissionsToViewComponent = ({
    projectHierarchyList,
    projectId,
    permission,
}: IGetPermissionsToViewComponent): IPermissionToVewWidget => {
    const projectUuid = `${projectId}-${UuidExtensionName.project}`;
    return {
        // if user does not have permission on a specific project - the searchInTree function will not find it and it
        // will return false
        isHavePermissions: permission ?? Boolean(searchInTree(projectHierarchyList, projectUuid)),
    };
};
export const mapperItemBySequence = (item, index) => ({ ...item, sequence: index });
