import { CustomizedButton, CustomizedFormErrorMessage, CustomizedModalBase, MyCustomSelect } from 'components/common';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/AliasPreferences/CreateAlias/createAlias.module.scss';
import {
    useMutationCreateAlias,
    useQueryGetActivityCodesByContract,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQueryAliasPreferences';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { Controller, useForm } from 'react-hook-form';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import { IAliasForm } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/AliasPreferences/alias.utils.type';
import { useMemo } from 'react';
import { orderBy } from 'lodash';
import { useProjectTreeStore } from 'store/projectContract.store';

interface IActivityCodesAliases extends ISelectOption<string> {
    scope: string;
}

export const CreateAlias = ({ showModal, onHideModal, tableData }) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
    } = useForm<IAliasForm>();

    const { contract } = useProjectTreeStore();
    const { data } = useQueryGetActivityCodesByContract({ contractId: contract?.id });
    const { mutate, isLoading } = useMutationCreateAlias();
    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    const activityCodesAliasesDataOptions: IActivityCodesAliases[] = useMemo(() => {
        return data
            ? orderBy(
                  data.map((i, index) => ({
                      id: index,
                      value: i.typeName,
                      scope: i.scope,
                      label: `${i.scope}: ${i.typeName}`,
                  })),
                  [(item: any) => item['label'].toLowerCase()],
                  ['asc'],
              )
            : [];
    }, [data]);

    const handleSave = (value) => {
        mutate(
            {
                alias: value.alias.trim(),
                contractId: contract?.id,
                name: value.activity_code.value,
                scope: value.activity_code.scope,
            },
            {
                onSuccess: () => {
                    handleSnackBar('Alias has Created', 'success');
                    onHideModal();
                    reset();
                },
                onError: (error) => handleSnackBar(error.response.data, 'error'),
                onSettled: () => {
                    return queryClient.invalidateQueries('useQueryGetActivityCodesAliases');
                },
            },
        );
    };

    const handleHideModal = () => {
        reset();
        onHideModal();
    };

    const isActivityCodeExist = (value) => {
        const isDuplicate = tableData.find((item) => {
            return item.scope === value.scope && item.typeName === value.value;
        });

        return isDuplicate ? 'Alias for this Activity code already exist' : true;
    };

    const isAliasExist = (value) => {
        const isDuplicate = tableData.find((item) => {
            return item.alias === value;
        });

        return isDuplicate ? 'Alias name already exist' : true;
    };

    return (
        <CustomizedModalBase isModalOpen={showModal} handleModalClose={handleHideModal}>
            <div className={classes.createAliasContainer}>
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className={classes.title}>Add Alias</div>
                    <div className={classes.subTitle}>Assign an alias to an activity code type:</div>

                    <div className={classes.content}>
                        <div className={classes.selectContainer}>
                            <div className={classes.selectTitle}>Activity Code Type:</div>
                            <div className={classes.selectWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <MyCustomSelect<IActivityCodesAliases>
                                            {...field}
                                            options={activityCodesAliasesDataOptions}
                                            id={'activity-code'}
                                        />
                                    )}
                                    defaultValue={null}
                                    name={'activity_code'}
                                    control={control}
                                    rules={{
                                        validate: {
                                            required: (value) => Boolean(value) || 'Must select an activity Code',
                                            isDuplicate: isActivityCodeExist,
                                        },
                                    }}
                                />
                                {errors.activity_code && (
                                    <CustomizedFormErrorMessage text={errors.activity_code.message} />
                                )}
                            </div>
                        </div>

                        <div className={classes.selectContainer}>
                            <div className={classes.selectTitle}>Alias:</div>
                            <div className={classes.selectWrapper}>
                                <Controller
                                    render={({ field }) => (
                                        <TextSearchInput
                                            {...field}
                                            placeholder={''}
                                            handleClear={() => {
                                                setValue('alias', '');
                                            }}
                                            isSearch={false}
                                        />
                                    )}
                                    defaultValue={''}
                                    name={'alias'}
                                    control={control}
                                    rules={{
                                        required: 'Alias is Required',
                                        minLength: { value: 2, message: 'Alias is to short' },
                                        validate: {
                                            isDuplicate: isAliasExist,
                                        },
                                    }}
                                />
                                {errors.alias && <CustomizedFormErrorMessage text={errors.alias.message} />}
                            </div>
                        </div>
                    </div>

                    <div className={classes.footer}>
                        <div className={classes.container}>
                            <CustomizedButton size={'large'} color={'secondary'} onClick={handleHideModal}>
                                Cancel
                            </CustomizedButton>

                            <CustomizedButton
                                type={'submit'}
                                size={'large'}
                                color={'primary'}
                                isLoading={isLoading}
                                disabled={isLoading}
                            >
                                Save
                            </CustomizedButton>
                        </div>
                    </div>
                </form>
            </div>
        </CustomizedModalBase>
    );
};
