import { DefaultColumnFilter } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryFilter/programLibraryFilter';
import { RowOptionsCell } from 'components/Settings/Components/Tags/components/RowOptionCell';
import { columnsCommonConfig } from 'components/common/Tables/columnsCommonConfig';
import { QueryResponse } from 'api/queries/getProgramGeneralHierarchyList.query';
import { customCaseSensitiveSort } from 'components/Dashboards/Project/Components/VersionCompare/TableColumns/TableColumns.utils';

export const defaultColumn = {
    Filter: DefaultColumnFilter,
};

export const initialState = {
    sortBy: [
        {
            id: 'projectName',
        },
        {
            id: 'name',
        },
    ],
};

export const columns = [
    {
        Header: 'Associated Project',
        accessor: (row) => row.projectName ?? 'Portfolio',
        id: 'projectName',
        width: 400,
        fixedSize: true,
        sortType: customCaseSensitiveSort,
    },

    {
        Header: 'Tag Name',
        accessor: 'name',
        sortType: customCaseSensitiveSort,
    },
    {
        Header: '# Associated Activities',
        accessor: 'count',
        width: 200,
        fixedSize: true,
        Cell: ({ value }) => {
            return value.toLocaleString();
        },
    },
    {
        Header: '',
        ...columnsCommonConfig.rowOptions,
        Cell: ({ row }) => {
            return <RowOptionsCell row={row} />;
        },
    },
];

export const convertHierarchyListToSelectOptions = (
    projectHierarchyList: QueryResponse | undefined,
): ISelectOption<number>[] => {
    if (projectHierarchyList === undefined || projectHierarchyList === null) return [];
    return projectHierarchyList.children.map((project) => ({
        value: project.id,
        label: project.name,
    }));
};
