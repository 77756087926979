import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { QueryKeys } from 'api/queryKeys';
import { Range } from 'components/common/GlobalFilterComponent/components/RangeFilter/rangeFilter';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { Aggregation } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ResourcesAnalysis/Widgets/resourcesAnalysisChart/resourcesAnalysisChart.utils';
import { ResourceType } from 'components/common/GlobalFilterComponent/components/resourceTypeFilter/resourceTypeFilter';
import { QuickAnswerFilter } from 'components/common/GlobalFilterComponent/components/quickAnswerFilter/quickAnswerFilter';

const queryFn = (params) => {
    return network.post(API_URL().GET_RESOURCE_CHART, params).then((response) => response.data);
};

export const useQueryGetResourceChart = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['getResourceChart', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.metaDataId) && Boolean(params.comparedMetaDataId) && Boolean(params.range),
        cacheTime: 0,
    });
};

interface QueryParams {
    metaDataId: number | undefined;
    comparedMetaDataId: number | undefined;
    range: Range | undefined;
    resourceId: number | null;
    startDate: number | null;
    endDate: number | null;
    filter: {
        filters: IFilter[];
    };
    resourceType?: ResourceType;
    aggregation: Aggregation;
    quickAnswerFilter?: QuickAnswerFilter;
}

export interface QueryResponse {
    chart: {
        actualUnit: number;
        budgetUnit: number;
        endDate: number;
        remainingUnit: number;
        startDate: number;
        budgetBarId: string;
        actualBarId: string;
        remainingBarId: string;
    }[];
    endChartDate: number;
    startChartDate: number;
    versionDate: number;
    info: IToolTip;
    budgetKpi: {
        activities: number;
        date: number;
    };
    actualKpi: {
        activities: number;
        date: number;
    };
    remainingKpi: {
        activities: number;
        date: number;
    };
    calculatedVersionDate: number;
}
