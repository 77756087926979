import classes from './CustomizedStatusBar.module.scss';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { CustomTooltip } from 'components/common/CustomizedStatusBar/CustomTooltip/CustomTooltip';
import { Fragment } from 'react';

export type Data = {
    [key: string]: {
        color: string;
        count: number;
        label: string;
    };
};

interface IDataIncludeWidth {
    color: string;
    count: number;
    label: string;
    width: number;
}

interface Props {
    data: Data;
}

export const CustomizedStatusBar = ({ data }: Props) => {
    let total = 0;
    for (const item in data) {
        if (data.hasOwnProperty(item)) {
            total += data[item].count;
        }
    }

    const dataIncludeWidth: IDataIncludeWidth[] = Object.values(data).map((item: Omit<IDataIncludeWidth, 'width'>) => {
        return { ...item, width: (item.count / total) * 100 };
    });

    return total > 0 ? (
        <CustomizedTooltip
            tooltipContent={<CustomTooltip data={dataIncludeWidth} />}
            triggerElement={
                <div className={classes.customizedStatusBarContainer}>
                    {dataIncludeWidth.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                {item.count > 0 && (
                                    <div
                                        key={index}
                                        className={classes.item}
                                        data-testid={'item'}
                                        style={{ width: `${item.width}%`, backgroundColor: item.color }}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            }
        />
    ) : null;
};
