import { create, StateCreator } from 'zustand';
import { Unionize } from 'utilitys/helpers/TsGeneral';
import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import { IContract, IProject } from 'store/projectContract.store';

interface Props {
    name: string | null;
    date: string;
    selectedOption: string | null;
    currentVersionFileType: 'MPP' | 'XER' | null;
    project: IProject | null;
    contract: IContract | null;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}
const initialState: Omit<Props, 'update'> = {
    name: null,
    date: moment().format(constants.formats.date.default),
    selectedOption: null,
    currentVersionFileType: null,
    project: null,
    contract: null,
};

const state: StateCreator<Props> = (set) => ({
    ...initialState,
    update: (value) => set({ ...value }),
});

export const useExportVersionStore = create(state);
