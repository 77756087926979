import classes from './AddCommentWidget.module.scss';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import RichTextArea from 'components/common/richTextArea/richTextArea';
import React, { useState } from 'react';
import {
    ICreateEditCommentPayload,
    useMutationCreateEditComment,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/commentsMutation';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import { useQueryClient } from 'react-query';
import { QueryParams, useQueryGetCommonResources } from 'api/queries/getCommonResources.query';
import { userMentionMapper } from 'components/Dashboards/Project/Components/CustomDashboard/ExternalComponents/editNarrative';
import { removeHtmlTagsFromString } from 'utilitys/helpers/general';
import { sortBy } from 'lodash';
import { useProjectTreeStore } from 'store/projectContract.store';

interface IAddCommentWidget {
    id: number;
    setIsAddComment: React.Dispatch<React.SetStateAction<boolean>>;
    invalidateQueriesKey: string[];
    isVirtual: boolean;
}

const commonResourcesKey: QueryParams['objectsList'] = ['users'];

export const AddCommentWidget = ({ id, setIsAddComment, invalidateQueriesKey, isVirtual }: IAddCommentWidget) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { project } = useProjectTreeStore();
    const { data } = useQueryGetCommonResources({ objectsList: commonResourcesKey, projectId: project?.id });

    const usersMention = sortBy(data?.users?.map(userMentionMapper), 'text') || [];

    const [singleCommentData, setSingleCommentData] = useState<ICreateEditCommentPayload>({
        cardId: id,
        comment: '',
        id: null,
        mentionedList: [],
        isVirtual: isVirtual,
    });

    const { mutate: createEditCommentMutate } = useMutationCreateEditComment();

    const { handleSnackBar } = useCustomSnackBar();
    const queryClient = useQueryClient();

    const handleEditorDescription = (value, mentionedList) => {
        setSingleCommentData({ ...singleCommentData, comment: value, mentionedList });
    };

    const cancelAddCommentMode = () => {
        setSingleCommentData({ ...singleCommentData, comment: '', mentionedList: [] });
        setIsAddComment(false);
    };

    const createComment = () => {
        if (removeHtmlTagsFromString(singleCommentData.comment).length === 0) return;

        const tempSingleCommentData = { ...singleCommentData };
        setIsLoading(true);

        createEditCommentMutate(tempSingleCommentData, {
            onSuccess: () => {
                return Promise.all(invalidateQueriesKey.map((key) => queryClient.invalidateQueries(key))).then(() => {
                    handleSnackBar('Comment Successfully Saved', 'success');
                    setIsAddComment(false);
                    setIsLoading(false);
                });
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Something went wrong', 'error');
                setIsLoading(false);
            },
        });
    };

    return (
        <div className={classes.addCommentWidgetContainer}>
            <div className={classes.actionButtonsWrapper}>
                <div className={classes.actionButtons}>
                    <CustomizedButton color={'secondary'} onClick={cancelAddCommentMode}>
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton color={'primary'} onClick={createComment} isLoading={isLoading}>
                        Add
                    </CustomizedButton>
                </div>
            </div>

            <RichTextArea
                onEditorDescription={handleEditorDescription}
                existingData={''}
                editorContentHeight={150}
                isAutoFocus={true}
                mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: usersMention || [],
                }}
                placeholder={'Enter your comments here. \nType @ to mention another user and notify them about it.'}
            />
        </div>
    );
};
