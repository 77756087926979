import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { useQueryGetActivityCodesAliases } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/queries/useQueryAliasPreferences';
import { CustomizedButton } from 'components/common';
import { CreateAlias } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/AliasPreferences/CreateAlias/createAlias';
import { useState } from 'react';
import MainTable from 'components/common/Tables/MainTable/MainTable';
import classes from './aliasPreferences.module.scss';
import {
    columns,
    initialState,
    defaultColumn,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/SubProgramPreferences/AliasPreferences/aliasPreferences.utils';
import NoData from 'components/common/NoData/noData';
import { useProjectTreeStore } from 'store/projectContract.store';

export const AliasPreferences = (): JSX.Element => {
    const [showCreateAliasModal, setShowCreateAliasModal] = useState<boolean>(false);

    const { contract } = useProjectTreeStore();
    const {
        data: activityCodesAliasesData,
        isLoading,
        isFetching,
    } = useQueryGetActivityCodesAliases({ contractId: contract?.id });

    const handleCreateAlias = () => {
        setShowCreateAliasModal(true);
    };

    const handleHideCreateAliasModal = () => {
        setShowCreateAliasModal(false);
    };

    return (
        <>
            <WidgetWithTitle
                title={'Activity Codes Alias'}
                titleComponents={[
                    <div className={classes.titleActions}>
                        <CustomizedButton onClick={handleCreateAlias}>Add Alias</CustomizedButton>
                    </div>,
                ]}
            >
                <div data-testid={'table-container'} className={classes.tableContainer}>
                    <MainTable
                        columns={columns}
                        data={activityCodesAliasesData?.aliasesList || []}
                        initialState={initialState}
                        defaultColumn={defaultColumn}
                        noData={<NoData>No alias mapping added yet. Click ‘Add Alias’ to add the first one.</NoData>}
                        isLoading={isLoading || isFetching}
                    />
                </div>
            </WidgetWithTitle>

            <CreateAlias
                showModal={showCreateAliasModal}
                onHideModal={handleHideCreateAliasModal}
                tableData={activityCodesAliasesData?.aliasesList}
            />
        </>
    );
};
