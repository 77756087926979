import { Details } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/programLibrary.types';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/programLibraryUploadFiles.module.scss';
import { TreeCrumbs } from 'components/common/TreeCrumbs/TreeCrumbs';
import { CustomizedButton, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';

import SwitchComponent from 'components/common/Switch/Switch';
import { useProjectTreeStore } from 'store/projectContract.store';
import { useQueryGetProgramGeneralHierarchyList } from 'api/queries/getProgramGeneralHierarchyList.query';
import { selectLastSubProgram } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryTable/ProgramLibraryUploadFiles/modalContent/mppModalContent.utils';

interface Props {
    details: Details;
    isUploading: boolean;
    handleFetchData: any;
    onClose: () => void;
}

export const MppModalContent = ({ details: detailsFromFile, isUploading, handleFetchData, onClose }: Props) => {
    const { project: storedProject, contract: storedContract } = useProjectTreeStore();
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [selectedContract, setSelectedContract] = useState<any>(null);
    const { data: projectHierarchyList, isLoading: loadingGeneralList } =
        useQueryGetProgramGeneralHierarchyList('ProgramLibraryUploadFiles');
    const { handleSnackBar } = useCustomSnackBar();

    const onSelectionFinish = ({ project, contract }) => {
        setSelectedProject(project);
        setSelectedContract(contract);
    };

    const { control, handleSubmit, watch, setValue } = useForm<Details>({
        values: {
            ...detailsFromFile,
            isBaseline: false,
            isTransformMPP: false,
        },
    });

    const { fields } = useFieldArray({
        control,
        name: 'versions',
    });
    const details = watch();

    const selectedNode = useMemo(() => {
        return storedContract || selectLastSubProgram({ projectHierarchyList, storedProject });
    }, [storedProject, storedContract, projectHierarchyList]);

    useEffect(() => {
        selectedNode && setSelectedContract(selectedNode);
    }, [selectedNode]);

    useEffect(() => {
        storedProject && setSelectedProject(storedProject);
        storedContract && setSelectedContract(storedContract);
    }, [storedProject, storedContract]);

    const handleFinishUpload = () => {
        if (!selectedContract) {
            handleSnackBar('Sub-Program is mandatory', 'error');
        } else {
            const payload = { details, contract: selectedContract, project: selectedProject };
            handleFetchData(payload);
        }
    };

    const handleIsBaseline = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        setValue('isBaseline', e.target.checked);
        fields.forEach((_, index) => {
            setValue(`versions.${index}.isBaseline`, value, { shouldDirty: true });
        });
    };
    return (
        <form onSubmit={handleSubmit(handleFinishUpload)}>
            <div className={classes.ProgramLibraryUploadFilesContainer}>
                <div className={classes.uploadFilesHeader}>
                    <div className={classes.uploadFilesHeaderSection}>
                        {loadingGeneralList ? (
                            <div>Loading</div>
                        ) : (
                            selectedContract?.uuid && (
                                <TreeCrumbs
                                    selectedNode={selectedContract.uuid}
                                    onSelectionFinish={onSelectionFinish}
                                    data={projectHierarchyList}
                                    showAddNewButton={false}
                                    showAll={false}
                                />
                            )
                        )}
                    </div>
                    <div className={classes.uploadFilesHeaderSection}>
                        <span>Source File:</span>
                        <span className={classes.uploadFilesHeaderName}>
                            <CustomizedTooltip
                                tooltipPosition={TooltipPosition.Center}
                                triggerElement={<div className={classes.ellipsis}>{details.file?.name}</div>}
                                tooltipContent={details.file?.name}
                            />
                        </span>
                    </div>
                </div>

                <div className={classes.content}>
                    <div>
                        Is Baseline / Re-baseline
                        <Controller
                            render={({ field }) => (
                                <SwitchComponent {...field} checked={field.value} onChange={handleIsBaseline} />
                            )}
                            name="isBaseline"
                            control={control}
                        ></Controller>
                    </div>

                    <div>
                        Transform MPP custom columns to Activity Codes
                        <Controller
                            render={({ field }) => <SwitchComponent {...field} />}
                            name="isTransformMPP"
                            control={control}
                        ></Controller>
                    </div>
                </div>

                <div className={classes.uploadFilesButtons}>
                    <CustomizedButton size={'medium'} color={'secondary'} disabled={isUploading} onClick={onClose}>
                        Cancel
                    </CustomizedButton>

                    <CustomizedButton
                        type={'submit'}
                        size={'medium'}
                        color={'primary'}
                        disabled={isUploading}
                        isLoading={isUploading}
                    >
                        Upload
                    </CustomizedButton>
                </div>
            </div>
        </form>
    );
};
