import { CustomizedTooltip } from 'components/common';
import React from 'react';
import { CriticalityScoreContent } from 'components/common/CustomTooltipContent/criticalityScoreContent';

interface Props {
    numOfPredecessors: number | null;
    numOfSuccessors: number | null;
    precedingActivities: number | null;
    succeedingActivites: number | null;
    succeedingRisk: string | null;
    precedingRisk: string | null;
    value: number;
    style?: React.CSSProperties;
}
const CriticalityScoreCell = ({
    numOfPredecessors,
    numOfSuccessors,
    precedingActivities,
    precedingRisk,
    succeedingRisk,
    succeedingActivites,
    value,
    style = {},
}: Props) => {
    return (
        <CustomizedTooltip
            triggerElement={<span style={style}>{value}</span>}
            tooltipContent={
                <CriticalityScoreContent
                    precedingActivities={precedingActivities}
                    succeedingActivites={succeedingActivites}
                    numOfPredecessors={numOfPredecessors}
                    numOfSuccessors={numOfSuccessors}
                    precedingRisk={precedingRisk}
                    succeedingRisk={succeedingRisk}
                />
            }
        />
    );
};

export default React.memo(CriticalityScoreCell);
