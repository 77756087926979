import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import colorsVars from 'styles/colors.module.scss';
import { IData } from 'api/queries/timelineAndDensity.query';

const calcValue = (value: number) => Math.ceil((value * 1000) / 10);

const colors = [
    colorsVars.color1,
    colorsVars.color2,
    colorsVars.color3,
    colorsVars.color4,
    colorsVars.color5,
    colorsVars.color6,
    colorsVars.color7,
    colorsVars.color8,
    colorsVars.color9,
    colorsVars.color10,
];

// ======================================================================================================================
const tooltip = ({
    tooltipLabel,
    numOfDelayDrivers,
    numOfTasksPerMonth,
    value,
}: {
    tooltipLabel: string;
    numOfDelayDrivers: number;
    numOfTasksPerMonth: number;
    value: number;
}) => {
    return `<div style="display: flex; flex-direction: column; gap: 4px">
                <div>${tooltipLabel}</div>
                <div>Total Score: ${value}</div>
                <div>${numOfTasksPerMonth === null ? '0' : numOfTasksPerMonth} tasks</div> 
                <div>${numOfDelayDrivers === null ? '0' : numOfDelayDrivers} delay drivers</div>
             </div>   `;
};

const getTickAmount = (dataLength: number) => {
    const num = dataLength / 20;
    return dataLength / num;
};

interface SerializeDataProps {
    mainData: IData[];
    data: IData[];
}

export const serializeData = ({ mainData, data }: SerializeDataProps) => {
    if (data.length === 0) return [];

    const dataSet = mainData.map((item) => {
        const obj = data.find((i) => i.endDate === item.endDate);
        return {
            x: obj ? moment(obj.endDate).format(constants.formats.date.monthAndYear) : -1,
            y: obj ? calcValue(obj.percentile) : -1,
            numOfTasksPerMonth: obj ? obj.numOfTasksPerMonth : undefined,
            numOfDelayDrivers: obj ? obj.numOfDelayDrivers : undefined,
            label: obj ? moment(obj.endDate).format(constants.formats.date.monthAndYear) : '',
        };
    });

    return [
        {
            name: '',
            data: dataSet,
        },
    ];
};

const getCategories = (data: IData[]) => {
    if (!data) return [];
    return data.map((item) => {
        const monthAndYear = moment(item.startDate).format(constants.formats.date.monthAndYear).split(' ');
        const month = monthAndYear[0];
        const year = monthAndYear[1];
        return [month, year];
    });
};

const calculateXPositionByDate = ({ chartContext, date }: { chartContext: any; date: number }) => {
    const categories: string[] = chartContext.data.twoDSeriesX;
    const formatedDate = moment(date).format('MMM YYYY');
    const index = categories.findIndex((item) => item === formatedDate);
    const gridWidth: number = chartContext.w.globals.gridWidth;
    const length: number = chartContext.w.globals.previousPaths[0].length;
    const boxWidth = gridWidth / length;
    return index ? boxWidth * index + boxWidth / 2 : null;
};

interface SerializeOptionsProps {
    mainData: IData[];
    themeStatus: boolean;
    showXaxisLabels: boolean;
    callback: ((value: number | null) => void) | undefined;
    todayLineDate: number | undefined;
    isShowDensity: boolean;
    isBaseline: boolean;
}

export const serializeOptions = ({
    mainData,
    themeStatus,
    showXaxisLabels = false,
    callback,
    todayLineDate,
    isShowDensity,
    isBaseline,
}: SerializeOptionsProps): ApexCharts.ApexOptions => {
    const categories = getCategories(mainData);
    const defaultRangeColor = [
        {
            color: themeStatus ? '#000000' : '#ffffff',
            from: -1,
            to: -1,
        },
        {
            color: colorsVars.defaultDensityColor,
            from: 0,
            to: 100,
        },
    ];
    const baselineRangeColor = [
        {
            color: themeStatus ? '#000000' : '#ffffff',
            from: -1,
            to: -1,
        },
        {
            color: colorsVars.defaultBaselineDensityColor,
            from: 0,
            to: 100,
        },
    ];
    const densityRangeColor = [
        {
            color: themeStatus ? '#000000' : '#ffffff',
            from: -1,
            to: -1,
        },
        {
            color: colors[0],
            from: 0,
            to: 10,
        },
        {
            color: colors[1],
            from: 11,
            to: 20,
        },
        {
            color: colors[2],
            from: 21,
            to: 30,
        },
        {
            color: colors[3],
            from: 31,
            to: 40,
        },
        {
            color: colors[4],
            from: 41,
            to: 50,
        },
        {
            color: colors[5],
            from: 51,
            to: 60,
        },
        {
            color: colors[6],
            from: 61,
            to: 70,
        },
        {
            color: colors[7],
            from: 71,
            to: 80,
        },
        {
            color: colors[8],
            from: 81,
            to: 90,
        },
        {
            color: colors[9],
            from: 91,
            to: 100,
        },
    ];

    const defaultRangeColorLogic = isBaseline ? baselineRangeColor : defaultRangeColor;

    return {
        grid: {
            show: false,
            padding: {
                top: showXaxisLabels ? 0 : -30,
                right: 0,
                bottom: -30,
                left: 0,
            },
        },
        chart: {
            type: 'heatmap',
            background: themeStatus ? '#000000' : '#ffffff',
            foreColor: themeStatus ? '#FEFEFE' : '#000000',
            toolbar: {
                show: false,
            },
            events: {
                click: undefined,
                mounted(chartContext) {
                    if (showXaxisLabels && callback && todayLineDate) {
                        callback(calculateXPositionByDate({ chartContext, date: todayLineDate }));
                    }
                },
                animationEnd(chartContext) {
                    if (showXaxisLabels && callback && todayLineDate) {
                        callback(calculateXPositionByDate({ chartContext, date: todayLineDate }));
                    }
                },
            },
        },
        xaxis: {
            type: 'category',
            categories: categories,
            tickAmount: mainData ? getTickAmount(mainData.length) : undefined,
            labels: {
                show: showXaxisLabels,
                rotate: 0,
            },
            position: 'top',
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            show: false,
        },
        stroke: {
            width: 0.5,
            colors: themeStatus ? ['#000000'] : ['#ffffff'],
        },
        states: {
            active: {
                filter: {
                    type: 'none',
                },
            },
        },
        plotOptions: {
            heatmap: {
                enableShades: false,
                colorScale: {
                    ranges: isShowDensity ? densityRangeColor : defaultRangeColorLogic,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            hideEmptySeries: true,
            theme: themeStatus ? 'dark' : 'light',
            y: {
                title: {
                    formatter: () => '',
                },
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                    const currentData = w.config.series[seriesIndex].data[dataPointIndex];
                    if (value === -1) return '';
                    return tooltip({
                        tooltipLabel: currentData.label,
                        numOfDelayDrivers: currentData.numOfDelayDrivers,
                        numOfTasksPerMonth: currentData.numOfTasksPerMonth,
                        value: value,
                    });
                },
            },
        },
    };
};
