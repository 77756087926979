import classes from './MainContent.module.scss';
import { CustomizedButton } from 'components/common/CustomizeButton/CustomizedButton';
import React, { useEffect } from 'react';
import CustomizedDateRangePicker from 'components/common/CustomizedDateRangePicker/CustomizedDateRangePicker';
import { getDateRange } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/helper';
import defaultArrowImage from 'styles/assets/svg/defualtArrow.svg';
import moment from 'moment-timezone';
import { TabOptions, TSideOption } from 'components/common/CustomFilters/DateRangeFilter/DateRangeFilter.utils';
import constants from 'components/common/Constants/constants';

interface IMainContentProps {
    rangeDate: number[];
    setRangeDate: React.Dispatch<React.SetStateAction<number[]>>;
    activeTab: TabOptions;
    setActiveTab: React.Dispatch<React.SetStateAction<TabOptions>>;
    activeSideMenuOption: string;
    setActiveSideMenuOption: React.Dispatch<React.SetStateAction<string>>;
    onApply: (value: number[] | string, label?: string) => void;
    menuOptions: TSideOption<string>[];
    existingData: { value?: string[] | string; type?: string };
    handlePopoverClose: () => void;
}

export const MainContent = ({
    rangeDate,
    setRangeDate,
    activeTab,
    setActiveTab,
    activeSideMenuOption,
    setActiveSideMenuOption,
    onApply,
    menuOptions,
    existingData,
    handlePopoverClose,
}: IMainContentProps) => {
    useEffect(() => {
        if (existingData.value?.length === 2) {
            setActiveTab('FIXED_DATE');
            setActiveSideMenuOption('FIXED_DATE');
            setRangeDate(Array.isArray(existingData.value) ? existingData.value.map(Number) : []);
        }
    }, []);

    useEffect(() => {
        if (activeTab === 'FIXED_DATE') {
            const option = menuOptions.find((item) => item.value === activeSideMenuOption);
            setRangeDate(option && Array.isArray(option.dateValue) ? option.dateValue : []);
        }
    }, [activeTab, activeSideMenuOption]);

    const handleSelection = (value) => {
        setRangeDate(getDateRange(value));
    };

    const handleApply = () => {
        if (activeTab === 'FIXED_DATE') {
            const label = `${moment(rangeDate[0]).format(constants.formats.date.default)} - ${moment(rangeDate[1]).format(constants.formats.date.default)}`;
            onApply(rangeDate, label);
        } else {
            const label = menuOptions.find((item) => item.value === activeSideMenuOption)?.inputLabel;
            onApply(activeSideMenuOption, label);
        }
    };

    const isApplyButtonDisable =
        (activeTab === 'FIXED_DATE' && rangeDate.length === 2 && rangeDate.some((item) => isNaN(item))) ||
        (activeTab === 'FIXED_DATE' && rangeDate.length === 0);

    const dateRangeStart = !isNaN(rangeDate[0]) ? moment(rangeDate[0]).format(constants.formats.date.default) : '';

    const dateRangeEnd = !isNaN(rangeDate[1]) ? moment(rangeDate[1]).format(constants.formats.date.default) : '';

    return (
        <div className={classes.mainContentContainer}>
            <div className={classes.content}>
                {activeTab === 'FIXED_DATE' ? (
                    <CustomizedDateRangePicker id={'date-range'} data={rangeDate} onSelection={handleSelection} />
                ) : (
                    <div className={classes.defaultContent}>
                        <div className={classes.title}>Choose a reference range</div>
                        <div className={classes.subTitle}>No exact date selection is needed in reference</div>
                        <img className={classes.imageWrapper} src={defaultArrowImage} alt={'arrow'} />
                    </div>
                )}
            </div>
            <div className={classes.footer}>
                <div className={classes.selectedValue}>
                    {activeTab === 'FIXED_DATE' && <>Selected Dates: {`${dateRangeStart} - ${dateRangeEnd}`}</>}
                </div>
                <div className={classes.actionButtons}>
                    <CustomizedButton size={'large'} color={'secondary'} onClick={handlePopoverClose}>
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton
                        size={'large'}
                        color={'primary'}
                        onClick={handleApply}
                        disabled={isApplyButtonDisable}
                    >
                        Apply
                    </CustomizedButton>
                </div>
            </div>
        </div>
    );
};
