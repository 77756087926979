import classes from 'components/Settings/Components/UserProfile/components/userProfile.module.scss';

export const ForceLogout = ({ handleForceLogout }) => {
    return (
        <div className={classes.adminActionWrapper}>
            <span className={classes.changePasswordTitle} onClick={() => handleForceLogout()}>
                Force Logout
            </span>
        </div>
    );
};
