import classes from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.module.scss';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import { Control, Controller, UseFormClearErrors, UseFormWatch } from 'react-hook-form';
import { MyCustomSelect } from 'components/common/MyCustomSelect/myCustomSelect';
import {
    fromYearValidation,
    fromMonthValidation,
    monthOptions,
    dateRangeValidation,
} from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.utils';
import { CustomizedFormErrorMessage } from 'components/common/CustomizedFormErrorMessage/CustomizedFormErrorMessage';
import React from 'react';
import { IFields, TDateRangeOption } from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import moment from 'moment-timezone';

interface Props {
    dateRangeOption: TDateRangeOption;
    setDateRangeOption: (value: TDateRangeOption) => void;
    control: Control<IFields, any>;
    yearOptions: ISelectOption<number>[];
    errors: FieldErrors<IFields>;
    clearErrors: UseFormClearErrors<IFields>;
    watch: UseFormWatch<IFields>;
    minDate: number | undefined;
    maxDate: number | undefined;
}

export const DateRangeFilterContent = ({
    dateRangeOption,
    setDateRangeOption,
    control,
    yearOptions,
    errors,
    clearErrors,
    watch,
    minDate,
    maxDate,
}: Props) => {
    const selectDateRangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selection = event.target.value as TDateRangeOption;
        if (selection === 'wholeProject') {
            clearErrors();
        }
        setDateRangeOption(selection);
    };

    const controlProps = (
        item: TDateRangeOption,
    ): {
        checked: boolean;
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
        value: TDateRangeOption;
        name: string;
    } => ({
        checked: dateRangeOption === item,
        onChange: selectDateRangeOption,
        value: item,
        name: 'date-range-option',
    });

    return (
        <div className={classes.mainContent}>
            <div className={classes.sectionWrapper}>
                <RadioComponent {...controlProps('dateRange')} id={'dateRange'} />
                <div className={classes.section}>
                    <div className={classes.title}>
                        <label htmlFor={'dateRange'}>From:</label>
                    </div>
                    <div className={classes.selectionWrapper}>
                        <div data-testid={'monthfrom-date-single-selection'} className={classes.singleSelection}>
                            <label>Month</label>
                            <Controller
                                render={({ field }) => (
                                    <MyCustomSelect<ISelectOption<number>>
                                        {...field}
                                        options={monthOptions}
                                        isDisabled={dateRangeOption === 'wholeProject'}
                                    />
                                )}
                                name="fromMonth"
                                control={control}
                                rules={{
                                    required: 'From month is Required',
                                    validate: {
                                        isFromMonthValid: (value) =>
                                            fromMonthValidation({
                                                dateRangeOption,
                                                fromMonth: value?.value,
                                                toMonth: watch('toMonth.value'),
                                                fromYear: watch('fromYear.value'),
                                                toYear: watch('toYear.value'),
                                            }),
                                        isDateRangeValid: () => {
                                            if (dateRangeOption === 'wholeProject') return;
                                            const startDate = moment
                                                .utc(
                                                    `${watch('fromMonth.value') + 1} ${watch('fromYear')?.value}`,
                                                    'M yyyy',
                                                )
                                                .valueOf();
                                            const endDate = moment
                                                .utc(
                                                    `${watch('toMonth.value') + 1} ${watch('toYear')?.value}`,
                                                    'M yyyy',
                                                )
                                                .valueOf();

                                            return dateRangeValidation({
                                                startDate,
                                                endDate,
                                                minDate,
                                                maxDate,
                                            });
                                        },
                                    },
                                }}
                            />
                        </div>

                        <div data-testid={'yearfrom-date-single-selection'} className={classes.singleSelection}>
                            <label>Year</label>
                            <Controller
                                render={({ field }) => (
                                    <MyCustomSelect<ISelectOption<number>>
                                        {...field}
                                        options={yearOptions}
                                        isDisabled={dateRangeOption === 'wholeProject'}
                                    />
                                )}
                                name="fromYear"
                                control={control}
                                rules={{
                                    required: 'From Year is Required',
                                    validate: (value) =>
                                        fromYearValidation({
                                            fromYear: value?.value,
                                            dateRangeOption,
                                            toYear: watch('toYear')?.value,
                                        }),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.section}>
                    <div className={classes.title}>To:</div>
                    <div className={classes.selectionWrapper}>
                        <div data-testid={'monthto-date-single-selection'} className={classes.singleSelection}>
                            <label>Month</label>
                            <Controller
                                render={({ field }) => (
                                    <MyCustomSelect<ISelectOption<number>>
                                        {...field}
                                        options={monthOptions}
                                        isDisabled={dateRangeOption === 'wholeProject'}
                                    />
                                )}
                                name="toMonth"
                                control={control}
                                rules={{
                                    required: 'To month is Required',
                                }}
                            />
                        </div>

                        <div data-testid={'yearto-date-single-selection'} className={classes.singleSelection}>
                            <label>Year</label>
                            <Controller
                                render={({ field }) => (
                                    <MyCustomSelect<ISelectOption<number>>
                                        {...field}
                                        options={yearOptions}
                                        isDisabled={dateRangeOption === 'wholeProject'}
                                    />
                                )}
                                name="toYear"
                                control={control}
                                rules={{
                                    required: 'To Year is Required',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.sectionWrapper}>
                <div className={classes.wholeProjectSection}>
                    <RadioComponent {...controlProps('wholeProject')} id={'wholeProject'} />
                    <label htmlFor={'wholeProject'}>Whole Range</label>
                </div>
            </div>

            <div className={classes.errorsWrapper}>
                {errors.fromMonth && <CustomizedFormErrorMessage text={errors.fromMonth.message} />}
                {errors.fromYear && <CustomizedFormErrorMessage text={errors.fromYear.message} />}
                {errors.toMonth && <CustomizedFormErrorMessage text={errors.toMonth.message} />}
                {errors.toYear && <CustomizedFormErrorMessage text={errors.toYear.message} />}
            </div>
        </div>
    );
};
