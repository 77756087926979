import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';
import qs from 'qs';

const mutationFn = ({ tagId }: QueryParams) => {
    const querystring = qs.stringify({ tagId }, { addQueryPrefix: true, skipNulls: true });
    const url = `${API_URL().DELETE_TAG}${querystring}`;

    return network.delete(url).then((response) => response.data);
};

export const useMutationDeleteTag = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    tagId: number;
}
