import { create, StateCreator } from 'zustand';
import { Unionize } from 'utilitys/helpers/TsGeneral';

interface Props {
    showModal: boolean;
    versionId: number | null;
    versionHashCode: string | null;
    update: (value: Unionize<Omit<Props, 'update'>>) => void;
}

const state: StateCreator<Props> = (set) => ({
    showModal: false,
    versionId: null,
    versionHashCode: null,
    update: (value) => set({ ...value }),
});

export const useCopyVersionStore = create(state);
