import React, { useCallback } from 'react';
import { RenameProjectModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/renameProjectModal';
import { RenameSubProgramModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/renameSubProgramModal';
import { ConfirmDeleteSubProgramModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/confirmDeleteSubProgramModal';
import { ConfirmDeleteProjectModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/confirmDeleteProjectModal';
import { ConfirmRefreshSubProgramModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/confirmRefreshSubProgramModal';
import { ConfirmRefreshProjectModal } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramLibrary/ProgramLibraryDropdownMenu/confirmRefreshProjectModal';
import { IContract, IProject, useProjectTreeStore } from 'store/projectContract.store';

export const ModalManger = React.memo(
    ({ callback }: { callback?: (project: IProject | null, contract: IContract | null) => void }) => {
        const { modalType, update } = useProjectTreeStore();

        const handleModalClose = useCallback(() => {
            update({ modalType: null });
        }, []);

        return (
            <>
                <ConfirmDeleteSubProgramModal
                    handleModalClose={handleModalClose}
                    isShowModal={modalType === 'DELETE_SUB_PROGRAM'}
                    callback={callback}
                />

                <ConfirmDeleteProjectModal
                    handleModalClose={handleModalClose}
                    isShowModal={modalType === 'DELETE_PROJECT'}
                    callback={callback}
                />

                <ConfirmRefreshSubProgramModal
                    handleModalClose={handleModalClose}
                    isShowModal={modalType === 'REFRESH_SUB_PROGRAM'}
                />

                <ConfirmRefreshProjectModal
                    handleModalClose={handleModalClose}
                    isShowModal={modalType === 'REFRESH_PROJECT'}
                />
                <RenameProjectModal onHideModal={handleModalClose} showModal={modalType === 'RENAME_PROJECT'} />
                <RenameSubProgramModal onHideModal={handleModalClose} showModal={modalType === 'RENAME_SUB_PROGRAM'} />
            </>
        );
    },
);
