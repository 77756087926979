import Icon from 'components/common/Icons/icon';
import classes from './HeaderBreadcrumbs.module.scss';
import { Breadcrumbs } from '@mui/material';
import React, { useMemo } from 'react';
import { findeNodeByUUID, ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';
import { useProjectTreeStore } from 'store/projectContract.store';
import { LoaderSpinner } from 'components/common/LodaerSpinner/LoaderSpinner';

interface IHeaderBreadcrumbs {
    data: ProjectTreeNode | undefined;
    handleDropdownOpen: ({
        event,
        parent,
    }: {
        event: React.MouseEvent<HTMLElement>;
        parent: ProjectTreeNode | null;
    }) => void;
    isDisabled?: boolean;
}

export const HeaderBreadcrumbs = ({ data, handleDropdownOpen, isDisabled = false }: IHeaderBreadcrumbs) => {
    const { project, contract } = useProjectTreeStore();

    const parent = useMemo(() => {
        return data && project ? findeNodeByUUID(data, project.uuid) : null;
    }, [data, project]);

    return data ? (
        <Breadcrumbs separator={<Icon name="header-arrow-right" size={'2rem'} color={'#8FA3C3'} />}>
            <div
                className={`${classes.breadcrumbItem} ${isDisabled ? classes.disabled : ''}`}
                onClick={(event) => !isDisabled && handleDropdownOpen({ event, parent: data })}
                id={'ProjectIdNameResponse_selection'}
            >
                {project?.title}
            </div>
            <div
                className={`${classes.breadcrumbItem}  ${isDisabled ? classes.disabled : ''}`}
                onClick={(event) => !isDisabled && handleDropdownOpen({ event, parent })}
                id={'ContractTestResponse_selection'}
            >
                {contract?.title || 'All'}
            </div>
        </Breadcrumbs>
    ) : (
        <LoaderSpinner />
    );
};
