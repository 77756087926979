import { useQuery } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { QueryKeys } from 'api/queryKeys';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

const queryFn = ({ versionId, itemId, numOfDaysToBeUrgent, filter }: QueryParams) => {
    const params = {
        matrixItemIndex: itemId,
        metaDataId: versionId,
        numOfDaysToBeUrgent,
        paging: {
            page: 0,
            size: 500,
        },
        filter,
    };

    return network.post(API_URL().FETCH_PROGRAM__STATUS_PRIORITIES_MATRIX_CARDS, params).then((response) => {
        return response.data;
    });
};

export const useQueryPrioritiesMatrixCards = (params: QueryParams) => {
    return useQuery<QueryResponse[], QueryError, QueryResponse[], [QueryKeys, unknown]>({
        queryKey: ['useQueryPrioritiesMatrixCards', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.itemId) && Boolean(params.versionId) && Boolean(params.numOfDaysToBeUrgent),
        cacheTime: 0,
    });
};

interface QueryParams {
    itemId: number | null;
    versionId: number | undefined;
    numOfDaysToBeUrgent: number;
    filter: { filters: IFilter[] };
}

export interface QueryResponse {
    id: number;
    isVirtual: boolean;
    type: {
        id: number;
        label: string;
        name: string;
    };
    assignee: {
        id: number;
        firstName: string;
        lastName: string;
        userName: string;
    };
    category: {
        id: number;
        label: string;
        name: string;
    };
    isDelayDriver: boolean;
    criticalityScore: number;
    duration: number;
    endDate: number;
    startDate: number;
    laneId: string;
    parentActivity: string;
    taskActivityType: string;
    title: string;
    versionDate: number;
    float: number;
    tagList: { id: number; name: string }[];
    dueDate: number;
    rank: number;
    cardIndicators: {
        isOverdue: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isCriticalPath: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isDelayDriver: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isConstraintDaySet: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isImportantStalled: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isImportantPredecessorOverdue: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isRcfRisk: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isLongDuration: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isImportantLag: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isSetBack: {
            isIndicatorOn: boolean | null;
            text: string;
        };
        isKcRisk: {
            isIndicatorOn: boolean | null;
            text: string;
        };
    };
}
