import { useQueryState } from 'hooks/useQueryState';
import React, { useEffect, useState } from 'react';
import { useLoginStore } from 'store/login.store';
import { useQueryGetUserDetail } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { useLoginHook } from 'components/Login/loginHook';
import { useForm } from 'react-hook-form';
import { encrypt, GENERIC_ERROR } from 'components/Login/login.utils';
import Layout from 'components/publicLayout/layout';
import classes from 'components/Mfa/mfaSignUp.module.scss';
import { CustomizedFormErrorMessage } from 'components/common';
import { NewButton } from 'components/common/CustomizeButton/newButton';
import { Link, useHistory } from 'react-router-dom';
import { useQueryGetQRCode } from 'components/Mfa/queries/useQueryMfa';
import { typedArrayToURL } from 'components/Dashboards/Project/Components/DataStories/dataStories.utils';
import { useMutationLogin } from 'api/mutations/login.mutation';
import { useQueryServerTime } from 'api/queries/serverTime.query';

const links = {
    google: {
        appStore: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
        googlePlay: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en',
    },
    microsoft: {
        appStore: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
        googlePlay: 'https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&pli=1',
    },
};

export const MfaSignUp = () => {
    const history = useHistory();
    const [type] = useQueryState('type');
    const [emailAfterMfa, setEmailAfterMfa] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string>('');
    const { data: serverTime } = useQueryServerTime();
    const { username, password } = useLoginStore();
    const { mutate: loginMutation, isLoading: isLoadingLogin } = useMutationLogin();
    const { data: user, isLoading: isLoadingUserDetail } = useQueryGetUserDetail({
        email: emailAfterMfa,
    });

    const { data: qrCode } = useQueryGetQRCode({ email: username });

    useLoginHook({ user, isSwitchUser: false });

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<{ mfaCode: string }>({
        defaultValues: {
            mfaCode: '',
        },
    });

    const handleLogin = ({ mfaCode }: { mfaCode: string }) => {
        if (!username || !password) return null;
        const time = serverTime?.time as number;
        const payload =
            time !== 0
                ? { encrypt: encrypt(JSON.stringify({ username, password, mfaCode }), time), time }
                : { username, password, mfaCode };
        loginMutation(payload, {
            onSuccess: (response) => {
                if (response.email) {
                    setEmailAfterMfa(response.email);
                    setError('');
                } else {
                    setError(GENERIC_ERROR);
                }
            },
            onError: () => {
                setError(GENERIC_ERROR);
            },
        });
    };

    useEffect(() => {
        if (!username || !password) {
            history.push('/login');
        }
    }, [username, password]);

    const link: { appStore: string; googlePlay: string } | null =
        type && ['google', 'microsoft'].includes(type) ? links[type] : null;

    return (
        <Layout>
            <div className={classes.loginFormWrapper}>
                <div className={classes.loginFormTitleWrapper}>Setup two-factor authentication app</div>
                <p>
                    To keep your account secure, Two-Factor Authentication (2FA) is required as part of your
                    organization's security preferences. <br />
                    This additional layer of security helps prevent unauthorized access, even if your password is
                    compromised.
                </p>
                <p>
                    Please follow these steps:
                    <br />
                    1. Download the <span className={classes.authenticator_type}>{type}</span> Authenticator app from
                    the{' '}
                    <a href={link?.appStore} target={'_blank'}>
                        App Store (iOS)
                    </a>{' '}
                    or{' '}
                    <a href={link?.googlePlay} target={'_blank'}>
                        Google Play (Android)
                    </a>
                    , to your mobile device, if you haven’t already.
                    <br />
                    2. Scan the QR code below with the app to link it to your account.
                </p>
                <div
                    className={classes.qrCode}
                    style={{
                        backgroundImage: `url(${typedArrayToURL(qrCode, 'image/*')})`,
                    }}
                />
                <p>3. Enter 6-digit code generated by the app to complete the setup.</p>
                <form onSubmit={handleSubmit(handleLogin)} autoComplete="off">
                    <div className={classes.formWrapper}>
                        <div className={classes.inputsContainer}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id={'mfaCode'}
                                    className={errors.mfaCode ? classes.invalid : ''}
                                    maxLength={6}
                                    {...register('mfaCode', {
                                        required: 'Code is Required',
                                    })}
                                />
                                {errors.mfaCode && <CustomizedFormErrorMessage text={errors.mfaCode.message} />}
                                {error && <CustomizedFormErrorMessage text={error} />}
                            </div>
                        </div>

                        <div className={classes.actionsWrapper}>
                            <NewButton
                                color="login"
                                variant="contained"
                                className={classes.submit}
                                disabled={isLoadingLogin || isLoadingUserDetail}
                                loading={isLoadingLogin || isLoadingUserDetail}
                                id="login-button"
                                type={'submit'}
                            >
                                {'Sign In'}
                            </NewButton>
                        </div>
                        <div className={classes.backToLoginLink}>
                            Having trouble? <Link to={'/login'}>Retry sign in</Link>, or{' '}
                            <a href={'mailto:cs@foresight.works'}>contact us</a> for help
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
};
