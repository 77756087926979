import { useMutation } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';

const mutationFn = (params: QueryParams) => {
    return network
        .post(API_URL().FETCH_PROGRAM_DASHBOARD_RCF_MODIFY_CLUSTERS_LIST, params)
        .then((response) => response.data);
};
export const useMutationDeleteTaskFromCluster = () => {
    return useMutation<unknown, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    taskIds: number[];
    clusterId: number;
    clusterType: string;
}
