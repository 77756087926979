import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import {
    FilterLabels,
    FilterNames,
    getFiltersData,
} from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import classes from 'components/Dashboards/ExcellenceApps/LogicChecker/components/selectedFilter/selectedFilter.module.scss';
import { excellenceAppsConfig } from 'components/Dashboards/ExcellenceApps/excellenceAppsConfig';
import { Link, useParams } from 'react-router-dom';
import { Pills } from 'components/common/pills/pills';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useHashmapConverter from 'hooks/useHashmapConverter';

export const SelectedFilters = () => {
    const { id: versionId } = useParams<{ id: string }>();

    const { versionList: versionFromHashMap } = useHashmapConverter(versionId);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const globalFilterData = useGlobalFilterDataStore((state) => state.globalFilterData);

    const filters = getFiltersData({
        [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
            ?.filter((item) => !item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
            ?.filter((item) => item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.FRAGNET_MILESTONE_ID_CARD_BOARD]: globalFilterData?.milestoneFragnet,
        [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
            ?.filter((item) => !item.value.isExclude)
            .map((i) => i.value.name),
        [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
            ?.filter((item) => item.value.isExclude)
            .map((i) => i.value.name),
    });

    const { generateFilters: formattedFilter } = useGlobalFiltersHook({
        widgetId: 'logicChecker',
        latestVersionId,
        filter: {
            filters,
        },
    });

    const baseLocation = `/dashboard/program/${excellenceAppsConfig.logicQualityCheck.link}`;

    return formattedFilter.length > 0 ? (
        <div className={classes.container}>
            <div>Applied Filters:</div>
            <div className={classes.pills}>
                {formattedFilter.map((filter) => {
                    const name = filter.name;
                    const data = filter.data || [];
                    if (data.length === 0) return null;
                    return (
                        <div key={`${name}`} className={classes.filtersContainer}>
                            <span key={`title-${name}`} className={classes.title}>
                                {FilterLabels[name] || name}:
                            </span>
                            <span key={`filters-${name}`} className={classes.value}>
                                {data.slice(0, 5).map((item, index) => {
                                    return (
                                        <Pills key={`filters-value-${name}-${index}`} className={classes.pill}>
                                            {item}
                                        </Pills>
                                    );
                                })}
                                <div className={classes.more} title={name}>
                                    {data.length > 5 && `& ${data.length - 5} more...`}
                                </div>
                            </span>
                        </div>
                    );
                })}
            </div>
            <div>
                <Link to={`${baseLocation}/${versionId}`}>Update Filters</Link>
            </div>
        </div>
    ) : null;
};
