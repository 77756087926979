import React from 'react';
import classes from 'components/common/ActionMenuItemWithIcon/actionMenuItemWithIcon.module.scss';
import LabelWithIcon from 'components/common/LabelWithIcon/LabelWithIcon';
import { CustomizedTooltip } from 'components/common/CustomizedTooltip/CustomizedTooltip';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import { kebabCase } from 'lodash';
import { MuiIcon } from 'components/common/muiIcon/muiIcon';

interface IActionMenuItemWithIconProps {
    handleClick: () => void;
    iconType?: string;
    iconText: string;
    iconSize?: string;
    isActive?: boolean;
    useMuiIcon?: boolean;
}
//TODO: remove isActive logic - it's a special case
export const ActionMenuItemWithIcon = ({
    handleClick,
    iconType = '',
    iconText,
    iconSize = '2rem',
    isActive = true,
    useMuiIcon = false,
}: IActionMenuItemWithIconProps) => {
    return isActive ? (
        <div
            className={classes.menuItemsWithIcon}
            onClick={handleClick}
            data-track-id={kebabCase(`menu-item-${iconText}`)}
        >
            {useMuiIcon ? (
                <LabelWithIcon
                    text={iconText}
                    size={iconSize}
                    muiIcon={<MuiIcon icon={iconType} fontSize={iconSize} />}
                />
            ) : (
                <LabelWithIcon icon={iconType} text={iconText} size={iconSize} />
            )}
        </div>
    ) : (
        <CustomizedTooltip
            tooltipContent={'Select at least one version'}
            triggerElement={
                <div className={`${classes.menuItemsWithIcon} ${classes.inactive}`}>
                    <LabelWithIcon icon={iconType} text={iconText} size={iconSize} />
                </div>
            }
            tooltipPosition={TooltipPosition.Bottom}
        />
    );
};
