import classes from './LogicCheckerFilters.module.scss';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { MyCustomSelect } from 'components/common';
import { InputOption, ValueContainer } from 'components/common/MyCustomSelect/myCustomSelect';
import { morefilterItems } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProgramCriticalAnalysis/utils';
import React from 'react';
import SwitchComponent from 'components/common/Switch/Switch';

export const statusOptions: ISelectOption<string>[] = [
    {
        value: 'To Do',
        label: 'To Do',
    },
    {
        value: 'In Progress',
        label: 'In Progress',
    },
    {
        value: 'Done',
        label: 'Done',
    },
];

export interface IsTypeFilterProps {
    typeFilterArr: ISelectOption<string>[];
    item: { lateFinish: number; lateStart: number; durationOverrun: number; activityType: string };
}
export const isTypeFilter = ({ typeFilterArr, item }: IsTypeFilterProps) => {
    const isAllTypes = typeFilterArr.map((type) => {
        switch (type.value) {
            case 'Late Finish Date':
                return item.lateFinish > 0;
            case 'Late Start Date':
                return item.lateStart > 0;
            case 'Overrun Duration':
                return item.durationOverrun > 0;
            case 'Milestones':
                return ['START_MILESTONE', 'FINISH_MILESTONE', 'Milestone'].includes(item.activityType);
            default:
                return false;
        }
    });

    return isAllTypes.every((item) => item);
};

export const LogicCheckerFilters = ({
    search,
    setSearch,
    setOnSearch,
    statusFilter,
    setStatusFilter,
    typeFilter,
    setTypeFilter,
    handleCriticalPath,
    isCriticalPath,
}) => {
    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const handleClearSearch = () => {
        setSearch('');
        setOnSearch('');
    };

    const handleOnSearch = () => {
        setOnSearch(search);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            handleOnSearch();
        }
    };

    return (
        <div className={classes.filters}>
            <div className={classes.selectWrapper}>
                <div className={classes.title}>Name</div>
                <div className={classes.filterTextSearch}>
                    <TextSearchInput
                        placeholder="Search Activities by Name"
                        value={search}
                        onChange={handleSearch}
                        handleClear={handleClearSearch}
                        maxLength={200}
                        handleClick={handleOnSearch}
                        onKeyDown={onKeyDown}
                        className={classes.textInput}
                    />
                </div>
            </div>
            <div className={classes.selectWrapper}>
                <div className={classes.title}>Status</div>
                <MyCustomSelect<ISelectOption<string>, true>
                    value={statusFilter}
                    options={statusOptions}
                    onChange={(value) => setStatusFilter(value)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    placeholder={'Select Status...'}
                    components={{
                        Option: InputOption,
                        ValueContainer: ValueContainer,
                    }}
                />
            </div>
            <div>
                Critical Path Only
                <SwitchComponent onChange={handleCriticalPath} checked={isCriticalPath} />
            </div>
            <div className={classes.selectWrapper}>
                <div className={classes.title}>More Filters</div>
                <MyCustomSelect<ISelectOption<string>, true>
                    value={typeFilter}
                    options={morefilterItems}
                    onChange={(value) => setTypeFilter(value)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isMulti={true}
                    placeholder={'Select More Filters...'}
                    components={{
                        Option: InputOption,
                        ValueContainer: ValueContainer,
                    }}
                />
            </div>
        </div>
    );
};
