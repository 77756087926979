import { IContract, IProject, TModalType } from 'store/projectContract.store';
import { Unionize } from 'utilitys/helpers/TsGeneral';
import { last } from 'lodash';

export type ProjectTreeNode = {
    children: ProjectTreeNode[] | null;
    id: number;
    name: string;
    parentUUID: null | `${ProjectTreeNode['id']}-${ProjectTreeNode['type']}`;
    type: 'root' | 'ProjectIdNameResponse' | 'ContractTestResponse';
    uuid: `${ProjectTreeNode['id']}-${ProjectTreeNode['type']}`;
};

export const findeNodeByUUID = (tree: ProjectTreeNode, uuid: ProjectTreeNode['uuid']): ProjectTreeNode | null => {
    if (tree.uuid === uuid) return tree;
    if (tree.children) {
        let result: ProjectTreeNode | null = null;
        for (let i = 0; result == null && i < tree.children.length; i++) {
            result = findeNodeByUUID(tree.children[i], uuid);
        }
        return result;
    }
    return null;
};
type storeProps = {
    project: IProject | null;
    contract: IContract | null;
    parentNode: ProjectTreeNode | null;
    currentList: ProjectTreeNode[];
    modalType: TModalType;
};

interface Props {
    project: IProject | null;
    contract: IContract | null;
    update: (value: Unionize<storeProps>) => void;
    data: ProjectTreeNode | null;
}

export const firstEnterUpdateProjectTreeStore = ({ project, contract, update, data }: Props) => {
    const updateLastProject = () => {
        const lastProject: ProjectTreeNode | undefined = last(data?.children);
        if (lastProject) {
            const lastContract: ProjectTreeNode | undefined = last(lastProject?.children);
            update({
                project: {
                    id: lastProject.id,
                    title: lastProject.name,
                    uuid: lastProject.uuid,
                },
                contract: lastContract
                    ? {
                          id: lastContract.id,
                          title: lastContract.name,
                          uuid: lastContract.uuid,
                      }
                    : null,
            });
        }
    };

    if (!project) {
        updateLastProject();
    } else {
        const projectNode = data && project.uuid ? findeNodeByUUID(data, project.uuid) : null;
        if (!projectNode) {
            updateLastProject();
        } else {
            const contractNode = data && contract ? findeNodeByUUID(data, contract.uuid) : null;
            if (!contractNode) {
                const lastContract: ProjectTreeNode | undefined = last(projectNode.children);
                update({
                    project: {
                        id: projectNode.id,
                        title: projectNode.name,
                        uuid: projectNode.uuid,
                    },
                    contract: lastContract
                        ? {
                              id: lastContract.id,
                              title: lastContract.name,
                              uuid: lastContract.uuid,
                          }
                        : null,
                });
            } else {
                update({
                    project: {
                        id: projectNode.id,
                        title: projectNode.name,
                        uuid: projectNode.uuid,
                    },
                    contract: {
                        id: contractNode.id,
                        title: contractNode.name,
                        uuid: contractNode.uuid,
                    },
                });
            }
        }
    }
};
