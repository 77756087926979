import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';
import { TPreferredSPIMethodO } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/ProjectPreferences.utils';

const mutationFn = (params: QueryParams) => {
    return network.post(API_URL().SET_PREFFERED_SPI_METHOD, params).then((response) => {
        return response.data;
    });
};

export const useMutationPrefferedSPIMethod = () => {
    return useMutation<string, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    projectId: number;
    selectedOption: TPreferredSPIMethodO;
}
