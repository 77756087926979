export const timeFilterInitialState = '30';
export const ALL_FILTER_BUTTON: ISelectOption<string> = {
    label: 'ALL',
    value: 'ALL',
};
export const TIME_FILTERS: ISelectOption<string>[] = [
    {
        label: '7D',
        value: '7',
    },
    {
        label: '30D',
        value: '30',
    },
    {
        label: '90D',
        value: '90',
    },
    {
        label: 'All',
        value: '-1',
    },
];
