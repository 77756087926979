import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useMutation } from 'react-query';
import qs from 'qs';
import { TMfaType } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { activeDirectory, token } from 'services/LocalStorage/localStorage';

const mutationFn = (params: QueryParams) => {
    const querystring = qs.stringify({ userName: params.userName }, { addQueryPrefix: true, skipNulls: true });
    const url = API_URL().SWITCH_USER;
    return network.post(`${url}${querystring}`).then((response) => {
        token.set(response.data.accessToken);
        network.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
        activeDirectory.set(
            window['ENV_VARIABLES'] ? window['ENV_VARIABLES']['REACT_APP_ACTIVE_DIRECTORY_URL'] : activeDirectory.get(),
        );
        return response.data;
    });
};

export const useMutationSwitchUser = () => {
    return useMutation<QueryResponse, QueryError, QueryParams>({
        mutationFn: (params) => mutationFn(params),
    });
};

interface QueryParams {
    userName: string;
}

interface QueryResponse {
    accessToken: string;
    customerId: null;
    defaultProjectId: number;
    email: string;
    mfa: TMfaType;
    phone: null;
    tokenType: 'Bearer';
}
