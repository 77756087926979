import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/components/projectPreferences.module.scss';
import { DefaultBaseLinePerVersion } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/DefaultBaseLinePerVersion/DefaultBaseLinePerVersion';
import { UniqueActivityKey } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/UniqueActivityKey/UniqueActivityKey';
import { BaselineComparableProgress } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/BaselineComparableProgress/baselineComparableProgress';
import { useProjectTreeStore } from 'store/projectContract.store';
import { PreferredSPIMethod } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/ProjectPreferences/PreferredSPIMethod/preferredSPIMethod';

export const ProjectPreferences = () => {
    const { project } = useProjectTreeStore();

    return (
        <WidgetWithTitle title={'Project Preferences'}>
            <div className={classes.formContainer}>
                <p className={classes.note}>
                    You will be prompted to confirm in case you make any changes to the project preferences.
                </p>
                <div className={classes.inputWrapper}>
                    <DefaultBaseLinePerVersion project={project} />
                </div>
                <div className={classes.inputWrapper}>
                    <UniqueActivityKey project={project} />
                </div>
                <div className={classes.inputWrapper}>
                    <BaselineComparableProgress project={project} />
                </div>
                <div className={classes.inputWrapper}>
                    <PreferredSPIMethod project={project} />
                </div>
            </div>
        </WidgetWithTitle>
    );
};
