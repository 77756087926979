import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { CustomizedRoundIcon, CustomizedTooltip } from 'components/common';
import { TooltipPosition } from 'components/common/CustomizedTooltip/customizedTooltip.enums';
import Icon from 'components/common/Icons/icon';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/style/table.module.scss';
import { TableV8 } from 'components/common/Tables/TableV8/tableV8';
import NoData from 'components/common/NoData/noData';
import { CustomPagination } from 'components/common/pagination/customPagination';
import { CustomTableColumnsModal } from 'components/common/Modals/CustomTableColumns/customTableColumnsModal';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { useReactTableState } from 'hooks/useReactTableState';
import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import { serializeColumnsVisibility } from 'components/Dashboards/widgets/keyActivities/utils';
import { useMutationSetWidgetColumns } from 'components/Dashboards/Project/Components/CustomDashboard/queries/useQuerySelectedTableColumns';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import useTableCurrentState from 'hooks/useTableCurrentState';
import { SelectedRangeLabel } from 'components/common/selectedRangeLabel/selectedRangeLabel';
import { useProductivityRateStore } from 'store/productivityRate.store';
import { getStringWithoutHash } from 'utilitys/helpers/general';
import { QueryResponse, useQueryGetRunRateAnalysisBreakdownQuery } from 'api/queries/getRunRateAnalysisBreakdown.query';
import {
    columnsV8,
    initialState,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityTable/productivityTable.utils';
import { TitleWithCounter } from 'components/common/titleWithCounter/titleWithCounter';
import { rangeOptions } from 'components/common/GlobalFilterComponent/components/RangeFilter/rangeFilter';
import { aggregationOptions } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Productivity/Widgets/productivityRate/productivityRate.utils';
import { getInputLabel } from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.utils';

export const ProductivityTable = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,
    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    localFilters,
}) => {
    const title = 'Run Rate | Activities Drilldown';
    const id = 'productivityTable';
    const componentKey = 'productivityTable';

    const {
        pagination,
        setPagination,
        sorting,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        columnOrder,
        setColumnOrder,
    } = useReactTableState({ initialSorting: widgetId && filter?.filters ? filter?.filters.sortingRequestList : [] });
    const { update } = useProductivityRateStore();
    const selectedStartDate = useProductivityRateStore((state) => state.selectedStartDate);
    const selectedEndDate = useProductivityRateStore((state) => state.selectedEndDate);
    const selectedValue = useProductivityRateStore((state) => state.selectedValue);
    const selectedSeries = useProductivityRateStore((state) => state.selectedSeries);
    const aggregation = useProductivityRateStore((state) => state.aggregation);
    const updatedInitialState = useTableCurrentState({ initialState, componentKey, widgetId });
    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const [modalColumnsOpen, setModalColumnsOpen] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState(0);
    const { handleSnackBar } = useCustomSnackBar();

    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters.mainFilters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                      [FilterNames.ACTIVITY_TYPE_FILTER]: globalFilterData?.activityType,
                      [FilterNames.PRODUCTIVITY_PRE_DEFINED]: globalFilterData?.productivityPreDefined,
                  }),
        [filter, globalFilterData],
    );

    const dateRangeFilterValue = useMemo(() => {
        return globalFilterData?.dateRangeFilter;
    }, [globalFilterData?.dateRangeFilter]);

    const range = useMemo(() => {
        const value = widgetId && filter ? localFilters?.range : dateRangeFilterValue;
        return Array.isArray(value) ? 'R_CUSTOM' : value;
    }, [widgetId, filter, localFilters?.range, dateRangeFilterValue]);

    const { startDate, endDate } = useMemo(() => {
        return {
            startDate: widgetId && filter ? filter?.filters.startDate : selectedStartDate,
            endDate: widgetId && filter ? filter?.filters.endDate : selectedEndDate,
        };
    }, [widgetId, filter, selectedEndDate, selectedStartDate]);

    const { data, isLoading, isFetching } = useQueryGetRunRateAnalysisBreakdownQuery({
        actualMetaDataId: widgetId && filter ? filter?.filters.actualMetaDataId : latestVersionId,
        comparedMetaDataId: widgetId && filter ? filter?.filters.comparedMetaDataId : compareVersionId,
        startDate,
        endDate,
        range,
        dataType: selectedValue,
        filter: {
            filters,
        },
        id: idList,
        sortingList: widgetId && filter ? filter?.filters.sortingRequestList : sorting,
        paging: { ...pagination, page: pagination.page - 1 },
        aggregation: aggregation?.value || localFilters?.aggregation,
    });

    const { generateFilters: formatedFilter } = useGlobalFiltersHook({
        widgetId,
        filter: widgetId && filter ? { filters: filter?.filters?.mainFilters || [] } : undefined,
        latestVersionId,
    });

    const route = `${getStringWithoutHash(window.location.href)}#${id}`;

    const pinBoardFilterData = useMemo(() => {
        return {
            mainFilters: filters,
            range: Array.isArray(dateRangeFilterValue) ? 'R_CUSTOM' : dateRangeFilterValue,
            sortingRequestList: sorting,
            actualMetaDataId: latestVersionId,
            comparedMetaDataId: compareVersionId,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
        };
    }, [filters, sorting, latestVersionId, compareVersionId, selectedStartDate, selectedEndDate]);

    useEffect(() => {
        updatedInitialState &&
            setColumnOrder(updatedInitialState.columnOrder ?? columnsV8.map((item) => item.id as string));
        updatedInitialState &&
            setColumnVisibility(
                serializeColumnsVisibility({ columns: columnsV8, hiddenColumns: updatedInitialState.hiddenColumns }) ??
                    {},
            );
    }, [updatedInitialState]);

    const tableInstance = useMemo(() => {
        const hiddenColumns = Object.keys(columnVisibility).reduce((acc: string[], item: string) => {
            columnVisibility[item] === false && acc.push(item);
            return acc;
        }, []);

        return {
            setColumnOrder,
            setHiddenColumns: (hiddenColumns) =>
                setColumnVisibility(serializeColumnsVisibility({ columns: columnsV8, hiddenColumns })),
            state: {
                hiddenColumns,
            },
            allColumns: columnOrder
                .map((item) => {
                    const column = columnsV8.find((column) => column.id === item);
                    if (column && column.id) {
                        return {
                            ...column,
                            isVisible: !hiddenColumns.includes(column.id),
                        };
                    }
                    return null;
                })
                .filter((x) => x),
        };
    }, [columnVisibility, columnOrder]);

    const { mutate: mutateSetWidgetColumns } = useMutationSetWidgetColumns();

    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    const setWidgetColumns = (widgetId: string) => {
        if (tableInstance) {
            mutateSetWidgetColumns(
                { columns: { hiddenColumns: tableInstance.state.hiddenColumns, columnOrder }, widgetId },
                {
                    onError: (error) => {
                        handleSnackBar(error?.response?.data || 'Something went wrong ', 'error');
                    },
                },
            );
        }
    };

    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
    }, [
        sorting,
        globalFilterData?.resourceType,
        globalFilterData?.quickAnswerFilter,
        dateRangeFilterValue,
        globalFilterData?.resource,
        selectedSeries,
        filters,
    ]);

    useEffect(() => {
        update({
            selectedValue: null,
            selectedSeries: null,
            selectedStartDate: null,
            selectedEndDate: null,
            dataPointIndex: null,
            seriesIndex: null,
        });
    }, [globalFilterData]);

    useEffect(() => {
        if (data) {
            setTotalPages(data.numOfPages);
        }
    }, [data]);

    const filteredRows = useMemo(() => data?.cardList || [], [data]);

    const rangeFilterValue = useMemo(() => {
        if (!range) return [];
        if (range === 'R_CUSTOM') {
            return [getInputLabel({ startDate, endDate })];
        }
        return [rangeOptions.find((item) => item.value === range)?.label || ''];
    }, [range, startDate, endDate]);
    const allFormattedFilters = [
        ...formatedFilter,
        {
            name: 'Date Range',
            data: rangeFilterValue,
        },
        {
            name: 'Aggregation',
            data: localFilters?.aggregation
                ? [aggregationOptions.find((item) => item.value === localFilters?.aggregation)?.label]
                : [],
        },
    ];

    return (
        <WidgetWithTitle
            id={id}
            tooltip={data?.info}
            title={<TitleWithCounter title={title} counter={data?.numOfAllCards} />}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={allFormattedFilters} />
                ) : (
                    <div className={classes.filters} key={'selectedRange'}>
                        <SelectedRangeLabel
                            selectedEndDate={selectedEndDate}
                            selectedStartDate={selectedStartDate}
                            seriesLabel={selectedSeries}
                        />
                    </div>
                ),
            ]}
            titleComponents={[
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <CustomizedTooltip
                    key={'CustomizTableColumns'}
                    tooltipPosition={TooltipPosition.Top}
                    tooltipContent={'Customize table columns'}
                    triggerElement={
                        <CustomizedRoundIcon enableHover={true} onClick={() => setModalColumnsOpen(true)}>
                            <Icon name={'tune-filter'} />
                        </CustomizedRoundIcon>
                    }
                />,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={pinBoardFilterData}
                    localFilters={{
                        range: dateRangeFilterValue,
                        aggregation: aggregation?.value,
                    }}
                    callBack={setWidgetColumns}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
            ]}
        >
            <div className={classes.tableContainer}>
                {updatedInitialState && (
                    <>
                        <TableV8<QueryResponse['cardList'][0]>
                            data={filteredRows}
                            columns={columnsV8}
                            isLoading={isLoading || isFetching}
                            noData={<NoData>No activities match the applied filter</NoData>}
                            sorting={sorting}
                            setSorting={setSorting}
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                            columnOrder={columnOrder}
                            setColumnOrder={setColumnOrder}
                        />

                        <div className={classes.pagination}>
                            <CustomPagination
                                totalPages={totalPages}
                                pagination={pagination}
                                setPagination={setPagination}
                                isDisabled={isFetching}
                            />
                        </div>
                    </>
                )}
            </div>

            <CustomTableColumnsModal
                onHideModal={() => setModalColumnsOpen(false)}
                showModal={modalColumnsOpen}
                widgetId={widgetId}
                componentKey={componentKey}
                tableInstance={tableInstance}
                initialState={initialState}
                columns={columnsV8}
            />
        </WidgetWithTitle>
    );
};
