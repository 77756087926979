import { useQuery } from 'react-query';
import { network } from 'services/network';
import { QueryKeys } from 'api/queryKeys';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { IFilter } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';
import { ColumnSort } from '@tanstack/react-table';
import {
    IAssignee,
    IStatus,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/battlecards.types';

export const queryKey: QueryKeys = 'getSchedulingSimulationResults';

const queryFn = (params: QueryParams): Promise<QueryResponse> => {
    return network.post(API_URL().GET_SCHEDULE_SIMULATION_RESULTS, params).then((response) => {
        return response.data;
    });
};

export const useQueryGetSchedulingSimulationResults = (params: QueryParams) => {
    const requestParams = { ...params };
    const cpmCacheIsReady = requestParams.cacheIsReady;
    delete requestParams.cacheIsReady;
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: [queryKey, requestParams],
        queryFn: () => queryFn(requestParams),
        enabled:
            Boolean(params.metaDataId !== null) &&
            Boolean(params.simulationDate) &&
            Boolean(cpmCacheIsReady) &&
            Boolean(params.key && params.key.length > 0),
        cacheTime: 0,
    });
};

interface QueryParams {
    metaDataId: number | null;
    filter: IFilter[];
    simulationDate: number;
    paging: {
        page: number;
        size: number;
    };
    key?: string | null;
    sorting: ColumnSort[];
    cacheIsReady?: boolean;
}

export interface QueryResponse {
    changedActivities: {
        id: number;
        activityId: string; // taskActivityId
        name: string; //    title: string;
        taskActivityType: string; // activityType
        virtual: boolean;
        tracked: boolean;
        taskVersionHashCode: string;
        parentActivity: string;
        grandParentActivity: string;
        criticalityScore: number;
        baselineStartDate: number;
        baselineFinishDate: number;
        startVariance: number;
        finishVariance: number;
        totalFloatVariance: number;
        remainingDurationVariance: number;
        criticalPathChange: boolean;
        assignee: IAssignee;
        modifiedByUser: boolean;
        rank: number;
        startVarianceFromBaseline: number;
        finishVarianceFromBaseline: number;
        wbsLevel: number;
        cardType: {
            id: number;
            name: string;
            label: string;
        };
        category: {
            id: number;
            name: string;
            label: string;
        };
        original: {
            id: number;
            activityId: string;
            status: IStatus;
            start: number;
            finish: number;
            remainingDuration: number;
            totalFloat: number;
            onCriticalPath: boolean;
        }; // status inside of original is original status
        modified: {
            id: number;
            activityId: string;
            status: IStatus;
            start: number;
            finish: number;
            remainingDuration: number;
            totalFloat: number;
            onCriticalPath: boolean;
        };
        isStatusOrDatesChanged: boolean;
    }[];
    settings: {
        key: string;
        value: string;
    }[];
    warnings: {
        key: string;
        value: string;
        details: string | null;
    }[];
    note: string;
    key: string;
    info: IToolTip;
    totalPages: number;
    totalChanges: number;
}
