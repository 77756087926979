import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { network } from 'services/network';
import { useQuery } from 'react-query';
import { TMfaType } from 'components/Settings/Components/UsersManagement/queries/useQueryUsersManagement';
import { QueryKeys } from 'api/queryKeys';

const queryFn = ({ email }: QueryParams) => {
    const url = `${API_URL().CHECK_MFA_ENABLED}/${email}`;
    return network.get(url).then((response) => {
        return response.data;
    });
};

export const useQueryCheckMFAEnabled = (params: QueryParams) => {
    return useQuery<QueryResponse, QueryError, QueryResponse, [QueryKeys, unknown]>({
        queryKey: ['CheckMFAEnabled', params],
        queryFn: () => queryFn(params),
        enabled: Boolean(params.email),
    });
};

interface QueryParams {
    email: string | undefined;
    password: string | undefined;
}

interface QueryResponse {
    mfaType: TMfaType;
    valid: boolean;
    mfaInit: boolean;
}
