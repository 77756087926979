import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/bulkAssign/bulkAssing.module.scss';
import {
    ConfirmSaveModal,
    CustomizedButton,
    CustomizedFormErrorMessage,
    CustomizedModalBase,
    MyCustomSelect,
} from 'components/common';
import React, { useState } from 'react';
import { useCustomSnackBar } from 'hooks/useCustomSnackBar';
import {
    useMutationBulkTags,
    useMutationBulkTagsRemove,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/bulkUserMutation';
import { Controller, useForm } from 'react-hook-form';
import { RadioComponent } from 'components/common/RadioComponent/radioComponent';
import useEventWithDimensions from 'hooks/useEventWithDimensions';
import { ICountersDataResponse } from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/Battlecards/queries/battleCardsQuery';
import { getObjectSumValues } from 'utilitys/helpers/general';
import { projectConfig } from 'components/Dashboards/Project/ProjectWrapper/projectConfig';
import SelectListWithCreate from 'components/common/selectListWithCreate/selectListWithCreate';

interface IBulkAssignTagsProps {
    tagsValue: ISelectOption<string>[];
    radioOption: 'add' | 'remove';
}

interface Props {
    isShowModal: boolean;
    tagsOptions: ISelectOption<string>[];
    searchedFilterData: any;
    bulkAssigneeToTagsUniqIdNotification: number;
    bulkRemoveTagsUniqIdNotification: number;
    handleModalClose: () => void;
    setIsBulkLoading: React.Dispatch<React.SetStateAction<boolean>>;
    cardsCounters?: Record<keyof ICountersDataResponse, number>;
}

export const BulkAssignTags = ({
    isShowModal,
    handleModalClose,
    tagsOptions,
    searchedFilterData,
    bulkAssigneeToTagsUniqIdNotification,
    bulkRemoveTagsUniqIdNotification,
    setIsBulkLoading,
    cardsCounters,
}: Props) => {
    const { handleSnackBar } = useCustomSnackBar();
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const { mutate: addTagsMutation } = useMutationBulkTags();
    const { mutate: removeTagsMutation } = useMutationBulkTagsRemove();
    const { sendEventWithDimensions } = useEventWithDimensions();
    const {
        watch,
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm<IBulkAssignTagsProps>({
        defaultValues: {
            tagsValue: [],
            radioOption: 'add',
        },
    });
    const { tagsValue, radioOption } = watch();

    const handleSave = () => {
        const tagsMutation: any = radioOption === 'add' ? addTagsMutation : removeTagsMutation;

        const fixedTagsValue = tagsValue.map((i) => ({ name: i.value }));

        const params =
            radioOption === 'add'
                ? {
                      bulkAssigneeToTagsUniqIdNotification,
                      tagToBulkAssignList: fixedTagsValue,
                      ...searchedFilterData,
                  }
                : {
                      bulkRemoveTagsUniqIdNotification,
                      tagToRemoveList: fixedTagsValue,
                      ...searchedFilterData,
                  };

        tagsMutation(params, {
            onSuccess: () => {
                setIsBulkLoading(true);
                sendEventWithDimensions({
                    category: 'Battlecards',
                    action: `Bulk Edit Tags - ${radioOption}`,
                    label: `filter: ${JSON.stringify(params.filter)}`,
                });
            },
            onError: (error) => {
                handleSnackBar(error?.response?.data || 'Bulk request Failed', 'error');
            },
            onSettled: () => {
                closeModal();
            },
        });
    };

    const closeModal = () => {
        handleModalClose();
        reset();
    };

    return (
        <>
            <CustomizedModalBase isModalOpen={isShowModal} handleModalClose={closeModal}>
                <div className={classes.bulkAssingContent}>
                    <form onSubmit={handleSubmit(() => setConfirmModal(true))}>
                        <div className={classes.title}>{projectConfig.battlecards.title} - Bulk Tags</div>

                        <div className={classes.optionContainer}>
                            <Controller
                                render={({ field }) => (
                                    <label>
                                        <RadioComponent {...field} value={'add'} checked={radioOption === 'add'} /> Add
                                        Tags
                                    </label>
                                )}
                                name="radioOption"
                                control={control}
                            />
                            <Controller
                                render={({ field }) => (
                                    <label>
                                        <RadioComponent
                                            {...field}
                                            value={'remove'}
                                            checked={radioOption === 'remove'}
                                        />{' '}
                                        Remove Tags
                                    </label>
                                )}
                                name="radioOption"
                                control={control}
                            />
                        </div>
                        <div className={classes.selectTitle}>
                            This will affect{' '}
                            <span className={classes.bold}>{getObjectSumValues(cardsCounters).toLocaleString()}</span>{' '}
                            activities matching the current filter
                        </div>
                        <div className={classes.selectWrapper}>
                            <Controller
                                render={({ field }) =>
                                    radioOption === 'add' ? (
                                        <SelectListWithCreate
                                            {...field}
                                            options={tagsOptions}
                                            placeholder={'Select Tag...'}
                                        />
                                    ) : (
                                        <MyCustomSelect<ISelectOption<string>, true>
                                            {...field}
                                            options={tagsOptions}
                                            isMulti
                                            placeholder={'Select Tag...'}
                                        />
                                    )
                                }
                                name="tagsValue"
                                control={control}
                                rules={{
                                    validate: {
                                        required: (value) => value.length > 0 || 'Must select at least one tag',
                                    },
                                }}
                            />
                            {errors.tagsValue && <CustomizedFormErrorMessage text={errors.tagsValue.message} />}
                        </div>

                        {tagsValue.length > 0 && (
                            <div className={classes.messageForTheUser}>
                                You will receive a notification once the bulk update is completed.
                            </div>
                        )}
                        <div className={classes.footer}>
                            <div className={classes.container}>
                                <CustomizedButton size={'large'} color={'secondary'} onClick={closeModal}>
                                    Cancel
                                </CustomizedButton>

                                <CustomizedButton size={'large'} color={'primary'} type={'submit'}>
                                    {radioOption === 'add' ? 'Assign' : 'Remove'}
                                </CustomizedButton>
                            </div>
                        </div>
                    </form>
                </div>
            </CustomizedModalBase>

            <ConfirmSaveModal
                isShowModal={confirmModal}
                onHideModal={() => setConfirmModal(false)}
                onConfirm={() => {
                    setConfirmModal(false);
                    handleSave();
                }}
                actionButtonLabel={'Yes'}
            >
                Are you sure you want to run this bulk action?
                <br />
                Large number of assignments may take a while...
            </ConfirmSaveModal>
        </>
    );
};
