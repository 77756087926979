import { useMutation } from 'react-query';
import { network } from 'services/network';
import { getAPI_URL as API_URL } from 'utilitys/consts/networkApiConst';
import { ProjectTreeNode } from 'components/common/TreeProgramFilter/ProjectTree/projectTree.utils';

const createProject = (params) => {
    return network.post(API_URL().CREATE_PROJECT, params).then((response) => {
        return response.data;
    });
};

const createContract = (params) => {
    return network.post(API_URL().CREATE_CONTRACT, params).then((response) => {
        return response.data;
    });
};

export const useMutationCreateProject = () => {
    return useMutation<ICreateProjectResponse, QueryError, ICreateProjectPayload>((params) => createProject(params));
};

export const useMutationCreateContract = () => {
    return useMutation<ICreateContractResponse, QueryError, ICreateContractPayload>((params) => createContract(params));
};

export interface ICreateProjectPayload {
    projectName: string;
    projectDescription: string;
    initialContractName: string;
    userGroups: string[];
    customerId?: number;
}

export interface ICreateProjectResponse {
    id: number;
    projectUid: ProjectTreeNode['uuid'];
    name: string;
}

interface ICreateContractPayload {
    contractName: string;
    projectId: number;
}

interface ICreateContractResponse {
    id: number;
    contractUid: ProjectTreeNode['uuid'];
    name: string;
}
