import React, { useEffect, useMemo, useState } from 'react';
import { useQueryState } from 'hooks/useQueryState';
import { useGlobalFilterDataStore } from 'store/globalFilterData.store';
import componentClasses from 'components/common/GlobalFilterComponent/components/dateRangeFilter/dateRangeFilter.module.scss';
import classes from 'components/common/GlobalFilterComponent/GlobalFilterComponent.module.scss';
import { noop } from 'lodash';
import TextSearchInput from 'components/common/TextSearchInput/textSearchInput';
import { SideMenu } from 'components/common/CustomFilters/DateRangeFilter/SideMenu/SideMenu';
import { Popover } from '@mui/material';
import { Range } from 'components/common/GlobalFilterComponent/components/RangeFilter/rangeFilter';
import { DateRangeFilterMain } from 'components/common/GlobalFilterComponent/components/dateRangeFilter/dateRangeFilterMain';
import { getInputLabel } from 'components/common/CustomFilters/TableDateRangeFilter/TableDateRangeFilter.utils';
import produce from 'immer';
import { useQueryGetProjectDatesForResourceChart } from 'api/queries/getProjectDatesForResourceChart.query';
import { useParams } from 'react-router-dom';
import useHashmapConverter from 'hooks/useHashmapConverter';

export type RangeWithCustom = Range | 'R_CUSTOM';

export const rangeOptions: ISelectOption<RangeWithCustom>[] = [
    { label: '2 Weeks', value: 'R_2W' },
    { label: '4 Weeks', value: 'R_4W' },
    { label: '3 Months', value: 'R_3M' },
    { label: '6 Months', value: 'R_6M' },
    { label: '12 Months', value: 'R_12M' },
    { label: 'Custom', value: 'R_CUSTOM' },
];

interface Props {
    methods?: any;
    name: string;
    setState?: React.Dispatch<React.SetStateAction<any>>;
    handleFilters?: () => void;
}

export const DateRangeFilter = ({ methods, name, setState, handleFilters }: Props) => {
    const [queryStringValue] = useQueryState(name);
    const { getValueFromStore } = useGlobalFilterDataStore();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [activeSideMenuOption, setActiveSideMenuOption] = useState<RangeWithCustom>('R_2W');
    const storeValue = getValueFromStore<unknown>(name);
    const formValue = methods?.getValues(name);
    const hashcode = Object.values(useParams()).filter(Boolean).join(',');
    const { versionList: versionFromHashMap } = useHashmapConverter(hashcode);
    const latestVersionId = versionFromHashMap[0]?.versionDbId;
    const compareVersionId = versionFromHashMap[1]?.versionDbId;

    const { data } = useQueryGetProjectDatesForResourceChart({
        metaDataId: latestVersionId,
        comparedMetaDataId: compareVersionId,
    });

    const isValue = useMemo(() => {
        return queryStringValue || storeValue || formValue;
    }, [queryStringValue, storeValue]);

    useEffect(() => {
        if (setState) {
            if (!isValue || isValue.length === 0) {
                setActiveSideMenuOption('R_2W');
                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft[name].isReady = true;
                        draft[name].isEmpty = true;
                        return draft;
                    });
                });
            }

            if (isValue && isValue.length > 0) {
                if (Array.isArray(isValue)) {
                    methods?.setValue(name, isValue.map(Number));
                    setActiveSideMenuOption('R_CUSTOM');
                } else {
                    methods?.setValue(name, isValue);
                    setActiveSideMenuOption(isValue);
                }

                setState((prev) => {
                    return produce(prev, (draft) => {
                        draft[name].isReady = true;
                        return draft;
                    });
                });
            }
        }
    }, [isValue, setState, methods, name]);

    const inputLabel = useMemo(() => {
        if (typeof formValue === 'string') {
            return rangeOptions.find((item) => item.value === formValue)?.label || '';
        }
        if (Array.isArray(formValue)) {
            return getInputLabel({ startDate: Number(formValue[0]), endDate: Number(formValue[1]) });
        }
        return '';
    }, [formValue]);

    const handleClear = () => {
        onApply('R_4W');
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const onApply = (value: (number | null)[] | string) => {
        methods?.setValue(name, value);
        handleFilters && handleFilters();
        setMenuOpen(false);
    };

    return (
        <div className={`${classes.selectWrapper} ${componentClasses.inputWidth}`}>
            <span className={classes.title}>Date Range</span>
            <TextSearchInput
                id={name}
                placeholder={'Select date range...'}
                value={inputLabel}
                handleClear={handleClear}
                onClick={handleMenuOpen}
                autoComplete={'off'}
                onChange={noop}
            />
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                open={menuOpen}
                sx={{
                    '.MuiPopover-paper': {
                        overflow: 'visible',
                    },
                    '.MuiPopover-root': {
                        overflow: 'visible',
                    },
                }}
            >
                <div className={componentClasses.popoverContentWrapper}>
                    <div className={componentClasses.popoverContent}>
                        <SideMenu<RangeWithCustom>
                            menuOptions={rangeOptions}
                            activeSideMenuOption={activeSideMenuOption}
                            setActiveSideMenuOption={setActiveSideMenuOption}
                            className={componentClasses.sideMenu}
                        />
                        <DateRangeFilterMain
                            activeSideMenuOption={activeSideMenuOption}
                            value={formValue}
                            onChange={onApply}
                            handlePopoverClose={handlePopoverClose}
                            minDate={data?.startProjectDate}
                            maxDate={data?.endProjectDate}
                        />
                    </div>
                </div>
            </Popover>
        </div>
    );
};
