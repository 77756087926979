import classes from 'components/common/Tables/Cells/errorCell.module.scss';
import CopyValue from 'components/common/CopyValue/CopyValue';
import React, { useState } from 'react';
import Icon from 'components/common/Icons/icon';
import { CustomizedRoundIcon } from 'components/common/CustomizedRoundIcon/CustomizedRoundIcon';
import { Popover } from '@mui/material';

export const ErrorCell = ({
    error = 'something went wrong',
    width = 'initial',
}: {
    error?: string;
    width?: string | number;
}) => {
    const [errorDetailsOpen, setErrorDetailsOpen] = useState(false);
    const errorOpenRef = React.useRef(null);
    const errorTooltip = `Error loading file. Please contact support. ${error}`;

    const toggleErrorDetails = () => {
        setErrorDetailsOpen(!errorDetailsOpen);
    };

    const closePopover = () => {
        setErrorDetailsOpen(false);
    };

    return (
        <div className={classes.errorCellContainer}>
            <CopyValue
                trigger={({ copyToClipboard }) => (
                    <div className={classes.inner} style={{ width }}>
                        <div onClick={copyToClipboard} className={classes.trigger}>
                            <div className={classes.icon}>
                                <CustomizedRoundIcon size={'3rem'} backGroundColor={'#ff0000'} hoverColor={'none'}>
                                    <Icon name="warning-icon" size="22" color="#ffffff" />
                                </CustomizedRoundIcon>
                            </div>
                        </div>
                    </div>
                )}
                value={errorTooltip}
                copySuccessMessage={'Error message copied.'}
                onHoverMessage={
                    <div className={classes.errorMessage}>
                        <div className={classes.copyErrorText}>
                            Click the red icon to copy error message to clipboard
                        </div>
                    </div>
                }
            />

            <div ref={errorOpenRef} onClick={toggleErrorDetails} className={classes.errorDetails}>
                Error Details
            </div>

            <Popover
                anchorEl={errorOpenRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock
                onClose={closePopover}
                open={errorDetailsOpen}
            >
                <div className={classes.popoverError}>
                    <div onClick={closePopover} className={classes.closePopoverIcon}>
                        <Icon name="filter-x-icon" size="2.4rem" />
                    </div>
                    <div>{error}</div>
                    <CopyValue
                        trigger={({ copyToClipboard }) => (
                            <div onClick={copyToClipboard} className={classes.popoverErrorCopyValue}>
                                Copy error message to clipboard
                            </div>
                        )}
                        value={errorTooltip}
                        copySuccessMessage={'Error message copied.'}
                        onHoverMessage={'Copy error message to clipboard'}
                    />
                </div>
            </Popover>
        </div>
    );
};
